<div class="page">
    <main class="content">
        <div class="wrap">
            <mat-icon class="svgIcon" [svgIcon]="svgIcon"
                [style]="iconStyle"></mat-icon>
            <div class="title-container">
                <h1>{{title}}</h1>
            </div>

            <div class="message">
                <ng-content></ng-content>
            </div>
        </div>
    </main>
</div>