import { animate, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { InviteApiService, ValidationTokenResponse } from 'src/app/api/services/invite.service';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { ApiObservable } from 'src/app/shared/models/api-observable';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';
@Component({
  selector: 'app-user-confirm-invitation',
  templateUrl: './user-confirm-invitation.component.html',
  styleUrls: ['./user-confirm-invitation.component.scss'],
  animations: [
    trigger('TopToDown', [
      transition('void => *', [
        style({ opacity: '0', transform: 'translateY(-10%)' }),
        animate('1000ms', style({ opacity: '1', transform: 'translateY(0%)' })),

      ])

    ]),
  ]
})
export class ExternalUserConfirmInvitationComponent implements OnInit {

  amount = 6;
  hasError = false;
  errorMessageBody?: string;
  partner: Partner | undefined;

  isLinear = true;
  isEditable = false;

  obs$: ApiObservable<ValidationTokenResponse> | null = null;

  form = this._fb.group({
    0: [undefined, [Validators.required, Validators.minLength(1)]],
    1: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    2: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    3: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    4: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    5: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],

  });

  mfaId?: number;
  document?: string;

  validToken = true;

  constructor(
    private _fb: UntypedFormBuilder,
    private _appPartner: AppPartner,
    private _activatedRoute: ActivatedRoute,
    private _inviteService: InviteApiService,
    private _router: Router,
    private _globalizationService: GlobalizationService
  ) { }

  ngOnInit(): void {
    this._appPartner.getPartner().subscribe(el => this.partner = el);
    this.errorMessageBody = this._globalizationService.translate('EXCEEDED_ATTEMPTS_RE_SEND');

    this._activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      this.mfaId = params.mfaId;
      this.document = params.document;
    });


  }

  submitToken(): void {

    if (!this.mfaId || !this.form.value) {
      return;
    }

    this.disableDefaultForm();


    let token = Object.values(this.form.value).toString();
    token = token.replace(/\s|,/g, '');

    this.obs$ = this._inviteService.confirm({ mfaId: this.mfaId, token });

    this.obs$.pipe(take(1)).subscribe({
      next: ({ optinId, key }) => {

        const navigate = optinId ? '../optin' : '../set-name';

        this._router.navigate([navigate],
          {
            relativeTo: this._activatedRoute,
            queryParams: { optinId, mfaId: this.mfaId, redirect: '../set-name', document: key }, skipLocationChange: true
          });
        
      },
      error: (err) =>{

        this.form.controls[0].setErrors({ attemptsToken: err.error?.errors[0]?.description });

        /*
      Codigo - 901
      Ainda tem tentativas
      */
  
        /*
        Codigo - 602
         O convite informado foi cancelado.
         Para maiores informações procure o responsável pelo convite.
        */
  
        /*
        Codigo - 603
         O prazo de validade de seu convite expirou! Para maiores informações procure o responsável pelo convite.
        */
  
        /*
        Codigo - 604
         Excedeu o número de tentativas de inserir o código enviado.
         Para maiores informações procure o responsável pelo convite.
        */
  
        // this.form.setErrors({ attemptsToken: msg })
        
      },
    })

  }

  unblock() {

    if (!this.form.hasError) {
      return;
    }

    this.form.controls[5].disable();
    this.form.controls[4].disable();
    this.form.controls[3].disable();
    this.form.controls[2].disable();
    this.form.controls[1].disable();
    this.form.controls[0].enable();
    document.getElementById(`inputtoken-0`)?.removeAttribute('disabled');
    document.getElementById(`inputtoken-0`)?.focus();
    this.form.reset();

  }

  private disableDefaultForm(withReset?: boolean) {

    for (let i = 0; i < this.amount; i++) {

      if (withReset)
        this.form.controls[i].reset();

      this.form.controls[i].disable();
      document.getElementById(`inputtoken-${i}`)?.setAttribute(`disabled`, '');

    }


  }

}
