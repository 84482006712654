import { Component, OnInit } from '@angular/core';
import { LoadingOverlayService } from './shared/services/loading-overlay.service';
import { LoadingService } from './api/services/common/loading.service';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loading?: boolean;

  constructor(
    private readonly _loadingOverlayService: LoadingOverlayService,
    private readonly _loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.addAppVersion();

    // Subscribe to loading state changes
    this._loadingService.isLoading().subscribe({
      next: (isLoading) => {
        // Only update if the state has changed
        if (this.loading !== isLoading) {
          isLoading
            ? this._loadingOverlayService.show()
            : this._loadingOverlayService.hide();
          this.loading = isLoading;
        }
      },
    });
  }

  // Add application version to the root element
  private addAppVersion(): void {
    document
      .getElementById('app-root')
      ?.setAttribute('app-version', environment.appVersion);
  }
}
