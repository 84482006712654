export class UserListReq {

    readonly serviceContract: string = '*';
    readonly page: number = 1;
    readonly userType: string = 'external';
    readonly pageSize: number = 10;
    readonly name?: string;
    readonly status?: boolean;
    readonly uaid?: number;
    readonly document?: string;
    readonly profileId?: number | Array<number>;
    readonly userRole?: number;
    readonly memberOf?: string;
    readonly isActive?: boolean;


    constructor(init: Partial<UserListReq>) {
        Object.assign(this, init);
    }
}
export class UserListReqInternal {

    readonly serviceContract?: string;
    readonly page?: number;
    readonly userType: string = 'internal';
    readonly pageSize?: number;
    readonly name?: string;
    readonly status?: boolean;
    readonly uaid: number = 1;
    readonly document?: string;
    readonly profileId?: number | Array<number>;
    readonly userRole?: number;
    readonly memberOf?: string;
    readonly isActive?: boolean;


    constructor(init: Partial<UserListReqInternal>) {
        Object.assign(this, init);
    }
}


export interface UserListResp {
    users: Array<{
        socialName: string;
        name: string;
        profileId: string;
        code: string;
        email: string;
        serviceContracts: Array<{ serviceContractCod: number; serviceContractName: string }>;
        document: number;
        userDomainType: number;
        memberOf?: string;
        active: boolean;
        functional?: string;

    }>;
    resultCount: number; remainingPages: number; page: number;
}
