import { InstitutionCod, PathFromLogo, Partner, ServiceContract, Theme } from '../models';
import { PartnerEnum } from '../partner.enum';

export class Mexico implements Partner {
  readonly theme: Theme = PartnerEnum.Mexico;
  readonly logo: PathFromLogo = PartnerEnum.MexicoLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceMexico;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionMexico;
  readonly primaryColor: string = '#FF6600';
}
