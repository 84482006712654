import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-users-pending-invite-table',
  templateUrl: './users-pending-invite-table.component.html',
  styleUrls: ['./users-pending-invite-table.component.scss']
})
export class UsersPendingInviteTableComponent {


  @Input() pendingInvitesUsers?: any;

  constructor(

  ) { }


}
