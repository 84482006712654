import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';

type status = 'ACTIVE' | 'INACTIVE';

interface ServiceContract {
  name: string;
  serviceContractCode: number;
  serviceContractId: number;
  graphicalInterface: boolean;
  status: status;
  url: string;
}
@Component({
  selector: 'app-modal-add-service-contract',
  templateUrl: './modal-add-service-contract.component.html',
  styleUrls: ['./modal-add-service-contract.component.scss']
})
export class ModalAddServiceContractComponent implements OnInit {


  maxLengthInstitutionCode = 8;

  prefix = 'https://gestaodeacessos.com.br/';

  nameControl = new FormControl('', [Validators.required]);
  serviceContractCodeControl = new FormControl(null, [Validators.required]);
  urlControl = new FormControl('', []);

  serviceContractForm = this._formBuilder.group({
    name: this.nameControl,
    serviceContractCode: this.serviceContractCodeControl,
    url: this.urlControl,
    status: [true],
    graphicalInterface: [false],
    brandName: ['', [Validators.required]],
    urlEmail: ['']

  });

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ModalAddServiceContractComponent, {
      name: string; serviceContractId?: number; serviceContractCode: number; url: string;
      status: string; hasInterface: boolean;
    }>,
    @Inject(MAT_DIALOG_DATA) public data?: ServiceContract,
  ) { }

  ngOnInit(): void {
    if (this.data) {
      const newItem =  {...this.data, url:this.data.url?.replace(this.prefix, ''), status: this.isInstitutionActive()};
      this.serviceContractForm.patchValue(newItem);
    }

  }

  private isInstitutionActive(): boolean {
    return this.data?.status === 'ACTIVE';
  }

  send(): void {
    const newServiceContract = {
      ...this.serviceContractForm.value,
      url: this.serviceContractForm.value.url,
      status: this.serviceContractForm.value.status ? 'ACTIVE' : 'INACTIVE',
      serviceContractCode: Number(this.serviceContractForm.get('serviceContractCode')?.value)
    };
    if (this.data?.serviceContractId) {
      newServiceContract.serviceContractId = this.data.serviceContractId;
    }
    this.dialogRef.close(newServiceContract);
  }

  handleCheckBox($event: MatCheckboxChange) {
    $event.checked ? this.urlControl.setValidators([Validators.required])  : this.urlControl.clearValidators()
    this.urlControl.updateValueAndValidity()
  }

}
