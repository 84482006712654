import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InterfaceServiceContract, ServicesContract } from 'src/app/api/models/institutions-resp';
import { FileUploadComponent } from '../file-upload/file-upload.component';


@Component({
  selector: 'app-modal-graphic-interface',
  templateUrl: './modal-graphic-interface.component.html',
  styleUrls: ['./modal-graphic-interface.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalGraphicInterfaceComponent implements OnInit {

  @ViewChild(FileUploadComponent) fileUpload !: FileUploadComponent;
  public logo?: string | ArrayBuffer | null | undefined;

  public isDefaultGraphical = true;
  public selectButtonsColor?: string;
  public selectLinksColor?: string;
  public selectBackground?: string;

  constructor(
    public dialogRef: MatDialogRef<ModalGraphicInterfaceComponent, {
      name: string; serviceContractId?: number; serviceContractCode: number; url: string;
      status: string; interfaceServiceContract?: InterfaceServiceContract;
    }>,
    @Inject(MAT_DIALOG_DATA) public data: ServicesContract
  ) {
  }

  ngOnInit(): void {
    if (this.data.interfaceServiceContract?.primaryColor && this.data.interfaceServiceContract?.secondaryColor && this.data.interfaceServiceContract?.logo) {
      this.isDefaultGraphical = false;
      this.selectButtonsColor = this.data.interfaceServiceContract?.primaryColor;
      this.selectLinksColor = this.data.interfaceServiceContract?.secondaryColor;
      this.selectBackground = this.data.interfaceServiceContract?.backgroundColor;
      this.logo = this.data.interfaceServiceContract?.logo;
    }
  }


  fileUploaded($event: { file: string | ArrayBuffer; size?: string | undefined }) {
    this.logo = $event?.file;
  }

  selectGraphicalType(graphicType: boolean) {
    this.isDefaultGraphical = graphicType;
  }

  confirm() {

    this.data = {
      ...this.data,
      interfaceServiceContract: {
        backgroundColor: this.isDefaultGraphical ? undefined : this.selectBackground,
        id: this.data.interfaceServiceContract?.id,
        logo: this.isDefaultGraphical ? undefined : this.logo,
        primaryColor: this.isDefaultGraphical ? undefined : this.selectButtonsColor,
        secondaryColor: this.isDefaultGraphical ? undefined : this.selectLinksColor
      }
    };

    this.dialogRef.close(this.data);
  }

  restart() {
    this.fileUpload.clearImage();
    this.selectButtonsColor = undefined;
    this.selectLinksColor = undefined;
    this.selectBackground = undefined;
  }


}
