<div>

  <div #parent
    [ngClass]="{'activeCursorGrab': activeCursorGrab, 'parent': true}"
    (mousedown)="startDragging($event)" (mouseup)="stopDragging($event)"
    (mouseleave)="stopDragging($event)" (mousemove)="moveEvent($event)">

    <!-- Users pending table -->
    <table id="22" aria-label="table" mat-table [dataSource]="dataSource || []"
      class="mat-elevation-z8">


      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th class="column-title" mat-header-cell *matHeaderCellDef> {{'NAME' | translate}}  </th>
        <td (click)="selectedItem(element)" mat-cell *matCellDef="let element">
        
          <span class="cell-information">{{element.name}}</span>
        </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="document">
        <th class="column-title" mat-header-cell *matHeaderCellDef> {{"DOCUMENT" | translate}} </th>
        <td (click)="selectedItem(element)" mat-cell *matCellDef="let element">
      
           <span class="cell-information">{{element.maskedDocument}} </span>
        </td>
      </ng-container>

      <!-- Profile Column -->
      <ng-container matColumnDef="profileName">
        <th class="column-title" mat-header-cell *matHeaderCellDef> {{'PROFILE' | translate}} </th>
        <td (click)="selectedItem(element)" mat-cell *matCellDef="let element">
       
          <span class="cell-information">{{element.profileName | translate}}</span> 
        </td>
      </ng-container>

  
      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th class="column-title" mat-header-cell *matHeaderCellDef>  {{'EMAIL' | translate}} </th>
        <td (click)="selectedItem(element)" mat-cell *matCellDef="let element">
       
          <span class="cell-information">{{element.email}}</span>
        </td>
      </ng-container>
     
      <!-- creatorUserName Column -->
      <ng-container matColumnDef="creatorUserName">
        <th class="column-title" mat-header-cell *matHeaderCellDef> {{'INVITATION_CREATOR' | translate}} </th>
        <td (click)="selectedItem(element)" mat-cell *matCellDef="let element">
          
          <span class="cell-information">{{element.creatorName}}</span>
        </td>
      </ng-container>
      <!-- dateCreated Column -->
      <ng-container matColumnDef="dateCreated">
        <th class="column-title" mat-header-cell *matHeaderCellDef> {{'INVITATION_CREATION_DATE' | translate}}</th>
        <td (click)="selectedItem(element)" mat-cell *matCellDef="let element">
         
          <span class="cell-information">{{element.dateCreated}}</span>
        </td>
      </ng-container>
       <!-- Status Column -->
       <ng-container matColumnDef="status">
        <th class="column-title status" mat-header-cell *matHeaderCellDef> {{'STATUS' | translate}} </th>
        <td (click)="selectedItem(element)" class="status-cell" mat-cell *matCellDef="let element">
        
          <div class="information-container">
            <mat-icon [class]="element.status">circle</mat-icon> 
            <span class="cell-information">{{element.status | translate}}</span> </div>
          
        </td>
      </ng-container>

     
      <tr  mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      

      <!-- Row shown when there is no matching data. -->
      <tr style="text-align: center;" class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8">{{'NO_USERS_FOUND' | translate}}</td>
      </tr>
    </table>

  </div>

  <div class="table-bottom" *ngIf="total_pages && (total_pages> 1) && page">

  
 
      <div style="width: 24px;height: 24px;">
        <mat-icon class="first-page-icon" matTooltip="{{'FIRST_PAGE' | translate}}"  tabindex="0" (click)="changePage(1)"
        *ngIf="page> 1"
        >first_page</mat-icon>
      </div>

      <div style="width: 24px;height: 24px;">
        <mat-icon tabindex="1" matTooltip="{{'PREVIOUS_PAGE' | translate}}"  (click)="changePage(page - 1)"
        *ngIf="page> 1"
        class="arrow-left">chevron_left</mat-icon>
      </div>

      <div style="display: flex;gap:24px">
        <ng-template ngFor let-i="index" let-xb [ngForOf]="counter()">
          <ng-container
            class="page-selection" *ngIf="showLinkNumber(i + 1)">
    
            <span  tabindex="2" (click)="!((i + 1) === page) && changePage(i + 1)"
              [ngClass]="(i + 1) === page
            ?
            'current-page' :
            'another-page'">{{i
              + 1}}</span>
          </ng-container>
    
        </ng-template>
      </div>
    
  
    

      <div style="width: 24px;height: 24px;">
        <mat-icon tabindex="3"  matTooltip="{{'NEXT_PAGE' | translate}}"  (click)="changePage(page + 1)" *ngIf="page != total_pages"
          class="arrow-right">chevron_right</mat-icon>
      </div>
      <div style="width: 24px;height: 24px;">
        <mat-icon class="last-page-icon" *ngIf="page != total_pages" matTooltip="{{'LAST_PAGE' | translate}}"   tabindex="0" (click)="changePage(total_pages)"
        
        >last_page</mat-icon>
      </div>
    
  </div>

</div>