<app-float-menu [showArrowBackButton]="true"></app-float-menu>


<div *ngIf="invitationSentSuccessfully" [@TopToDown]
  class="messageBlockMobile">
  <mat-icon svgIcon="checkbox-circle"></mat-icon>
  <p>
    {{"INVITE_SEND_SUCCESS" | translate}}
  </p>
</div>


<div class="page">



      <app-user-name-authenticate></app-user-name-authenticate>
 

  <main class="content">

    <div class="title">

      <p class="subtitle">{{"INVITE_USER" | translate:
        {channel:partner?.partnerName} }}
      </p>
      <!-- <p class="subtitle-info">{{showMessage()}}</p> -->
    </div>

    <div *ngIf="invitationSentSuccessfully" [@TopToDown]
      class="messageBlock">
      <mat-icon svgIcon="checkbox-circle"></mat-icon>
      <p>
        {{"INVITE_SEND_SUCCESS" | translate}}
      </p>
    </div>

    <div
      class="form-container" [ngClass]="{'form-container-filled':
      filledInvitation, 'form-container-SentSuccess':
      invitationSentSuccessfully}">

      <app-form [EnablePopState]="EnablePopState" [formGroup]="form"
        [ngClass]="{'filledInvitationForm': filledInvitation}">

        <div class="form-invite"
          [ngClass]="{'filledInvitation': filledInvitation}">
          <app-form-control label="{{'NAME_LASTNAME' | translate}}"
            id="name" name="name" type="text" [maxLength]="255">
          </app-form-control>
          <mat-form-field class="selectedUserType" appearance="fill">
            <mat-label>{{"TYPE_USER" | translate}}</mat-label>
            <mat-select id="profileId-box" name="profileId"
              formControlName="profileId">
              <mat-option *ngFor="let item of profileList"
                [value]="item.id" [id]="item.name">
                {{showProfileName(item.id) | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field *ngIf="(!filledInvitation ||
            form.get('document')?.value) && isAlliance"
            class="inputCpforTaxId"
            appearance="fill">
            <mat-label>{{"CPF" | translate}}
            </mat-label>
            <input data-testid="document" matInput type="text"
              mask="000.000.000-00"
              formControlName="document"
              [readonly]="form.get('id')?.value"
              />
            <mat-hint *ngIf="!filledInvitation" align="end">
              <mat-icon style="width: 17px; height: 17px;"
                svgIcon="alert-circle"></mat-icon>
              <span>
                {{"PERSONAL_DATA" | translate}}
              </span>
            </mat-hint>
          </mat-form-field>

          <app-form-control label="{{'EMAIL' | translate}}" id="email"
            name="email" type="email">
          </app-form-control>

            <app-form-control 
            label="{{'TELEPHONE' | translate}}"
            id="phone"
            name="phone"
            type="phone">
          </app-form-control>
          
       
          

          <mat-form-field class="selectLanguageFormField" appearance="fill">
            <mat-label>{{(!filledInvitation? "LANGUAGE" : "LANGUAGE_EMAIL_SMS")
              | translate}}</mat-label>
            <mat-select id="language-invite-select" name="language"
              formControlName="language">
              <mat-option *ngFor="let language of languageList"
                [value]="language.key" [id]="language.key">
                {{language.label | translate}}
              </mat-option>
            </mat-select>
            <mat-hint *ngIf="!filledInvitation" align="end">
              <mat-icon style="width: 17px; height: 17px;"
                svgIcon="alert-circle"></mat-icon>
              <span style="text-align: justify;">
                {{"DEFINE_WHICH_LANGUAGE" | translate}}
              </span>
            </mat-hint>
          </mat-form-field>

          <mat-form-field *ngIf="invitationSentSuccessfully"
            appearance="fill">
            <mat-label>{{"CODE_INVITE" | translate}}

            </mat-label>
            <input disabled matInput
              value="******" />
          </mat-form-field>

          <mat-form-field class="invitation-validity"
            *ngIf="invitationSentSuccessfully"
            appearance="fill">
            <mat-label>{{"VALIDITY_INVITE" | translate}}
            </mat-label>
            <input disabled matInput
              [value]="invitationValidity" />
          </mat-form-field>


        </div>
        <div class="actions">
          <div>

            <mat-divider [inset]="true"></mat-divider>
          </div>
          <div class="buttons">
            <button
              *ngIf="!invitationSentSuccessfully"
              mat-icon-button>
              <mat-icon svgIcon="edit"
                (click)="editForm()"></mat-icon>
            </button>
            <!-- <button mat-icon-button>
              <mat-icon (click)="deleteForm()" svgIcon="trash"></mat-icon>
            </button> -->
          </div>

        </div>

      </app-form>



    </div>


    <div class="footer-container">


      <mat-divider class="hidden-mobile" [inset]="true"></mat-divider>
      <div class="footer-button">

        <button
          *ngIf="!invitationSentSuccessfully"
          class="conclude-button" (click)="backToPreviousPage()"
          mat-stroked-button>
          {{"CANCEL_INVITATION" | translate}}
        </button>


        <button *ngIf="!invitationSentSuccessfully; else
          showConcludeButton"
          data-testid="continue-button"
          type="button" class="continue-button" mat-raised-button
          color="primary"
          [disabled]="form.invalid" (click)="filledInvitation ?
          submit() : confirmInvitation()">{{(filledInvitation ?
          'SEND_INVITATION' : 'CONTINUE') |
          translate}}

          <mat-icon>arrow_forward</mat-icon>

        </button>
        <ng-template #showConcludeButton>
          <button mat-stroked-button
            class="conclude-button"
            (click)="returnToPageUserList()">
            {{concludeMessage}}

          </button>
        </ng-template>


      </div>
    </div>

  </main>
</div>
