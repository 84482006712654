import { Component, OnDestroy, OnInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserApiService } from 'src/app/api/services/user-api.service';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';

@Component({
  selector: 'app-external-user-name',
  templateUrl: './external-user-name.component.html',
  styleUrls: ['./external-user-name.component.scss']
})
export class ExternalUserNameComponent implements OnInit, OnDestroy, AfterContentChecked {

  currentStep = 1;
  maxLength = 80;

  isLinear = true;
  isEditable = false;

  partner: Partner | undefined;

  EnablePopState = false;

  form = this._formBuilder.group({
    name: ['', [Validators.required, Validators.maxLength(this.maxLength)]]
  });

  state: {
    document?: string;
    mfaId?: number;
    password?: string;
    name?: string;
    optinId?: number;
    optinText?: string;
    redirect?: string;
    socialName?: string;
  } = {};

  private subscription: Subscription = new Subscription();

  constructor(
    private _appPartner: AppPartner,
    private _router: Router,
    private _userApi: UserApiService,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private _ref: ChangeDetectorRef
  ) { }

  ngAfterContentChecked() {
    this._ref.detectChanges();
  }

  ngOnInit(): void {
    this._appPartner.getPartner().subscribe(el => this.partner = el);

    this._activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.state = { ...params };
      if (this.state?.name) {
        this.form.setValue({ name: this.state.name });
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  createUser() {


    const socialName = this.form.value.name || this.state.name;
    this.state.socialName = socialName;
    this._router.navigate(['../set-password'], { relativeTo: this._activatedRoute, queryParams: this.state, skipLocationChange: true });


  }

}
