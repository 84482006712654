import { Component, Input, OnInit } from '@angular/core';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';

@Component({
  selector: 'app-login-prototype',
  templateUrl: './login-prototype.component.html',
  styleUrls: ['./login-prototype.component.scss']
})
export class LoginPrototypeComponent implements OnInit {

  @Input() set isDefaultGraphical(isDefault: boolean) {
    if(isDefault) {
      this._appPartner.getPartner().subscribe(el => this._partner = el);
    }
    this._isDefaultGraphical = isDefault;
  };

  @Input() set logo(logo: string | ArrayBuffer | null | undefined) {
      this._logo = logo;
  };

  @Input() set buttonsColor(color: string | undefined) {
    if(!color) {
       this._buttonsColor = '#C4C4C4';
    } else {
      this._buttonsColor = color;
    }
  }

  @Input() set linksColor(color: string | undefined) {
    if(!color) {
       this._linksColor = '#C4C4C4';
    } else {
      this._linksColor = color;
    }
  }

  @Input() set selectBackground(color: string | undefined) {
    if(!color) {
       this._selectBackground = 'white';
    } else {
      this._selectBackground = color;
    }
  }

  get isDefaultGraphical(): boolean {
    return this._isDefaultGraphical;
  }
  get partner(): Partner | undefined {
    return this._partner;
  }

  get logo(): string | ArrayBuffer | null | undefined{
    return this._logo;
  }

  get buttonsColor(): string | undefined {
    return this._buttonsColor;
  }

  get linksColor(): string | undefined {
    return this._linksColor;
  }

  get selectBackground(): string | undefined {
    return this._selectBackground;
  }

  private _isDefaultGraphical !: boolean;
  private _logo !: string | ArrayBuffer | null | undefined ;
  private _partner: Partner | undefined;
  private _buttonsColor?: string;
  private _linksColor?: string;
  private _selectBackground !: string | undefined;

  constructor(
   private _appPartner: AppPartner
  ) { }

  ngOnInit(): void {

  }

}
