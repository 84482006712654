import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { GlobalizationService } from '../../globalization/services/globalization.service';

import { ToastrService } from 'ngx-toastr';
import { ApiObservable } from '../models/api-observable';

import { Authenticate } from 'src/app/api/models/authentication-resp';
import { RenewalApiService } from 'src/app/api/services/renewal.service';

export declare type FormControlType = 'text';

@Component({
  selector: 'app-form-token-group-expired-password',
  templateUrl: './form-token-group-expired-password.component.html',
  styleUrls: ['./form-token-group-expired-password.component.scss']
})
export class FormTokenGroupExpiredPasswordComponent implements OnInit, OnDestroy {

  @Input() auth_data!: Authenticate;
  @Input() title: string = this.globalizationService.translate('FIRST_ACCESS_TOKEN_TITTLE');
  @Input() weSentResent: string = this.globalizationService.translate('WE_SENT');
  @Input() subTitle: string = this.globalizationService.translate('FIRST_ACCESS_TOKEN_SUB_TITTLE');
  @Input() userRealEmail!: string;
  @Output() tokenSuccess: EventEmitter<{ firstName: string; lastName: string }> = new EventEmitter();



  amount = 6;


  invalidToken = false;
  exceededAttempts = false;
  blockedUser = false;
  token_expired = false;
  attempts = true;
  userEmail?: string | null;
  blockedUserTime = '';

  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;


  Totalmilliseconds!: number;

  public timeDifference!: number;
  public secondsToDday!: number;
  public minutesToDday!: number;
  public hoursTotal!: number;

  form = this._fb.group({
    0: [undefined, [Validators.required, Validators.minLength(1)]],
    1: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    2: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    3: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    4: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    5: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],

  });

  obs$: ApiObservable<{
    accessToken: string; refresh_token: string; scope: string;
    id_token: string; token_type: string; expires_in: string;
  }> | null = null;

  private subscription!: Subscription;

  constructor(private _fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private globalizationService: GlobalizationService,
    private renewalApiService: RenewalApiService,
    private router: Router) {

  }

  ngOnInit(): void {

    this.userEmail = this.auth_data.email;
    this.Totalmilliseconds = this.auth_data.expiresMilliseconds;

    if ((this.Totalmilliseconds && isNaN(this.Totalmilliseconds) || !this.userEmail)) {
      this.router.navigateByUrl('');
    }

    this.subscription = interval(1000)
      .subscribe((milliseconds) => {
        this.getTimeDifference(milliseconds);
      });

  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  submitToken(): void {

    this.disableDefaultForm();

    let token = Object.values(this.form.value).toString();
    token = token.replace(/\s|,/g, '');


    this.renewalApiService.renewalValidTokenPassword({ mfaId: this.auth_data.mfaId, tokenValue: token }).pipe(take(1)).subscribe((data) => {
      this.tokenSuccess.emit(data);
    }, (err) => {

      this.tokenInvalid({ msg: err.error?.errors[0]?.description, code: err.error?.errors[0]?.code });

    });


  }


  unblock() {


    if (this.exceededAttempts) {
      return;
    }

    this.form.controls[5].disable();
    this.form.controls[4].disable();
    this.form.controls[3].disable();
    this.form.controls[2].disable();
    this.form.controls[1].disable();
    this.form.controls[0].enable();
    document.getElementById(`inputtoken-0`)?.removeAttribute('disabled');
    document.getElementById(`inputtoken-0`)?.focus();
    this.form.reset();
    this.invalidToken = false;
  }

  reSendToken(): void {
    this.renewalApiService.generateMfaToRenewPassword({ email: this.userRealEmail }).pipe(take(1)).subscribe((data) => {

      this.weSentResent = this.globalizationService.translate('WE_RESENT');
      this.subscription.unsubscribe();

      this.blockedUser = false;
      this.exceededAttempts = false;
      this.invalidToken = false;
      this.token_expired = false;
      this.toastr.clear();
      this.toastr.success(this.globalizationService.translate('CODE_SENT'), '',
        { positionClass: 'toast-top-center', toastClass: 'ngx-toastr form-token-group-toast' });

      this.Totalmilliseconds = data.expiresMilliseconds;

      this.form.reset();
      this.unblock();
      this.subscription = interval(1000)
        .subscribe((milliseconds) => { this.getTimeDifference(milliseconds); });


    });
  }


  private getTimeDifference(milliseconds: number) {
    this.timeDifference = this.Totalmilliseconds - (milliseconds * 1000);
    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits(timeDifference: number) {

    const sec = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    const min = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    const hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute)
      % this.hoursInADay);
    const daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour
      * this.SecondsInAMinute * this.hoursInADay));
    const hoursTotal = (daysToDday * 24) + hoursToDday;

    if (this.endOfTime(min, sec) && (hoursTotal < 1)) {

      this.token_expired = true;
      this.form.disable();
      this.subscription.unsubscribe();
      this.toastr.error(this.globalizationService.translate('CODE_EXPIRED'), '',
        { positionClass: 'toast-top-center', disableTimeOut: true, toastClass: 'ngx-toastr form-token-group-toast-error' });
    } else {
      this.secondsToDday = sec;
      this.minutesToDday = min;
      this.hoursTotal = hoursTotal;
    }
  }

  private endOfTime(min: number, sec: number): boolean {
    return (sec + (min * 60)) > 0 ? false : true;
  }

  private userblocked(): void {
    this.toastr.clear();
    this.disableDefaultForm();
    this.blockedUser = true;

    this.toastr.error(this.globalizationService.translate('REGISTRATION_BLOCKED'), '',
      { positionClass: 'toast-top-center', toastClass: 'ngx-toastr form-token-group-toast-error-user-blocked', disableTimeOut: true });

  }

  private disableDefaultForm(withReset?: boolean) {

    for (let i = 0; i < this.amount; i++) {

      if (withReset)
        this.form.controls[i].reset();

      this.form.controls[i].disable();
      document.getElementById(`inputtoken-${i}`)?.setAttribute(`disabled`, '');

    }


  }

  private tokenInvalid({ msg, code }: { msg: string; code: number }): void {



    // Usuário errou o codigo mas ainda tem tentativas - OK
    if (code === 602) {

      this.invalidToken = true;
      this.form.controls[0].setErrors({ attemptsToken: msg });
    }

    // Token expirado
    if (code === 603) {

      this.token_expired = true;
      this.disableDefaultForm();
      this.toastr.error(msg, '',
        { positionClass: 'toast-top-center', disableTimeOut: true, toastClass: 'ngx-toastr form-token-group-toast-error' });
    }

    // Usuário excedeu as tentativas
    if (code === 604) {

      this.disableDefaultForm();
      this.exceededAttempts = true;
      this.toastr.error(msg, '',
        { positionClass: 'toast-top-center', disableTimeOut: true, toastClass: 'ngx-toastr form-token-group-toast-error' });
    }
    // Usuário foi bloqueado por 30 minutos
    if (code === 605) {

      this.disableDefaultForm();
      this.exceededAttempts = true;
      this.toastr.error(msg, '',
        { positionClass: 'toast-top-center', disableTimeOut: true, toastClass: 'ngx-toastr form-token-group-toast-error' });
    }

    if (code === 500) {
      this.disableDefaultForm();
      this.toastr.error(msg, '',
        { positionClass: 'toast-top-center', disableTimeOut: true, toastClass: 'ngx-toastr form-token-group-toast-error' });
    }

    // Usuário bloqueado
    // if(code === 602) {
    //   this.invalidToken = true;
    //   this.exceededAttempts = true;
    //   this.disableDefaultForm();
    // }

    // if (attempts) {
    //   this.attempts = attempts;

    //   this.disableDefaultForm(true);

    //   this.form.controls[0].setErrors({ attemptsToken: '42' });


    //   this.invalidToken = true;

    // } else {

    //   this.invalidToken = true;
    //   this.exceededAttempts = true;
    //   this.attempts = 0;
    //   this.disableDefaultForm();
    //   this.toastr.error(this.globalizationService.translate('EXCEEDED_NUMBER_ATTEMPTS'), '',
    //     { positionClass: 'toast-top-center', disableTimeOut: true });
    // }

  }

}
