import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { GlobalizationService } from '../globalization/services/globalization.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FallBackLanguageResolver {
  constructor(private _globalizationService: GlobalizationService) {}

  // Resolve method that is called when the route is activated
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    // Get the current language from the GlobalizationService
    const currentLanguage = this._globalizationService.currentLanguage;

    // Set the temporary language using the current language code
    return this._globalizationService.setTemporaryLanguage(
      currentLanguage.value
    );
  }
}
