import { Injectable } from '@angular/core';
import { ApiObservable } from 'src/app/shared/models/api-observable';
import { PartnerGuardModule } from './partner-guard.module';
import { ApiService } from '../api/services/common/api.service';


export interface PartnerAutomation {
  institutionCod: string,
  serviceContractCod: number,
  channelType?: string,
  logo?: string,
  primaryColor?: string,
  institutionName?: string,
  secondaryColor?: string,
  backgroundColor?: string,
  language?:string,
  channelClientId: string
}

@Injectable({
  providedIn: PartnerGuardModule
})
export class ServiceContractService {

  constructor(
    private api: ApiService
  ) { }

  getPartnerInfo(partner: string): ApiObservable<PartnerAutomation> {
    return this.api.get<PartnerAutomation>(`/v1/public/service-contracts/${partner}`);
  }
}
