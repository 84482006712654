import { ServiceContract, Partner, Theme, InstitutionCod, PathFromLogo } from '../models';
import { PartnerEnum } from '../partner.enum';

export class Bin implements Partner {
  readonly theme: Theme = PartnerEnum.Bin;
  readonly logo: PathFromLogo = PartnerEnum.BinLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceBin;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionBin;
  readonly primaryColor: string = '#FF6600';
}
