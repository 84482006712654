import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { GroupApiService } from 'src/app/api/services/group-api.service';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ValidatorsUtils } from 'src/app/shared/validators/validators.utils';
import { Subscription, Observable } from 'rxjs';
import {  take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { ModalConfirmationComponent } from 'src/app/shared/modal-confirmation/modal-confirmation.component';
import { AuthApiService } from 'src/app/api/services/auth-api.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { UserApiService } from 'src/app/api/services/user-api.service';
import { ModalConfirmationInvitationComponent } from 'src/app/shared/modal-confirmation-invitation/modal-confirmation-invitation.component';
import { ProfilesInfo } from 'src/app/api/models/profiles-resp';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { InviteApiService } from 'src/app/api/services/invite.service';
import { ChannelApiService } from 'src/app/api/services/channel-api.service';
import { PartnerEnum } from 'src/partner/partner.enum';
import { InstitutionsApiService } from 'src/app/api/services/institutions-api.service';


@Component({
  selector: 'app-user-invite',
  templateUrl: './user-invite.component.html',
  styleUrls: ['./user-invite.component.scss'],
  animations: [
    trigger('TopToDown', [
      transition('void => *', [
        style({ opacity: '0', transform: 'translateY(-10%)' }),
        animate('1000ms', style({ opacity: '1', transform: 'translateY(0%)' })),

      ])

    ]),
  ]
})
export class UserInviteComponent implements OnInit, OnDestroy {

  channel$?: Observable<string>;
  partner: Partner | undefined;
  // channelName?: string;
  invitationValidity?: string;

  EnablePopState = false;

  dateExpired?: string;

  languageList = [
    { key: 'pt-BR' , label: 'LANGUAGES.PORTUGUESE' },
    { key: 'en-US', label: 'LANGUAGES.ENGLISH' },
    { key: 'es', label: 'LANGUAGES.SPANISH' }
  ];

  scotiaMaxPhoneLength = 15;

  form = this._formBuilder.group({
    name: ['', [Validators.required, ValidatorsUtils.noWhitespaceValidator(), ValidatorsUtils.compositeNameValidator()]],
    profileId: ['', [Validators.required]],
    document: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', [Validators.required]],
    language: ['', [Validators.required]]
  });

  emailSuffix?: string | null;
  isAlliance:boolean = false;


  private _profileList?: ProfilesInfo[];
  private _filledInvitation = false;
  private _invitationSentSuccessfully = false;
  private _invitationMfaId?: number;

  private subscription: Subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    public location: Location,
    private _appPartner: AppPartner,
    private _activatedRoute: ActivatedRoute,
    private _groupApi: GroupApiService,
    private _channelApiService: ChannelApiService,
    private _authService: AuthApiService,
    private _formBuilder: UntypedFormBuilder,
    private _userApi: UserApiService,
    private _inviteService: InviteApiService,
    private _router: Router,
    private _globalizationService: GlobalizationService,
    private _institutionsService: InstitutionsApiService,
  ) { }

  get concludeMessage() {
    return window.innerWidth > 556 ? this._globalizationService.translate('CLOSE') :
      this._globalizationService.translate('BACK_TO_THE_HOME');
  }

  get invitationSentSuccessfully() {
    return this._invitationSentSuccessfully;
  }

  set invitationSentSuccessfully(isInvitationSent) {
    this._invitationSentSuccessfully = isInvitationSent;
  }




  get invitationMfaId() {
    return this._invitationMfaId;
  }

  set invitationMfaId(mfaId) {
    this._invitationMfaId = mfaId;
  }



  get filledInvitation() {
    return this._filledInvitation;
  }

  set filledInvitation(isFilled) {
    this._filledInvitation = isFilled;
  }


  get profileList() {
    return this._profileList;
  }

  set profileList(list) {
    this._profileList = list;
  }




  @HostListener('window:beforeunload')
  onReload() {

    return !this.filledInvitation;
  }

  @HostListener('window:popstate')
  onPopState() {
    if (this.filledInvitation) {


      const dialogRef = this.dialog.open(ModalConfirmationInvitationComponent, {
        width: '320px',
        height: '300px',
        data: {
          title: this._globalizationService.translate('WANT_TO_CANCEL_THE_SENT_INVITATION'),
          description: this._globalizationService.translate('WANT_TO_CANCEL_THE_SENT_INVITATION_SUB')
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {

          this._router.navigate(['../'], { relativeTo: this._activatedRoute });
        }

      });

    } else {

      this._router.navigate(['../'], { relativeTo: this._activatedRoute });
    }

  }

  ngOnInit(): void {
    this.channel$ = this._channelApiService.channel$;

    this._appPartner.getPartner().subscribe(el => this.partner = el);

    const defaultLanguage = this.partner?.language;

    if(defaultLanguage && this.languageList.some(l => l.key === defaultLanguage)) {
      this.form.patchValue({language: defaultLanguage})
    }

    this.isAlliance = this._channelApiService.isAlliance()
    this.subscription.add(this._groupApi.getProfile().pipe(take(1)).subscribe({
      next: ({profiles}) => {
        // Remove a criação de perfil admin quando for usuário Aliança
        if(this.isAlliance) {
          this.scotiaMaxPhoneLength = 11;
          this.profileList =  profiles.filter(e => e.name !== 'ADMINISTRADOR_ALLIANCE');
        } else {
          this.profileList =  profiles;
        }
      },
    }));


    if (!this.isAlliance) {
      this.form.get('document')?.clearValidators();
      this.form.get('document')?.updateValueAndValidity();
    }

    this.subscription.add(this._institutionsService.getInstitution()
      .subscribe(institutionInformation => {
        this.emailSuffix = institutionInformation.emailSuffix;
        this.form.get('email')?.addValidators(ValidatorsUtils.emailSuffixValidator(this.emailSuffix));
        this.form.get('email')?.updateValueAndValidity();
      }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  submit(): void {
    this.subscription.add(this._userApi.userInvitation({
      ...this.form.value,
      name: this.form.get('name')?.value.trim(),
      serviceContracts: [parseInt(this.partner?.serviceContract ?? '0', 10)],
      institutionCod: this.partner?.institutionCod
    }).pipe(take(1)).subscribe(response => {
      this.invitationSentSuccessfully = true;
      this.dateExpired = new Date(response.expiresAt).toLocaleString(this._globalizationService.currentLanguage.value);
      this.invitationValidity = this.dateExpired;
      this.invitationMfaId = response.mfaId;

    }));
  }

  confirmInvitation(): void {
    this.form.disable();
    this.filledInvitation = true;
  }

  editForm(): void {
    this.form.enable();
    this.filledInvitation = false;
  }

  showMessage(): string {
    return this.filledInvitation ? this._globalizationService.translate('CHECK_INVITATION') :
      this._globalizationService.translate('FILL_IN_THE_FIELD');
  }

  deleteForm(): void {
    if (!this.invitationSentSuccessfully) {
      const dialogRef = this.dialog.open(ModalConfirmationInvitationComponent, {
        width: '320px',
        height: '300px',
        data: {

          title: this._globalizationService.translate(
            'ARE_SURE_WANT_DISCONTINUE_REGISTRATION') + '?',
          description: this._globalizationService.translate(
            'ARE_SURE_WANT_DISCONTINUE_REGISTRATION_SUB') + '.'
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {

          this._router.navigate(['../'], { relativeTo: this._activatedRoute });
        }

      });

    } else {
      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        width: '320px',
        height: '288px',
        data: {
          title: this._globalizationService.translate('WANT_TO_CANCEL_THE_SENT_INVITATION'),
          description: this._globalizationService.translate('WANT_TO_CANCEL_THE_SENT_INVITATION_SUB')
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result && this.invitationMfaId && this.invitationSentSuccessfully) {
          this.subscription.add(this._inviteService.deleteInvite({ mfaId: this.invitationMfaId })
            .subscribe(() => this.returnToPageUserList()));
        }

      });
    }
  }

  showProfileName(p: number): string {


    if (!this.profileList || !p) {
      return '';
    }
    return this.profileList?.filter(el => el.id === p)[0].name;
  }

  returnToPageUserList(): void {
    this.location.back();
    // this._router.navigate(['../pending'], { relativeTo: this._activatedRoute });
  }


  getNameUser(): string {
    return this._authService.userDetails.name;
  }

  backToPreviousPage(): void {

    if (this.form.dirty) {
      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        width: '320px',
        height: '288px',
        panelClass: 'interrupt-registration',
        data: {
          title: this._globalizationService.translate('WANT_TO_INTERRUPT_REGISTRATION'),
          description: this._globalizationService.translate('WANT_TO_INTERRUPT_REGISTRATION_SUB'),
          yesText: this._globalizationService.translate('YES_INTERRUPT')
        }
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          this.returnToPageUserList();
        }

      });
    } else {
      this.returnToPageUserList();
    }
  }



  // showLanguageName(name: string): string {
  //   if (name === 'pt-BR') {
  //     return this._globalizationService.translate('LANGUAGES.PORTUGUESE');
  //   } else if (name === 'en-US') {
  //     return this._globalizationService.translate('LANGUAGES.ENGLISH');
  //   } else {
  //     return this._globalizationService.translate('LANGUAGES.SPANISH');
  //   }
  // }

  // partnerDoNotHaveCpf(): boolean {
    // return this.isAlliance
    // const partner = this._activatedRoute.snapshot.paramMap.get('partner');
    // const latamOrCaribe = [PartnerEnum.Scotia, PartnerEnum.ScotiaChile, PartnerEnum.Butterfield, PartnerEnum.Mexico] as Array<string>;
    // return (partner && latamOrCaribe.includes(partner)) || false;
  // }


}
