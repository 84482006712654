<div class="content-modal">
    <div class="header-modal">
        <h2 mat-dialog-title>{{data.title}}</h2>
    </div>
    <div mat-dialog-content>
        <p>{{data.description}}</p>
    </div>
    <div *ngIf="data.reverseOrderOfActionButtons; else elseBlock"
        class="controls-modal">

        <button mat-button (click)="onNoClick()" cdkFocusInitial>{{data.noText ?
            data.noText : 'NO_DELETE' | translate}}</button>
        <button mat-button (click)="onYesClick()">{{data.yesText ? data.yesText
            :
            'YES_CANCEL' | translate}}</button>
    </div>
    <ng-template #elseBlock>
        <div class="controls-modal">
            <button mat-button (click)="onYesClick()">{{data.yesText ?
                data.yesText
                :
                'YES_CANCEL' | translate}}</button>
            <button mat-button (click)="onNoClick()" cdkFocusInitial>{{data.noText
                ?
                data.noText : 'NO_DELETE' | translate}}</button>
        </div>

    </ng-template>

</div>