export const environment = {
    production: false,
    channelClientIdInternal: 'a129168c10a50a4ecafc5e8dab0af20025efdb72',
    channelClientIdExternal: 'Yt93613a5337a10bffc3dc183bb7f490cb29c929',
    channelClientIdLatamAlliance: 'cfwyoJK2Km27crd0iznxe0suz0AYs7KKZC8VYcgY',
    api: {
        baseUrl: 'https://cat.api.firstdata.com/bwa/access-management-api-qa',
        apiKey: 'SGBkJGe5ewkDU6RPWAfCvPhkyvqZ2NSO'
    },
    appVersion: require('../../package.json').version + '.28.11.2024-qa',
};
