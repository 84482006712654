import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input,Output, EventEmitter, ViewChild, ElementRef, HostListener,
  AfterViewInit, AfterContentInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import {  Router } from '@angular/router';
import { InvitedUser } from 'src/app/api/models/invite';
import { ProfilesInfo } from 'src/app/api/models/profiles-resp';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { AppPartner } from 'src/partner/partner.service';


export interface Filters {
  creatorName: string;
  name: string;
  username: string;
  dateCreated: string;
  profile: string;
  email: string;
  serviceContract: string | Array<string>;
}
interface filterForm {
  creatorName: FormControl<string>;
  name: FormControl<string>;
  userName: FormControl<string>;
  dateCreated: FormControl<string>;
  profile: FormControl<string[]>;
}


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements AfterContentInit,AfterViewInit,OnChanges {


  @Input() list?: InvitedUser[];
  @Input() link: [k: string] = ['/..'];

  @Input() page?: number;
  @Input() total_pages?: number;
  @Input() totalElements?: number;
  @Output() selected = new EventEmitter<InvitedUser>();

  @Input() filtersActive?: Partial<Filters>

  @Output() selectedPage = new EventEmitter<{page: number; filters: Partial<Filters> | undefined}>();


  filtersLoaded: Partial<Filters> | undefined;


  formFilter: FormGroup<filterForm> = this._fb.group({
    creatorName: ['', []],
    name: ['', []],
    userName: ['', []],
    dateCreated: ['', []],
    profile: [[''], []]
  });

  // checkbox-button
  // isAllListSelect = false;


  // Profiles
  @Input() profileList?: ProfilesInfo[];


  // table column and data
  displayedColumns: string[] = ['name','email', 'profileName', 'creatorUserName', 'dateCreated', 'status'];
  // table data
  dataSource?: MatTableDataSource<InvitedUser>;
  // Selected item (checkbox)
  selection = new SelectionModel<InvitedUser>(true, []);
  // Mouse event (drag and drop with scroll)
  mouseDown = false;
  startX: any;
  scrollLeft: any;
  activeCursorGrab = false;
  drag = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const element = document.querySelector('.parent') as HTMLElement;
    if (element?.scrollWidth > element?.clientWidth) {
      this.activeCursorGrab = true;
    } else {
      this.activeCursorGrab = false;
    }
  }
  @HostListener('mousemove', ['$event'])
  mouseup(event: Event): void {
   this.drag = true;
  }
  @HostListener('mousedown', ['$event'])
  mousedown(event: Event): void {
    this.drag = false;
  }

  @ViewChild('parent', {static: true}) slider!: ElementRef;



  constructor(
    private _router: Router,
    private _appPartner: AppPartner,
    private _fb: UntypedFormBuilder,
    private _globalizationService: GlobalizationService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}


  ngOnChanges(changes: SimpleChanges) {

    if(changes.list?.currentValue && !changes.list.isFirstChange()) {
      this.dataSource = new MatTableDataSource(this.list);
      this.selection.clear();
    }
    if(changes.filtersActive?.currentValue) {
      const { profile, creatorName, name, userName, dateCreated } = changes.filtersActive.currentValue

      const userProfile = typeof profile === 'string' ? profile.split(','): profile

      this.formFilter.patchValue({profile: userProfile, creatorName, name, userName, dateCreated});
      this.filtersLoaded = Object.fromEntries(Object
        .entries(changes.filtersActive.currentValue)
        .filter(([_, v]) => v != ''));
      this.formFilter.markAsDirty();
    }
  }


  ngAfterViewInit(): void {
    //Ativa o mouse, para arrastar a tabela, caso o scroll do conteiner esteja ativo.
    const element = document.querySelector('.parent') as HTMLElement;
    if (element?.scrollWidth > element?.clientWidth) {
      this.activeCursorGrab = true;
    }
    // detecta a mudança
    this._changeDetectorRef.detectChanges();
  }


  selectedItem(element: InvitedUser){

    if(!this.drag) {
      this.selected.emit(element);
    }
  }

  ngAfterContentInit(): void {
    this.dataSource = new MatTableDataSource(this.list);
  }

  onSubmit() {

    this.filtersLoaded = Object.fromEntries(Object
      .entries(this.formFilter.value)
      .filter(([_, v]) => (v != '') || (v != undefined)));
    this.changePage(1);
  }

  backToHomePage() {
    const isFiserv = this._appPartner.getPartnerValue()?.partnerName === 'fiserv';
    const urlIncludesFiserv =  window.location.pathname.includes('fiserv');
    const shouldNotPutFiservInUrl = isFiserv && !urlIncludesFiserv;
    this._router.navigate([`${shouldNotPutFiservInUrl ? '/': this._appPartner.getPartnerValue()?.partnerName}`]);
  }

  buttonDeleteInviteText(amount: number): string {

    const multi: boolean = amount > 1;
    const text = multi  ?
    this._globalizationService.translate('DELETE_SELECTED_INVITATIONS_MULTI' ,{amount}):
    this._globalizationService.translate('DELETE_SELECTED_INVITATIONS' ,{amount});
    return text;
  }
  buttonResendInviteText(amount: number): string {
    const multi: boolean = amount > 1;
    const text = multi  ?
    this._globalizationService.translate('RESEND_SELECTED_INVITATIONS_MULTI' ,{amount}):
    this._globalizationService.translate('RESEND_SELECTED_INVITATIONS' ,{amount});
    return text;
  }


  clearFilter() {
    this.filtersLoaded = undefined;
    this.selection.clear();
    this.formFilter.reset();
    this.changePage(1);
  }

  changePage(page: number) {

      this.selectedPage.emit({page, filters: this.filtersLoaded});
  }
    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource?.data.length;
      return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
      if (this.isAllSelected()) {
        this.selection.clear();
        return;
      }
      if(this.dataSource) {
        this.selection.select(...this.dataSource.data);
      }
    }

    isCheckboxChecked(invite: InvitedUser): boolean {
    return this.selection.selected.some(u => u.document === invite.document);
    }


  startDragging(e: MouseEvent ) {
    this.mouseDown = true;
    this.startX = e.pageX - this.slider.nativeElement.offsetLeft;
    this.scrollLeft = this.slider.nativeElement.scrollLeft;
  }
  stopDragging(e: MouseEvent ) {
    this.mouseDown = false;
  }
  moveEvent(e: MouseEvent ) {
    e.preventDefault();


    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - this.slider.nativeElement.offsetLeft;
    const scroll = x - this.startX;
    this.slider.nativeElement.scrollLeft = this.scrollLeft - scroll;
  }

  // Bottom of table
  counter() {
    if (!this.page) return;
    if (this.page < 7) return new Array(10);
    return new Array(this.page + 4);
  }


  showLinkNumber(index: number) {
    const pagesAfterAndBefore = window.screen.width > 700 ? 3 : 2;

    if (this.page) {
      if (this.page + pagesAfterAndBefore < index) {
        return false;
      }
      if (this.page - pagesAfterAndBefore > index) {
        return false;
      }
    }

    if (this.total_pages) {
      if (index > this.total_pages) {
        return false;
      }
    }

    return true;
  }



}

export interface List {
  document?: string;
  description?: string;
  total_users?: number;
  name?: string;
  cpf?: string;
  email?: string;
  status: string;

}
