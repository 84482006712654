import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { FinishProcessWithRedirectResp } from 'src/app/api/models/finish-process-with-redirect-resp';
import { RenewalApiService } from 'src/app/api/services/renewal.service';
import { UserApiService } from 'src/app/api/services/user-api.service';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';

@Component({
  selector: 'app-user-forgot-password',
  templateUrl: './user-forgot-password.component.html',
  styleUrls: ['./user-forgot-password.component.scss']
})
export class UserForgotPasswordComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('stepper') stepper: MatStepper | undefined;

  isLinear = true;
  isEditable = false;

  partner: Partner | undefined;

  EnablePopState = false;

  email = '';
  first_name = '';
  last_name = '';
  showInputPasswordForm = false;
  auth_data: any;

  client_id?: string;
  redirect_uri?: string;
  to_login = true;
  state: {
    redirect_uri?: string;
    client_id?: string;
    user_name?: string;
  } = {};

  form = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.email])]
  });

  formPassword = this.formBuilder.group({
    password: ['', [Validators.required]],
  });

  private subscription: Subscription = new Subscription();

  constructor(
    private _appPartner: AppPartner,
    private renewalApiService: RenewalApiService,
    private userService: UserApiService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this._appPartner.getPartner().subscribe(el => this.partner = el);

    this.route.queryParams.subscribe(params => {

      const { client_id, redirect_uri, user_name } = params;

      if (user_name) {
        this.form.controls.email.setValue(user_name);
      }

      this.client_id = client_id;
      this.redirect_uri = redirect_uri;
    });
  }

  ngAfterViewInit() {
    if (this.stepper) {
      this.stepper._getIndicatorType = () => 'number';
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  authCompleted({ firstName, lastName }: {
    firstName: string;
    lastName: string;
  }, stepper: MatStepper): void {
    this.first_name = firstName;
    this.last_name = lastName;
    stepper.next();
    this.showInputPasswordForm = true;
  }

  submit(stepper: MatStepper) {

    const bodyRequest: {
      email: string;
      channelClientId?: string;
      redirectUri?: string;
    } = { email: this.form.value.email, channelClientId: this.client_id, };

    if (this.redirect_uri) {
      bodyRequest.redirectUri = decodeURIComponent(this.redirect_uri);
    }

    this.subscription.add(this.renewalApiService
      .generateMfaToRenewPassword(bodyRequest)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          this.auth_data = {
            mfaId: data.mfaId,
            remainingAttempts: data.remainingAttempts,
            expiresMilliseconds: data.expiresMilliseconds,
            tokenId: data.tokenId,
            email: data.email
          };
          this.email = this.form.value.email;
          stepper.next();
        },
        error: (err) => {

          if (err.status === 628) {
            this.router.navigateByUrl('error-not-authorized', { skipLocationChange: true });
          } else {
            throw err;
          }
        },
      })
    );
  }

  receivePassword({ password }: { password: string }, stepper: MatStepper) {
    this.subscription.add(this.userService.updateUserPassword(this.auth_data.mfaId, password)
      .subscribe((value: FinishProcessWithRedirectResp) => {
        this.to_login = value.toLogin;
        this.state.redirect_uri = value.url;
        this.state.client_id = value.clientId;
        this.state.user_name = value.userName;
        stepper.next();
      }));
  }

  goToLogin() {
    if (this.state.redirect_uri && !this.to_login) {
      window.open(this.state.redirect_uri, '_self');
    } else {
      this.router.navigate(['../login'], { relativeTo: this.route, queryParams: this.state });
    }
  }
}
