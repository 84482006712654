import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  // first page is 1
  @Input() page?:number;
  @Input() totalPages?:number;
  @Input() totalElements?:number;

  @Output() changeToPage = new EventEmitter<number>();

  // TODO
  // @Output() firstPage = new EventEmitter<void>();
  // @Output() prevPage = new EventEmitter<void>();
  // @Output() nextPage = new EventEmitter<void>();
  // @Output() lastPage = new EventEmitter<void>();


  constructor() { }

  ngOnInit(): void {
  }

  _firstPage() {
    this.changeToPage.emit(1);
  }

  _prevPage() {
    this.changeToPage.emit(this._actualPage() - 1);
  }

  _changeToPage(page:number) {
    this.changeToPage.emit(page);
  }

  _nextPage() {
    this.changeToPage.emit(this._actualPage() + 1);
  }

  _lastPage() {
    this.changeToPage.emit(this.totalPages);
  }

  private _actualPage(): number {
    return this.page || 1
  }

  getPages(): number[] {
    const pages = [];
    for (let i = Math.max(0, this._actualPage() - 4); i <= Math.min(this.maxPage(), this._actualPage() + 2); i++) {
      pages.push(i);
    }
    return pages;
  }

  private maxPage(): number {
    return this.totalElements ? Math.ceil(this.totalElements / 10) - 1 : 0;
  }

  
}
