import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api/services/common/api.service';

export interface PartnerAutomation {
  institutionCod: string;
  serviceContractCod: number;
  channelType?: string;
  logo?: string;
  primaryColor?: string;
  institutionName?: string;
  secondaryColor?: string;
  backgroundColor?: string;
  language?: string;
  channelClientId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ServiceContractService {
  constructor(private api: ApiService) {}

  getPartnerInfo(partner: string): Observable<PartnerAutomation> {
    return this.api.get<PartnerAutomation>(
      `/v1/public/service-contracts/${partner}`
    );
  }
}
