import { Location } from '@angular/common';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';

import { GlobalizationService } from 'src/app/globalization/services/globalization.service';

@Component({
    selector: 'app-form',
    templateUrl: './app-form.component.html'
})
export class AppFormComponent implements OnInit {

    form?: UntypedFormGroup;
    @Input() EnablePopState?: boolean = true;

    constructor(
        private _dialog: MatDialog,
        private _controlContainer: ControlContainer,
        private _globalizationService: GlobalizationService,
        private _router: Router,
        private route: ActivatedRoute,
        private _location: Location
    ) { }

    @HostListener('window:popstate')
    onPopState() {
        if (!this.EnablePopState) {
            history.pushState({}, '', this._router.url);
            this._location.historyGo(-1);
            return;
        }
        const dialogRef = this._dialog.open(ModalConfirmationComponent, {
            width: '320px',
            height: '300px',
            data: {
                title: this._globalizationService.translate('ARE_YOU_SURE_DISCONTINUE_REGISTRATION'),
                description: this._globalizationService.translate('ARE_YOU_SURE_DISCONTINUE_REGISTRATION_SUB_TITLE')
            }
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {

                this._router.navigate(['../'], { relativeTo: this.route });
            }

        });

    }

    ngOnInit(): void {

        this.form = this._controlContainer.control as UntypedFormGroup;

        this._router.events.pipe(filter((event: any) => event.navigationTrigger === 'popstate'))
            .subscribe(() => {
                this._location.go(this._router.url);
            });

    }

}

