<form [formGroup]="form" (ngSubmit)="sendEmail.emit(form.value)">
    <mat-card>

        <mat-card-title *ngIf="title" class="step2-title">

            <h2>{{title}}</h2>
        </mat-card-title>
        <mat-card-subtitle *ngIf="subtitle">

            <p>{{subtitle}}</p>
        </mat-card-subtitle>

        <mat-card-content>

            <app-form-control label="{{'EMAIL' | translate}}" id="email" name="email" type="email">
            </app-form-control>

        </mat-card-content>
        <mat-card-actions align="end">

            <div class="send">

                <button type="submit" id="onlyemail" class="continue-button" mat-raised-button color="primary"
                    [disabled]="!form.get('email')?.valid" translate>{{'CONTINUE' | translate}}
                    <mat-icon>arrow_forward</mat-icon>
                </button>
            </div>

        </mat-card-actions>
    </mat-card>
</form>