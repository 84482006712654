import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, Subject, throwError } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { StorageService } from 'src/app/storage/services/storage.service';
import { AppPartner } from 'src/partner/partner.service';
import { TokenResp } from '../models/token-resp';
import { AuthApiService } from '../services/auth-api.service';
import { CookieService } from '../services/cookie.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  isRefreshing = false;

  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authenticationService: AuthApiService,
    private _snackService: SnackBarService,
    private _router: Router,
    private _ngZone: NgZone,
    private _partner: AppPartner,
    private _cookieService: CookieService,
    private _storageServe: StorageService,
    private _globalizationService: GlobalizationService,
    public dialog: MatDialog
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        const apiError = error as HttpErrorResponse;

        const apiErrors = JSON.parse(JSON.stringify(apiError));

        if (!apiErrors.error?.errors) {
          this._snackService.showError(
            this._globalizationService.translate('ERRORS.DEFAULT')
          );
          return throwError(() => error);
        }
        const { code, description } = apiErrors.error.errors[0];
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          (code === 601 || code === 600) &&
          !request.url.includes('/logout')
        ) {
          if (code === 600 && this.authenticationService.token?.accessToken) {
            const partnerName =
              this._partner
                .getPartnerValue()
                ?.routerPartnerName?.toLocaleLowerCase() || 'fiserv';
            this._snackService.showError(description);
            this.dialog.closeAll();
            this.authenticationService
              .logout()
              .pipe(
                take(1),
                finalize(() => {
                  this._ngZone.run(() =>
                    this._router.navigate([`${partnerName}/login`])
                  );
                })
              )
              .subscribe();
            this._storageServe.remove('userDetails');
            this._storageServe.remove('userChannel');
            this._cookieService.deleteFromDifferentPath('currentToken');
          } else if (code === 601) {
            return this.handle401Error(request, next);
          } else {
            return EMPTY;
          }
        }
        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authenticationService.refreshToken().pipe(
        switchMap((tokens: TokenResp) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(tokens.accessToken);
          return next.handle(this.addToken(request));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap(() => next.handle(this.addToken(request)))
      );
    }
  }

  private addToken(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authenticationService.token.accessToken}`,
      },
    });
  }
}
