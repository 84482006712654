<div>
    <form *ngIf="shouldShowFilters()" class="filter-container" [formGroup]="formFilter" (ngSubmit)="onSubmit()">
        <mat-form-field *ngIf="shouldActiveFilter('name')">
            <mat-label>{{'NAME' | translate}}</mat-label>
            <input maxlength="100" matInput name="name" formControlName="name">
        </mat-form-field>

        <mat-form-field *ngIf="shouldActiveFilter('email')">
            <mat-label>{{'EMAIL' | translate}}</mat-label>
            <input maxlength="100" matInput name="email" formControlName="email">
            <mat-error [translate]="'ERRORS.FORMS.EMAIL'" [translateParams]="{field: 'EMAIL'}"></mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="shouldActiveFilter('userName')">
            <mat-label>{{'EMAIL' | translate}}</mat-label>
            <input maxlength="100" matInput name="userName" formControlName="userName">
        </mat-form-field>

        <mat-form-field *ngIf="shouldActiveFilter('creatorName')">
            <mat-label>{{'CREATOR' | translate}}</mat-label>
            <input maxlength="100" matInput name="creatorName" formControlName="creatorName">
        </mat-form-field>


        <mat-form-field *ngIf="shouldActiveFilter('dateCreated')">
            <mat-label>{{'INVITATION_CREATION_DATE' | translate}}</mat-label>
            <input mask="00/00/0000" matInput name="dateCreated" formControlName="dateCreated">
            <mat-error [translate]="'ERRORS.FORMS.DATECREATED'" [translateParams]="{field: 'DATECREATED'}"></mat-error>
        </mat-form-field>

        <mat-form-field class="select-profile-field" *ngIf="shouldActiveFilter('profile')">
            <mat-label>{{'PROFILE' | translate}}</mat-label>
            <mat-select matNativeControl formControlName="userRole" multiple>
                <mat-option *ngFor="let profile of profileList" [value]="profile.id">
                    {{profile.name | translate}}
                </mat-option>
            </mat-select>
            <mat-error [translate]="'ERRORS.FORMS.PROFILE'" [translateParams]="{field: 'PROFILE'}"></mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="shouldActiveFilter('status')">
            <mat-label>{{'STATUS' | translate}}</mat-label>
            <mat-select id="status" formControlName="status" matNativeControl multiple>
                <mat-option value="true">{{'ACTIVE' | translate}}</mat-option>
                <mat-option value="false">{{'INACTIVE' | translate}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="select-service-contract-field" *ngIf="shouldActiveFilter('serviceContract')">
            <mat-label>{{'SERVICES_CONTRACTS' | translate}}</mat-label>
            <mat-select matNativeControl formControlName="serviceContract" multiple>
                <mat-option *ngFor="let item of
            serviceContracts" [value]="item.serviceContractCod">{{item.serviceContractCod+ ' - '+item.serviceContractName}}</mat-option>
            </mat-select>
            <mat-error [translate]="'ERRORS.FORMS.PROFILE'" [translateParams]="{field: 'PROFILE'}"></mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="shouldActiveFilter('datePeriod')">
            <mat-label>{{'DATE_PERIOD' | translate}}</mat-label>
            <mat-date-range-input [rangePicker]="picker" [max]="maxDate" (click)="picker.open()">
                <input matInput matStartDate formControlName="dhInit">
                <input matInput matEndDate formControlName="dhEnd">
            </mat-date-range-input>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <button class="search-button" mat-stroked-button color="primary" type="submit"
            [disabled]="!formFilter.valid">{{'SEARCH' | translate}}</button>
        <button class="clear-filters" mat-stroked-button (click)="clearFilter()"
            [disabled]="!formFilter.dirty">{{'CLEAN' | translate}}</button>
    </form>

    <div #parent [ngClass]="{'activeCursorGrab': activeCursorGrab, 'parent': true}" (mousedown)="startDragging($event)"
        (mouseup)="stopDragging($event)" (mouseleave)="stopDragging($event)" (mousemove)="moveEvent($event)">

        <!-- Users pending table -->
        <table id="22" aria-label="table" mat-table [dataSource]="dataSource || []" class="mat-elevation-z8">

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th class="column-title" mat-header-cell *matHeaderCellDef> {{'NAME' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <span class="cell-information">{{element.name}} {{isItMe(element.code)}}</span>
                </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
                <th class="column-title" mat-header-cell *matHeaderCellDef> {{'EMAIL' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <span class="cell-information">{{element.email}}</span>
                </td>
            </ng-container>

            <!-- creatorUserName Column -->
            <ng-container matColumnDef="creatorName">
                <th class="column-title" mat-header-cell *matHeaderCellDef> {{'INVITATION_CREATOR' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <span class="cell-information">{{element.creatorName}}</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="handleClickRow(row)"
                (keydown.enter)="navigateToDetails(row)"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr style="text-align: center;" class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="8">{{'NO_USERS_FOUND' | translate}}</td>
            </tr>
        </table>
    </div>

    <div class="table-bottom" *ngIf="total_pages && (total_pages> 1) && page">
        <div style="width: 24px;height: 24px;">
            <mat-icon class="first-page-icon" matTooltip="{{'FIRST_PAGE' | translate}}" tabindex="0"
                (click)="changePage(1)" *ngIf="page> 1">
                first_page
            </mat-icon>
        </div>

        <div style="width: 24px;height: 24px;">
            <mat-icon tabindex="1" matTooltip="{{'PREVIOUS_PAGE' | translate}}" (click)="changePage(page - 1)"
                *ngIf="page> 1" class="arrow-left">
                chevron_left
            </mat-icon>
        </div>

        <div style="display: flex;gap:24px">
            <ng-template ngFor let-i="index" let-xb [ngForOf]="counter()">
                <ng-container class="page-selection" *ngIf="showLinkNumber(i + 1)">
                    <span tabindex="2" (click)="!((i + 1) === page) && changePage(i + 1)"
                        [ngClass]="(i + 1) === page ? 'current-page' : 'another-page'">
                        {{i + 1}}
                    </span>
                </ng-container>
            </ng-template>
        </div>

        <div style="width: 24px;height: 24px;">
            <mat-icon tabindex="3" matTooltip="{{'NEXT_PAGE' | translate}}" (click)="changePage(page + 1)"
                *ngIf="page != total_pages" class="arrow-right">
                chevron_right
            </mat-icon>
        </div>

        <div style="width: 24px;height: 24px;">
            <mat-icon class="last-page-icon" *ngIf="page != total_pages" matTooltip="{{'LAST_PAGE' | translate}}"
                tabindex="0" (click)="changePage(total_pages)">
                last_page
            </mat-icon>
        </div>
    </div>
</div>