import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


export type availableLanguages = 'pt-BR' | 'es' | 'en-US'

@Injectable({
  providedIn: 'root'
})
export class DatepickerLanguageService {
  
  private static languageChanged$ = new BehaviorSubject<availableLanguages>('en-US');


  constructor() {}

  setLanguage(language: availableLanguages) {
    DatepickerLanguageService.languageChanged$.next(language);
  }

  provideDateLocale() {
    return DatepickerLanguageService.languageChanged$.value ;
  }
  
  
}

