<app-float-menu></app-float-menu>
<div class="page-error-unavailable-component">

  <app-user-name-authenticate></app-user-name-authenticate>


  <app-page-error [title]="title">
    <p>{{"PAGE_UNAVAILABLE_BACK_MESSAGE" | translate}}</p>
    <div id="try-again-button-box">
      <button id="try-again-button" type="button" (click)="backClicked()" mat-raised-button>{{'BACK_TO_HOME' |
        translate}}
      </button>
    </div>
  </app-page-error>
</div>
