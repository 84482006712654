<div class="close-icon">

    <mat-icon style="cursor: pointer;" (click)="dialogRef.close()">close</mat-icon>

</div>
<div class="main-container">
    <h1>
        {{titleText()}}
        ?
    </h1>
    <h3>
        {{'WARNING_IMPORTATION_CONFIRM' | translate}}
    </h3>
    <div class="table parent">
        <div  class="header">
            <div
                class="header-title">
                <span>
                    {{'DOCUMENT' | translate}}
                </span>
                <mat-icon (click)="orderListByDocument()">{{orderByDocument
                    ? 'arrow_drop_up' :'arrow_drop_down' }}</mat-icon>
            </div>
            <div
                class="header-title">
                <span>
                   {{'PROFILE' | translate}}
                </span>
                <mat-icon (click)="orderListByProfile()">{{orderByProfile
                    ? 'arrow_drop_up' :'arrow_drop_down' }}</mat-icon>
            </div>
            <div class="header-title">
                <span>
                    {{'NAME' | translate}}
                </span>
                <mat-icon (click)="orderListByName()">{{orderByName
                    ? 'arrow_drop_up' :'arrow_drop_down' }}</mat-icon>
            </div>
            <div
                class="header-title">
                <span>
                    {{'SURNAME' | translate}}
                </span>
                <mat-icon (click)="orderListBySurname()">{{orderBySurname
                    ? 'arrow_drop_up' :'arrow_drop_down' }}</mat-icon>
            </div>
            <div class="header-title">
                <span>
                    {{'EMAIL' | translate}}
                </span>
                <mat-icon (click)="orderListByEmail()">{{orderByUserEmail
                    ? 'arrow_drop_up' :'arrow_drop_down' }}</mat-icon>
            </div>
            <div class="header-title">
                <span>
                    {{'MOBILE_PHONE' | translate}}
                </span>
                <mat-icon (click)="orderListByPhone()">{{orderByPhone
                    ? 'arrow_drop_up' :'arrow_drop_down' }}</mat-icon>
            </div>
        </div>

        <div class="body">
            <ng-template ngFor let-item
                [ngForOf]="list | slice:startPage:endPage"
                let-i="index">
                <span class="cell">{{item.documento}}</span>
                <span class="cell">{{item.profile}}</span>
                <span class="cell">{{item.name}}</span>
                <span class="cell">{{item.surName}}</span>
                <span class="cell">{{item.email}}</span>
                <span class="cell">{{item.mobilePhone | phone}}</span>
            </ng-template>

        </div>
    </div>

    <div class="table-bottom"
        *ngIf="total_pages && (total_pages> 1) && page">
        <mat-icon tabindex="1" (click)="selectedPage(page - 1)"
            *ngIf="page> 1"
            class="arrow-left">chevron_left</mat-icon>
        <ng-template ngFor let-i="index" let-xb
            [ngForOf]="counter()">
            <ng-container
                class="page-selection"
                *ngIf="showLinkNumber(i + 1)">

                <span tabindex="2" (click)="selectedPage(i + 1)"
                    [ngClass]="(i + 1) === page
                            ?
                            'current-page' :
                            'another-page'">{{i
                    + 1}}</span>
            </ng-container>

        </ng-template>

        <mat-icon tabindex="3" (click)="selectedPage(page + 1)"
            *ngIf="page
                        !=
                        total_pages"
            class="arrow-right">chevron_right</mat-icon>
    </div>

    <div class="actions">
        <button (click)="dialogRef.close()" mat-stroked-button>{{'CANCEL' | translate}}</button>
        <button (click)="dialogRef.close(data.list)" class="confirmation-button"
            mat-flat-button color="primary">{{'CONFIRM_IMPORTATION' | translate}}</button>
    </div>
</div>
