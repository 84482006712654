<div class="form-token-container">
    <!-- Loop through placeholders array to generate input fields -->
    <span *ngFor="let holder of placeholders; index as i">
      <mat-form-field appearance="fill" [formGroup]="form">
        <input 
          #input 
          [formControlName]="name + i"
          maxLength="1"
          (input)="setFormValue($event, i)"
          (paste)="onPaste($event, i)" 
          (keydown)="onKeydown($event, i)"
          [attr.type]="type"
          [attr.name]="i"
          [attr.data-testid]="'inputtoken-'+i"
          id="inputtoken-{{i}}" 
          matInput 
        />
      </mat-form-field>
    </span>
  </div>
  
  <div class="errors-container">
    <!-- Display error message for the first input field if it has 'attemptsToken' error -->
    <mat-error *ngIf="form.get(name + '0')?.hasError('attemptsToken')">
      {{ getErrorMessage() }}
    </mat-error>
  </div>
  