
<div class="close-container">

  <button mat-icon-button
    mat-dialog-close>
        <mat-icon>
          close
      </mat-icon>
  </button>

</div>

<div class="container">
        <h1 class="title">
            {{title | translate}}
        </h1>

        <div class="panel">

            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>{{'NAME' | translate}}</mat-label>
                <input type="text" [maxlength]="255" matInput [formControl]="nameFormControl">
              </mat-form-field>

              <div class="actions-buttons-container">
                <button mat-stroked-button mat-dialog-close>{{'BACK' | translate}}</button>
                <button (click)="confirmGroup()" [disabled]="!nameFormControl.valid" class="create-button" mat-flat-button color="primary">
                  {{actionButtonText | translate}}</button>
              </div>
             
        

        </div>

    
      

    
    </div>
   

