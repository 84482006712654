import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/api/models/import-req';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';

@Component({
  selector: 'app-import-table-alliance-external',
  templateUrl: './import-table-alliance-external.component.html',
  styleUrls: ['./import-table-alliance-external.component.scss']
})
export class ImportTableAllianceExternalComponent implements OnInit {


  list?: User[];

  orderByDocument = false;
  orderByProfile = false;
  orderBySurname = false;
  orderByName = false;
  orderByPhone = false;
  orderByUserEmail = false;

  page = 1;
  total_pages?: number;
  startPage = 0;
  endPage = 10;

  serviceContractName?: string;


  constructor(
    private _globalizationService: GlobalizationService,
    public dialogRef: MatDialogRef<ImportTableAllianceExternalComponent>, @Inject(MAT_DIALOG_DATA)
    public data: { list: User[]; serviceContractName: string }) {
  }

  ngOnInit(): void {
    this.list = this.generateOriginalDataList();
    this.total_pages = Math.ceil(this.data.list.length / 10);
    this.serviceContractName = this.data.serviceContractName;
  }

  quantityOfRecords(): number {
    return this.data.list.length;
  }


  generateOriginalDataList(): User[] {
    return JSON.parse(JSON.stringify(this.data.list));
  }

  selectedPage(page: number) {

    this.endPage = page * 10;
    this.startPage = this.endPage - 10;
    this.page = page;
  }

  titleText(): string {
    const multi: boolean = this.quantityOfRecords() > 1;
    const text = multi ? this._globalizationService.translate('CONFIRM_IMPORTATION_FOR_MULTI', {amount: this.quantityOfRecords()}):
    this._globalizationService.translate('CONFIRM_IMPORTATION_FOR', {amount: this.quantityOfRecords()});
    return text;
  }

  counter(): number[] | undefined {
    if (!this.page) return;
    if (this.page < 7) return new Array(10);
    return new Array(this.page + 4);
  }

  showLinkNumber(index: number): boolean {
    const pagesAfterAndBefore = window.screen.width > 700 ? 3 : 2;

    if (this.page) {
      if (this.page + pagesAfterAndBefore < index) {
        return false;
      }
      if (this.page - pagesAfterAndBefore > index) {
        return false;
      }
    }

    if (this.total_pages) {
      if (index > this.total_pages) {
        return false;
      }
    }

    return true;
  }



  orderListByDocument() {

    if (this.orderByDocument) {
      this.list?.sort((a, b) => {


        if(!a.documento || !b.documento) {
          return 0;
        }


        const nameA = a.documento.toUpperCase(); // ignore upper and lowercase
        const nameB = b.documento.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.orderByDocument = false;
    } else {
      this.list?.sort((a, b) => {


        if(!a.documento || !b.documento) {
          return 0;
        }


        const nameA = a.documento.toUpperCase(); // ignore upper and lowercase
        const nameB = b.documento.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.orderByDocument = true;
    }

    this.orderByProfile = false;
    this.orderBySurname = false;
    this.orderByName = false;
    this.orderByUserEmail = false;
    this.orderByPhone = false;
  }

  orderListByProfile() {

    if (this.orderByProfile) {
      this.list?.sort((a, b) => {

        const nameA = a.profile.toUpperCase(); // ignore upper and lowercase
        const nameB = b.profile.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.orderByProfile = false;
    } else {
      this.list?.sort((a, b) => {

        const nameA = a.profile.toUpperCase(); // ignore upper and lowercase
        const nameB = b.profile.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.orderByProfile = true;
    }

    this.orderByDocument = false;
    this.orderBySurname = false;
    this.orderByPhone = false;
    this.orderByUserEmail = false;
    this.orderByName = false;
  }

  orderListBySurname() {

    if (this.orderBySurname) {
      this.list?.sort((a, b) => {

        const nameA = a.surName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.surName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.orderBySurname = false;
    } else {
      this.list?.sort((a, b) => {

        const nameA = a.surName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.surName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.orderBySurname = true;
    }

    this.orderByDocument = false;
    this.orderByProfile = false;
    this.orderByPhone = false;
    this.orderByUserEmail = false;
    this.orderByName = false;
  }

  orderListByName() {

    if (this.orderByName) {
      this.list?.sort((a, b) => {

        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.orderByName = false;
    } else {
      this.list?.sort((a, b) => {

        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.orderByName = true;
    }
    this.orderByDocument = false;
    this.orderByProfile = false;
    this.orderBySurname = false;
    this.orderByPhone = false;
    this.orderByUserEmail = false;
  }

  orderListByPhone() {
   if (this.orderByPhone) {
      this.list?.sort((a, b) => {

        const nameA = a.mobilePhone.toUpperCase(); // ignore upper and lowercase
        const nameB = b.mobilePhone.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.orderByPhone = false;
    } else {
      this.list?.sort((a, b) => {

        const nameA = a.mobilePhone.toUpperCase(); // ignore upper and lowercase
        const nameB = b.mobilePhone.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.orderByPhone = true;
    }
    this.orderByDocument = false;
    this.orderByProfile = false;
    this.orderBySurname = false;
    this.orderByName = false;
    this.orderByUserEmail = false;

  }
  orderListByEmail() {
    if (this.orderByUserEmail) {
      this.list?.sort((a, b) => {

        const nameA = a.email.toUpperCase(); // ignore upper and lowercase
        const nameB = b.email.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      });
      this.orderByUserEmail = false;
    } else {
      this.list?.sort((a, b) => {

        const nameA = a.email.toUpperCase(); // ignore upper and lowercase
        const nameB = b.email.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.orderByUserEmail = true;
    }
    this.orderByDocument = false;
    this.orderByProfile = false;
    this.orderBySurname = false;
    this.orderByName = false;
    this.orderByPhone = false;
  }

}
