<div [ngClass]="{'upload-file-comp': (!hasFile && !imageUploaded) || !ShouldHaveImagePreview}"
    (dragover)="false" (dragend)="false"
    (drop)="fileDrop($event)">

    <ng-template #elseBlock>
        <input type="file" [accept]="acceptType" style="display:none"
            class="file-input"
            (change)="fileChoose($event)" #fileUpload>

        <button mat-icon-button
            (click)="fileUpload.click()">
            <mat-icon svgIcon="file-upload2"></mat-icon>
        </button>

        <p> {{'DRAG_AND_DROP_OR' | translate}} <span class="search-info"
                (click)="fileUpload.click()">{{'SEARCH2'| translate}}</span>
            {{'THE' | translate}} {{fileTextUsedFor | translate}} {{'IN_FORMAT' | translate}} {{fileTextType}}.
            <span *ngIf="optionalUpload">({{'OPTIONAL' | translate}})</span>
        </p>
    </ng-template>

    <div *ngIf="(hasFile || imageUploaded) && ShouldHaveImagePreview; else elseBlock">
        <div class="preview-container">
            <img [alt]="fileName" [title]="fileName" [src]="imagePreview()">

        </div>
        <input type="file" [accept]="acceptType" style="display:none"
            class="file-update"
            (change)="fileChoose($event)" #updateFile>
        <div class="action-box">
            <button mat-icon-button
                (click)="clearImage()">
                <mat-icon >close</mat-icon>
            </button>
        </div>

    </div>

</div>
