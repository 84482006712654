import { Injectable } from '@angular/core';

import { ApiService } from './common/api.service';
import { ApiModule } from '../api.module';
import { ApiObservable } from 'src/app/shared/models/api-observable';
import { User } from '../models/import-req';
import { InviteResponse } from '../models/invite';
import { HttpParams } from '@angular/common/http';
import { ApiRespNoContent } from '../models/api-resp';
import { Filters } from 'src/app/shared/table-list-invited-alliance/table-list-invited-alliance.component';

interface params {page: number; filters: Partial<Filters>; institutionCode: string}
export interface InviteEditable {  
  name: string,
  email: string,
  userName: string,
  profileId: number,
  groups?: number[],
  language: string,
  serviceContracts: number[],
  institutionCod: number,
  phone?: string,
  document?: string 
}
type re = InviteResponse | User[];
export interface ValidationTokenResponse { 
  optinId?: number; 
  optinText?: string; 
  key?: string;
  }
@Injectable({
  providedIn: ApiModule
})
export class InviteApiService {

  constructor(
    private api: ApiService
  ) { }

  getInvite(key: string): ApiObservable<{ mfaId: number }> {
    return this.api.get<{ mfaId: number }>(`/v1/public/invites?key=${key}`);
  }
  getInvitePending(document: string): ApiObservable<{
    document: string | null;
    email: string;
    expiresAt: string;
    memberOf: Array<string>;
    dateCreated: Date; 
    creatorEmail: string; 
    creatorName: string;
    createdDate?:string;
    groups?: Array<{id: number, name: string}>;
    name: string;
    profileId: number;
    profileName: string;
    roles: Array<string>;
    status: string;
    telephone: string;
    userId: string;
    userType: string;
    username: string;
    mfaId: number;
    language: string;
    locked: boolean;
  }> {
    return this.api.get<{
      document: string | null;
      email: string;
      expiresAt: string;
      memberOf: Array<string>;
      dateCreated: Date; 
      creatorEmail: string; 
      creatorName: string;
      name: string;
      locked: boolean;
      profileId: number;
      profileName: string;
      roles: Array<string>;
      groups?: Array<{id: number, name: string}>;
      status: string;
      telephone: string;
      userId: string;
      userType: string;
      username: string;
      mfaId: number;
      language: string;
    }>(`/v1/invites/pending?key=${document}`);
  }
  confirm({ mfaId, token }: { mfaId: number; token: string }): ApiObservable<ValidationTokenResponse> {
    return this.api.post<{ mfaId: number; token: string }, ValidationTokenResponse>
      ('/v1/public/invites/confirm', { mfaId, token });
  }

  validationMfaUser({ mfaId, tokenValue }: { mfaId: number; tokenValue: string }):
    ApiObservable<{ mfaId: number; remainingAttempts: number }> {
    return this.api.post<{ mfaId: number; tokenValue: string }, { mfaId: number; remainingAttempts: number }>
      ('/v1/public/users/token-validation', { mfaId, tokenValue });
  }

  resendInvite( mfaId: number, invite?:InviteEditable
  ): ApiObservable<{
    blocked_time_milliseconds: number;
    expires_milliseconds: number;
    token_id: number;
  }> {
    return this.api.post<InviteEditable | undefined, {
      blocked_time_milliseconds: number;
      expires_milliseconds: number;
      token_id: number;
    }>
      (`/v1/invites/${mfaId}`, invite);
  }

  deleteInvite({ mfaId }: { mfaId: number }): ApiObservable<ApiRespNoContent> {
    return this.api.post(`/v1/invites/delete/${mfaId}`, {});
  }
  resendInviteBatch(invites: number[]): ApiObservable<ApiRespNoContent> {
    return this.api.post(`/v1/invites/resend/batch`, {mfaIds: invites});
  }

  deleteInviteBatch(invites: number[]): ApiObservable<ApiRespNoContent> {
    return this.api.post(`/v1/invites/delete/batch`, {mfaIds: invites});
  }

  sendInvite(email: string): ApiObservable<{
    email: string; mfaId: number;
    tokenId: number; remainingAttempts: number; expiresMilliseconds: number;
  }> {
    return this.api.postSendInvite<{ email: string }, {
      email: string;
      mfaId: number;
      tokenId: number;
      remainingAttempts: number;
      expiresMilliseconds: number;
    }>('/v1/public/users/forgot-password', { email });
  }

  // getInternalUsersPendingInvite(institutionCode: string, page: number = 1, filters?: HttpParams): ApiObservable<InviteResponse> {
  //   return this.api.get<InviteResponse>(`/v1/invites/pending?institutionId=${institutionCode}&page=${page}&size=10`, filters);
  // }

  // getExternalUsersPendingInvite(filters?: HttpParams): ApiObservable<User[]> {
  //   return this.api.get<User[]>(`/v1/invites/pending`, filters);
  // }
  // getExternalUsersPendingInviteAlliance(page: number = 1, filters?: HttpParams): ApiObservable<InviteResponse> {
  //   return this.api.get<InviteResponse>(`/v1/invites/pending?page=${page}&size=10`, filters);
  // }


  getExternalUsersPendingInviteAlliance(p?: Partial<params>): ApiObservable<InviteResponse> {

      let params;
      const filters = p?.filters;
      const page = p?.page;
      const institutionId = p?.institutionCode;
      const query = Object.fromEntries(Object
        .entries({page, institutionId, ...filters, size: 10})
        .filter(([_, v]) => v != '' &&  v != undefined));
        const c: any = {...query};
        if (c) {
          params =  new HttpParams({fromObject: c});
        }



      return this.api.get<InviteResponse>(`/v1/invites/pending`, params);

  }
  getExternalUsersPendingInvite(institutionId?: string): ApiObservable<User[]> {
    let params;
    if(institutionId) {
        params = new HttpParams({fromObject: {institutionId}});
      }
      return this.api.get<User[]>(`/v1/invites/pending`,params);

  }


}
