import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { ServiceContract } from 'src/partner/models';
import { PartnerEnum } from 'src/partner/partner.enum';
import { AuthApiService } from '../api/services/auth-api.service';
import { HelpersModule } from './helpers.module';

@Injectable({ providedIn: HelpersModule })

export class TypeGuardService implements CanActivate {
  constructor(public auth: AuthApiService, public router: Router) { }
  canActivate(route: ActivatedRouteSnapshot): boolean {

    /*
    *
    *   Check if some type of user can access the route
    *   the user type expected is passed through the route
    *       {
    *       path: 'myPath',
    *       component: myComponent,
    *       canActivate: [TypeGuardService],
    *       data: {
    *       expectedUserType: ['Admin']
    *       }
    *
    */

    // user type expected
    const { expectedUserType }: { expectedUserType?: string[] } = route.data;

    // verify if user is logged
    const { isAuthenticated } = this.auth;

    const partner: string = route.paramMap.get('partner') || 'fiserv';

     // user type logged
     const { userType } = this.auth.userDetailsUsingPrefix('_'+partner+'_');

    // check if the user is not logged or has not expected type
    if (!isAuthenticated || !(userType && expectedUserType?.includes(userType))) {
      this.router.navigate([`${partner || ''}/login`]);
      return false;
    }
    // check if user logged is from different partner
    // if ((userType === 'LATAM_ALLIANCE') && !this.isLatam(partner)) {
    //   this.router.navigate([`${partner || ''}/login`]);
    //   return false;
    // }

    // confirms that the user is logged and has the expected type
    return true;
  }

  isLatam(partner: ServiceContract | null | string): boolean {
    const LatamPartnerList = [PartnerEnum.Scotia, PartnerEnum.ScotiaChile, PartnerEnum.Butterfield, PartnerEnum.Mexico] as Array<string>;
    return !!partner && LatamPartnerList.includes(partner);
  }
}
