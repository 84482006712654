<mat-form-field appearance="fill" [formGroup]="form" [ngClass]="{'readonlyline-height-readonly': disabled}"
    >
  <mat-label>{{label}} </mat-label>

  <span
    *ngIf="prefix" matPrefix>{{prefix}}</span>

  <input *ngIf="(type === 'text')
        &&
        name !== 'name'
        &&
        name !== 'socialName'"
    matInput [name]="name"
    [attr.data-testid]="name"
    [maxlength]="maxLength"
    type="text" [formControlName]="name"
    [readonly]="disabled" />

  <input *ngIf="type === 'institutionCod'"
    appOnlyNumbersDirective
    [maxlength]="maxLength"
    matInput [name]="name"
    [attr.data-testid]="name"
    type="text" [formControlName]="name"
    [readonly]="disabled" />
  <!-- <input *ngIf="type === 'institutionCod'"
    appOnlyNumbersDirective
    [maxLength]="maxLength"
    (input)="limitedInput($event)"
    matInput [name]="name"
    [attr.data-testid]="name"
    type="number" [formControlName]="name"
    [readonly]="disabled" /> -->

  <input *ngIf="(type === 'number')
        &&
        name !== 'name'
        &&
        name !== 'socialName'"
    matInput [name]="name"
    [attr.data-testid]="name"
    onkeydown="return event.keyCode !== 69"
    type="number" [formControlName]="name"
    [readonly]="disabled" />
  <input *ngIf="(type === 'url')
        &&
        name !== 'name'
        &&
        name !== 'socialName'"
    matInput [name]="name"
    [ngStyle]="objStyle"
    [attr.data-testid]="name"
    type="url" [formControlName]="name"
    [readonly]="disabled" />
  <input *ngIf="type === 'email'"
    matInput [name]="name"
    [maxlength]="255"
    autocomplete="off"
    data-testid="email"
    [type]="'text'" [formControlName]="name"
    [readonly]="disabled" />
  <input *ngIf="type === 'password'"
    matInput [name]="name"
    data-testid="password"
    autocomplete="off"
    [type]="toggleEyesOpen ? 'text' : 'password'" [formControlName]="name"
    [readonly]="disabled" />
  <input *ngIf="type === 'text' && (name === 'name' || name === 'socialName')"
    matInput [name]="name"
    [maxlength]="maxLength"
    [attr.data-testid]="name"
    appSpecialCharacterDirective
    type="text" [formControlName]="name"
    [readonly]="disabled" />
  <input *ngIf="type === 'internalFunctional'"
    data-testid="internalFunctional"
    matInput [name]="name"
    autocomplete="off"
    type="text" [formControlName]="name"
    [readonly]="disabled" />

  <mat-icon matSuffix id="pw-login-icon" svgIcon="{{toggleEyesOpen ?
        'eyes-open'
        :
        'eyes-closed'}}"
    *ngIf="type === 'password'"
    (click)="switchEyes()"></mat-icon>
  <input *ngIf="type === 'cpf' && showDocumentMask" matInput [name]="name"
    type="text"
    [formControlName]="name"
    autocomplete="off"
    [attr.data-testid]="name"
    mask="000.000.000-00"
    [readonly]="disabled" />
  <input *ngIf="type === 'cpf' && !showDocumentMask" matInput [name]="name"
    type="text"
    [formControlName]="name"
    [attr.data-testid]="name"
    autocomplete="off"
    [readonly]="disabled" />
  <input *ngIf="type === 'phone'" matInput
    [name]="name" type="text"
    inputmode="numeric"
    pattern="[0-9]*"
    appOnlyNumbersDirective
    maxlength="15"
    minlength="10"
    [formControlName]="name"
    autocomplete="off"
    [attr.data-testid]="name"
    [readonly]="disabled" />
  <input *ngIf="type === 'cpf_cnpj'" autocomplete="off" matInput [name]="name"
    [attr.data-testid]="name"
    autocomplete="off"
    [formControlName]="name" mask="CPF_CNPJ"
    [readonly]="disabled" />

  <span [ngClass]="{'hasValueIn' : control.value}" *ngIf="prefixWithUrl"
    class="unit">{{prefixWithUrl}}</span>

  <mat-select *ngIf="type === 'select'" [name]="name"
    [formControlName]="name">
    <mat-option *ngFor="let item of items" [value]="item.value">{{item.text}}
    </mat-option>
  </mat-select>
  <mat-select *ngIf="type === 'multi-select'" [name]="name"
    [formControlName]="name" multiple>
    <mat-option *ngFor="let item of items" [value]="item.value">{{item.text}}</mat-option>
  </mat-select>

  <mat-select [compareWith]="compareObjects" *ngIf="type === 'inputMid'"
    [attr.name]="name" [formControlName]="name"
    disableOptionCentering panelClass="inputMidFormControl" multiple>
    <mat-select-trigger *ngIf="midList">

      <span *ngIf="this.form?.value['mid']?.length">

        {{midList[0].mid}}

      </span>

      <span style="color: grey;" *ngIf="this.form?.value['mid']?.length">
        - {{midList[0].name}}
      </span>

      <span *ngIf="!this.form?.value['mid']?.length">
        <span>''</span>
      </span>

      <span *ngIf="this.form?.value['mid']?.length> 1"
        class="example-additional-selection">
        (+{{this.form.value['mid']?.length - 1}}
        {{(this.form.value['mid']?.length === 2 ? 'OTHER' : 'OTHERS') |
        translate}})
      </span>
    </mat-select-trigger>
    <ng-container *ngFor="let mids of midList">
      <mat-option [value]="mids">

        <span>{{mids.mid}}</span>
        <span style="color: grey;"> - {{mids.name}}</span>
      </mat-option>
    </ng-container>

  </mat-select>

  <mat-error [style.display]="multiErrors ? 'block' : 'none'" *ngFor="let
        error
        of control?.errors | keyvalue"
    [translate]="'ERRORS.FORMS.' + error.key.toUpperCase()"
    [translateParams]="{field: label}">
  </mat-error>

  <div data-testid="change-button-formControlComponent" id="formControlLink"
    (click)="changeButton.emit()" *ngIf="suffix"
    matSuffix>{{suffix}}</div>

  <mat-error *ngIf="!multiErrors && showError" [translate]="'ERRORS.FORMS.' +
        getErrorMessage()"
    [translateParams]="{field: label, emailEndsWidth,minLength: 10, maxLength:
        maxLength}"></mat-error>

</mat-form-field>