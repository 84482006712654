<mat-form-field  class="language-container"
    appearance="fill">
    <mat-select-trigger>
        <div class="icon-container">
            <mat-icon [svgIcon]="currentLanguage.flagIcon" aria-hidden="false"></mat-icon>
            <span
                class="text-info">&nbsp;{{
                currentLanguage.name
                | translate}}</span>
            <mat-icon svgIcon="arrow-language"></mat-icon>
        </div>

        <div>
            <mat-select data-testid="language-selector" id="language-selector"
                panelClass="language-selector"
                name="language"
                [value]="currentLanguage.value"
                (selectionChange)="onLanguageChange($event.value)">
                <ng-template ngFor [ngForOf]="languages" let-language>
                    <mat-option
                        class="language-option-selector"
                        *ngIf="currentLanguage.value !== language.value"
                        [value]="language.value"
                        [id]="language.value">
                        <mat-icon [svgIcon]="language.flagIcon"></mat-icon>{{language.name| translate}}
                    </mat-option>

                </ng-template>

            </mat-select>

        </div>




    </mat-select-trigger>
</mat-form-field>