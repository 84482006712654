import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable()
export class BaseUrlPartnerGuardInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

      if(request.url.includes('/v1/public/service-contracts/')) {
         request = request.clone({ url: `${environment.api.baseUrl}${request.url}` ,setHeaders: {ServiceContract: '0'}});
    }



    return next.handle(request);
  }
}
