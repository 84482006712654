<div class="content-modal">

    <div class="header-container">

        <div class="action-container">
            <button (click)="closeModal()" class="close-channel-application-button" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>

        </div>


        <h1 class="title-text">{{data.title}}</h1>

        <p class="description-text">{{data.description}}</p>


    </div>


    <mat-tab-group (selectedTabChange)="onTabChange($event)" #tabGroup dynamicHeight>
        <mat-tab label="{{'INITIAL_DATA' | translate}}">
            <div class="first-session-container">
                <mat-card class="card">
                    <mat-card-content>
                        <form class="form" [formGroup]="initialFormData">
                            <div class="input-session">

                                <div class="input-container">
                                    <mat-form-field appearance="fill">
                                        <mat-label>APM</mat-label>
                                        <input matInput maxlength="50" formControlName="apm">
                                    </mat-form-field>
                                </div>
                                <div class="input-container">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{"NAME" | translate}}</mat-label>
                                        <input matInput maxlength="255" formControlName="name">
                                    </mat-form-field>
                                </div>
                                <div class="language-selector">
                                    <mat-form-field class="selectLanguageFormField" appearance="fill">
                                        <mat-label>{{ "LANGUAGE" | translate}}</mat-label>
                                        <mat-select id="language-invite-select" name="language"
                                            formControlName="language">
                                            <mat-option *ngFor="let enum of languageList" [id]="enum.key"
                                                [value]="enum.key">
                                                {{showLanguageName(enum.key)}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="warning-container"> <mat-icon svgIcon="warning2"></mat-icon>
                                        <span>{{'LANGUAGE_WARNING' | translate}} </span>
                                    </div>
                                </div>
                            </div>

                            <div class="checkbox-container">
                                <mat-checkbox formControlName="latam">{{'CHANNEL_APPLICATION_LATAM' |
                                    translate}}</mat-checkbox>
                                <div class="warning-container"> <mat-icon svgIcon="warning2"></mat-icon>
                                    <span>{{'SELECT_THIS_OPTION_IF_IT_IS_AN_ALLIANCE_LATAM_INSTITUTION_2' |
                                        translate}} </span>
                                </div>
                            </div>

                            <div [class.active]="isChannelActive" class="information-container">
                                <div class="status-container"> <mat-icon>circle</mat-icon>
                                    <span>{{'CHANNEL_APPLICATION' | translate}} {{(isChannelActive ? 'ACTIVE' :
                                        'INACTIVE' )| translate}}</span>
                                </div>
                                <div class="warning-container"> <mat-icon svgIcon="warning2"></mat-icon>
                                    <span>{{'NEW_CHANNEL_WARNING_OTHERWISE' | translate}}</span>
                                </div>
                            </div>
                        </form>

                    </mat-card-content>

                </mat-card>
            </div>
        </mat-tab>
        <mat-tab #tab2 [disabled]="tab2Disabled" label="{{'AUTHENTICATIONS' | translate}}">
            <div class="auth-session-container">
                <mat-card class="card">
                    <mat-card-content>

                        <div *ngIf="!openEditingForm && nestedForm.length" class="nested-form-container">
                            <ng-container *ngFor="let item of nestedForm; let last = last; trackBy: trackByFn">
                                <div class="item-container">
                                    <div class="data-container">
                                        <div class="label">{{'AUTHENTICATION_TYPE' | translate}}</div>
                                        <div class="value typeChannelAuthenticate">{{item.userType.type}}</div>
                                    </div>
                                    <div class="data-container">
                                        <div class="label">Channel Client ID</div>
                                        <div class="text-id">{{item.channelClientID}}</div>
                                    </div>
                                    <div class="data-container">
                                        <div class="label">{{'STATUS' | translate}}</div>
                                        <div class="value">
                                            <div [ngClass]="{'circle': true, 'circle-green': item.active}"></div>
                                            <span [class.font-green]="item.active"> {{(item.active ? 'ACTIVE' :
                                                'INACTIVE' )| translate}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="edit-container">
                                        <button (click)="editForm(item)" mat-icon-button type="button">
                                            <mat-icon svgIcon="edit"></mat-icon>
                                        </button>
                                    </div>
                                    <div class="delete-container">
                                        <button (click)="deleteItem(item)" *ngIf="!item.id" mat-icon-button
                                            type="button">
                                            <mat-icon svgIcon="trash"></mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <mat-divider class="divider"></mat-divider>
                            </ng-container>
                        </div>

                        <button *ngIf="!openEditingForm && !allTypesFilled" (click)="addNewForm()" color="primary"
                            class="add-new-form-button" mat-button>
                            {{'ADD_NEW_AUTHENTICATION_TYPE' | translate}}
                        </button>

                        <form *ngIf="openEditingForm" class="form" [formGroup]="authFormData">
                            <div class="input-session">

                                <div class="input-container">
                                    <mat-form-field appearance="fill">
                                        <mat-label>{{'AUTHENTICATION_TYPE' | translate}}</mat-label>
                                        <mat-select formControlName="userType">
                                            <mat-option (onSelectionChange)="optionChange($event)"
                                                *ngFor="let auth of authAllTypes" [value]="auth">
                                                {{auth.type}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="input-container client-id">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Channel Client ID</mat-label>
                                        <input readonly matInput formControlName="channelClientID">
                                    </mat-form-field>
                                    <button
                                        [disabled]="!!authFormData.get('id')?.value || (disabledGenerateSHA256 && !authFormData.get('userType')?.value)"
                                        mat-stroked-button (click)="generateSHA256()">{{'GENERATE' |
                                        translate}}</button>
                                </div>
                                <div class="status-container">
                                    <mat-slide-toggle class="global-default" formControlName="active">
                                        {{(authFormData.value.active ? 'ACTIVE' : 'INACTIVE') | translate}}
                                    </mat-slide-toggle>
                                </div>
                            </div>

                            <div class="radio-buttons-div">
                                <mat-radio-group formControlName="mfaAuth" aria-label="Select an option">
                                    <mat-radio-button [value]="true">{{'MFA_REQUIRED' | translate}}</mat-radio-button>
                                    <mat-radio-button class="radio-btn-2" [value]="false">{{'MFA_OPTIONAL' | translate}}</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <mat-divider></mat-divider>

                            <div class="url-session" formArrayName="linksChannel">
                                <div [ngClass]="{'url-container': true, 'active-trash':!isFirst }"
                                [formGroupName]="i" *ngFor="let url of linksChannel.controls; let i = index; let isFirst = first">
                                    <div  class="info">
                                        <mat-form-field  appearance="fill">
                                            <mat-label>{{'RETURN_URL' | translate}}</mat-label>
                                            <input matInput formControlName="link"/>
                                            <mat-error *ngIf="url.hasError('pattern') && url.touched">
                                                {{'INVALID_URL_WARNING' | translate}}
                                            </mat-error>
                                        </mat-form-field>
                                       
                                           <mat-form-field appearance="fill">
                                                <mat-label>{{'SERVICE_CONTRACT' | translate}}</mat-label>
                                                <mat-select formControlName="serviceContractId">
                                                    <mat-option 
                                                        *ngFor="let svc of serviceContracts" [value]="svc.id">
                                                        {{svc.name}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <div class="remove-container">
                                                <button mat-icon-button type="button" (click)="removeUrlInput(i)">
                                                    <mat-icon svgIcon="trash"></mat-icon>
                                                </button>
                                            </div>
                                        
                                        
                                    </div>
                                </div>
                            </div>


                        </form>
                        <div *ngIf="openEditingForm" class="button-add-urls-container">
                            <button class="url-text" (click)="addUrlInput()" color="primary"
                                [disabled]="linksChannel.length > 0 && linksChannel.invalid" mat-button>
                                {{'ADD_CALLBACK_URL' | translate}}
                            </button>

                        </div>

                        <div class="action-container" *ngIf="openEditingForm">
                            <button mat-stroked-button (click)="closeForm()" class="exit-button">{{'BACK' |
                                translate}}</button>
                            <button mat-flat-button (click)="addNestedForm()" [disabled]="!authFormData.valid"
                                color="primary" class="conclude-button">
                                {{'CLOSE' | translate}}
                            </button>
                        </div>

                    </mat-card-content>
                </mat-card>

            </div>

        </mat-tab>
        <mat-tab #tab4 [disabled]="tab4Disabled" label="{{'LOGO_OPTIONAL' | translate}}">
            <div class="logo-session-container">
                <mat-card class="card">
                    <mat-card-content>
                        <div class="file-container">
                            <app-file-upload [imageUploaded]="logo"
                                (fileUploaded)="fileUploaded($event)"></app-file-upload>
                        </div>
                        <div class="text-container">
                            <h1 class="title">{{'UPLOADING_TIPS' | translate}}</h1>
                            <div class="message-container">
                                <span class="circle">A</span>
                                <span class="text"> SVG. Scalable Vector Graspanhics
                                    {{'IS_A_WEB_COMPATIBLE_VECTOR_FILE_FORMAT' | translate}}</span>
                            </div>
                            <div class="message-container">
                                <span class="circle">B</span>
                                <span class="text"> {{'DOES_NOT_SUPPORT_OTHER_TYPES' | translate}} </span>
                            </div>
                            <div class="message-container">
                                <span class="circle">C</span>
                                <span class="text">{{'SEEK_HELP_FROM_A_DESIGN_PROFESSIONAL' | translate}}</span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

        </mat-tab>
        <!-- <mat-tab #tab5 [disabled]="tab5Disabled" label="{{'LANGUAGE_OPTIONAL' | translate}}">
            <div class="logo-session-container">
                <mat-card class="card">
                    <mat-card-content>
                        <app-language-selector
                        [isSystemLanguage] = "false"
                        [channelLanguage] = "languageChannel"
                        (languageSelected)="chosenLanguage($event)"></app-language-selector>
                    </mat-card-content>
                    <div class="warning-container"> <mat-icon svgIcon="warning2"></mat-icon>
                        <span>{{'LANGUAGE_WARNING' | translate}}</span>
                    </div>
                </mat-card>
            </div>

        </mat-tab> -->
    </mat-tab-group>


    <div *ngIf="showActionsButtons" class="controls-modal">
        <button mat-stroked-button (click)="closeModal()" class="exit-button">{{'CANCEL' | translate}}</button>
        <button mat-flat-button *ngIf="!isLastTab" [disabled]="disableNextTabButton" color="primary"
            class="next-tab-button" (click)="goToNextTab(tabGroup)">
            {{'CONTINUE' | translate}}
            <mat-icon>arrow_forward</mat-icon>
        </button>
        <button mat-flat-button *ngIf="isLastTab" [disabled]="disableCompleteProcessButton || !nestedForm.length "
            color="primary" class="next-tab-button" (click)="completeProcess()">
            {{'FINISH' | translate}}
        </button>
    </div>

</div>