import { Injectable } from '@angular/core';
import { LoaderComponent } from '../loader/loader.component';
import { OverlayService } from './overlay.service';

@Injectable({
  providedIn: 'root'
})
export class LoadingOverlayService {

  constructor(private _overlay: OverlayService) { }

  show() {
    this._overlay.show(LoaderComponent);
  }

  hide() {
    this._overlay.hide();
  }
}
