import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: "sortBy"})
export class SortPipe implements PipeTransform {

    transform(items: any[] | null | undefined, property: string): any[] {
        if(!items || !property) {
            return []
        }
        return items.sort((a,b) => a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1)
    }
}