import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { Channel } from 'src/app/api/models/channel';
import { ChannelApiService } from 'src/app/api/services/channel-api.service';
import { StorageService } from 'src/app/storage/services/storage.service';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';


@Component({
  selector: 'app-external-channel-list',
  templateUrl: './external-channel-list.component.html',
  styleUrls: ['./external-channel-list.component.scss']
})
export class ExternalChannelListComponent implements OnInit, OnDestroy {

  channel$?: Observable<string>;

  partner: Partner | undefined;

  channels?: Channel[];

  partnerName?: string;

  private _subscription: Subscription = new Subscription();

  constructor(
    private _appPartner: AppPartner,
    private _channelApiService: ChannelApiService,
    private _activatedRoute:ActivatedRoute,
    private _router: Router,
    private _storageServe: StorageService,
    public dialog: MatDialog,
   ) { }

  ngOnInit(): void {

    const channelJSON = this._storageServe.get('userChannel');

    if((channelJSON && !!JSON.parse(channelJSON)?.clientId) && !history.state.changeChannel) {
      this._router.navigate(['../users'], {relativeTo: this._activatedRoute})
     }

     const routeParams = this._activatedRoute.snapshot.paramMap;
     this.partnerName = routeParams.get('partner') || '';

    this._subscription.add(this._appPartner.getPartner().subscribe(el => this.partner = el));

    this._subscription.add(this._channelApiService.showChannelTypes()
    .subscribe({
      next: (list) => {
        if(list.length == 1) {
          this.chosenChannel(list[0]);
        }
        this.channels = list;
      },
    }));

    this.channel$ = this._channelApiService.channel$;
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  chosenChannel(channel: Channel) {
    this._subscription.add(this._channelApiService.setCurrentChannel(channel).subscribe({
      next: () => {
        this._router.navigate(['../users'], {relativeTo: this._activatedRoute})
      }
    }));
  }

 



}


