import { Component, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
    selector: 'app-form-password',
    templateUrl: './form-password.component.html',
    styleUrls: ['./form-password.component.scss'],
  })
@Injectable()
export class FormPasswordComponent {

    @Input() first_access = false;
    @Input() passwordForm!: FormGroup;
    @Output() formSubmit = new EventEmitter<FormGroup>();
    
    hidePassword = true;
    hideConfirmPassword = true;

    constructor(){}

    togglePasswordVisibility() {
        this.hidePassword = !this.hidePassword;
    }

    toggleConfirmPasswordVisibility() {
        this.hideConfirmPassword = !this.hideConfirmPassword;
    }

}
