<mat-card
  class="inactive-registration-card">
  <mat-divider [vertical]="true"></mat-divider>
  <div class="container">
    <mat-card-subtitle>
      <mat-icon style="width: 17px; height: 17px;" svgIcon="alert-circle">
      </mat-icon><span>{{"ALERT" | translate}}</span></mat-card-subtitle>
    <mat-card-content> <p> <strong>{{content}}</strong></p>
    </mat-card-content>
    <mat-card-footer> <p>{{footer}}</p> </mat-card-footer>
  </div>

</mat-card>
