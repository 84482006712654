<div class="content-modal">
  <div [class.ocult]="postponeAction" class="close-container">
    <mat-icon *ngIf="data.putOff" (click)="onCloseClick()" svgIcon="close"></mat-icon>
  </div>
  <div class="header-modal">
    <h2 mat-dialog-title>{{title}}</h2>
  </div>
  <div [class.subtitle]="postponeAction" mat-dialog-content>
    <p>{{subtitle}} </p>
  </div>
  <div [class.ocult]="confirmRenew || postponeAction"
    class="actions-container">
    <mat-form-field
      [class.expanded]="expandedPanelSearch" id="panel-expanded"
      appearance="fill">
      <input matInput type="search"
        placeholder="{{search}}"

        class="search-input" (keyup)="applyFilter($event)" #input>
      <mat-icon matSuffix (click)="ShowExpandedPanelSearch()"
        svgIcon="search"></mat-icon>
      <mat-icon matSuffix
        (click)="closeExpandMenu()"
        svgIcon="close"></mat-icon>
    </mat-form-field>

    <div [class.hide]="expandedPanelSearch"
      class="select-all-container">
      <p>{{"TOTAL_USERS" | translate}} ({{usersToRenewal.length}})</p>
      <!-- ({{ filteredUsers?.length || usersToRenewal.length}}) -->
      <button *ngIf="!confirmRenew && !postponeAction"
        class="select-all-users"
        mat-button
        (click)="onSelectAllUsers(users)">{{"SELECT_ALL" | translate}}
      </button>
    </div>
  </div>

  <mat-divider *ngIf="!confirmRenew && !postponeAction" class="dividir"></mat-divider>
  <div class="controls-modal">
    <mat-selection-list #users
      (selectionChange)="onSelection($event,
      users.selectedOptions.selected)"
      [compareWith]="compareObjects"
      [class.ocult]="confirmRenew || postponeAction">

      <ng-template ngFor let-user
        [ngForOf]="filteredUsers || usersToRenewal"
        let-i="index">

        <mat-list-option
          checkboxPosition="before"
          [selected]="selectedUser(user)"
          [value]="user">
          <span class="name">{{user.name}}</span>
          <p class="document" *ngIf="!userLatam() && user.document">
            {{"CPF" | translate}} {{user.document | mask:
            '000.000.000-00'}}
          </p>
          <p class="document" *ngIf="userLatam()">
            {{"EMAIL" | translate}}: {{user.username}}
          </p>
        </mat-list-option>
        <mat-divider *ngIf="(i + 1) !== (filteredUsers?.length ||
          usersToRenewal.length)"></mat-divider>
      </ng-template>
    </mat-selection-list>
  </div>
  <ng-template [ngIf]="!filteredUsers?.length && input.value">
    <div class="no-content-container">
      <span>{{"NO_USERS_FOUND" | translate}}</span>
    </div>
  </ng-template>
  <mat-divider *ngIf="!confirmRenew && !postponeAction" class="dividir"></mat-divider>
  <ng-template [ngIf]="confirmRenew">
    <div class="confirmRenew-text-body">
      <p> <strong>{{checkedValues.length}}
          {{"USER_WILL_RENEWAL" | translate}}</strong> <br>
        {{usersToRenewal.length -
        checkedValues.length}}
        {{"USER_WILL_INACTIVATION" | translate}}</p>
    </div>
  </ng-template>

  <div class="container-button-confirm">
    <button class="confirm" *ngIf="!confirmRenew && !postponeAction"
      mat-button
      (click)="onRenewClick()">{{"RENEW"
      | translate}}
      {{checkedValues.length ?
      '('+ checkedValues.length + ')': ''}}</button>
  </div>

  <ng-template [ngIf]="confirmRenew">
    <div class="container-buttons-confirm-renew">
      <button class="back-button"
        mat-stroked-button (click)="onBackClick()">{{"BACK" |
        translate}}</button>
      <button class="confirm-renew"
        mat-button
        (click)="onConfirmClick(checkedValues)">{{"CONFIRM"
        |
        translate}}</button>
    </div>

  </ng-template>
  <ng-template [ngIf]="postponeAction">
    <div class="container-buttons-confirm-renew">
      <button class="back-button"
        mat-stroked-button (click)="onPostponeClick()">{{"YES_POSTPONE"
        |
        translate}}</button>
      <button class="confirm-renew"
        mat-button (click)="ngOnInit()">{{"NO_KEEP" | translate}}</button>
    </div>

  </ng-template>

</div>
