import { Injectable } from '@angular/core';

import { ApiService } from './common/api.service';

import { ApiModule } from '../api.module';

import { TokenResetPassword } from '../models/token-reset-password';
import { UserSignupReq } from '../models/user-signup-req';
import { UserSignupResp } from '../models/user-signup-resp';

import { ApiObservable } from 'src/app/shared/models/api-observable';
import { UserInvitationReq } from '../models/user/user-invitation-req';
import { HttpParams } from '@angular/common/http';
import { UserListReq, UserListReqInternal, UserListResp } from '../models/user-list';
import { PROFILE_ID } from '../enums/profile.enum';
import { FinishProcessWithRedirectResp } from '../models/finish-process-with-redirect-resp';

interface UserEditResponse {
  name: string; email: string; telephone: string;
  profileId: number; status: boolean; socialName: string;
  language: string; locked: boolean; groups?: Array<number>;
}

interface UserEdit extends UserEditResponse {
  userId: string;
}

interface ChangePasswordWhenLogged { oldPassword:string, newPassword:string };
interface ChangePasswordWhenLoggedResponse {
  code: number,
  message: string
};

export interface UpdateExternalUser extends Object {
  document: string | null;
  maskedDocument: string;
  email: string;
  department?: string;
  manager?: string;
  creatorName?: string;
  createdDate?: string;
  memberOf?: string;
  groups?: Array<{id: number, name: string}>;
  userDomainType?: string;
  title?: string;
  status: boolean;
  creator: boolean;
  active?: boolean;
  name: string;
  profileId: PROFILE_ID;
  profileName?: string;
  socialName: string;
  telephone: string;
  userId: string;
  username: string;
  language: string;
  locked: boolean;
  serviceContracts?: Array<{
    name: string;
    profileId: PROFILE_ID;
    profileName: string;
    profileDescription: string;
    status: boolean;
    serviceContractCod: number;
  }>;
}

@Injectable({
  providedIn: ApiModule
})
export class UserApiService {

  constructor(
    private api: ApiService
  ) { }

  create(body: UserSignupReq): ApiObservable<UserSignupResp> {
    return this.api.post<UserSignupReq, UserSignupResp>('/v1/users', body);
  }
  edit({ name, email, telephone, profileId, language, status, userId, socialName, locked, groups }: UserEdit):
     ApiObservable<Record<string, never>> {
    return this.api.post<UserEditResponse, Record<string, never>>(`/v1/users/update/${userId}`,
      { name, email, telephone, profileId, language, status, socialName, groups, locked });
  }
  delete({ userId }: { userId: string }): ApiObservable<any> {
    return this.api.post(`/v1/users/delete/${userId}`, {});
  }
  getUserByDocument(key: string): ApiObservable<{ name: string; email: string }> {
    return this.api.get<{ name: string; email: string }>(`/v1/public/users/${key}`);
  }

  getUsersListCreatedBy(fiservCreatorUsername: string): ApiObservable<{
    username: string;
    name: string;
    document: string;
    status: boolean;
  }[]> {
    return this.api.get<{
      username: string;
      name: string;
      document: string;
      status: boolean;
    }[]>(`/v1/users?creatorUsername=${fiservCreatorUsername}`);
  }

  getUserById(id: string, userType: ('internal' | 'external')): ApiObservable<UpdateExternalUser> {
    return this.api.get<UpdateExternalUser>(`/v1/users?key=${id}&userType=${userType}`);
  }

  getUsersByServiceContract(params:
    UserListReq | UserListReqInternal):
    ApiObservable<UserListResp> {
    return this.api.get<UserListResp>(`/v1/users`, new HttpParams({ fromObject: { ...params } }));
  }
  getInternalUsersByServiceContract(params: {
    userType: string;
    uaid: number;
    name?: string;
    functional?: string;
    email?: string;
    memberOf?: string;
  }):
    ApiObservable<{
      users: Array<{
        name: string;
        functional: string;
        memberOf: string;
        email: string;
        manager: string;
        status: string;
        active: boolean;
      }>;
      resultCount: number; remainingPages?: number; page?: number; userType: string;
    }
    > {
    return this.api.get<{
      users: Array<{
        name: string;
        functional: string;
        memberOf: string;
        manager: string;
        email: string;
        status: string;
        active: boolean;
      }>;
      resultCount: number; remainingPages?: number; page?: number; userType: string;
    }
    >(`/v1/users`, new HttpParams({ fromObject: { ...params } }));
  }
  // get first and last name
  tokenResetPassword(email: string): ApiObservable<TokenResetPassword> {
    return this.api.post<{ email: string }, TokenResetPassword>('/v1/public/password/email', { email });
  }

  createUser({ mfaId, name, password }: { mfaId: number; name: string; password: string }): ApiObservable<FinishProcessWithRedirectResp> {
    return this.api.post<{ mfaId: number; name: string; password: string },
      FinishProcessWithRedirectResp>('/v1/public/invites', { mfaId, name, password });
  }

  // create an new invite
  userInvitation(body: UserInvitationReq): ApiObservable<{ expiresAt: string; mfaId: number }> {
    return this.api.post<UserInvitationReq, { expiresAt: string; mfaId: number }>('/v1/users/invite', body);
  }


  updateUserPassword(mfaId: number, password: string): ApiObservable<FinishProcessWithRedirectResp> {
    return this.api.post<{ mfaId: number; password: string },
    FinishProcessWithRedirectResp>('/v1/public/users/password', { mfaId, password });
  }

  editUserPasswordWhenLogged({ oldPassword, newPassword }: ChangePasswordWhenLogged): ApiObservable<ChangePasswordWhenLoggedResponse> {
    return this.api.post<ChangePasswordWhenLogged,ChangePasswordWhenLoggedResponse>('/v1/users/password/edit', { oldPassword, newPassword });
  }

}
