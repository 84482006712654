<div class="page">
    <main class="content">
        <div class="title">
            <div class="title-container">
                <span class="material-icons" mat-dialog-close>
                    close
                </span>
                <h1 class="title-interface">{{"GRAPHIC_INTERFACE" | translate }}</h1>
                <p>{{"GRAPHIC_INTERFACE_DEFINITION" | translate }}</p>
            </div>
        </div>
    </main>

    <div class="graphical-interface-section">
        <div class="graphical-container" [style.marginBottom]="isDefaultGraphical ? '50px': ''">
            <div class="graphical-type">
                <div class="graphical-type--default" (click)="selectGraphicalType(true)"
                    [class.selected]="isDefaultGraphical">{{"FISERV_STANDARD" | translate}}</div>
                <div class="graphical-type--personalize" [class.selected]="!isDefaultGraphical"
                    (click)="selectGraphicalType(false)">{{"FISERV_CUSTOMIZE" | translate}}</div>
            </div>
        </div>

        <ng-container *ngIf="!isDefaultGraphical">
            <div class="restart-option">
                <a (click)="restart()"> {{"RESTART" | translate }} </a>
            </div>
        </ng-container>

        <div class="screen-design">
            <div class="screen-design--type">
                <app-login-prototype [isDefaultGraphical]="isDefaultGraphical" [logo]="logo"
                    [buttonsColor]="selectButtonsColor" [linksColor]="selectLinksColor"
                    [selectBackground]="selectBackground"></app-login-prototype>
            </div>

            <div class="screen-design--info">
                <ng-container *ngIf="isDefaultGraphical">
                    <div class="description">
                        <p>{{"PROFILE_STANDARD_INFO" | translate}}</p>

                        <p>{{"PROFILE_STANDARD_INFO2" | translate}}<span class="highlight-msg"> {{"FISERV_CUSTOMIZE" |
                                translate}}</span></p>
                    </div>

                    <div class="requisite">
                        <p class="requisite--desc">{{"REQUIRED_INFO" | translate}}</p>

                        <div class="requisite--steps">
                            <span class="size-x circle">A</span>
                            <span>{{"REQUIRED_INFO_FIRST_STEP" | translate}}
                            </span>
                        </div>

                        <div class="requisite--steps">
                            <span class="size-x circle">B</span>
                            <span>{{"REQUIRED_INFO_SECOND_STEP" | translate}} </span>
                        </div>

                        <div class="requisite--steps">
                            <span class="size-x circle">C</span>
                            <span>{{"REQUIRED_INFO_THIRD_STEP" | translate}}</span>
                        </div>
                    </div>

                </ng-container>

                <ng-container *ngIf="!isDefaultGraphical">
                    <div class="file-container">
                        <div class="file-image">
                            <app-file-upload [imageUploaded]="logo"
                                (fileUploaded)="fileUploaded($event)"></app-file-upload>
                        </div>
                        <div class="file-description">
                            <p class="requisite--desc">{{"UPLOAD_TIPS" | translate }}</p>
                            <div class="requisite--steps">
                                <span class="size-x2 circle">A</span>
                                <span>{{"REQUIRED_INFO_FIRST_STEP" | translate}}
                                </span>
                            </div>
                            <div class="requisite--steps">
                                <span class="size-x2 circle">B</span>
                                <span>{{"REQUIRED_INFO_SECOND_STEP" | translate}}
                                </span>
                            </div>
                            <div class="requisite--steps">
                                <span class="size-x circle">C</span>
                                <span>{{"REQUIRED_INFO_THIRD_STEP" | translate}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="divider">{{"COLORS" | translate}}</div>
                    <div class="color-selection">
                        <div class="pick-color" [style.borderColor]="selectButtonsColor">
                            <div class="color-default"
                                [style.background]="!selectButtonsColor ? '#FFFF' : selectButtonsColor">
                                <input type="color" class="hidden-color" [(ngModel)]="selectButtonsColor">
                            </div>
                            <div class="type-color">
                                <label>{{"PRIMARY_COLORS" | translate}}</label>
                                <div>{{selectButtonsColor}}</div>
                            </div>
                        </div>
                        <div>
                            {{"PRIMARY_COLORS_MSG" | translate}}
                        </div>
                    </div>

                    <div class="color-selection">
                        <div class="pick-color" [style.borderColor]="selectLinksColor">
                            <div class="color-default"
                                [style.background]="!selectLinksColor ? '#FFFF' : selectLinksColor">
                                <input type="color" class="hidden-color" [(ngModel)]="selectLinksColor">
                            </div>
                            <div class="type-color">
                                <label>{{"SECONDARY_COLOR" | translate }}</label>
                                <div>{{selectLinksColor}}</div>
                            </div>
                        </div>
                        <div>
                            {{"SECONDARY_COLOR_MSG" | translate }}
                        </div>
                    </div>

                    <div class="color-selection">
                        <div class="pick-color" [style.borderColor]="selectBackground">
                            <div class="color-default"
                                [style.background]="!selectBackground ? '#FFFF' : selectBackground">
                            <input type="color" class="hidden-color" [(ngModel)]="selectBackground">
                            </div>
                            <div class="type-color">
                                <label>{{"BACKGROUND_COLOR" | translate}}</label>
                                <div>{{selectBackground}}</div>
                            </div>
                        </div>
                        <div class="color-description">
                            {{"BACKGROUND_COLOR_MSG" | translate}}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="footer-container">

        <div class="footer-button">

            <button class="conclude-button" mat-stroked-button mat-dialog-close>
                {{'CANCEL' | translate}}
            </button>
            <button class="conclude-button" mat-stroked-button type="submit" mat-flat-button color="primary"
                (click)="confirm()"
                [disabled]="isDefaultGraphical ? false : !(logo && (selectButtonsColor === '#FFFF' && selectLinksColor == '#FFFF' || selectLinksColor && selectButtonsColor))">
                {{'CLOSE' | translate}}
            </button>
        </div>
    </div>

</div> 