import { Component, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'app-card-expired-password',
  templateUrl: './card-expired-password.component.html',
  styleUrls: ['./card-expired-password.component.scss']
})
export class CardExpiredPasswordComponent {

  @Output() startNewPasswordProcess: EventEmitter<void> = new EventEmitter();

}
