/* eslint-disable */
export enum UserProfile {
    GESTOR_INSTITUCIONAL_ALLIANCE = 'GESTOR_INSTITUCIONAL_ALLIANCE',
    SUPORTE_N1 = 'SUPORTE_N1',
    BACKOFFICE = 'BACKOFFICE',
    SOMENTE_LEITURA = 'SOMENTE_LEITURA',
    CLIENTE_MERCHANTE = 'CLIENTE_MERCHANTE',
    ASSISTENTE_OPERACAO_MERCHANTE = 'ASSISTENTE_OPERACAO_MERCHANTE',
    ASSISTENTE_CONSULTA_MERCHANTE = 'ASSISTENTE_CONSULTA_MERCHANTE',
    GESTOR_INSTITUCIONAL_LATAM_ALLIANCE = 'GESTOR_INSTITUCIONAL_LATAM_ALLIANCE',
    SOMENTE_LEITURA_LATAM = 'SOMENTE_LEITURA_LATAM',  
    ADMINISTRADOR_ALLIANCE = 'ADMINISTRADOR_ALLIANCE',
    OPERADOR_ALLIANCE = 'OPERADOR_ALLIANCE',
    ADMIN_GRAFANA_BANCOLOMBIA = 'AP-LAC-10467-GRAFANA-ADMIN-BANCOLOMBIA',
    ADMIN_GRAFANA_BANCOLOMBIA_APM = 'AP-LAC-APM0008786-GRAFANA-ADMIN-BANCOLOMBIA',
    ADMIN_GRAFANA_UAT = 'AP-LAC-10467-GRAFANA-ADMIN-UAT',
}