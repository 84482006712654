import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input,Output, EventEmitter, ViewChild, ElementRef, HostListener,
  AfterViewInit, AfterContentInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import {  ActivatedRoute, Router } from '@angular/router';
import { userList } from 'src/app/api/models/group-resp';
import { InvitedUser } from 'src/app/api/models/invite';
import { ProfilesInfo } from 'src/app/api/models/profiles-resp';
import { AuthApiService } from 'src/app/api/services/auth-api.service';
import { ChannelApiService } from 'src/app/api/services/channel-api.service';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { AppPartner } from 'src/partner/partner.service';


export interface Filters {
  creatorName: string;
  name: string;
  username: string;
  dateCreated: string;
  profile: string;
  email: string;
  serviceContract: string;
  status: Array<string>;
}
interface filterForm {
  creatorName: FormControl<string>;
  name: FormControl<string>;
  username: FormControl<string>;
  dateCreated: FormControl<string>;
  profile: FormControl<string>;
  userName: FormControl<string>;
  email: FormControl<string>;
  serviceContract: FormControl<string>;
  status: FormControl<Array<string>>;
}

@Component({
  selector: 'app-table-users',
  templateUrl: './table-users.component.html',
  styleUrls: ['./table-users.component.scss']
})
export class TableUsersComponent implements AfterContentInit,AfterViewInit,OnChanges {


  @Input() list?: userList[];
  @Input() link: [k: string] = ['/..'];

  @Input() page?: number;
  @Input() total_pages?: number;
  @Input() totalElements?: number;
  @Input() serviceContracts?: Array<{ serviceContractCod: string; serviceContractName: string }>;
  @Output() selected = new EventEmitter<userList>();

  @Output() selectedPage = new EventEmitter<{page: number; filters?: Partial<Filters>}>();

  @Output() resendBatch = new EventEmitter<{list: number[]; filters?: Partial<Filters>; page?: number}>();
  @Output() deleteBatch = new EventEmitter<{list: number[]; filters?: Partial<Filters>; page?: number}>();

  @Input() invitedUser?: boolean;

  filtersLoaded: Partial<Filters> | undefined;


  formFilter: FormGroup<filterForm> = this._fb.group({
    creatorName: ['', []],
    name: ['', []],
    email: ['', []],
    userName: ['', []],
    dateCreated: ['', []],
    userRole: [[''], []],
    status:[null, []],
    serviceContract: [null, []]
  });

  // checkbox-button
  // isAllListSelect = false;

  @Input() displayedFilters: Array<string> = ['name', 'email', 'creatorName', 'dateCreated' , 'profile'];


  // Profiles
  @Input() profileList?: ProfilesInfo[];


  // table column and data
  @Input() displayedColumns: string[] = ['name','document', 'profileName', 'email', 'creatorName', 'dateCreated', 'status'];
  // table data
  dataSource?: MatTableDataSource<userList>;
  // Selected item (checkbox)
  selection = new SelectionModel<userList>(true, []);
  // Mouse event (drag and drop with scroll)
  mouseDown = false;
  startX: any;
  scrollLeft: any;
  activeCursorGrab = false;
  drag = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const element = document.querySelector('.parent') as HTMLElement;
    if (element?.scrollWidth > element?.clientWidth) {
      this.activeCursorGrab = true;
    } else {
      this.activeCursorGrab = false;
    }
  }
  @HostListener('mousemove', ['$event'])
  mouseup(event: Event): void {
   this.drag = true;
  }
  @HostListener('mousedown', ['$event'])
  mousedown(event: Event): void {
    this.drag = false;
  }

  @ViewChild('parent', {static: true}) slider!: ElementRef;



  constructor(
    private _router: Router,
    private _appPartner: AppPartner,
    private _fb: UntypedFormBuilder,
    private _authApi: AuthApiService,
    private _activatedRoute: ActivatedRoute,
    private _channelApiService: ChannelApiService,
    private _globalizationService: GlobalizationService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}


  ngOnChanges(changes: SimpleChanges) {
    if(changes.list?.currentValue && !changes.list.isFirstChange()) {
      this.dataSource = new MatTableDataSource(this.list);
      this.selection.clear();
    }
  }
  // listOfStringProfile(): string[] {
  //   return this.profileList?.map(({ name }) => name) || [''];
  // }

  ngAfterViewInit(): void {
    //Ativa o mouse, para arrastar a tabela, caso o scroll do conteiner esteja ativo.
    const element = document.querySelector('.parent') as HTMLElement;
    if (element?.scrollWidth > element?.clientWidth) {
      this.activeCursorGrab = true;
    }

    // detecta a mudança
    this._changeDetectorRef.detectChanges();
  }

  navigateToDetails(item: any) {
    this._router.navigate(this.openPage(item), {relativeTo: this._activatedRoute}) ;
  }

  handleClickRow(item: any) {
    if(!this.drag) {
      this._router.navigate(this.openPage(item), {relativeTo: this._activatedRoute});
    }
  }
  openPage(item: any): string[] {

    if (this.invitedUser) {
      return [`${this.invitePending() ? '../invite/'+item.userId : 'invite/'+item.userId}`];
    }
    if (item.uaid) {
      this._channelApiService.setCurrentChannel(item.description);
      return ['../users/' + item.uaid];
    }
    const shouldRemovePendingFromPath = this._router.url.includes('/pending');
    const newUrl = shouldRemovePendingFromPath ? ['../user/' + item.code] : ['user/' + item.code];

    return newUrl;
  }

  invitePending(): boolean {
    return this._activatedRoute.snapshot.data.pending;
  }

  profileName(id: string): string {
    const pn = parseInt(id);
    const profileName = this.profileList?.find(e => e.id === pn)?.name;
    return profileName || '';
  }

  deleteInvite() {
    const listOfInvitesToDelete = this.selection.selected.map(({code}) => Number(code));
    this.deleteBatch.emit({list: listOfInvitesToDelete, filters: this.filtersLoaded, page: this.page});
  }

  resendInvite() {
    const listOfInvitesToResend = this.selection.selected.map(({code}) => Number(code));
    this.resendBatch.emit({list: listOfInvitesToResend, filters: this.filtersLoaded, page: this.page});
  }

  isItMe(code: string): string {
    return this._authApi.userDetails.userId === code ? `(${this._globalizationService.translate('I')})` : '';
  }

  selectedItem(element: userList){

    if(!this.drag) {
      this.selected.emit(element);
    }
  }

  ngAfterContentInit(): void {
    this.dataSource = new MatTableDataSource(this.list);
  }

  showActiveStatusText(item: any): string | undefined {
    if(item?.locked) {
      return this._globalizationService.translate('BLOCKED');
    }
    return item?.active ?
    this._globalizationService.translate('ACTIVE') :
    this._globalizationService.translate('INACTIVE');
  }

  insertActiveStatusClass(item: any): string  {
    if(item?.locked) {
      return 'blocked';
    }
    return item?.active ?
    'active':
    'inactive';
  }

  onSubmit() {

    this.filtersLoaded = Object.fromEntries(Object
      .entries({...this.formFilter.value,
        status: this.formFilter.value.status && this.formFilter.value.status.length > 1 ? undefined : this.formFilter.value.status})
      .filter(([_, v]) => v != '' &&  v != undefined));


    this.changePage(1);
  }

  backToHomePage() {
    const isFiserv = this._appPartner.getPartnerValue()?.partnerName === 'fiserv';
    const urlIncludesFiserv =  window.location.pathname.includes('fiserv');
    const shouldNotPutFiservInUrl = isFiserv && !urlIncludesFiserv;
    this._router.navigate([`${shouldNotPutFiservInUrl ? '/': this._appPartner.getPartnerValue()?.partnerName}`]);
  }

  shouldActiveFilter(name: string): boolean {
    return this.displayedFilters.some(e => e === name);
  }
  shouldShowFilters(): boolean {
    return this.displayedFilters.length > 0;
  }


  clearFilter() {
    this.filtersLoaded = undefined;
    this.selection.clear();
    this.formFilter.reset();
    this.changePage(1);
  }

  changePage(page: number) {

      this.selectedPage.emit({page, filters: this.filtersLoaded});
  }
    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected(): boolean {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource?.data.length;
      return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
      if (this.isAllSelected()) {
        this.selection.clear();
        return;
      }
      if(this.dataSource) {
        this.selection.select(...this.dataSource.data);
      }
    }

    isCheckboxChecked(invite: userList): boolean {
    return this.selection.selected.some(u => u.document === invite.document);
    }


  startDragging(e: MouseEvent ) {
    this.mouseDown = true;
    this.startX = e.pageX - this.slider.nativeElement.offsetLeft;
    this.scrollLeft = this.slider.nativeElement.scrollLeft;
  }
  stopDragging(e: MouseEvent ) {
    this.mouseDown = false;
  }
  moveEvent(e: MouseEvent ) {
    e.preventDefault();


    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - this.slider.nativeElement.offsetLeft;
    const scroll = x - this.startX;
    this.slider.nativeElement.scrollLeft = this.scrollLeft - scroll;
  }

  // Bottom of table
  counter() {
    if (!this.page) return;
    if (this.page < 7) return new Array(10);
    return new Array(this.page + 4);
  }


  showLinkNumber(index: number) {
    const pagesAfterAndBefore = window.screen.width > 700 ? 3 : 2;

    if (this.page) {
      if (this.page + pagesAfterAndBefore < index) {
        return false;
      }
      if (this.page - pagesAfterAndBefore > index) {
        return false;
      }
    }

    if (this.total_pages) {
      if (index > this.total_pages) {
        return false;
      }
    }

    return true;
  }




}

