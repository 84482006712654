import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { share, distinctUntilChanged, takeUntil, map, delay } from 'rxjs/operators';
import { ApiObservable } from 'src/app/shared/models/api-observable';

@Injectable({
  providedIn: 'root'
})
export class ApiStatusService {

  private _queue: Map<string, [BehaviorSubject<boolean>, ApiObservable<any>]> = new Map();
  private _isLoadingSubscribers: Set<string> = new Set();
  private _isLoading$ = new BehaviorSubject(false);
  private _unsubscribeIsLoading$ = new Subject<void>();
  private _subscription: Subscription | null = null;

  addObservable<T>(obs$: Observable<T>): ApiObservable<T> {
    const id = uuidv4();
    const newObs$ = ApiObservable.fromObservable(
      obs$,
      id,
      (i, isLoading$, apiObs$) => this._addSubscriber(i, isLoading$, apiObs$),
      (i) => this._addLoadingSubscriber(i),
      (i) => this._removeSubscriber(i)
    );

    return newObs$;
  }

  addObservableWithoutLoading<T>(obs$: Observable<T>): ApiObservable<T> {
    const id = uuidv4();
    const newObs$ = ApiObservable.fromObservable(
      obs$,
      id,
    );

    return newObs$;
  }

  private _addSubscriber<T>(id: string, isLoading$: BehaviorSubject<boolean>, obs$: ApiObservable<T>) {
    if (this._isLoadingSubscribers.has(id)) {
      this._isLoadingSubscribers.delete(id);
      return;
    }
    this._queue.set(id, [isLoading$, obs$]);

    this._updateSubscribersStatus();
  }

  private _addLoadingSubscriber(id: string) {
    this._isLoadingSubscribers.add(id);

    if (!this._queue.has(id))
      return;

    this._isLoadingSubscribers.delete(id);
    this._removeSubscriber(id);
    this._updateSubscribersStatus();
  }

  private _removeSubscriber(id: string) {
    this._queue.delete(id);
    this._isLoadingSubscribers.delete(id);
  }

  private _updateSubscribersStatus() {
    if (this._subscription != null) {
      this._subscription.unsubscribe();
      this._subscription = null;
    }

    this._subscription = combineLatest(this.loadingQueue$).pipe(
      delay(20),
      map(v => v.reduce((p, c) => p || c)),
      distinctUntilChanged()
    )
    .subscribe(e => this._isLoading$.next(e));
  }

  private get loadingQueue$(): BehaviorSubject<boolean>[] {
    return Array.from(this._queue.values()).map(o => o[0]);
  }

  get isLoading$(): Observable<any> {
    return this._isLoading$.pipe(
      distinctUntilChanged(),
      share(),
      takeUntil(this._unsubscribeIsLoading$)
    );
  }
}
