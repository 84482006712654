import { InstitutionCod, PathFromLogo, Partner, ServiceContract, Theme } from '../models';
import { PartnerEnum } from '../partner.enum';

export class Sicredi implements Partner {
  readonly theme: Theme = PartnerEnum.Sicredi;
  readonly logo: PathFromLogo = PartnerEnum.SicrediLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceSicredi;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionSicredi;
  readonly primaryColor: string = '#3FA110';
}
