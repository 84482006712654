import { PartnerEnum } from '../partner.enum';

export type PathFromLogo = typeof AllpathFromLogo[number];


const AllpathFromLogo = [
    PartnerEnum.BinLogo,
    PartnerEnum.ButterfieldLogo,
    PartnerEnum.CitibankLogo,
    PartnerEnum.CredicardLogo,
    PartnerEnum.CrefisaLogo,
    PartnerEnum.FiservLogo,
    PartnerEnum.HipercardLogo,
    PartnerEnum.InterLogo,
    PartnerEnum.ItauLogo,
    PartnerEnum.RedepopLogo,
    PartnerEnum.CaixaLogo,
    PartnerEnum.SicrediLogo,
    PartnerEnum.SipagLogo,
    PartnerEnum.AfinzLogo,
    PartnerEnum.ScotiaLogo,
    PartnerEnum.ScotiaChileLogo,
    PartnerEnum.MexicoLogo
] as const;
