import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InternalUserResolver } from './helpers/internal-user.resolver';
import { PageErrorNotFoundComponent } from 'src/app/shared/page-error-not-found/page-error-not-found.component';
import { PageErrorUnavailableComponent } from './shared/page-error-unavailable/page-error-unavailable.component';
import { PageErrorNotAuthorizedComponent } from './shared/page-error-not-authorized/page-error-not-authorized.component';
import { PartnerGuardService } from './partner-guard/partner.guard';
import { InternalRedirectGuardService } from './helpers/internalredirect.guard';

const routes: Routes = [

  { path: 'error-page-unavailable', component: PageErrorUnavailableComponent },
  { path: 'error-not-authorized', component: PageErrorNotAuthorizedComponent },
  {
    path: 'actuator',
    loadChildren: () => import('./site/actuator/actuator.module').then(m => m.ActuatorModule),
  },
  {
    path: 'login',
    canActivate: [InternalRedirectGuardService],
    component: PageErrorNotFoundComponent
  },
  {
    path: '',
    canActivate: [InternalRedirectGuardService],
    component: PageErrorNotFoundComponent
  },
  {
    path: 'channels',
    canActivate: [InternalRedirectGuardService],
    component: PageErrorNotFoundComponent
  },
  {
    path: 'fiserv',
    loadChildren: () => import('./site/fiserv/fiserv.module').then(m => m.FiservModule),
    resolve: {
      InternalUserResolver
    },
    data: {
      partner: 'fiserv',
      onlyPtBr: true
    }
  },
  {
    path: ':partner',
    loadChildren: () => import('./site/partner/partner.module').then(m => m.PartnerModule),
    canActivate: [PartnerGuardService],
  },
  { path: '**', component: PageErrorNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

