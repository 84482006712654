import { Injectable } from '@angular/core';
import tinycolor from 'tinycolor2';

export interface PaletteHex {
  [key: number]: string
}
 
@Injectable({
  providedIn: 'root',
})
export class ColorPaletteService {
  generatePalette(baseColor: string):PaletteHex {
    const baseLight = tinycolor(baseColor);
    const baseDark = this._multiply(tinycolor(baseColor).toRgb(), tinycolor(baseColor).toRgb());
		const baseTriad:any[] = tinycolor(baseColor).tetrad();

    const palette ={
    50: tinycolor(tinycolor.mix(baseLight, baseColor, 12)).toHexString(),
    100: tinycolor(tinycolor.mix(baseLight, baseColor, 30)).toHexString(),
    200: tinycolor(tinycolor.mix(baseLight, baseColor, 50)).toHexString(),
    300: tinycolor(tinycolor.mix(baseLight, baseColor, 70)).toHexString(),
    400: tinycolor(tinycolor.mix(baseLight, baseColor, 85)).toHexString(),
    500: tinycolor(tinycolor.mix(baseLight, baseColor, 100)).toHexString(),
    600: tinycolor(tinycolor.mix(baseDark, baseColor, 87)).toHexString(),
    700: tinycolor(tinycolor.mix(baseDark, baseColor, 70)).toHexString(),
    800: tinycolor(tinycolor.mix(baseDark, baseColor, 54)).toHexString(),
    900: tinycolor(tinycolor.mix(baseDark, baseColor, 25)).toHexString(),
    A100: tinycolor(tinycolor.mix(baseDark, baseTriad[4], 15)).saturate(80).lighten(65).toHexString(),
    A200: tinycolor(tinycolor.mix(baseDark, baseTriad[4], 15)).saturate(80).lighten(55).toHexString(),
    A400: tinycolor(tinycolor.mix(baseDark, baseTriad[4], 15)).saturate(100).lighten(45).toHexString(), 
    A700: tinycolor(tinycolor.mix(baseDark, baseTriad[4], 15)).saturate(100).lighten(40).toHexString(),}
 
    return palette;
  }
 private _multiply(rgb1:tinycolor.ColorFormats.RGBA, rgb2:tinycolor.ColorFormats.RGBA){
		rgb1.b = Math.floor(rgb1.b * rgb2.b / 255);
		rgb1.g = Math.floor(rgb1.g * rgb2.g / 255);
		rgb1.r = Math.floor(rgb1.r * rgb2.r / 255);
		return tinycolor('rgb ' + rgb1.r + ' ' + rgb1.g + ' ' + rgb1.b);
	};
  private _getColorObject(value: tinycolor.Instance, name: string) {
		const c = tinycolor(value);
		return {
			name: name,
			hex: c.toHexString(),
			darkContrast: c.isLight()
		};
	}
}