import { InstitutionCod, PathFromLogo, Partner, ServiceContract, Theme } from '../models';
import { PartnerEnum } from '../partner.enum';

export class Citibank implements Partner {
  readonly theme: Theme = PartnerEnum.Citibank;
  readonly logo: PathFromLogo = PartnerEnum.CitibankLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceCitibank;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionCitibank;
  readonly primaryColor: string = '#0F6CAE';
}
