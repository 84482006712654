import { Injectable } from '@angular/core';

import { ApiService } from './common/api.service';
import { ApiModule } from '../api.module';
import { ApiObservable } from 'src/app/shared/models/api-observable';


@Injectable({
  providedIn: ApiModule
})
export class OptinApiService {

  constructor(
    private api: ApiService
  ) { }

  acceptOptin({ optinId, key }: { optinId: number; key: string }): ApiObservable<void> {
    return this.api.post<{ optinId: number; key: string }, void>(`/v1/public/optin/user`, { optinId, key });
  }


  retrieveTextOptin(document: string): ApiObservable<{ optinText: string }> {
    return this.api.get<{ optinText: string }>(`/v1/public/optin/user/${document}`);
  }

}
