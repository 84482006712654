import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {
  private readonly _defaultConfig: MatSnackBarConfig = {
    duration: 3000,
    horizontalPosition: 'end',
    verticalPosition: 'top',
  };

  constructor(
    private _snackBar: MatSnackBar,
    private zone: NgZone
  ) { }

  showSuccess(message: string, action?: string | undefined) {
    this.zone.run(() => {
      this._snackBar.open(message, action, {
        ...this._defaultConfig,
        panelClass: ['success', 'notification']
      });
    });
  }

  showError(message: string, action?: string | undefined) {
    this.zone.run(() => {
      this._snackBar.open(message, action, {
        ...this._defaultConfig,
        panelClass: ['error', 'notification']
      });
    });
  }

  showErrorAndCheckAfterDismissed(message: string, action?: string | undefined): MatSnackBarRef<any> {
    return this._snackBar.open(message, action, {
      ...this._defaultConfig,
      panelClass: ['error', 'notification']
    });
  }
}
