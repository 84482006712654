import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { InviteApiService } from 'src/app/api/services/invite.service';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';

@Component({
  selector: 'app-external-user-document',
  templateUrl: './user-document.component.html',
  styleUrls: ['./user-document.component.scss']
})
export class ExternalUserDocumentComponent implements OnInit, OnDestroy {

  partner: Partner | undefined;

  email = new FormControl('', [Validators.required, Validators.email]);

  private subscription: Subscription = new Subscription();

  constructor(
    public _appPartner: AppPartner,
    private _activatedRoute: ActivatedRoute,
    private _inviteService: InviteApiService,
    private _globalizationService: GlobalizationService,
    private _router: Router,
  ) {
  }

  ngOnInit(): void {
    this._appPartner.getPartner().subscribe(el => this.partner = el);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  goToConfirmInformation(): void {

    const email = this.email.value;

    if(email) {
      this.subscription.add(this._inviteService.getInvite(email).subscribe((data) => {
        this._router.navigate(['confirm-invitation'],
          {
            relativeTo: this._activatedRoute,
            queryParams: { mfaId: data.mfaId, document: email }, skipLocationChange: true
          });
      }));
    }
  }



  getErrorMessage() {
    let field:string = this._globalizationService.translate('EMAIL');
    if (this.email.hasError('required')) {
      return  this._globalizationService.translate('ERRORS.FORMS.REQUIRED', {field});
    }

    return this.email.hasError('email') ? this._globalizationService.translate('ERRORS.FORMS.EMAIL', {field}) : '';
  }


}
