<div class="panel">

  <div class="logo-container">
    <img [src]="partner?.logo" alt="logo institucional">

  </div>
  <div class="title-container">
    <h2>{{'WELCOME'! | translate}}</h2>
    <p>
      {{'ENTER_LOGIN_EMAIL' | translate}}
    </p>
  </div>



  <div class="form-container">

    <div class="input-email">
      <mat-form-field appearance="fill">
        <mat-label>{{'EMAIL' | translate}}</mat-label>
        <input (keyup.enter)="email.valid && goToConfirmInformation()" matInput [formControl]="email" required>
        <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>
    </div>
   

    <div class="box-button-actions">
      <button
        color="primary"
        (click)="goToConfirmInformation()"
        mat-raised-button 
        [disabled]="!email.valid">
        {{'LOGIN'| translate}}
        <mat-icon>arrow_forward</mat-icon>
      </button>

    </div>

  </div>
  <div class="language-container">
    <app-language-selector></app-language-selector>

  </div>


</div>
