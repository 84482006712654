import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  @Input() typeRequired = 'image/svg+xml';
  @Input() acceptType = 'image/svg+xml';
  @Input() fileTextUsedFor = 'LOGO';
  @Input() fileTextType = 'SVG';
  @Input() optionalUpload = true;
  @Input() ShouldHaveImagePreview = true;
  @Input() imageUploaded:  string | ArrayBuffer | undefined | null;
  @Output() fileUploaded: EventEmitter<{ file: string | ArrayBuffer; size?: string }> = new EventEmitter();
  @Output() catchException: EventEmitter<{ info: string }> = new EventEmitter();


  private imageBase64?: string | ArrayBuffer;
  hasFile = false;
  fileName?: string;


  constructor(private _sanitizer: DomSanitizer) { }

  imagePreview(): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl('' + (this.imageBase64 || this.imageUploaded));
  }

  fileChoose(e: Event) {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    this.readfile(file);
  }
  fileDrop(e: DragEvent) {
    e.preventDefault();

    const file = e.dataTransfer?.files[0];

    if (file) {
      this.readfile(e.dataTransfer?.files[0]);
    }

  }
  readfile(file: File) {

    if (file.type !== this.typeRequired) {
      this.catchException.emit({ info: 'The file is not the type required' });
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = (event) => {

      if (event.target?.result) {
        this.imageBase64 = event.target?.result;
        this.hasFile = true;
        this.fileName = file.name;
        this.fileUploaded.emit({ file: event.target?.result });

      } else {
        this.catchException.emit({ info: 'Unidentified error' });
      }

    };

  }
  clearImage() {
    this.imageBase64 = undefined;
    this.hasFile = false;
    this.fileName = undefined;
    this.fileUploaded.emit({ file: '' });
  }

}
