<form [formGroup]="form" (ngSubmit)="sendName.emit(form.value)">
    <mat-card>
        <mat-card-title *ngIf="title" class="step2-title">

            <h2 id="titleChangeName">{{title}}</h2>
        </mat-card-title>
        <mat-card-subtitle *ngIf="subtitle">

            <p>{{subtitle}}</p>
        </mat-card-subtitle>

        <mat-card-content style="margin-top: 32px;">

            <app-form-control label="{{'NAME_LASTNAME' | translate}}" id="name"
                name="name" type="text">
            </app-form-control>

        </mat-card-content>

        <mat-card-actions>

            <button id="continueButton" style="width: 100%; height: 45px;"
                class="continue-button" mat-raised-button
                color="primary" [disabled]="!form.controls['name'].valid">{{'CONTINUE'
                | translate}}

                <mat-icon>arrow_forward</mat-icon>

            </button>

        </mat-card-actions>
    </mat-card>
</form>