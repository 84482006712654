import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';

@Component({
  selector: 'app-page-error',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss'],
})
export class PageErrorComponent {
  @Input() title = '';
  @Input() svgIcon = 'unplugged';
  @Input() iconStyle?: Partial<CSSStyleDeclaration> = {
    width: '120px',
    height: '120px',
  };

  partner: Partner | undefined;

  constructor(private readonly _appPartner: AppPartner) {
    this._appPartner.getPartner().subscribe((el) => (this.partner = el));
  }
}
