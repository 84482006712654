import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  InjectionToken,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './routes';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import GlobalizationHandler from './globalization/services/globalization-handler';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  provideHttpClient,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RefreshTokenInterceptor } from './api/interceptors/refresh-token.interceptor';
import { GeolocationInterceptor } from './api/interceptors/geolocation.interceptor';
import { HttpLoadingRequestInterceptor } from './api/interceptors/loading.interceptor';
import { ChannelAuthorizationInterceptor } from './api/interceptors/channelAuthorization.interceptor';
import { BaseUrlInterceptor } from './api/interceptors/base-url.interceptor';
import { LanguageInterceptor } from './api/interceptors/language.interceptor';
import { AuthInterceptor } from './api/interceptors/auth.interceptor';
import { HttpErrorInterceptorComponent } from './api/interceptors/http-error.interceptor';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';

import { MatIconRegistry } from '@angular/material/icon';
import { HelpersRegistry } from './helpers/helpers.registry';
import { GlobalErrorHandler } from './errors/global-error-handler';
import { provideToastr } from 'ngx-toastr';

export const HttpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, 'assets/i18n/', '.json');

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [MatIconRegistry, DomSanitizer],
      useFactory: HelpersRegistry.init,
    },

    provideHttpClient(),
    provideAnimations(),
    provideToastr(),
    importProvidersFrom([
      BrowserAnimationsModule,
      BrowserModule,
      HttpClientModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
      }),
      TranslateModule.forRoot({
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: GlobalizationHandler,
        },
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => HttpLoaderFactory(http),
          deps: [HttpClient],
        },
      }),
    ]),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GeolocationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoadingRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ChannelAuthorizationInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorComponent,
      multi: true,
    },
    provideRouter(routes),
  ],
};
