import { InstitutionCod, PathFromLogo, Partner, ServiceContract, Theme } from '../models';
import { PartnerEnum } from '../partner.enum';

export class Redepop implements Partner {
  readonly theme: Theme = PartnerEnum.Redepop;
  readonly logo: PathFromLogo = PartnerEnum.RedepopLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceRedepop;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionRedepop;
  readonly primaryColor: string = '#EC7000';
}
