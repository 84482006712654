import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot
} from '@angular/router';
import { ServiceContract } from 'src/partner/models';
import { PartnerEnum } from 'src/partner/partner.enum';
import { AuthApiService } from '../api/services/auth-api.service';
import { HelpersModule } from './helpers.module';
import { StorageService } from '../storage/services/storage.service';

@Injectable({ providedIn: HelpersModule })

export class AuthLoginGuard implements CanActivate {
    constructor(public auth: AuthApiService, public router: Router, private _storageService : StorageService) { }
    canActivate(route: ActivatedRouteSnapshot): boolean {

        // verify if user is logged
        const { isAuthenticated } = this.auth;

        const partner = route.paramMap.get('partner') || 'fiserv';
        
        // check if user comes from another domain/site and want to do login and be redirected
        const userWantToRedirectAfterLogin: boolean = route.queryParamMap.get('redirect_uri') ? true : false;

        // user type logged
        const { userType } = this.auth.userDetailsUsingPrefix('_'+partner+'_');
        
        // user type expected
        const { expectedUserType }: { expectedUserType?: string[] } = route.data;
        


        if (!userType || !expectedUserType?.includes(userType)) {
            return true;
        }

        // check if user logged is from different partner
        if ((userType === 'LATAM_ALLIANCE') && !this.isLatam(partner)) {
            return true;
        }

        if (isAuthenticated && !userWantToRedirectAfterLogin) {
            this.router.navigate([`${partner}/channels`]);
            return false;
        }
        // confirms that the user is not logged or do not want to redirect
        return true;
    }
    isLatam(partner: ServiceContract | null | string): boolean {
        const LatamPartnerList =
            [PartnerEnum.Scotia, PartnerEnum.ScotiaChile, PartnerEnum.Butterfield, PartnerEnum.Mexico] as Array<string>;
        return !!partner && LatamPartnerList.includes(partner);
    }
}



// import { Injectable } from '@angular/core';
// import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// import { AppPartner } from 'src/partner/partner.service';
// import { AuthApiService } from '../api/services/auth-api.service';

// import { CookieService } from '../api/services/cookie.service';

// import { HelpersModule } from './helpers.module';

// @Injectable({ providedIn: HelpersModule })

// export class FirstData implements CanActivate {
//     constructor(
//         private router: Router,
//         private cookieService: CookieService,
//         private _partner: AppPartner,
//         private _authAPI: AuthApiService
//     ) { }

//     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

//         if (this._authAPI.isFD) {
//             // authorised so return true
//             return true;
//         }

//         // not logged in so redirect to login page with the return url
//         // this.authenticationService.logout();

//         if (!this._partner.existPartner(route.url[1]?.toString())) {
//             this.router.navigate([`accessmanagement/login`])
//             return false;
//         } else {
//             this.router.navigate(['accessmanagement/error-page-not-found'])
//             return false;
//         }
//     }
// }

// @Injectable({ providedIn: HelpersModule })
// export class Alliance implements CanActivate {
//     constructor(
//         private router: Router,
//         private cookieService: CookieService,
//         private _partner: AppPartner,
//         private _authAPI: AuthApiService
//     ) { }

//     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

//         if (this._authAPI.isALLIANCE) {
//             // authorised so return true
//             return true;
//         }

//         // not logged in so redirect to login page with the return url
//         // this.authenticationService.logout();
//         if (!this._partner.existPartner(route.url[1]?.toString())) {
//             this.router.navigate(['accessmanagement/error-page-not-found'])
//             return false;
//         } else {
//             this.router.navigate([`accessmanagement/${this._partner.getPartnerValue()?.theme || 'bin'}/login`])
//             return false;
//         }

//         // return false;
//     }
// }

// @Injectable({ providedIn: HelpersModule })
// export class Merchante implements CanActivate {
//     constructor(
//         private router: Router,
//         private _partner: AppPartner,
//         private _authAPI: AuthApiService
//     ) { }

//     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

//         if (this._authAPI.isMERCHANTE) {
//             // authorised so return true
//             return true;
//         }

//         // not logged in so redirect to login page with the return url
//         // this.authenticationService.logout();
//         this.router.navigate([`accessmanagement/${this._partner.getPartnerValue()?.theme || 'bin'}/login`])
//         return false;
//     }
// }

// @Injectable({ providedIn: HelpersModule })
// export class UserAllianceWithToken implements CanActivate {
//     constructor(
//         private router: Router,
//         private _partner: AppPartner,
//         private _authAPI: AuthApiService
//     ) { }

//     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

//         if (this._authAPI.isALLIANCE) {

//             this.router.navigate([`accessmanagement/${this._partner.getPartnerValue()?.theme || 'bin'}/channels`])
//             return false;
//         }


//         return true;
//     }
// }
// @Injectable({ providedIn: HelpersModule })
// export class UserMerchantWithToken implements CanActivate {
//     constructor(
//         private router: Router,
//         private _partner: AppPartner,
//         private _authAPI: AuthApiService
//     ) { }

//     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

//         if (this._authAPI.isMERCHANTE) {

//             this.router.navigate([`accessmanagement/${this._partner.getPartnerValue()?.theme || 'bin'}/channels`])
//             return false;
//         }


//         return true;
//     }
// }
// @Injectable({ providedIn: HelpersModule })
// export class UserFirstDataWithToken implements CanActivate {
//     constructor(
//         private router: Router,
//         private _authAPI: AuthApiService
//     ) { }

//     canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

//         if (this._authAPI.isFD) {

//             this.router.navigate([`accessmanagement`])
//             return false;
//         }


//         return true;
//     }
// }
