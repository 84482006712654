import {
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { AuthApiService } from 'src/app/api/services/auth-api.service';
import { FormChangePasswordLoggedComponent } from '../change-password-logged/change-password-logged.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router, RouterLink } from '@angular/router';
import { finalize, Observable, Subscription, take } from 'rxjs';
import { ChannelApiService } from 'src/app/api/services/channel-api.service';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';
import { SnackBarService } from '../services/snackbar.service';
import { Language } from 'src/app/globalization/models/language';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { StorageService } from 'src/app/storage/services/storage.service';
import { CookieService } from 'src/app/api/services/cookie.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-user-name-authenticate',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonModule,
    TranslateModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './user-name-authenticate.component.html',
  styleUrls: ['./user-name-authenticate.component.scss'],
})
export class UserNameAuthenticateComponent implements OnInit, OnDestroy {
  readonly socialName: WritableSignal<string> = signal('');
  readonly partnerName: WritableSignal<string> = signal('');
  readonly partner: WritableSignal<Partner | undefined> = signal(undefined);
  readonly userCanChangePassword: WritableSignal<boolean> = signal(false);
  readonly languages: WritableSignal<Language[]> = signal(
    this._globalizationService.availableLanguages
  );
  readonly currentLanguage: WritableSignal<Language> = signal(
    this._globalizationService.currentLanguage
  );
  readonly disableChannelClick: WritableSignal<boolean> = signal(false);

  private readonly _subscription: Subscription = new Subscription();

  channel$?: Observable<string>;

  constructor(
    private readonly _authApiService: AuthApiService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _channelApiService: ChannelApiService,
    private readonly _appPartner: AppPartner,
    private readonly _globalizationService: GlobalizationService,
    private readonly _ngZone: NgZone,
    private readonly _router: Router,
    private readonly _cookieService: CookieService,
    private readonly _storageServe: StorageService,
    private readonly _snackBarService: SnackBarService,
    public readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.socialName.set(
      this._authApiService.getSocialName() || this._authApiService.getName()
    );
    this.userCanChangePassword.set(this._userCanChangePassword());
    this.partnerName.set(this._routeParams().get('partner') ?? 'fiserv');

    this.channel$ = this._channelApiService.channel$;

    this.disableChannelClick.set(
      !!this._activatedRoute.snapshot.data.disableChannelClick
    );

    this._subscription.add(
      this._appPartner.getPartner().subscribe({
        next: (el) => {
          this.partner.set(el);
        },
      })
    );
  }

  private _routeParams(): ParamMap {
    return this._activatedRoute.snapshot.paramMap;
  }
  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  logout(): void {
    this._subscription.add(
      this._authApiService
        .logout()
        .pipe(
          take(1),
          finalize(() => {
            this._ngZone.run(() =>
              this._router.navigate([`${this.partnerName()}/login`])
            );
          })
        )
        .subscribe()
    );
    this._storageServe.remove('userDetails');
    this._storageServe.remove('userChannel');
    this._cookieService.deleteFromDifferentPath('currentToken');
  }
  private _userCanChangePassword(): boolean {
    return this._authApiService.isALLIANCE;
  }

  changeLanguage(value: string): void {
    this._globalizationService.setCurrentLanguage(value);
    this.currentLanguage.set(this._globalizationService.currentLanguage);
  }

  private _userFirstName(): string {
    return this._authApiService.getName().split(' ')[0];
  }
  private _userLastName(): string {
    return this._authApiService.getName().split(' ')[1];
  }

  private _userEmail(): string {
    return this._authApiService.getEmail();
  }

  changePassword(): void {
    const dialog = this.dialog.open(FormChangePasswordLoggedComponent, {
      panelClass: 'change-password-logged-dialog-container',
      autoFocus: true,
      disableClose: true,
      data: {
        firstName: this._userFirstName(),
        lastName: this._userLastName(),
        email: this._userEmail(),
      },
    });
    dialog.afterClosed().subscribe({
      next: (response?: string) => {
        if (response) {
          this._snackBarService.showError(response);
        }
      },
    });
  }
}
