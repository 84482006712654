import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { PasswordValidators } from '../form-change-password/form-change-password.component';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { UserApiService } from 'src/app/api/services/user-api.service';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { LoadingService } from 'src/app/api/services/common/loading.service';
import { finalize, Subscription } from 'rxjs';

export interface FormPasswordInterface {
  oldPassword: FormControl<string | null>;
  newPassword: FormControl<string | null>;
  confirmNewPassword: FormControl<string | null>;
}

export type PasswordInterface = Partial<{
  oldPassword: string | null;
  newPassword: string | null;
  confirmNewPassword: string | null;
}>;

export interface ChangePasswordBody {
  oldPassword: string;
  newPassword: string;
}

@Component({
  selector: 'app-change-password-logged',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    TranslateModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
  templateUrl: './change-password-logged.component.html',
  styleUrls: ['./change-password-logged.component.scss'],
})
export class FormChangePasswordLoggedComponent implements OnDestroy {
  readonly newPasswordControl: FormControl = new FormControl('', [
    Validators.required,
    PasswordValidators.lengthRange,
    PasswordValidators.containsLowercase,
    PasswordValidators.containsUppercase,
    PasswordValidators.containsNumber,
    PasswordValidators.containsSpecialChar,
    PasswordValidators.noFirstName(this.data.firstName),
    PasswordValidators.noLastName(this.data.lastName),
    PasswordValidators.noEmail(this.data.email),
    PasswordValidators.noWhitespace,
    PasswordValidators.noIdenticalSequence,
  ]);
  readonly oldPasswordControl: FormControl = new FormControl('', [
    Validators.required,
  ]);
  readonly confirmNewPasswordControl: FormControl = new FormControl('', [
    Validators.required,
  ]);

  readonly passwordForm: FormGroup<FormPasswordInterface> =
    this._formBuilder.group(
      {
        oldPassword: this.oldPasswordControl,
        newPassword: this.newPasswordControl,
        confirmNewPassword: this.confirmNewPasswordControl,
      },
      { validators: this.passwordsMatch }
    );

  readonly hideOldPassword: WritableSignal<boolean> = signal(true);
  readonly hideNewPassword: WritableSignal<boolean> = signal(true);
  readonly hideConfirmNewPassword: WritableSignal<boolean> = signal(true);
  readonly shouldActiveInEveryUrl: WritableSignal<boolean> = signal(
    this._loadingService.shouldActiveInEveryUrl()
  );

  private readonly _subscription: Subscription = new Subscription();

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _userApiService: UserApiService,
    private readonly _loadingService: LoadingService,
    public dialogRef: MatDialogRef<FormChangePasswordLoggedComponent, string>,
    @Inject(MAT_DIALOG_DATA)
    public data: { firstName: string; lastName: string; email: string }
  ) {}

  ngOnDestroy(): void {
    this._loadingService.activeLoadingForEveryUrl(
      this.shouldActiveInEveryUrl()
    );
    this._subscription.unsubscribe();
  }

  toggleOldPasswordVisibility(): void {
    this.hideOldPassword.update((value) => !value);
  }
  toggleNewPasswordVisibility(): void {
    this.hideNewPassword.update((value) => !value);
  }

  toggleConfirmNewPasswordVisibility(): void {
    this.hideConfirmNewPassword.update((value) => !value);
  }

  passwordsMatch(group: AbstractControl): {
    passwordMismatch: boolean;
  } | null {
    const password = group.get('newPassword')?.value;
    const confirmPassword = group.get('confirmNewPassword')?.value;
    return password === confirmPassword ? null : { passwordMismatch: true };
  }

  submitForm(data: PasswordInterface): void {
    const { oldPassword, newPassword } = data;
    if (oldPassword && newPassword) {
      this._loadingService.activeLoadingForEveryUrl(true);
      this._subscription.add(
        this._userApiService
          .editUserPasswordWhenLogged({ oldPassword, newPassword })
          .pipe(
            finalize(() =>
              this._loadingService.activeLoadingForEveryUrl(
                this.shouldActiveInEveryUrl()
              )
            )
          )
          .subscribe({
            next: (response) => {
              this.dialogRef.close(response.message);
            },
          })
      );
    }
  }
}
