import { InstitutionCod, Partner, PathFromLogo, ServiceContract, Theme } from '../models';
import { PartnerEnum } from '../partner.enum';

export class Caixa implements Partner {
  readonly theme: Theme = PartnerEnum.Caixa;
  readonly logo: PathFromLogo = PartnerEnum.CaixaLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceCaixa;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionCaixa;
  readonly primaryColor: string = '#F39200';
}
