import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Directive({
  selector: '[appIsLoading]'
})
export class IsLoadingDirective implements OnChanges {
  @Input('appIsLoading') isLoading: boolean | null = false;

  private _spinnerFactory: ComponentFactory<MatProgressSpinner>;
  private _spinner: ComponentRef<MatProgressSpinner> | null = null;
  private _container: any;

  constructor(
    private _el: ElementRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _viewContainerRef: ViewContainerRef,
    private _renderer: Renderer2
  ) {
    this._spinnerFactory = this._componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);

    this._container = this._renderer.createElement('div');
    this._renderer.addClass(this._container, 'loader-container');

    this._renderer.insertBefore(this._el.nativeElement.parentElement, this._container, this._el.nativeElement);
    this._renderer.appendChild(this._container, this._el.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.isLoading)
      return;

    if (changes.isLoading.currentValue)
      this._createSpinner();
    else if (!changes.isLoading.currentValue && !changes.isLoading.firstChange)
      this._destroySpinner();
  }

  private _createSpinner(): void {
    if (!this._spinner) {
      this._renderer.addClass(this._el.nativeElement, 'hidden');

      this._spinner = this._viewContainerRef.createComponent(this._spinnerFactory);
      this._spinner.instance.diameter = 20;
      this._spinner.instance.mode = 'indeterminate';
      this._renderer.appendChild(this._container, this._spinner.location.nativeElement);
    }
  }

  private _destroySpinner(): void {
    if (this._spinner) {
      this._renderer.removeClass(this._el.nativeElement, 'hidden');

      this._spinner.destroy();
      this._spinner = null;
      this._viewContainerRef.clear();
    }
  }
}
