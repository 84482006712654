import { InstitutionCod, PathFromLogo, Partner, ServiceContract, Theme } from '../models';
import { PartnerEnum } from '../partner.enum';

export class ScotiaChile implements Partner {
  readonly theme: Theme = PartnerEnum.ScotiaChile;
  readonly logo: PathFromLogo = PartnerEnum.ScotiaChileLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceScotiaChile;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionScotiaChile;
  readonly primaryColor: string = '#EC111A';
}
