
import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot
} from '@angular/router';
import { AllPartnerItems, AllThemeItems, Partner } from 'src/partner/models';
import { PartnerEnum } from 'src/partner/partner.enum';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PartnerGuardModule } from './partner-guard.module';
import { PartnerAutomation, ServiceContractService } from './service-contracts.service';
import { AppPartner } from 'src/partner/partner.service';
import { ColorPaletteService, PaletteHex } from '../helpers/color-palette.service';
import { GlobalizationService } from '../globalization/services/globalization.service';
import { StorageService } from '../storage/services/storage.service';


@Injectable({providedIn: PartnerGuardModule})
export class PartnerGuardService implements CanActivate {


  private _partnerActive?:Partner;

    constructor( 
        private _serviceContractService: ServiceContractService,
        private colorPaletteService: ColorPaletteService,
        private _appPartner: AppPartner,
        private _globalizationService: GlobalizationService,
        private _storageService: StorageService,
        public router: Router
    ) 
        { }
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {

       
        const partner =  route.paramMap.get('partner') ?? '';

      
        if (AllThemeItems.some(p => (p === partner)) || (partner === '')) {
            this._partnerActive = AllPartnerItems.filter((t) => t.theme.includes(route.paramMap.get('partner')))[0];
        } 
        
        if(this._appPartner.getPartnerValue()) {
            return true
        }

        return this._serviceContractService.getPartnerInfo(partner).pipe(
          
            map(data => {
                if(data){
                    const primaryColor = data.primaryColor ?? this._partnerActive?.primaryColor ?? "#ff6600";
                    this.applyPartnerConfig(data, partner);
                    const palette = this.colorPaletteService.generatePalette(primaryColor);
                    this.updateTheme(palette)
                    this.setUserLanguage(partner,data.language)
                    this.updateVariableColor({primaryColor, secondaryColor: data.secondaryColor, backgroundColor: data.backgroundColor})
                    return true;

                } else {
                    this.router.navigateByUrl('accessmanagement/page-not-found', { skipLocationChange: true });
                    return false
                }
            }),
            catchError(() => {
                this._globalizationService.setTemporaryLanguage('pt-BR');
                this.router.navigateByUrl('accessmanagement/page-not-found', { skipLocationChange: true });
                return of(false)
            }) 
        ) 

    }

    applyPartnerConfig(data: PartnerAutomation, partnerName: string) {
    

        const newPartner = { 
          institutionCod: data.institutionCod, 
          channelType: data.channelType, 
          language: data.language, 
          channelClientId: data.channelClientId,
          logo: data.logo ?? this._partnerActive?.logo ?? PartnerEnum.FiservLogo ,
          serviceContract: data.serviceContractCod.toString(),
          partnerName: data.institutionName || partnerName,
          routerPartnerName: partnerName,
          theme: this._partnerActive?.theme ?? 'default'
        }
        this._appPartner.setPartner(newPartner);
    }

    updateTheme(palette: PaletteHex) {
      const root = document.documentElement;
      const arrayOfPalette:any = Object.keys(palette);
      for(let i = 0, len = arrayOfPalette.length; i < len; i++) {
        root.style.setProperty(`--palette-${arrayOfPalette[i]}`,palette[arrayOfPalette[i]])
      }

    }

    updateVariableColor({primaryColor, secondaryColor , backgroundColor}: any) {
        document.documentElement.style.setProperty('--primary-color', primaryColor || null);
        document.documentElement.style.setProperty('--background-color', backgroundColor || null);
        document.documentElement.style.setProperty('--secondary-color', secondaryColor || null);
    }

    setUserLanguage(partner: string, language: string = 'en-US') {
        const userHasSetLanguage = this._storageService.getUsingPrefix('current_language', `_${partner}_`)
        if(userHasSetLanguage) {
            this._globalizationService.setCurrentLanguageWithPrefix(userHasSetLanguage, `_${partner}_` );
        } else if(language) {
            this._globalizationService.setTemporaryLanguage(language)
        } else {
            this._globalizationService.setTemporaryLanguage("pt-BR")
        } 
    }
    
}
