import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthApiService } from 'src/app/api/services/auth-api.service';
import { CookieService } from 'src/app/api/services/cookie.service';
import { StorageService } from 'src/app/storage/services/storage.service';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';

@Component({
  selector: 'app-float-menu',
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    TranslateModule,
    OverlayModule,
    RouterModule,
  ],
  templateUrl: './float-menu.component.html',
  styleUrls: ['./float-menu.component.scss'],
})
export class FloatMenuComponent implements OnInit {
  @Input() logo?: any;
  @Input() showArrowBackButton = false;

  isOpen = true;
  extendsMenu = false;
  partner: Partner | undefined;
  mobileMenuActive = false;
  isFD = false;
  disableClose = true;
  activeHomeMenu = false;
  activeUsersInternalMenu = false;
  activeReportMenu = false;
  activeSettings = false;
  isSuperAdmin = false;

  private subscription: Subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    public location: Location,
    private _authApiService: AuthApiService,
    private _router: Router,
    private _appPartner: AppPartner,
    private _cookieService: CookieService,
    private _storageServe: StorageService,
    private _activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this._appPartner.getPartner().subscribe((el) => (this.partner = el))
    );
    this.isFD = this._authApiService.isFD;
    this.activeHomeMenu = this.shouldActiveHomeMenu();
    this.activeUsersInternalMenu = this.shouldActiveUsersInternalMenu();
    this.activeReportMenu = this.shouldActiveReportMenu();
    this.isSuperAdmin = this.userIsSuperAdmin();
    this.activeSettings = this.shouldActiveSettings();
  }

  closeMenu(): void {
    if (this.extendsMenu) {
      this.extendsMenu = false;
    }
  }

  showMobileMenu(): void {
    this.mobileMenuActive = !this.mobileMenuActive;
  }
  logout() {
    const routeParams = this._activatedRoute.snapshot.paramMap;
    const partnerName = routeParams.get('partner') || 'fiserv';

    this.subscription.add(
      this._authApiService
        .logout()
        .pipe(
          finalize(() => {
            this.dialog.closeAll();
            this._cookieService.deleteFromDifferentPath('currentToken');
            this._storageServe.remove('userDetails');
            this._storageServe.remove('userChannel');
            this._router.navigate([`${partnerName}/login`]);
          })
        )
        .subscribe()
    );
  }

  openInternalUsersList(): string[] {
    return [`/fiserv/users/internal`];
  }
  openReport(): string[] {
    return [`/fiserv/report-compliance`];
  }

  openInstitutionChannelList(): string[] {
    return [`/fiserv/settings`];
  }

  backToList() {
    if (this._activatedRoute.snapshot.data.pending) {
      this._router.navigate(['../../pending'], {
        relativeTo: this._activatedRoute,
      });
    } else if (this._activatedRoute.snapshot.data.user_logs) {
      this._router.navigate(['../../user_logs'], {
        relativeTo: this._activatedRoute,
      });
    } else {
      this.location.back();
    }
  }

  homeLink(): string[] {
    return [
      `/${this._appPartner
        .getPartnerValue()
        ?.routerPartnerName?.toLocaleLowerCase()}`,
    ];
  }

  private userIsSuperAdmin() {
    return this._authApiService
      .getProfileName()
      .includes('AP-LAC-10467-SGDA2Admin');
  }

  private shouldActiveHomeMenu(): boolean {
    const routeParams = this._activatedRoute.snapshot.paramMap;
    const partnerName = routeParams.get('partner') || 'fiserv';
    return partnerName === 'fiserv'
      ? this._router.url.endsWith('/fiserv')
      : this._router.url.endsWith(`${partnerName}/users`);
  }
  private shouldActiveUsersInternalMenu(): boolean {
    return this._router.url.includes('users/internal');
  }
  private shouldActiveReportMenu(): boolean {
    return this._router.url.includes('report-compliance');
  }
  private shouldActiveSettings(): boolean {
    return this._router.url.includes('settings');
  }
}
