import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';

interface RequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private _http: HttpClient) {}

  // get<P>(url: string, params?: HttpParams): Observable<P> {

  //   const obs$ = this._http.get<P>(url, {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }, params
  //   }, );

  //   return this._apiStatusService.addObservable(obs$);
  // }

  // getWithProgress<P>(url: string, params?: HttpParams): Observable<any> {

  //   const obs$ = this._http.get<P>(url, {
  //     observe: 'events',
  //     reportProgress: true,
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }, params
  //   }, );

  //   return this._apiStatusService.addObservableWithoutLoading(obs$);
  // }

  // downloadFile(url: string, filename: string = ''): void {
  //   this._http.get(url, { responseType: 'blob' as 'json' }).subscribe(
  //     (response: any) => {
  //       const dataType = response.type;
  //       const binaryData = [];
  //       binaryData.push(response);
  //       const downloadLink = document.createElement('a');
  //       downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
  //       if (filename)
  //         downloadLink.setAttribute('download', filename);
  //       document.body.appendChild(downloadLink);
  //       downloadLink.click();
  //     }
  //   );
  // }

  // postFormUrlEncoded<P>(url: string, body: HttpParams): Observable<P> {
  //   const obs$ = this._http.post<P>(url, body.toString(), {
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //     }
  //   });

  //   return this._apiStatusService.addObservable(obs$);
  // }

  // post<Q, P>(url: string, body: Q, refreshToken?: string): Observable<P> {
  //   const obs$ = this._http.post<P>(url, body, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   });

  //   return this._apiStatusService.addObservable(obs$);
  // }

  // postSendInvite<Q, P>(url: string, body: Q): Observable<P> {
  //   const obs$ = this._http.post<P>(url, body, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   });
  //   return this._apiStatusService.addObservable(obs$);
  // }

  // postWithFullResponse<Q, P>(url: string, body: Q, refreshToken?: string): Observable<HttpResponse<P>> {
  //   const obs$ = this._http.post<P>(url, body, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     observe: 'response',
  //     responseType: 'json'
  //   });

  //   return this._apiStatusService.addObservable(obs$);
  // }

  // put<Q, P>(url: string, body: Q, refreshToken?: string): Observable<P> {
  //   const obs$ = this._http.put<P>(url, body, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   });

  //   return this._apiStatusService.addObservable(obs$);
  // }

  // postCustomHeader<Q, P>(url: string, body: Q, header?: HttpHeaders): Observable<ApiResp<P>> {
  //   const obs$ = this._http.post<ApiResp<P>>(url, body, { headers: header });
  //   return this._apiStatusService.addObservable(obs$);
  // }

  // Método GET
  get<T>(endpoint: string, options?: RequestOptions): Observable<T> {
    return this._http
      .get<T>(endpoint, options)
      .pipe(map((response) => this.adapter(response)));
  }
  // Método POST
  post<Q, T>(
    endpoint: string,
    data: Q,
    options?: RequestOptions
  ): Observable<T> {
    return this._http
      .post<T>(endpoint, this.toApi(data), options)
      .pipe(map((response) => this.adapter(response)));
  }

  // Método PUT
  put<T>(endpoint: string, data: any, options?: RequestOptions): Observable<T> {
    return this._http
      .put<T>(endpoint, this.toApi(data), options)
      .pipe(map((response) => this.adapter(response)));
  }

  // Método DELETE
  delete<T>(endpoint: string, options?: RequestOptions): Observable<T> {
    return this._http
      .delete<T>(endpoint, options)
      .pipe(map((response) => this.adapter(response)));
  }

  // Adapter para transformar os dados vindos da API
  private adapter(response: any): any {
    // Transformar os dados de acordo com as necessidades da aplicação
    return response;
  }

  // Adapter para transformar os dados que vão para a API
  private toApi(data: any): any {
    // Transformar os dados para o formato esperado pela API
    return data;
  }
}
