import { TranslateParams } from './../globalization/models/translate-params';
import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';

import { SnackBarService } from '../shared/services/snackbar.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private _globalizationService: GlobalizationService,
    private _snackService: SnackBarService
  ) {}

  handleError(error: Error) {
    let message = 'ERRORS.DEFAULT';
    let params: TranslateParams | undefined;

    if (error instanceof HttpErrorResponse) {
      if (error.status > 0 && error.error?.errors) {
        const { code, description } = error.error.errors[0];

        message = `ERRORS.API.${code}`;
        params = new TranslateParams({
          fallback: description,
        });
      }
    }

    this._snackService.showError(
      this._globalizationService.translate(message, params)
    );
  }
}
