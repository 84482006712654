<div class="panel" id="external-user-done">
    <mat-stepper class="default-model"
        ariaLabel="default-model-index-{{stepper.selectedIndex}}"
        [linear]="isLinear"
        [selectedIndex]="currentStep" #stepper>
        <mat-step [editable]="isEditable">

        </mat-step>
        <mat-step [editable]="isEditable">

        </mat-step>
        <mat-step [editable]="isEditable">

        </mat-step>
        <mat-step [editable]="isEditable">
            <div id="welcome-container">
                <div>
                    <mat-icon svgIcon="checksquare"></mat-icon>
                </div>
                <h2>{{"YOUR_ACCESS_READY" | translate}} {{name}}!</h2>
                <p>{{"REMENBER_ACCESS" | translate}}</p>
            </div>
            <div id="form-container">
                <div id="box-button-actions">
                    <button type="submit" class="enter-login-external"
                        color="primary"
                        mat-raised-button (click)="goToLogin()">{{"LOG_IN" |
                        translate}}
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </div>
            </div>
        </mat-step>
    </mat-stepper>
</div>