import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { ValidatorsUtils } from '../validators/validators.utils';

@Component({
  selector: 'app-form-change-name',
  templateUrl: './form-change-name.component.html',
  styleUrls: ['./form-change-name.component.scss']
})
export class FormChangeNameComponent {


  @Input() title?: string;
  @Input() subtitle?: string;

  @Output() sendName: EventEmitter<{ name: string }> = new EventEmitter();

  form = this._fb.group({
    name: ['', [Validators.required, ValidatorsUtils.noWhitespaceValidator(), ValidatorsUtils.compositeNameValidator()]]
  });

  private _user_display_name = '';

  constructor(private _fb: UntypedFormBuilder) {}


  @Input() set user_display_name(value: string) {

    this._user_display_name = value;
    this.form.get('name')?.setValue(this.user_display_name);

  }

  get user_display_name(): string {

    return this._user_display_name;

  }


}
