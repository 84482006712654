<div class="main-container">
  <p class="show-screen" >{{"SCREEN_DEMONSTRATION" | translate}}</p>
  <div [style.background]="!isDefaultGraphical ? selectBackground: 'white'" class="background">
  <div class="panel" id="external-user-insert-document">
    <div class="entrance">
      <div class=logo-container>
        <img [src]="isDefaultGraphical ? partner?.logo : logo">
      </div>

      <h2>{{'WELCOME'! | translate}}</h2>
    </div>

    <div class="register-design">
      <div class="input-email">
        <span>{{"EMAIL" | translate}}</span>
        <mat-icon class="mat-icon-rtl-mirror" class="chevron-config" svgIcon="chevron-right2"></mat-icon>
      </div>

      <div class="input-pass">
        <span>{{"PASSWORD" | translate}}</span>
        <mat-icon matSuffix id="pw-login-icon" class="eyes-config" svgIcon="eyes-closed"></mat-icon>
      </div>

      <div class="forgot-pass" [style.color]="!isDefaultGraphical ? linksColor: '#FF6600'" >
        <p>{{"FORGET_MY_PASSWORD" | translate}}</p>
      </div>

      <div class="enter-button" [style.background]="!isDefaultGraphical ? buttonsColor: '#FF6600' ">
        <span>{{"ENTER" | translate}}</span>
        <mat-icon>arrow_forward</mat-icon>
      </div>

      <div class="first-access">
        <div [style.color]="!isDefaultGraphical ? linksColor: 'rgba(0, 0, 0, 0.87)'" >{{"FIRST_ACCESS" | translate }}</div>
        <!-- <mat-icon class="arrow-icon">arrow_forward</mat-icon> -->
      </div>

    </div>
  </div>
</div>
</div>