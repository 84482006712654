import { Component, Input } from '@angular/core';
import loadingJson from '@assets/lottie/loading.json';
import partnerLoading from '@assets/lottie/partner-load.json';
import { PartnerEnum } from 'src/partner/partner.enum';
import { AppPartner } from 'src/partner/partner.service';


export declare type LoaderType = 'determinate' | 'indeterminate';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  @Input() value = 0;
  @Input() type: LoaderType = 'indeterminate';
  loadingPathJson?: any;

  constructor(private _appPartner: AppPartner) {

    this._appPartner.getPartner().subscribe(p => {
      if (p && (p.theme === PartnerEnum.Fiserv)) {
        this.loadingPathJson = loadingJson;
      } else {
        this.loadingPathJson = partnerLoading;
      }
    });

  }


}
