import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordValidators } from '../form-change-password/form-change-password.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserApiService } from 'src/app/api/services/user-api.service';

export interface FormPasswordInterface {
  oldPassword: FormControl<string | null>; 
  newPassword:FormControl<string | null>; 
  confirmNewPassword: FormControl<string | null>; 
}

export type PasswordInterface = Partial<{ oldPassword: string | null; newPassword: string | null; confirmNewPassword: string | null; }>

export interface ChangePasswordBody {
  oldPassword: string; 
  newPassword: string
}

@Component({
    selector: 'app-change-password-logged',
    templateUrl: './change-password-logged.component.html',
    styleUrls: ['./change-password-logged.component.scss'],
  })

export class FormChangePasswordLoggedComponent {
    
    passwordForm: FormGroup<FormPasswordInterface>;
    
    hideOldPassword = true;
    hideNewPassword = true;      
    hideConfirmNewPassword = true;

    constructor(
      private _formBuilder: FormBuilder,
      private _userApiService: UserApiService,
      public dialogRef: MatDialogRef<FormChangePasswordLoggedComponent, string>,
      @Inject(MAT_DIALOG_DATA) public data: { firstName: string; lastName:string; email:string }
      ){
        this.passwordForm = this._formBuilder.group({
            oldPassword: ['', 
              Validators.required,
            ],
            newPassword: ['', 
              [
                Validators.required,
                PasswordValidators.lengthRange,
                PasswordValidators.containsLowercase,
                PasswordValidators.containsUppercase,
                PasswordValidators.containsNumber,
                PasswordValidators.containsSpecialChar,
                PasswordValidators.noFirstName(data.firstName),
                PasswordValidators.noLastName(data.lastName),
                PasswordValidators.noEmail(data.email),
                PasswordValidators.noWhitespace,
                PasswordValidators.noIdenticalSequence
              ]              
            ],
            confirmNewPassword: ['', Validators.required]
          }, {validators: this.passwordsMatch});
    }


    toggleOldPasswordVisibility(): void {
        this.hideOldPassword = !this.hideOldPassword;
    }
    toggleNewPasswordVisibility(): void {
        this.hideNewPassword = !this.hideNewPassword;
    }

    toggleConfirmNewPasswordVisibility(): void {
        this.hideConfirmNewPassword = !this.hideConfirmNewPassword;
    }

    passwordsMatch(group: AbstractControl):{
      passwordMismatch: boolean;
    } | null {
      const password = group.get('newPassword')?.value;
      const confirmPassword = group.get('confirmNewPassword')?.value;
      return password === confirmPassword ? null : {passwordMismatch: true}
    }

    submitForm(data: PasswordInterface) {
      const {oldPassword, newPassword } = data;
      if(oldPassword && newPassword ) {
        this._userApiService.editUserPasswordWhenLogged({oldPassword, newPassword }).subscribe({
          next: (response) => {
            this.dialogRef.close(response.message)
          },
        })
      }
    }

}
