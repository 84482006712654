import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import icons from './icons.json';
import logos from './logos.json';
import flags from './flags.json';
export class HelpersRegistry {

  static init(
    iconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,

  ): () => Promise<void> {

    for (const icon of icons.icons) {
      iconRegistry.addSvgIcon(icon, domSanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/${icon}.svg`));
    };
    for (const logo of logos.logos) {
      iconRegistry.addSvgIcon(logo, domSanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/partner/${logo}.svg`));
    };

    for (const flag of flags.flags) {
      iconRegistry.addSvgIcon(flag, domSanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/flags/${flag}.svg`));
    };

    return () => Promise.resolve();

  }


}



