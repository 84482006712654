import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoleUserEnum } from 'src/app/api/enums/role-user.enum';
import { AuthApiService } from 'src/app/api/services/auth-api.service';


@Component({
  selector: 'app-modal-warning-renewal',
  templateUrl: './modal-warning-renewal.component.html',
  styleUrls: ['./modal-warning-renewal.component.scss']
})
export class ModalWarningRenewalComponent implements OnInit {

  renewalPeriodEnd: string;
  constructor(
    public dialogRef: MatDialogRef<ModalWarningRenewalComponent>, @Inject(MAT_DIALOG_DATA)
     public data: { renewalPeriodEnd: string },
     private _authService: AuthApiService) {
    this.renewalPeriodEnd = data.renewalPeriodEnd;
  }

  isRenewerExternalUser(): boolean {
    return this._authService.hasRoles([RoleUserEnum.SGDA2_RENEWAL_EXTERNAL_USERS]);
  }

  ngOnInit(): void {

  }

}
