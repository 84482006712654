import { PartnerEnum } from '../partner.enum';

export type Theme = typeof AllThemeItems[number];

export type AllTheme = Required<typeof AllThemeItems>;

export const AllThemeItems = [
    PartnerEnum.Scotia,
    PartnerEnum.Bin,
    PartnerEnum.Butterfield,
    PartnerEnum.Caixa,
    PartnerEnum.Citibank,
    PartnerEnum.Credicard,
    PartnerEnum.Crefisa,
    PartnerEnum.Fiserv,
    PartnerEnum.Hipercard,
    PartnerEnum.Itau,
    PartnerEnum.Inter,
    PartnerEnum.Redepop,
    PartnerEnum.Sicredi,
    PartnerEnum.Sipag,
    PartnerEnum.Afinz,
    PartnerEnum.ScotiaChile,
    PartnerEnum.Mexico
] as const;

