import {
  Component,
  importProvidersFrom,
  Inject,
  NgZone,
  OnInit,
} from '@angular/core';
import { AuthApiService } from 'src/app/api/services/auth-api.service';
import { FormChangePasswordLoggedComponent } from '../change-password-logged/change-password-logged.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { finalize, Observable, take } from 'rxjs';
import { ChannelApiService } from 'src/app/api/services/channel-api.service';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';
import { SnackBarService } from '../services/snackbar.service';
import { Language } from 'src/app/globalization/models/language';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { StorageService } from 'src/app/storage/services/storage.service';
import { CookieService } from 'src/app/api/services/cookie.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-user-name-authenticate',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonModule,
    TranslateModule,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './user-name-authenticate.component.html',
  styleUrls: ['./user-name-authenticate.component.scss'],
})
export class UserNameAuthenticateComponent implements OnInit {
  socialName?: string;
  channel$?: Observable<string>;
  partnerName?: string;
  partner: Partner | undefined;

  userCanChangePassword: boolean = false;

  readonly languages: Language[];
  currentLanguage: Language;

  disableChannelClick: boolean = false;

  constructor(
    private readonly _authApiService: AuthApiService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _channelApiService: ChannelApiService,
    private readonly _appPartner: AppPartner,
    private readonly _globalizationService: GlobalizationService,
    private readonly _ngZone: NgZone,
    private readonly _router: Router,
    private readonly _cookieService: CookieService,
    private readonly _storageServe: StorageService,
    @Inject(MAT_SNACK_BAR_DEFAULT_OPTIONS)
    private readonly _snackBarService: SnackBarService,
    public readonly dialog: MatDialog
  ) {
    this.languages = _globalizationService.availableLanguages;
    this.currentLanguage = _globalizationService.currentLanguage;
  }
  ngOnInit() {
    this.socialName =
      this._authApiService.getSocialName() || this._authApiService.getName();
    const routeParams = this._activatedRoute.snapshot.paramMap;
    this.partnerName = routeParams.get('partner') ?? 'fiserv';
    this.channel$ = this._channelApiService.channel$;

    this.disableChannelClick =
      !!this._activatedRoute.snapshot.data.disableChannelClick;

    // Disabled change password
    //this.userCanChangePassword = this._userCanChangePassword();

    this._appPartner.getPartner().subscribe({
      next: (el) => {
        this.partner = el;
      },
    });
  }

  // ngOnDestroy() {
  //   this._subscription.unsubscribe();
  // }

  logout() {
    const partnerName =
      this._appPartner
        .getPartnerValue()
        ?.routerPartnerName?.toLocaleLowerCase() ?? 'fiserv';
    this._authApiService
      .logout()
      .pipe(
        take(1),
        finalize(() => {
          this._ngZone.run(() =>
            this._router.navigate([`${partnerName}/login`])
          );
        })
      )
      .subscribe();
    this._storageServe.remove('userDetails');
    this._storageServe.remove('userChannel');
    this._cookieService.deleteFromDifferentPath('currentToken');
  }
  private _userCanChangePassword(): boolean {
    // only Alliance user can change password
    return this._authApiService.isALLIANCE;
  }

  changeLanguage(value: string): void {
    this._globalizationService.setCurrentLanguage(value);
    this.currentLanguage = this._globalizationService.currentLanguage;
  }

  changePassword() {
    const firstName = this._authApiService.getName().split(' ')[0];
    const lastName = this._authApiService.getName().split(' ')[1];
    const email = this._authApiService.getEmail();

    const dialog = this.dialog.open(FormChangePasswordLoggedComponent, {
      panelClass: 'change-password-logged-dialog-container',
      autoFocus: true,
      disableClose: true,
      data: { firstName, lastName, email },
    });
    dialog.afterClosed().subscribe({
      next: (response?: string) => {
        if (response) {
          this._snackBarService.showError(response);
        }
      },
    });
  }
}
