import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { WINDOW } from '../partner.module';
import { animate, style, transition, trigger } from '@angular/animations';
import { UntypedFormBuilder, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AppPartner } from '../../../../partner/partner.service';
import { Partner, PartnerUrlParams } from '../../../../partner/models/index';
import { ApiObservable } from '../../../shared/models/api-observable';
import { AuthApiService } from 'src/app/api/services/auth-api.service';
import { CookieService } from 'src/app/api/services/cookie.service';
import { StorageService } from 'src/app/storage/services/storage.service';
import { Authenticate } from 'src/app/api/models/authentication-resp';
import { RenewalApiService } from 'src/app/api/services/renewal.service';
import { ToastrService } from 'ngx-toastr';
import { LocationService } from 'src/app/api/services/location.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { UserTypeEnum } from 'src/app/api/enums/user-type.enum';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { PathLocationStrategy } from '@angular/common';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-external-login',
  templateUrl: './external-login.component.html',
  styleUrls: ['./external-login.component.scss'],
  animations: [
    trigger('downToTop', [
      transition('void => *', [
        style({ opacity: '0', transform: 'translateY(-10%)' }),
        animate('500ms', style({ opacity: '1', transform: 'translateY(0%)' })),

      ])

    ]),
  ],
})
export class ExternalLoginComponent implements OnInit, OnDestroy {

  partner: Partner | undefined;
  userValid = false;
  minLength = 9;

  state?: {
    email: string;
  };

  form = this._fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(this.minLength)]]
  });

  form2 = this._fb.group({
    email: ['', [Validators.required, Validators.email]],
    newPassword: ['', [Validators.required, Validators.minLength(this.minLength), Validators.maxLength(64), this.forbiddenNameValidator()]]
  });


  userHasToken = false;

  auth_data: Authenticate = new Authenticate({});

  obs$: ApiObservable<Record<string, unknown>> | null = null;

  amount = 6;

  expiredPassword = false;
  inactiveRegistration = false;
  userMFAforChangePasswordCompleted = false;
  first_name = '';
  last_name = '';
  email = '';

  client_id?: string;
  redirect_uri?: string;

  private _state?: string;

  userCanLogWithoutEmail:boolean = false;

  private subscription: Subscription = new Subscription();

  constructor(
    @Inject(WINDOW) private window: Window,
    private _fb: UntypedFormBuilder,
    private renewalApiService: RenewalApiService,
    private _authService: AuthApiService,
    private _appPartner: AppPartner,
    private _cookieService: CookieService,
    private router: Router,
    private toastr: ToastrService,
    private storageServe: StorageService,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private snackBarService: SnackBarService,
    private _globalizationService: GlobalizationService,
    private pathLocationStrategy: PathLocationStrategy
  ) { }

  ngOnInit(): void {

    this._appPartner.getPartner().subscribe(el => this.partner = el);

    if(this.partner?.channelType?.toLowerCase() === 'grafana') {
      this.form.get('email')?.removeValidators(Validators.email);
      this.userCanLogWithoutEmail = true;
    }


    /*
    * Checks if the user becomes from another application
    * and want only to authentication in this application and back to de origin.
    * client_id and redirect_uri are used
    */


    const { client_id, redirect_uri, user_name } = this.splitParamsFromUrl();

    if (user_name) {
      this.form.controls.email.setValue(user_name);
    }

    // verify if client_id and redirect_uri exists as queryParam
    if (client_id && redirect_uri) {
      // validate client_id and redirect_uri

      this.subscription.add(this.locationService.validateClientIdAnRedirectURI(client_id, redirect_uri).subscribe({
        complete: () => {
          this.client_id = client_id;
          this.redirect_uri = redirect_uri;

          if(this.route.snapshot.queryParams.state) {
            this._state = this.route.snapshot.queryParams.state;
          }


        },
        error: (err) => {

          if (err?.error?.errors && err.error.errors[0]?.code === 628) {
            this.router.navigateByUrl('error-not-authorized', { skipLocationChange: true }).then(() => {
              this.snackBarService.showError(this._globalizationService.translate('URI_INVALID'));
            });
          }
        }
      }));

    } else {
      /*
      * Check if user is authenticated but doesn't have a type
      * it's happens when a user is logging but the endpoint that gives userDetails fails
      * in this case we gonna to call user details again and redirect the user
      * but in case it's fails again, we just reload the page
      */

      if (!this._authService.userDetails.userType && this._authService.isAuthenticated) {
        this.subscription.add(this._authService.getUserDetails().subscribe({
          next: (userData) => {
            if (userData.userType === UserTypeEnum.ALLIANCE) {
              this.router.navigate(['../']);
            }
          },
          error: () => {
            this._cookieService.deleteAll();
            this.storageServe.remove('userDetails');
            this.storageServe.remove('userChannel');
          }
        }));
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  handleEnter($event: Event): void {

    $event.preventDefault();

    if(this.expiredPassword || this.inactiveRegistration) {
      // TODO
    } else if (this.userValid) {
      this.submit();
    } else {
      this.validEmail();
    }
  }

  splitParamsFromUrl(): PartnerUrlParams {


    const absolutePathWithParams = this.pathLocationStrategy.path();

    const path = this.route.snapshot.fragment
      ? decodeURIComponent(`${absolutePathWithParams}#${this.route.snapshot.fragment}`)
      : decodeURIComponent(absolutePathWithParams);

    const splitParams = path.replace(/^.*login\?/, '').split('&').reduce((acc, curr) => {
      const [key, val] = curr.split('=');
      const obj = { [key]: val };
      return { ...obj, ...acc };
    }, {} as PartnerUrlParams);

    return splitParams;
  }

  submit(): void {
    const { email, password } = this.form.value;

    this.subscription.add(this._authService.login(email, password, this.redirect_uri, this.client_id).subscribe(
      {
        next: (data) => {
          this._cookieService.deleteFromDifferentPath('currentToken');
          this.auth_data = new Authenticate(data);
          this.userHasToken = true;
        },
        error: (err) => {
          const { code, description } = err?.error?.errors?.[0];
          const snackBarError = this.snackBarService.showErrorAndCheckAfterDismissed(description);
          if (code === 624) {
            snackBarError.afterDismissed().subscribe(() => this.navigationForget());
          }
        }
      }
    ));
  }

  changeUser(): void {
    this.userValid = false;
    this.userHasToken = false;
    this.expiredPassword = false;
    this.inactiveRegistration = false;
  }

  validEmail(): void {

    const { email } = this.form.value;


    if(email.includes('@')) {

      this.obs$ = this._authService.validEmail(email);

      this.subscription.add(this.obs$.subscribe(
        {
          complete: () => {
            this.expiredPassword = false;
            this.inactiveRegistration = false;
            this.userValid = true;
          },
          error: (err) => {
            if (!err?.error?.errors) {
              return;
            }
            const { code, description } = err.error.errors[0];
  
            if (code === 624) {
              this.expiredPassword = true;
              this.userValid = true;
              this.form2.patchValue({ email });
            }
            if (code === 625) {
              this.userValid = true;
              this.inactiveRegistration = true;
            }
            if (code === 404) {
              this.snackBarService.showError(description);
            }
            this.form.updateValueAndValidity();
          }
        }
      ));

    } else if (this.userCanLogWithoutEmail) {
      const existingParams = new URLSearchParams(window.location.search);
      existingParams.set('user_name', email)
      window.open(`fiserv/login?${existingParams.toString()}`, '_self');
    }

  }

  authCompleted(token: {
    accessToken: string;
    refreshToken: string;
    scope: string;
    idToken: string;
    tokenType: string;
    expiresIn: number;
    redirectUri?: string;
    authorizationCode?: string;
    serviceContract?: string;
    institutionCod?: string;
  }): void {

    if (token.redirectUri && token.authorizationCode) {
      let url = `${token.redirectUri}?code=${token.authorizationCode}`;
      if (token.serviceContract) {
        url = `${url}&service_contract=${token.serviceContract}`;
      }
      if (token.institutionCod) {
        url = `${url}&institutionCode=${token.institutionCod}`;
      }
      if (this.client_id) {
        url = `${url}&client_id=${this.client_id}`;
      }
      if(this._state) {
        url = `${url}&state=${this._state}`;
      }
      
      window.open(url, '_self');
      return;
    }

    this._cookieService.deleteFromDifferentPath('currentToken');
    this.storageServe.remove('userDetails');
    this.storageServe.remove('userChannel');

    const routeParams = this.route.snapshot.paramMap;
    const partnerName = routeParams.get('partner');

    this._cookieService.set('currentToken', JSON.stringify(token),
      {
        path: window.location.pathname.includes('accessmanagement') ? `/accessmanagement/${partnerName}` :
          `/${partnerName}`, expires: token.expiresIn
      });
    this.subscription.add(this._authService.getUserDetails()
      .pipe(finalize(() => this.router.navigate(['../channels'], { relativeTo: this.route })))
      .subscribe());

  }

  startNewPasswordProcess() {
    this.subscription.add(this.renewalApiService.generateMfaToRenewPassword({ email: this.form2.get('email')?.value }).subscribe(r => {
      this.auth_data = new Authenticate(r);
      this.userHasToken = true;
    }));
  }
  authResetPassword({ firstName, lastName }: { firstName: string; lastName: string }): void {
    this.first_name = firstName;
    this.last_name = lastName;
    this.email = this.form2.get('email')?.value;
    this.userMFAforChangePasswordCompleted = true;
  }
  receivePassword() {
    const password = this.form2.get('newPassword')?.value;
    this.form2.disable();
    this.subscription.add(this.renewalApiService.setNewPassword({ mfaId: this.auth_data.mfaId, password })
      .subscribe(
        {
          complete: () => {
            this.window.location.reload();
          },
          error: (err) => {
            this.form2.enable();
            if (err?.error?.errors && err.error.errors[0]?.description) {
              this.toastr.error(err.error.errors[0].description, '',
                { positionClass: 'toast-top-center', disableTimeOut: true, toastClass: 'ngx-toastr external-login-toast-error' });
            }
          }
        })
    );
  }


  forbiddenNameValidator(): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = new RegExp(this.first_name).test(control.value) ||
        new RegExp(this.last_name).test(control.value) ||
        new RegExp(this.email.split('@')[0]).test(control.value);
      return forbidden ? { forbiddenName: { value: control.value } } : null;
    };
  }
  navigationForget() {
    this.router.navigate(['../forgot-password'], {
      relativeTo: this.route, queryParams:
        { user_name: this.form.value.email, redirect_uri: this.redirect_uri, client_id: this.client_id }
    });
  }
}
