import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phone',
})
export class PhonePipe implements PipeTransform {

    transform(value: string): string {
        return value.length > 11 ?
        value.replace(/(\d{2})(\d{2})(\d{5})(\d{3})/, '$1 $2 $3 $4') : value.replace(/(\d{2})(\d{5})(\d{4})/, '$1 $2 $3');
    }

}
