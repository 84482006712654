import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';
import { GroupApiService } from 'src/app/api/services/group-api.service';
import { userList } from 'src/app/api/models/group-resp';

import { AuthApiService } from 'src/app/api/services/auth-api.service';
import { FormControl, UntypedFormBuilder, Validators, } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserApiService } from 'src/app/api/services/user-api.service';
import { UserListReq } from 'src/app/api/models/user-list';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { Subscription, Observable } from 'rxjs';
import { InviteApiService } from 'src/app/api/services/invite.service';
import { ProfilesInfo } from 'src/app/api/models/profiles-resp';
import { RoleUserEnum } from 'src/app/api/enums/role-user.enum';

import { ChannelApiService } from 'src/app/api/services/channel-api.service';
import { PartnerEnum } from 'src/partner/partner.enum';
import { ImportApiService } from 'src/app/api/services/import.service';
import { ApiObservable } from 'src/app/shared/models/api-observable';

import { statusCodesError } from 'src/app/api/interceptors/http-error-interceptor';
import { ModalWarningComponent } from 'src/app/shared/modal-warning/modal-warning.component';
import { User } from 'src/app/api/models/import-req';
import { ModalConfirmationComponent } from 'src/app/shared/modal-confirmation/modal-confirmation.component';
import loadingJson from '@assets/lottie/dotsloading.json';
import { ImportTableAllianceExternalComponent } from '../import-table-alliance/import-table-alliance-external.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { Filters } from 'src/app/shared/table-list-invited-alliance/table-list-invited-alliance.component';

import { InvitedUser } from 'src/app/api/models/invite';
import { ModalConfirmationInvitationComponent } from 'src/app/shared/modal-confirmation-invitation/modal-confirmation-invitation.component';
import { Location } from '@angular/common';
import { FiltersLogs } from 'src/app/shared/table-user-logs/table-user-logs.component';
import { ContentDetailLogs, LogsApiService } from 'src/app/api/services/logs.service';
import { TableUserLogsDescriptionComponent } from 'src/app/shared/table-user-logs-description/table-user-logs-description.component';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ModalWarningRenewalComponent } from 'src/app/shared/modal-warning-renewal/modal-warning-renewal.component';
import { ModalRenewalComponent } from 'src/app/shared/modal-renewal/modal-renewal.component';
import { RenewalApiService } from 'src/app/api/services/renewal.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpParams } from '@angular/common/http';

interface Group {name: string; id:number};

export enum TabId {
  Users = 0,
  Pending = 1,
  Logs = 2,
  Groups = 3,
}

@Component({
  selector: 'app-external-user-list',
  templateUrl: './external-user-list.component.html',
  styleUrls: ['./external-user-list.component.scss'],
  animations: [
    trigger('TopToDown', [
      transition('void => *', [
        style({ opacity: '0', transform: 'translateY(-10%)' }),
        animate('1000ms', style({ opacity: '1', transform: 'translateY(0%)' })),

      ])

    ]),
  ]
})
export class ExternalUserListComponent implements OnInit, OnDestroy {

  @ViewChild('matTabsContainer', {static: false}) tabGroup?: MatTabGroup;


  tabId = TabId;

  channel$?: Observable<string>;
  channelSelected?: string;
  partner: Partner | undefined;
  list?: userList[];
  profileList?: ProfilesInfo[];

  pageUsersList = 1;
  totalPagesUsersList?:number
  result_count = 0;
  remaining_pages = 0;

  pagePendingList = 1;
  totalPagesPendingList?: number;
  totalElementsPendingList?: number;
  userName?: string;
  userEmail?: string;

  activeExternalUsersFilters?: any;


  disabledUsersList = false;

  showMobileMenu = false;
  allianceActiveFilters?: Partial<Filters>;
  filterNoActive = false;

  profileActiveName? = '';

  filterOthers = false;
  customFilter = false;
  readOnlyProfile = false;
  activeFilter?: number;
  showLabel = true;
  othersFiltersActive = false;
  requestError = false;
  othersFilterModal = false;
  form = this.formBuilder.group({
    name: [null],
    email: [null],
    document: [null],
    status: [null],
    userRole: [null],
  });


  links?: Array<{ label: string; id: number }>;

  displayedColumns = ['name','userDomainType', 'email','active'];
  displayedFilters = ['name', 'profile', 'status'];

  pendingInvitesUsers?: any[];
  pendingInvitesUsersAlliance?: InvitedUser[];
  pendingInvitesUsersGrafana?: InvitedUser[];
  showUploadComponent = false;
  newImportationDialog:  MatDialogRef<any, any> | undefined;
  obs$: ApiObservable<User[]> | null = null;
  requestedObs?: Subscription;
  loadingPathJson = loadingJson;


  toastText = '';
  showToastConfirmation = false;
  showToastConfirmationWarning = false;
  timeOutMessageResend: string | number | NodeJS.Timeout | undefined;
  timeOutMessageUploadedFile: string | number | NodeJS.Timeout | undefined;


  selectedIndexTab: undefined | number;

  userHasPermissionToSeePendingList: boolean = false;

  partnerCanViewLogsTabs: boolean = false;
  listUsersLog?: ContentDetailLogs[];
  pageUserLogs = 1;
  totalPagesUserLogs?: number;
  institutions?: Array<{ serviceContractCod: string; serviceContractName: string }>;
  filterLogsLoaded?: Partial<FiltersLogs>;


  groupsList?:{
    name: string,
    id: number
  }[]


  pageGroups = 1;
  totalPagesGroups?:number;
  totalElementsGroups?:number;
  dataSourceGroupTable?:MatTableDataSource<Group>;
  displayedGroupData: any[] = [];
  displayedColumnsGroups:string[] = ['name'];
  filterGroupControl:FormControl<string | null> = new FormControl('');

  isAlliance: boolean = false;
  userCanInvite: boolean = false;
  canUploadUsers:boolean = false;
  isGrafana:boolean = false;
  isLatamAlliance:boolean = false;

  partnerName?: string;

  searchWithQueryParams = false;

  private _subscription: Subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _appPartner: AppPartner,
    private formBuilder: UntypedFormBuilder,
    private _groupApi: GroupApiService,
    private _userApiService: UserApiService,
    private _channelApiService: ChannelApiService,
    private _renewalService: RenewalApiService,
    private _importApiService: ImportApiService,
    private _router: Router,
    private _logsApiService: LogsApiService,
    private _authService: AuthApiService,
    private _userApi: UserApiService,
    private _globalizationService: GlobalizationService,
    private _inviteApi: InviteApiService,
    private _activatedRoute: ActivatedRoute,
    private _changeDetectorRef: ChangeDetectorRef,
    private _location: Location
  ) { }
  ngOnInit(): void {
    this.channel$ = this._channelApiService.channel$;
    this.isAlliance = this._isAlliance();
    this.userCanInvite = this._userCanInvite()
    this.canUploadUsers = this._canUploadUsers();
    this.isGrafana = this._isGrafana();
    this.isLatamAlliance = this._isLatamAlliance();


    const routeParams = this._activatedRoute.snapshot.paramMap;
    this.partnerName = routeParams.get('partner') || '';

    this._subscription.add(this._appPartner.getPartner().subscribe(el =>{
      this.partner = el
      if(el?.serviceContract){
      this.institutions = [ {serviceContractCod: el.serviceContract, serviceContractName: el.theme}]}
    }));
    this._subscription.add(this._groupApi.getProfile().subscribe(p => this.profileList = p?.profiles));

    this.partnerCanViewLogsTabs = this._partnerCanViewLogsTabs();
    this.channelSelected = this.route.snapshot.paramMap.get('channel') ?? '';

    this.getNameUser();

    this.userHasPermissionToSeePendingList = this._authService.hasRoles(RoleUserEnum.SGDA2_VIEW_INVITE_PENDING_LIST)

    if (!this.partnerDoNotHaveCpf()) {
      this.form.controls.document.setValidators([Validators.pattern('^([0-9]){3}([0-9]){3}([0-9]){3}([0-9]){2}$')]);
    }


    if(this.isAlliance) {

      this.displayedColumns = ['socialName', 'name', 'document', 'profileName', 'email', 'creatorName', 'active'];
      this.displayedFilters = ['email', 'profile','creatorName', 'status'];
    }

    if(this.isGrafana) {
      this.displayedColumns = ['socialName', 'name', 'email', 'profileName', 'creatorName', 'active'];
      this.displayedFilters = ['email', 'creatorName'];
    }

    if (this.isLatamAlliance) {
      this.displayedColumns = ['socialName', 'name', 'userDomainType', 'email', 'active'];
      this.displayedFilters = ['name', 'profile', 'status', 'serviceContract'];
    }

    this.searchWithQueryParams = true;

    if (this._authService.userIsAdmin()) {

      this.userEmail = this._authService.getEmail();

      this._subscription.add(this._renewalService.checkAccessRenewalPeriod().subscribe(r => {
        if (r && typeof r.renewalId === 'number' && typeof r.putOff === 'boolean' && r.renewalPeriodEnd) {
          this.showRenewal({ renewalId: r.renewalId, putOff: r.putOff, renewalPeriodEnd: r.renewalPeriodEnd });
        }
      }));
    }
    this._selectInitialTab();
    if((this.tabUrlIndex() === 0) && !this.selectedIndexTab) {
      this._loadData(0)
    }
    
  }

  private _selectInitialTab() {

    this._changeDetectorRef.detectChanges();

    this.tabGroup?._allTabs.map((el, index) => {

      const tabId= Number(el?.content?.viewContainerRef.element.nativeElement.attributes?.['id']?.value);

      if(this.tabUrlIndex() === tabId) {
        this.selectedIndexTab = index;
      }
    })

  }

  onTableChange(event: MatTabChangeEvent) {

    const selectedTab = event.tab;
    // refere-se aos id que tem em cada mat-tab
    const tabId= Number(selectedTab?.content?.viewContainerRef.element.nativeElement.attributes['id'].value);

    this._loadData(tabId);

  }

  private _loadData(tabId: TabId) {

    const mapping = {
      [TabId.Users]: {
        loader: () => {
          !this.list && this.selectedPage({}), this.replaceUrlWith('')
        }
      },
      [TabId.Pending]: {
        loader: () => {
        !this.pendingInvitesUsersAlliance && this.searchUsersPendingInvite({}),
         this.replaceUrlWith('/pending' , this.searchWithQueryParams?
        this._activatedRoute.snapshot.queryParams : this.allianceActiveFilters)
        }
      },
        [TabId.Logs]: {
          loader: () => {
            !this.listUsersLog && this.searchUserLogs({}), this.replaceUrlWith('/user-logs')
          }
        },
        [TabId.Groups]: {loader: () => {
          !this.dataSourceGroupTable && this.searchGroups(), this.replaceUrlWith('/groups')
        }
      },
    }

    mapping[tabId].loader()

  }


  private tabUrlIndex(): number | undefined {
    return this._activatedRoute.snapshot.data.tab;
  }


  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private showRenewal({
    renewalId,
    putOff,
    renewalPeriodEnd
  }: { renewalId: number; putOff: boolean; renewalPeriodEnd: string }): void {

    const showWarningRenewal = this.dialog.open(ModalWarningRenewalComponent, {
      closeOnNavigation: false,
      disableClose: true,
      hasBackdrop: false,
      width: '100vw',
      height: 'auto',
      maxHeight: '126px',
      panelClass: 'renewal-warning',
      maxWidth: '100vw',
      position: { bottom: '0' },
      data: { renewalPeriodEnd }
    });


    showWarningRenewal.afterClosed().subscribe((response) => {

      if (response && this.userEmail) {
        this._subscription.add(this._userApiService.getUsersListCreatedBy(this.userEmail).subscribe(r => {
          if (!r.length) {
            return;
          }
          this.dialog.open(ModalRenewalComponent, {
            closeOnNavigation: false,
            disableClose: true,
            width: '100vw',
            height: '699px',
            panelClass: 'renewal-users-list',
            maxHeight: '88vh',
            maxWidth: '100vw',
            position: { bottom: '0' },
            data: {
              renewalId,
              putOff,
              renewalPeriodEnd,
              list: r
            },

          });
        }));
      }
    });
  }

  private _isGrafana():boolean {
    return this._channelApiService.isGrafana();
  }

  private _isLatamAlliance():boolean {
    return this._channelApiService.isLatamAlliance();
  }

  searchUserLogs({page = 1,filters}: {page?: number; filters?: Partial<FiltersLogs>}) {


    const query:any = Object.fromEntries(Object
      .entries({ page, serviceContractFilter: this.partner?.serviceContract, isListUser: true, size: 10, ...filters})
      .filter(([_, v]) => v != null));


      this._subscription.add(this._logsApiService.retrieveDetailLogs(query).subscribe({
        next: (r) => {

          this.totalPagesUserLogs = r.totalPages;
          this.pageUserLogs = r.pageable.pageNumber;
          this.listUsersLog = r.content;
          this.filterLogsLoaded = filters
        },
      }))


  }


  detailsUserLogsDescription(item: ContentDetailLogs) {

    let query:any = {
      userName: item.userName,
      isListUser: false,
      serviceContractFilter: this.institutions && this.institutions[0].serviceContractCod,
      page: 1,
      size: 10,
    };

    query = Object.fromEntries(Object
      .entries(query)
      .filter(([_, v]) => v != null));


      this._subscription.add(this._logsApiService
      .retrieveDetailLogs(query)
        .subscribe({
          next: (value) => {

              this.dialog.open(TableUserLogsDescriptionComponent, {
                disableClose: false,
                panelClass: 'logs-description',
                autoFocus: false,
                width: '95vw',
                maxWidth: 'fit-content',
                data: {
                  logs: value,
                  serviceContractFilter: this.institutions && this.institutions[0].serviceContractCod,
                  user: item
                },
              })



          },
        }
     ))
  }


  showOthersFilterModal(): void {
    if (window.innerWidth <= 556) {
      this.showMobileMenu = true;
    } else {

      this.othersFiltersActive = !this.othersFiltersActive;
    }
  }

  showOthersFilter(): void {
    this.othersFiltersActive = !this.othersFiltersActive;
  }


  showProfileName(p: number): string {

    if (!this.profileList || !p) {
      return '';
    }
    return this.profileList?.filter(el => el.id === p)[0].description;
  }

  compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id === object2.id;
  }


  goToInviteUser(): Array<string> {
    return [`${this.invitePending() ? '../invite' : 'invite'}`];
  }

  searchByProfileId(id: number): void {

    const params: any = {
       page: 1,
       userRole: id,
       serviceContract: this.partner?.serviceContract,
       institutionCod: this.partner?.institutionCod };

    const query = new UserListReq(params);


    this._subscription.add(this._userApi.getUsersByServiceContract(query).subscribe({
      next: (r) => {
        this.list = r.users;
        this.totalPagesUsersList = r.remainingPages + 1
        this.pageUsersList = r.page || 1;
        this.disableFilters();
        const profile = id;
        this.activeFilter = profile;
        this.customFilter = false;
        this.form.reset();
        this.othersFiltersActive = false;
      },
      error: () => this.requestError = true
    }));

  }



  selectedPage({page = 1,filters}: {page?: number; filters?: Partial<Filters>}): void {

    const params = Object.fromEntries(Object
    .entries({
      page,
      serviceContract: this.partner?.serviceContract,
       institutionCod: this.partner?.institutionCod, ...filters })
    .filter(([_, v]) => v != '' &&  v != undefined));

  const query = new UserListReq(params);

  this._subscription.add(this._userApi.getUsersByServiceContract(query).subscribe({
    next: (r) => {
      this.list = r.users;
      this.pageUsersList = r.page || 1;
      this.totalPagesUsersList = r.remainingPages + page;
      this.activeExternalUsersFilters = params;
    },
    error: () => this.requestError = true
  }));

  }

  deleteInvitesBatch({list, filters, page}: {list: number[]; filters?: Partial<Filters> | undefined; page?: number}) {

    if(page && this.pendingInvitesUsers && (list.length === this.pendingInvitesUsers.length) && (page > 1)) {
      page = page - 1;
    }

    const multiInvites: boolean = list.length > 1;

    const title = multiInvites ?
    this._globalizationService.translate('CONFIRM_DELETE_INVITE_MULTI' ,{amount: list.length}):
    this._globalizationService.translate('CONFIRM_DELETE_INVITE' ,{amount: list.length});

    const yesText = this._globalizationService.translate('YES_CONFIRM');
    const noText = this._globalizationService.translate('NO_CANCELED');


    const toastTextTranslated = multiInvites ?
    this._globalizationService.translate('DELETED_INVITATIONS_MULTI'):
    this._globalizationService.translate('DELETED_INVITATIONS');


     const dialogRef = this.dialog.open(ModalConfirmationInvitationComponent, {
       panelClass: 'cancel-invite-batch',
       width: '429px',
       height: '212px',
       autoFocus: false,
       data: {
         title,
         yesText,
         noText
       }
     });

     dialogRef.afterClosed().subscribe(result => {
      if(result) {

        this._subscription.add(this._inviteApi.deleteInviteBatch(list).subscribe({
          next: () => {
           this.searchUsersPendingInvite({filters, page});
          },
          complete: () => {
            this.toastText = toastTextTranslated;
            this.showToastConfirmationWarning = true;
            this.showToastConfirmation = true;
            clearTimeout(this.timeOutMessageUploadedFile);
            this.timeOutMessageUploadedFile =  setTimeout(() => {
              this.showToastConfirmation = false;
              this.showToastConfirmationWarning = false;
            }, 10000);
          }
        }));
      }
     });


  }
  resendInvitesBatch({list, filters, page}: {list: number[]; filters?: Partial<Filters> | undefined; page?: number}) {

    const multiInvites: boolean = list.length > 1;


    const title = multiInvites ?
    this._globalizationService.translate('CONFIRM_REINVITE_MULTI' ,{amount: list.length}):
    this._globalizationService.translate('CONFIRM_REINVITE' ,{amount: list.length});

     const yesText = this._globalizationService.translate('YES_CONFIRM');
     const noText = this._globalizationService.translate('NO_CANCELED');


     const toastTextTranslated = multiInvites ?
    this._globalizationService.translate('RESEND_SUCCESSFULLY_MULTI'):
    this._globalizationService.translate('RESEND_SUCCESSFULLY');



     const dialogRef = this.dialog.open(ModalConfirmationInvitationComponent, {
       panelClass: 'cancel-invite-batch',
       width: '429px',
       height: '212px',
       autoFocus: false,
       data: {
         title,
         yesText,
         noText
       }
     });


     dialogRef.afterClosed().subscribe(result => {

      if(result) {
        this._subscription.add(this._inviteApi.resendInviteBatch(list).subscribe({
          next: () => {
            this.searchUsersPendingInvite({filters, page});
          },
          complete: () => {
            this.toastText = toastTextTranslated;
            this.showToastConfirmation = true;
            clearTimeout(this.timeOutMessageUploadedFile);
            this.timeOutMessageUploadedFile =  setTimeout(() => {
              this.showToastConfirmation = false;
            }, 10000);
          },
          error: (err) => {
              /*
              / O código 910 indica que o convite já foi deletado.
              / > Remove o usuário da lista de convites pendentes.
              */
              if(err.error?.errors && err.error.errors[0].code === 920) {
                this.searchUsersPendingInvite({filters, page: 1 });
              }
              throw err;
          },
        }));
      }
     });

  }



  disableFilters(): void {
    this.filterNoActive = false;
    this.profileActiveName  = undefined;
    this.filterOthers = false;
    this.readOnlyProfile = false;
  }

  getNameUser() {
    this.userName = this._authService.getName();
  }


  private searchGroups() {
    this._groupApi.getAll().subscribe({
      next: (groups) => {
        this.dataSourceGroupTable = new MatTableDataSource(groups);
        this.searchGroupWithFilter();
        this.filterGroupControl.valueChanges.subscribe(() => {
          this.searchGroupWithFilter();
        })
      }
    })
  }

  searchGroupWithFilter() {
    this.pageGroups = 1;
    this.updateDisplayedData();
  }

  changePageGroup(page: number) {
    this.pageGroups = page;
    this.updateDisplayedData();
  }

  private updateDisplayedData() {
    if(this.pageGroups && this.dataSourceGroupTable) {
      const start = (this.pageGroups - 1) * 10;
      const end = start + 10;
      this.applyGroupFilters(start,end)
    }
  }

  private applyGroupFilters(start = 0, end = 10) {
     if(this.dataSourceGroupTable) {
      const filteredData = this.dataSourceGroupTable.data.filter(item => {
        return item.name.toLowerCase().includes(this.filterGroupControl.value?.toLocaleLowerCase() || '')
      });
      this.displayedGroupData = filteredData.slice(start, end);
      this.totalPagesGroups = Math.ceil(filteredData.length / 10);
      this.totalElementsGroups = filteredData.length;

     }
  }

  searchUsersPendingInvite({page = 1,filters}: {page?: number; filters?: Partial<Filters>}) {
    if(this.searchWithQueryParams) {
      filters = this._activatedRoute.snapshot.queryParams;
    }

    if(this.isAlliance || this.isGrafana) {
      this._inviteApi.getExternalUsersPendingInviteAlliance({page, filters}).subscribe((data) => {

        this.pendingInvitesUsersAlliance = data.content;
        this.pendingInvitesUsersGrafana = data.content;
        this.totalElementsPendingList = data.totalElements;
        this.totalPagesPendingList = data.totalPages;
        this.pagePendingList = page;
        this.othersFiltersActive = false;
        this.filterNoActive = true;
        this.activeFilter = undefined;
        this.customFilter = false;
        this.allianceActiveFilters = filters;
        this.searchWithQueryParams = false;

        const query = Object.fromEntries(Object
          .entries({...filters})
          .filter(([_, v]) => v != '' &&  v != undefined));

        this.replaceUrlWith('/pending', query)

        this._router.navigate([], {relativeTo: this._activatedRoute, queryParams: query, queryParamsHandling: '', replaceUrl: false})

      });

    } else {

      this._inviteApi.getExternalUsersPendingInvite().subscribe((data) => {

        this.pendingInvitesUsers = data.sort((a: { name: string }, b: { name: string }) => (a.name > b.name ? 1 : -1));
        this.othersFiltersActive = false;
        this.filterNoActive = true;
        this.activeFilter = undefined;
        this.customFilter = false;

      });
    }

  }

  private replaceUrlWith(path: string, queryParams?:any) {
        // Cria um objeto HttpParams a partir do objeto de parâmetros
        let params = new HttpParams();
        for (const key in queryParams) {
          if (queryParams.hasOwnProperty(key)) {
            params = params.set(key, queryParams[key]);
          }
        }

        // Converte HttpParams em string
        const queryParamsString = params.toString();

        // Pega a URL atual sem queryParams e atualiza com o parâmetro passado
        const currentUrl = this._location.path().split('?')[0].replaceAll(/\/(?:pending|user-logs|groups)\b/g, '') + path;

        // Atualiza a URL usando location.go
        this._location.go(`${currentUrl}${queryParamsString ? '?'+queryParamsString : ''}`);
  }

  selectedPendingUser($event: InvitedUser) {
    const newUrl = this._location.path().split('?')[0].replaceAll('/pending', '') + '/invite';
    this._router.navigate([newUrl, $event.userId]);
  }


  _userCanInvite(): boolean {
    return this._authService.hasRoles([RoleUserEnum.SGDA2_INVITE_EXTERNAL_USER]);
  }

  EditLabelText(label: string): string {
    return window.innerWidth > 556 ? label : label.split(' ')[0];
  }


  showImportationModal(templateRef: TemplateRef<any>, event?: MouseEvent) {
    event?.stopPropagation()
    this.newImportationDialog = this.dialog.open(templateRef, {
          panelClass: 'upload-float-file',
          autoFocus: false,

    });
  }
  sendDataToExtract(data: { file: string | ArrayBuffer; size?: string }) {
    const institutionCod  = Number(this._appPartner.getPartnerValue()?.institutionCod);
    const serviceContractCod  = Number(this._appPartner.getPartnerValue()?.serviceContract);

    if (typeof data.file === 'string' && institutionCod && serviceContractCod) {

      this.obs$ = this._importApiService
        .extractUsersFromXlsx(
          {file:data.file.replace('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,', ''),
          serviceContractCod,institutionCod});
      this.requestedObs = this.obs$.subscribe({
        next: (list) => {
          this.openConfirmationModal(list);
        },
        error: (error) => {

          if (!statusCodesError.includes(error.status)) {
            this.showErrorModal(error.error?.errors);
          }

        }
      });

    }
  }
  showErrorModal(errorList?: { code: number; type: string; description: string }[]) {
    let text;

    const title = this._globalizationService.translate('THERE_WAS_A_PROBLEM_IMPORTING');
    const description = this._globalizationService.translate('REVIEW_THE_INFORMATION_FOR_THE_FILE_BEING_IMPORTED_AND_TRY_AGAIN');

    if (errorList && Array.isArray(errorList) && errorList[0]?.description) {
      text = [];

      for (const error of errorList) {
        text.push(`${error.description}\n`);
      }

    }
    this.dialog.open(ModalWarningComponent, {
      width: '429px',
      height: '320px',
      autoFocus: false,
      panelClass: 'fiserv-modal-warning-importation-list',
      data: {
        title,
        description,
        dataDownloadTextPlain: text,
        fileName: `error list`,
        showErrorFromImportation: !!text
      }
    });
  }


  openConfirmationModal(list: User[]) {

    this.dialog.open(ImportTableAllianceExternalComponent, {
      disableClose: true,
      panelClass: 'data-confirm-list',
      autoFocus: false,
      width: '95vw',
      maxWidth: 'fit-content',
      data: {
        list,
        serviceContractName: this._appPartner.getPartnerValue()?.partnerName
      }
    }).afterClosed().subscribe({
      next: (response) => {
        if (response) {
          this.sendListToUpload(response);
        } else {
          this.confirmCancel(list);
        }
      }
    });

  }
  confirmCancel(list: User[]) {
    const title = this._globalizationService.translate('ARE_YOU_SURE_YOU_WANT_TO_STOP_THE_IMPORT');
    const description = this._globalizationService.translate('BY_INTERRUPTING_THIS_IMPORT_NO_INFORMATION_WILL_BE_MAINTAINED');
    const yesText = this._globalizationService.translate('YES_INTERRUPT');
    const noText = this._globalizationService.translate('NO_KEEP');

    this.dialog.open(ModalConfirmationComponent, {
      width: '429px',
      height: '269px',
      panelClass: 'confirm-cancel-upload',
      data: {
        title,
        description,
        yesText,
        noText
      }
    }).afterClosed().subscribe(result => {

      if (!result) {
        this.openConfirmationModal(list);

      }

    });
  }

  cancelRequest() {
    this.requestedObs?.unsubscribe();
  }


  sendListToUpload(list: User[]) {
    const institutionCod  = this._appPartner.getPartnerValue()?.institutionCod;
    const serviceContract  = this._appPartner.getPartnerValue()?.serviceContract;
    const toastText = this._globalizationService.translate('IMPORT_COMPLETED_SUCCESSFULLY');

    if(institutionCod && serviceContract) {

      const importUserObs = this._importApiService.uploadUsersFromXlsx(
        {
          list,
          institutionCod: Number(institutionCod),
          serviceContractCod: Number(serviceContract)
        });


        importUserObs.subscribe({
          error: (error) => {
            if (!statusCodesError.includes(error.status)) {
              this.showErrorModal(error.error?.errors);
            }
          },
          complete: () => {
              if(this.pendingInvitesUsersAlliance || this.pendingInvitesUsersGrafana) {
                this.searchUsersPendingInvite({page: this.pagePendingList, filters: this.allianceActiveFilters});
              }
              this.newImportationDialog?.close();
              this.toastText = toastText;
              this.showToastConfirmation = true;
              clearTimeout(this.timeOutMessageUploadedFile);
              this.timeOutMessageUploadedFile =  setTimeout(() => {
                this.showToastConfirmation = false;
              }, 5000);
          }
        });
    }


  }

  showFilterText(): string {
    return window.innerWidth <= 556 ? '' : this._globalizationService.translate('FILTERS');
  }


  partnerDoNotHaveCpf(): boolean {
    const partner = this._activatedRoute.snapshot.paramMap.get('partner');
    const LatamOrCaribe = [PartnerEnum.Scotia, PartnerEnum.ScotiaChile, PartnerEnum.Butterfield, PartnerEnum.Mexico] as Array<string>;
    return (partner && LatamOrCaribe.includes(partner)) || false;
  }


  invitePending(): boolean {
    return this._activatedRoute.snapshot.data.pending;
  }

  private _isAlliance(): boolean {
   return this._appPartner.isAlliance();
  }

  private _partnerCanViewLogsTabs(): boolean {
    const partner = this._activatedRoute.snapshot.paramMap.get('partner') || '';
    const Alliance = [PartnerEnum.Sicredi, PartnerEnum.Afinz] as Array<string>;
    return Alliance.some(p => p.toLocaleLowerCase() === partner.toLocaleLowerCase());
  }

  private _canUploadUsers(): boolean {
    const profileName = this._authService.getProfileName();
    return ['AP-LAC-10467-ChannelAdmin', 'ADMINISTRADOR_ALLIANCE'].includes(profileName) && this.isAlliance;
  }

}

