<div class="panel" id="external-user-name">
    <mat-stepper class="default-model"
        ariaLabel="default-model-index-{{stepper.selectedIndex}}"
        [linear]="isLinear"
        [selectedIndex]="currentStep" #stepper>
        <mat-step [editable]="isEditable"></mat-step>

        <mat-step [editable]="isEditable">
            <div id="welcome-container">
                <h2>{{"WE_ARE_ALMOST_THERE" | translate}}</h2>
                <p>{{"FINALLY_CONFIRM_YOUR_NAME_TREATED" | translate}}</p>
            </div>
            <div id="form-container">
                <app-form (submit)="createUser()"
                    [EnablePopState]="EnablePopState" [formGroup]="form">
                    <app-form-control label="{{'NAME' | translate}}" id="name"
                        name="name" type="text" [maxLength]="maxLength">
                    </app-form-control>
                    <p class="mt-15">{{"UP_TO_CHARACTERS" | translate:
                        {characters: maxLength} }}</p>
                    <div id="box-button-actions">
                        <button 
                            class="enter-login-external" 
                            id="onlydocument"
                            type="submit" mat-raised-button
                            [disabled]="!form.valid">
                            {{"CONTINUE" | translate}}
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </div>
                </app-form>
            </div>
        </mat-step>
        <mat-step [editable]="isEditable"></mat-step>
        <mat-step [editable]="isEditable"></mat-step>
    </mat-stepper>
</div>