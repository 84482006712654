import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PageErrorComponent } from '../page-error/page-error.component';

@Component({
  selector: 'app-page-error-not-found',
  standalone: true,
  imports: [TranslateModule, PageErrorComponent],
  templateUrl: './page-error-not-found.component.html',
  styleUrls: ['./page-error-not-found.component.scss'],
})
export class PageErrorNotFoundComponent {}
