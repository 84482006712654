import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';

import { AppPartner } from '../partner/partner.service';

import { AppRoutingModule } from './app-routing.module';
import { ErrorHandlerModule } from './errors/error-handler.module';
import { GlobalizationModule } from './globalization/globalization.module';
import { HelpersModule } from './helpers/helpers.module';
import { SharedModule } from './shared/shared.module';

import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import GlobalizationHandler from './globalization/services/globalization-handler';

import { AuthInterceptor } from './api/interceptors/auth.interceptor';
import { AuthApiService } from './api/services/auth-api.service';
import { ApiService } from './api/services/common/api.service';
import { LoadingOverlayService } from './shared/services/loading-overlay.service';
import { OverlayService } from './shared/services/overlay.service';
import { DatepickerLanguageService } from './api/services/datepickerLanguage.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';


import { PartnerModule } from './site/partner/partner.module';
import { PartnerGuardModule } from './partner-guard/partner-guard.module';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HelpersModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    GlobalizationModule,
    ErrorHandlerModule,
    SharedModule,
    PartnerGuardModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: GlobalizationHandler
      },
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => HttpLoaderFactory(http),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AppPartner, 
    AuthApiService, 
    PartnerModule,    
    ApiService, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export const HttpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, 'assets/i18n/', '.json');

