import { Component, OnInit } from '@angular/core';
import { ApiStatusService } from './api/services/common/api.status.service';
import { LoadingOverlayService } from './shared/services/loading-overlay.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { GlobalizationService } from './globalization/services/globalization.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DatepickerLanguageService, availableLanguages } from './api/services/datepickerLanguage.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private _apiStatusService: ApiStatusService,
    private _loadingOverlayService: LoadingOverlayService,
    private _translateService: TranslateService,
    private _globalizationService: GlobalizationService,

  ) { }

  ngOnInit(): void {

    this.addAppVersion();
   
    this._apiStatusService.isLoading$.subscribe(e => {
      if (e) {
        this._loadingOverlayService.show();
      } else {
        this._loadingOverlayService.hide();
      }
    });

  }


  private addAppVersion(): void {
    document.getElementById('app-root')?.setAttribute('app-version', environment.appVersion);
  }



}


