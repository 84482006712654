<div class="pagination" *ngIf="totalPages && (totalPages> 1) && page">

    <span class="total-information-text">
        {{'TOTAL_REGISTERED_PROPERTIES' | translate}}: {{totalElements}}
    </span>

    <div class="pagination-controls">

        <div class="buttons-back-container">

            
            <button class="first-page-icon" *ngIf="!(page === 1)" matTooltip="{{'FIRST_PAGE' | translate}}"
            mat-button (click)="_firstPage()" >
            <mat-icon svgIcon="chevron-left-all"></mat-icon>
        </button>
        <button mat-button matTooltip="{{'PREVIOUS_PAGE' | translate}}"
        (click)="_prevPage()" *ngIf="!(page === 1)">
        <mat-icon svgIcon="chevron-left2"></mat-icon>
        </button>
    </div>

        <ng-container *ngFor="let pg of getPages()">
            <button
                [ngClass]="{'page-button-index': true, 'actual-page': (pg + 1) === page}"
                mat-button
                (click)="_changeToPage(pg + 1)">
                {{pg + 1}}
            </button>
        </ng-container>

        <div class="buttons-next-container" >
            <button mat-button matTooltip="{{'NEXT_PAGE' | translate}}"
            (click)="_nextPage()" *ngIf="!(page === totalPages)" >
            <mat-icon svgIcon="chevron-right2"></mat-icon>
        </button>

        <button class="last-page-icon" matTooltip="{{'LAST_PAGE' | translate}}"
            mat-button (click)="_lastPage()"
            *ngIf="!(page === totalPages)">
            <mat-icon svgIcon="chevron-right-all"></mat-icon>
        </button>
        </div>


    </div>
</div>
