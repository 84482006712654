
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { StorageService } from 'src/app/storage/services/storage.service';

@Injectable()
export class ChannelAuthorizationInterceptor implements HttpInterceptor {

  constructor(private _storageServe: StorageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const channelJSON = this._storageServe.get('userChannel');

    if(channelJSON) {
      const channel = JSON.parse(channelJSON);
      request = request.clone({
        setHeaders: {ChannelClientIdSelected: channel?.clientId},
      });
     }
    
     return next.handle(request);
  }
}
