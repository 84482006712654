<div>

  <form class="filter-container" [formGroup]="formFilter" (ngSubmit)="onSubmit()">

   
    <mat-form-field>
      <mat-label>{{"GUEST_USER_NAME" | translate}}</mat-label>
      <input maxlength="100" matInput name="name" formControlName="name">
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'EMAIL' | translate}}</mat-label>
      <input maxlength="100" matInput name="userName" formControlName="userName">
      <mat-error [translate]="'ERRORS.FORMS.EMAIL'"
      [translateParams]="{field: 'EMAIL'}"></mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{"CREATOR" | translate}}</mat-label>
      <input maxlength="100" matInput name="creatorName" formControlName="creatorName">
    </mat-form-field>
  
    
    <mat-form-field>
      <mat-label>{{'INVITATION_CREATION_DATE' | translate}}</mat-label>
      <input mask="00/00/0000" matInput name="dateCreated" formControlName="dateCreated">
      <mat-error [translate]="'ERRORS.FORMS.DATECREATED'"
      [translateParams]="{field: 'DATECREATED'}"></mat-error>
    </mat-form-field>
    
    <mat-form-field class="select-profile-field">
      <mat-label>{{"PROFILE" | translate}}</mat-label>
      <mat-select matNativeControl formControlName="profile" multiple>
        <mat-option *ngFor="let profile of profileList" [value]="profile.name">
          {{profile.name | translate}}
        </mat-option>
      </mat-select>
      <mat-error [translate]="'ERRORS.FORMS.PROFILE'"
      [translateParams]="{field: 'PROFILE'}"></mat-error>
    </mat-form-field>

    
    <button class="search-button"  mat-stroked-button color="primary" type="submit" [disabled]="!formFilter.valid">{{"SEARCH" | translate}}</button>
    <button class="clear-filters"  mat-stroked-button (click)="clearFilter()" [disabled]="!formFilter.dirty">{{"CLEAN" | translate}}</button>
  </form>
 

  <div #parent
    [ngClass]="{'activeCursorGrab': activeCursorGrab, 'parent': true}"
    (mousedown)="startDragging($event)" (mouseup)="stopDragging($event)"
    (mouseleave)="stopDragging($event)" (mousemove)="moveEvent($event)">

    <!-- Users pending table -->
    <table id="22" aria-label="table" mat-table [dataSource]="dataSource || []"
      class="mat-elevation-z8">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox 
                      *ngIf="totalElements"
                      (change)="$event ? toggleAllRows() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      >
        </mat-checkbox>
        </th>
        <td class="checkbox-cell" mat-cell *matCellDef="let row">
          <div class="checkbox-container">
            <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="isCheckboxChecked(row)">

          </mat-checkbox>
          </div>
          

        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th class="column-title" mat-header-cell *matHeaderCellDef> {{'NAME' | translate}}  </th>
        <td (click)="selectedItem(element)" mat-cell *matCellDef="let element">
        
          <span class="cell-information">{{element.name}}</span>
        </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="document">
        <th class="column-title" mat-header-cell *matHeaderCellDef> {{"DOCUMENT" | translate}} </th>
        <td (click)="selectedItem(element)" mat-cell *matCellDef="let element">
      
           <span class="cell-information">{{element.maskedDocument}} </span>
        </td>
      </ng-container>

      <!-- Profile Column -->
      <ng-container matColumnDef="profileName">
        <th class="column-title" mat-header-cell *matHeaderCellDef> {{'PROFILE' | translate}} </th>
        <td (click)="selectedItem(element)" mat-cell *matCellDef="let element">
       
          <span class="cell-information">{{element.profileName | translate}}</span> 
        </td>
      </ng-container>

  
      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th class="column-title" mat-header-cell *matHeaderCellDef>  {{'EMAIL' | translate}} </th>
        <td (click)="selectedItem(element)" mat-cell *matCellDef="let element">
       
          <span class="cell-information">{{element.email}}</span>
        </td>
      </ng-container>
     
      <!-- creatorUserName Column -->
      <ng-container matColumnDef="creatorUserName">
        <th class="column-title" mat-header-cell *matHeaderCellDef> {{'INVITATION_CREATOR' | translate}} </th>
        <td (click)="selectedItem(element)" mat-cell *matCellDef="let element">
          
          <span class="cell-information">{{element.creatorName}}</span>
        </td>
      </ng-container>
      <!-- dateCreated Column -->
      <ng-container matColumnDef="dateCreated">
        <th class="column-title" mat-header-cell *matHeaderCellDef> {{'INVITATION_CREATION_DATE' | translate}}</th>
        <td (click)="selectedItem(element)" mat-cell *matCellDef="let element">
         
          <span class="cell-information">{{element.dateCreated}}</span>
        </td>
      </ng-container>
       <!-- Status Column -->
       <ng-container matColumnDef="status">
        <th class="column-title status" mat-header-cell *matHeaderCellDef> {{'STATUS' | translate}} </th>
        <td (click)="selectedItem(element)" class="status-cell" mat-cell *matCellDef="let element">
        
          <div class="information-container"><mat-icon [class]="element.status">circle</mat-icon> <span class="cell-information">{{element.status | translate}}</span> </div>
          
        </td>
      </ng-container>

     
      <tr  mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      

      <!-- Row shown when there is no matching data. -->
      <tr style="text-align: center;" class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8">{{'NO_USERS_FOUND' | translate}}</td>
      </tr>
    </table>

  </div>

  <div class="table-bottom" *ngIf="total_pages && (total_pages> 1) && page">

  
 
      <div style="width: 24px;height: 24px;">
        <mat-icon class="first-page-icon" matTooltip="{{'FIRST_PAGE' | translate}}"  tabindex="0" (click)="changePage(1)"
        *ngIf="page> 1"
        >first_page</mat-icon>
      </div>

      <div style="width: 24px;height: 24px;">
        <mat-icon tabindex="1" matTooltip="{{'PREVIOUS_PAGE' | translate}}"  (click)="changePage(page - 1)"
        *ngIf="page> 1"
        class="arrow-left">chevron_left</mat-icon>
      </div>

      <div style="display: flex;gap:24px">
        <ng-template ngFor let-i="index" let-xb [ngForOf]="counter()">
          <ng-container
            class="page-selection" *ngIf="showLinkNumber(i + 1)">
    
            <span  tabindex="2" (click)="!((i + 1) === page) && changePage(i + 1)"
              [ngClass]="(i + 1) === page
            ?
            'current-page' :
            'another-page'">{{i
              + 1}}</span>
          </ng-container>
    
        </ng-template>
      </div>
    
  
    

      <div style="width: 24px;height: 24px;">
        <mat-icon tabindex="3"  matTooltip="{{'NEXT_PAGE' | translate}}"  (click)="changePage(page + 1)" *ngIf="page
        !=
        total_pages"
          class="arrow-right">chevron_right</mat-icon>
      </div>
      <div style="width: 24px;height: 24px;">
        <mat-icon class="last-page-icon" *ngIf="page
        !=
        total_pages" matTooltip="{{'LAST_PAGE' | translate}}"   tabindex="0" (click)="changePage(total_pages)"
        
        >last_page</mat-icon>
      </div>
    
  </div>

  <section class="actions">

    <div class="row">

      <button class="back"  (click)="backToHomePage()" mat-stroked-button>{{'BACK' | translate}}</button>

      <button  *ngIf="totalElements" [disabled]="!selection.hasValue()" (click)="deleteInvite()" class="cancel" mat-flat-button color="primary">
        {{buttonDeleteInviteText(selection.selected.length)}} </button>
      <button *ngIf="totalElements" [disabled]="!selection.hasValue()" (click)="resendInvite()"class="send" mat-flat-button color="primary">
        {{buttonResendInviteText(selection.selected.length)}}</button>


    </div>
  </section>
</div>