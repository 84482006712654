import { Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Observable, Subscription, defer, from, switchMap, of, BehaviorSubject } from 'rxjs';
import { UserType } from 'src/app/api/models/user-type';
import { UserTypeApiService } from 'src/app/api/services/user-type-api.service';
import { ChannelApiService } from 'src/app/api/services/channel-api.service';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { ImportApiService } from 'src/app/api/services/import.service';
import { ServicesContract } from 'src/app/api/models/institutions-resp';

export interface DialogData {
  channelId: number;
  title: string;
  description: string;
  confirmText?: string;
  exitText?: string;
}
interface AuthFormData {
  id: number;
  userType: UserType;
  channelClientID: string;
  mfaAuth: boolean;
  active: boolean;
  linksChannel: { link: 'string'; id?: number, serviceContractId: number }[];
}

interface NewChannel {
  id: number;
  apm: string;
  name: string;
  latam: boolean;
  logo?: string | ArrayBuffer;
  language: string;
  channelsAuthenticate: Array<AuthFormData>;
}

interface TabState {
  tab: MatTab;
  disable: boolean;
}
@Component({
  selector: 'app-modal-channel-application',
  templateUrl: './modal-channel-application.component.html',
  styleUrls: ['./modal-channel-application.component.scss']
})
export class ModalChannelApplicationComponent implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription();

  // TABS
  @ViewChild('tabGroup') tabGroup!: MatTabGroup;
  @ViewChild('tab2') tab2!: MatTab;
  @ViewChild('tab3') tab3!: MatTab;
  @ViewChild('tab4') tab4!: MatTab;
  disableNextTabButton = true;
  showActionsButtons = false;
  isLastTab = false;
  isFirstTab = true;
  tab2Disabled = true;
  tab3Disabled = true;
  tab4Disabled = true;

  private _tabStates: TabState[] = [];
  serviceContracts?: ServicesContract[];

  //Channel information
  private _channelInformation?: any;

  // FIRST TAB
  apmControl = new FormControl('', [Validators.maxLength(50)]);
  nameControl = new FormControl('', [Validators.required, Validators.maxLength(255)]);
  languageControl = new FormControl('', []);
  maxInputs = 9999;
  isChannelActive = false;
  initialFormData = new FormGroup({
    id: new FormControl(undefined),
    apm: this.apmControl,
    name: this.nameControl,
    latam: new FormControl(false),
    language: this.languageControl,
  });
  allTypesFilled: boolean = false;
  languageChannel?: string;
  languageList = [
    { key: 'pt-BR' },
    { key: 'en-US' },
    { key: 'es' }
  ];

  // SECOND TAB
  authFromDataSubscribe?: Subscription;
  authFormData: FormGroup<any> = new FormGroup({
    id: new FormControl(undefined),
    userType: new FormControl('', Validators.required),
    channelClientID: new FormControl('', Validators.required),
    mfaAuth: new FormControl<boolean | null>(null, Validators.required),
    active: new FormControl<boolean | null>(true),
    linksChannel: new FormArray([])
  });
  authAllTypes: UserType[] = [];
  nestedForm: Array<AuthFormData> = [];
  authAllTypesUserSelect: string[] = [];
  openEditingForm = false;
  editedItem?: AuthFormData;
  userIsEditingItem = false;
  disabledGenerateSHA256 = true;


  // LOGO TAB
  logo?: string | ArrayBuffer;
  disableCompleteProcessButton = true;

  editingAnExistingChannel = false;

  constructor(
    public dialogRef: MatDialogRef<ModalChannelApplicationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _channelApiService: ChannelApiService,
    private _userTypeApiService: UserTypeApiService,
    private _formBuilder: UntypedFormBuilder,
    private _globalizationService: GlobalizationService,
    private _importApiService: ImportApiService
  ) { }

  ngOnDestroy(): void {
    this.authFromDataSubscribe?.unsubscribe();
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    
    // this.isLatam = this.initialFormData.value.latam ? 1: 0;

    // this.initialFormData.controls.latam.valueChanges.subscribe(isLatam => {
    //   this.isLatam = isLatam ? 1 : 0;
    // })
    // this.initialFormData.controls.latam.valueChanges.subscribe({
    //   next:(isLatam) => {
    //     this.imporApiService.serviceContractByLatamType(this.isLatam).subscribe({
    //       next()
    //     })
    //   }
    // })
    // this.serviceContracts$ = this.imporApiService.serviceContractByLatamType(this.isLatam)


    if (this.data.channelId) {
      this.editingAnExistingChannel = true;
      this.subscription.add(this._channelApiService.getChannelById(this.data.channelId).subscribe(channel => {
        this.initialFormData.patchValue({
          id: channel.id,
          apm: channel.apm,
          name: channel.name,
          latam: channel.latam,
          language: channel.language === null ? 'en-US' : channel.language
        });
        this._importApiService.serviceContractByLatamType((channel.latam === true) ? 1 : 0).subscribe({
          next: (value) => {
            this.serviceContracts = value;
          },
        });

        this._channelInformation = channel

        this.nestedForm = [];
        channel.channelsAuthenticate.forEach((auth: AuthFormData) => {
          this.authFormData.patchValue({
            id: auth.id,
            userType: auth.userType,
            channelClientID: auth.channelClientID,
            mfaAuth: auth.mfaAuth,
            active: auth.active,
            linksChannel: [],
          });
          auth.linksChannel.map(e =>
            this.linksChannel.push(this._formBuilder
              .group({ id: [e.id], link: [e.link, [Validators.required]], serviceContractId: [e.serviceContractId] })));

          this.nestedForm.push(this.authFormData.value);

          this.restoreTabStates();

          this.openEditingForm = false;
          this.authFormData.reset({ active: false });
          this.linksChannel.clear();


          this.showActionsButtons = !!this.nestedForm.length;
        });

        if (this.editingAnExistingChannel) {
          this.initialFormData.get('latam')?.disable();
          this.isChannelActive = channel.active
        }

        this.logo = channel.logo;

        this.openEditingForm = false;
        this.disableNextTabButton = false;
        this.tab2.disabled = false;
        //this.tab3.disabled = false;
        this.tab4.disabled = false;
      }));
    } else {
      this.initialFormData.patchValue({
        language: 'en-US'
      });
      this.addUrlInput();
      this.disableNextTabButton = true;
    }
    this.loadStatusFromInitialData();
    this.showActionsButtons = true;

    this.checkDisableCheckBox();
  }

  checkDisableCheckBox() {
    if (this.openEditingForm || this.nestedForm.length > 0) {
      this.initialFormData.get('latam')?.disable();
    } else {
      this.initialFormData.get('latam')?.enable();
    }
  }

  get linksChannel() {
    return this.authFormData.get('linksChannel') as FormArray;
  }

  completeProcess() {
    const newChannel: any = {
      ...this.initialFormData.value,
      latam: this.initialFormData.get('latam')?.value,
      channelsAuthenticate: this.nestedForm
    };
    if (this.logo) {
      newChannel.logo = this.logo;
    }


    this.dialogRef.close(newChannel);

  }

  loadStatusFromInitialData() {
    this.authFromDataSubscribe = this.initialFormData.statusChanges.subscribe(e => {
      this.disableNextTabButton = !(e === 'VALID');
      this.disableCompleteProcessButton = !(e === 'VALID');
    });
  }

  deleteItem(item: AuthFormData) {
    this.nestedForm = this.nestedForm.filter(e => e.channelClientID !== item.channelClientID);
    if (this.nestedForm.length) {
      this.showActionsButtons = true;
    } else {
      this.showActionsButtons = false;
    }

    this.loadTypeChannelAuthenticates();
  }

  loadTypeChannelAuthenticates(addTypeChannelAuthenticate?: UserType) {
    this.authAllTypesUserSelect = [];
    this.nestedForm.forEach(e => this.authAllTypesUserSelect.push(e.userType.type));
    this.subscription.add(this._userTypeApiService.getUserTypes(this.initialFormData.get('latam')?.value === true, this.authAllTypesUserSelect)
      .subscribe(types => {
        this.authAllTypes = types;
        if (addTypeChannelAuthenticate) {
          this.authAllTypes.push(addTypeChannelAuthenticate);
        }


        this.allTypesFilled = types.length === 0

      }));

    this.isChannelActive = this.nestedForm.some(item => item.active);

    this.checkDisableCheckBox()
  }

  trackByFn(index: number, item: any) {
    return index;
  }

  closeForm() {
    this.restoreTabStates();
    this.userIsEditingItem = false;
    this.openEditingForm = false;
    this.authFormData.reset({
      active: true,
      linksChannel: this._formBuilder.array([this._formBuilder
        .group({ link: ['', [Validators.required]], serviceContractId: [''] })])
    });
    this.linksChannel.clear();

    this.checkDisableCheckBox()

    this.showActionsButtons = !!this.nestedForm.length;
  }

  addNewForm() {

    this.userIsEditingItem = false;

    this.loadTypeChannelAuthenticates();

    this.authFormData = this._formBuilder.group({
      userType: new FormControl('', Validators.required),
      channelClientID: new FormControl('', Validators.required),
      mfaAuth: new FormControl<boolean | null>(null, Validators.required),
      active: new FormControl<boolean | null>(true),
      linksChannel: this._formBuilder.array([])
    });

    // store the initial state of tabs
    this.tabGroup._tabs.forEach(tab => this._tabStates.push({ tab, disable: tab.disabled }));
    // disable all tabs
    this.tabGroup._tabs.forEach(tab => tab.disabled = true);

    this.openEditingForm = true;
    this.showActionsButtons = false;

    this.checkDisableCheckBox()
  }

  optionChange(option: MatOptionSelectionChange<UserType>) {
    if (option.source.value !== this.authFormData.value.userType) {
      this.disabledGenerateSHA256 = false;
      if (!this.editingAnExistingChannel) {
        this.authFormData.get('channelClientID')?.setValue('');
      }
    }
  }


  editForm(item: AuthFormData) {


    // id: new FormControl(item.id),
    // userType: new FormControl([item.userType, [Validators.required]]),
    // channelClientID: new FormControl([item.channelClientID, [Validators.required]]),
    // active: new FormControl(item.active),
    // linksChannel: new FormArray([])


    this.authFormData = new FormGroup({
      id: new FormControl(item.id),
      userType: new FormControl({ value: item.userType, disabled: this.editingAnExistingChannel }, Validators.required),
      channelClientID: new FormControl({ value: item.channelClientID, disabled: this.editingAnExistingChannel }, Validators.required),
      mfaAuth: new FormControl(item.mfaAuth),
      active: new FormControl(item.active),
      linksChannel: new FormArray([])
    });

    item.linksChannel.map(e =>
      this.linksChannel.push(this._formBuilder
        .group({ id: e.id, link: [e.link, [Validators.required]], serviceContractId: [e.serviceContractId] })));


    this.loadTypeChannelAuthenticates(item.userType);

    this.userIsEditingItem = true;
    this.editedItem = item;
    this.openEditingForm = true;
    this.showActionsButtons = false;
    this.disabledGenerateSHA256 = true;

    // store the initial state of tabs
    this.tabGroup._tabs.forEach(tab => this._tabStates.push({ tab, disable: tab.disabled }));
    // disable all tabs
    this.tabGroup._tabs.forEach(tab => tab.disabled = true);

    this.checkDisableCheckBox()

    this.authFormData.updateValueAndValidity();
  }

  restoreTabStates() {
    this._tabStates.forEach(({ tab, disable }) => tab.disabled = disable);
  }

  private SHA256Hash(text: string): Observable<string> {
    return defer(() => {
      const data = new TextEncoder().encode(text);
      return from(crypto.subtle.digest('SHA-256', data));
    }).pipe(
      switchMap((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
        return of(hashHex);
      })
    );
  }
  generateSHA256() {
    const textToHash = this.initialFormData.value.name + this.authFormData.value.userType.type + new Date();
    if (textToHash) {
      this.subscription.add(this.SHA256Hash(textToHash.trim()).subscribe(generatedHash => {
        this.authFormData.patchValue({ channelClientID: generatedHash });
      }));
    }
  }

  addNestedForm() {

    if (this.userIsEditingItem) {
      this.authFormData.enable();
      // remove item that user was editing
      this.nestedForm = this.nestedForm.filter(e => e.channelClientID !== this.editedItem?.channelClientID);
      // add item
      this.nestedForm.push(this.authFormData.value);


    } else {
      this.nestedForm.push(this.authFormData.value);
    }


    // restore tabs
    this.restoreTabStates();

    this.openEditingForm = false;
    this.authFormData.reset({ active: false });
    this.linksChannel.clear();

    this.checkDisableCheckBox();

    this.showActionsButtons = !!this.nestedForm.length;

    if (this.editingAnExistingChannel) {
      this.disableCompleteProcessButton = false;
    }

    this.isChannelActive = this.nestedForm.some(item => item.active);

  }

  addUrlInput() {
    if (this.linksChannel.length < this.maxInputs) {
      this.linksChannel.push(this._formBuilder.group({ link: ['', [Validators.required]], serviceContractId: '' }));
    
        this._importApiService.serviceContractByLatamType((this.initialFormData.get('latam')?.value === true) ? 1 : 0).subscribe({
          next: (value) => {
            this.serviceContracts = value;
          },
        });
       
    
    }
  }

  removeUrlInput(index: number) {
    this.linksChannel.removeAt(index);
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.isLastTab = event.index === this.tabGroup._tabs.length - 1;
    this.isFirstTab = event.index === 0;
    if (!this.isFirstTab) {
      this.authFromDataSubscribe?.unsubscribe();
    }
    // Unlock manual select tab as it enters the selected tab
    switch (event.index) {
      case 0:
        this.showActionsButtons = true;
        this.loadStatusFromInitialData();
        break;
      case 1:
        this.tab2.disabled = false;
        this.showActionsButtons = !!this.nestedForm.length;
        break;
      case 2:
        // this.tab3.disabled = false;
        this.tab4.disabled = false;
        this.showActionsButtons = true;
        break;
      case 3:
        this.tab4.disabled = false;
        break;
    }
  }
  fileUploaded($event: { file: string | ArrayBuffer; size?: string | undefined }) {

    this.disableCompleteProcessButton = this.disableNextTabButton;

    this.logo = $event.file;

  }

  showLanguageName(name: string): string {
    if (name === 'pt-BR') {
      return this._globalizationService.translate('LANGUAGES.PORTUGUESE');
    } else if (name === 'en-US') {
      return this._globalizationService.translate('LANGUAGES.ENGLISH');
    } else {
      return this._globalizationService.translate('LANGUAGES.SPANISH');
    }
  }

  goToNextTab(tabGroup: MatTabGroup): void {

    const index: number = tabGroup.selectedIndex ?? 0;
    const nextIndex: number = index + 1;

    if (nextIndex < tabGroup._tabs.length) {
      tabGroup.selectedIndex = nextIndex;
    }
  }

  closeModal(): void {
    this.dialogRef.close(false);
  }

  // onOptionChange(value: string) {
  //   this.authFormData.get('mfaAuth')?.setValue(value);
  //   console.log(this.authFormData.get('mfaAuth')?.value);
  // }

}
