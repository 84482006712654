import { InstitutionCod, PathFromLogo, Partner, ServiceContract, Theme } from '../models';
import { PartnerEnum } from '../partner.enum';

export class Hipercard implements Partner {
  readonly theme: Theme = PartnerEnum.Hipercard;
  readonly logo: PathFromLogo = PartnerEnum.HipercardLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceHipercard;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionHipercard;
  readonly primaryColor: string = '#BA1319';
}
