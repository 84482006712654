import { UserPasswordComponent } from './first-access/user-password/user-password.component';
import { Location } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ExternalLoginComponent } from './external-login/external-login.component';

import { ExternalChannelListComponent } from './channel-list/external-channel-list.component';
import { ExternalUserListComponent, TabId } from './user-list/external-user-list.component';

import { UserInviteComponent } from './user-invite/user-invite.component';
import { ExternalUserDocumentComponent } from './first-access/user-document.component';
import { ExternalUserConfirmInvitationComponent } from './first-access/user-confirm-invitation/user-confirm-invitation.component';
import { UserOptInComponent } from './first-access/user-optin/user-optin.component';
import { ExternalUserEditComponent } from './user-edit/user-edit.component';
import { ExternalUserNameComponent } from './first-access/user-name/external-user-name.component';
import { UserForgotPasswordComponent } from './user-forgot-password/user-forgot-password.component';
import { ExternalUserDoneComponent } from './first-access/user-done/external-user-done.component';

import { TypeGuardService } from 'src/app/helpers/type.guard';
import { UserTypeEnum } from 'src/app/api/enums/user-type.enum';
import { AuthLoginGuard } from 'src/app/helpers/auth.guard';
import { ChannelGuardService } from 'src/app/helpers/channel.guard';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'channels',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: ExternalLoginComponent,
    canActivate: [AuthLoginGuard],
    data: {
      expectedUserType: [UserTypeEnum.ALLIANCE, UserTypeEnum.LATAM_ALLIANCE, UserTypeEnum.GRAFANA]
    }
  },
  {
    path: 'first-access',
    children: [
      { path: '', component: ExternalUserDocumentComponent },
      { path: 'confirm-invitation', component: ExternalUserConfirmInvitationComponent },
      { path: 'optin', component: UserOptInComponent },
      { path: 'set-name', component: ExternalUserNameComponent },
      { path: 'set-password', component: UserPasswordComponent },
      { path: 'user-done', component: ExternalUserDoneComponent }
    ],
  },
  {
    path: 'forgot-password',
    component: UserForgotPasswordComponent
  },
  {
    path: 'channels',
    component: ExternalChannelListComponent,
    canActivate: [TypeGuardService],
    data: {
      disableChannelClick: true,
      expectedUserType: [UserTypeEnum.ALLIANCE, UserTypeEnum.LATAM_ALLIANCE, UserTypeEnum.GRAFANA]
    }
  },
  {
    path: 'users',
    children: [
      { path: '', component: ExternalUserListComponent, data: {tab: TabId.Users} },
      {
        path: 'pending', children: [
          { path: '', component: ExternalUserListComponent, data: { pending: true, tab: TabId.Pending } }
        ]
      },
      {
        path: 'user-logs', children: [
          { path: '', component: ExternalUserListComponent, data: { pending: true, tab: TabId.Logs } }
        ]
      },
      {
        path: 'groups', children: [
          { path: '', component: ExternalUserListComponent, data: { pending: true, tab: TabId.Groups } }
        ]
      },
      { path: 'user/:userId', component: ExternalUserEditComponent },
      {
        path: 'invite', children: [
          { path: '', component: UserInviteComponent },
          { path: ':userId', component: ExternalUserEditComponent, data: { invitedUser: true, pending: true } }
        ]
      },
    ],
    canActivate: [TypeGuardService, ChannelGuardService],
    data: {
      expectedUserType: [UserTypeEnum.ALLIANCE, UserTypeEnum.LATAM_ALLIANCE, UserTypeEnum.GRAFANA]
    }
  },


  { path: 'app-external-user-done', component: ExternalUserDoneComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [Location]
})
export class PartnerRoutingModule { }
