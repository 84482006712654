<div class="main-toolbar">

  <img mat-card-image [src]="partner()?.logo" alt="logo">

  <div class="persona_info">
    <button mat-button [disabled]="disableChannelClick()" [routerLink]="['/'+ partnerName()  +'/channels']"
      [state]="{changeChannel: true}" matTooltip="{{'CLICK_TO_CHANGE_CHANNEL' | translate}}" class="user-channel">
      {{ channel$ | async }}
      <mat-icon svgIcon="transfer"></mat-icon>
    </button>

    <button mat-button [matMenuTriggerFor]="extendsMenu" class="user-name">
      {{'HELLO' | translate}}{{socialName()}}
      <mat-icon svgIcon="user-circle"></mat-icon>
    </button>
  </div>

</div>



<mat-menu class="menu-con-user-name-auth" #extendsMenu="matMenu">

  @if (userCanChangePassword()) {
  <button mat-menu-item (click)="changePassword()">
    <mat-icon svgIcon="lock2"></mat-icon> {{'CHANGE_PASSWORD' | translate}}
  </button>
  }



  <button mat-menu-item [matMenuTriggerFor]="languagesToChoose">
    <mat-icon svgIcon="globe"></mat-icon> {{'CHANGE_LANGUAGE' | translate}}
  </button>

  <button mat-menu-item (click)="logout()">
    <mat-icon svgIcon="logout"></mat-icon> {{'SIGN_OUT' | translate}}
  </button>
</mat-menu>


<mat-menu #languagesToChoose="matMenu">

  @for (language of languages(); track $index) {
  @if (currentLanguage().value != language.value) {
  <button mat-menu-item (click)="changeLanguage(language.value)">
    <mat-icon [svgIcon]="language.flagIcon"></mat-icon>
    {{language.name | translate}}</button>
  }
  }

</mat-menu>
