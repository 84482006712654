import { ApiModule } from './../api.module';
import { ApiObservable } from './../../shared/models/api-observable';
import { ApiService } from 'src/app/api/services/common/api.service';
import { Injectable } from '@angular/core';
import { MigratedUser, Resume, ServiceContractWithInstitution, User } from '../models/import-req';
import { ServicesContract } from '../models/institutions-resp';
/* eslint-disable @typescript-eslint/naming-convention */
export enum MigratedStatus {
    A_CONVIDAR = 'A_CONVIDAR',
    CONVITES_ENVIADOS = 'CONVITES_ENVIADOS',
    ACESSOS_EFETIVADOS = 'ACESSOS_EFETIVADOS',
    TODOS = 'TODOS'
}

export type LevelMigrates = keyof typeof MigratedStatus;


@Injectable({ providedIn: ApiModule })
export class ImportApiService {

    constructor(
        private api: ApiService
    ) { }

    extractUsersFromXlsx({ file, institutionCod, serviceContractCod }:
    { file: string | ArrayBuffer; institutionCod: number; serviceContractCod: number }): ApiObservable<User[]> {
        return this.api.post<string | ArrayBuffer, User[]>(`/v1/import/extract/users/${serviceContractCod}/${institutionCod}`, file);
    }

    uploadUsersFromXlsx({ list, institutionCod, serviceContractCod }:
        { list: User[]; institutionCod: number; serviceContractCod: number }): ApiObservable<null> {
        return this.api.post<User[], null>(`/v1/import/users/${serviceContractCod}/${institutionCod}`, list);
    }
    scheduleEmail(userList: MigratedUser[], serviceContract: number, instituicao: number): ApiObservable<null> {
        return this.api.post<MigratedUser[], null>(`/v1/import/users/schedule-email/${serviceContract}/${instituicao}`, userList);
    }
    serviceContractsToMigrate(): ApiObservable<ServiceContractWithInstitution[]> {
        return this.api.get<ServiceContractWithInstitution[]>(`/v1/service-contracts-with-migration`);
    }

    serviceContractByLatamType(isLatam: number): ApiObservable<ServicesContract[]>  {
        return this.api.get<ServicesContract[]>(`/v1/public/service-contracts/latam-type/${isLatam}`)
    }

    migratedUsers({ institutionCod, serviceContractCod, migratedStatus }:
        { institutionCod: number; serviceContractCod: number; migratedStatus: LevelMigrates }): ApiObservable<MigratedUser[]> {
        return this.api.get<MigratedUser[]>(`/v1/import/users/${serviceContractCod}/${institutionCod}/${MigratedStatus[migratedStatus]}`);
    }
    resume({ institutionCod, serviceContractCod }:
        { institutionCod: number; serviceContractCod: number }): ApiObservable<Resume> {
        return this.api.get<Resume>(`/v1/import/users/${serviceContractCod}/${institutionCod}/resume`);
    }


}
