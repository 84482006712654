import { ApiModule } from './../api.module';
import { ApiObservable } from './../../shared/models/api-observable';
import { ApiService } from 'src/app/api/services/common/api.service';
import { InstitutionInformation, InstitutionsList } from '../models/institutions-resp';
import { Injectable } from '@angular/core';
import { SaveInstitution } from '../models/institutions-req';
import { ApiRespNoContent } from '../models/api-resp';

@Injectable({ providedIn: ApiModule })
export class InstitutionsApiService {

    constructor(
        private api: ApiService
    ) { }

    getInstitutions(page: number): ApiObservable<InstitutionsList[]> {
        return this.api.get<InstitutionsList[]>(`/v1/institutions?page=${page}`);
    }
    getInstitutionsWithServicesContracts(page: number): ApiObservable<InstitutionsList[]> {
        return this.api.get<InstitutionsList[]>(`/v1/admin/institutions?page=${page}`);
    }
    InstitutionsDetails(id: number): ApiObservable<InstitutionInformation> {
        return this.api.get<InstitutionInformation>(`/v1/admin/institutions/${id}`);
    }

    getInstitutionByCode(code: string): ApiObservable<
        {
            institutionCod: string; institutionName: string; emailSuffix: string; logo?: string;
            serviceContracts: Array<{ serviceContractCod: string; serviceContractName: string }>;
        }> {
        return this.api.get<{
            institutionCod: string; institutionName: string; emailSuffix: string;logo?: string;
            serviceContracts: Array<{ serviceContractCod: string; serviceContractName: string }>;
        }>(`/v1/institutions/${code}`);
    }

    saveInstitution(institution: SaveInstitution): ApiObservable<InstitutionInformation> {
        return this.api.post<SaveInstitution, InstitutionInformation>
          ( `/v1/admin/institutions`, institution);
      }

    connectChannel(institutionWithChannels: {
        institutionId: number;
        channelIds: Array<number>;
      }): ApiObservable<any> {
        return this.api.post<{
            institutionId: number;
            channelIds: Array<number>;
          }, any>
          ('/v1/admin/institutions/connect-channels', institutionWithChannels);
      }



    getInstitution(): ApiObservable<
        {
            institutionCod: string; institutionName: string; emailSuffix: string;
            serviceContracts: Array<{ serviceContractCod: string; serviceContractName: string }>;
        }> {
        return this.api.get<{
            institutionCod: string; institutionName: string; emailSuffix: string;
            serviceContracts: Array<{ serviceContractCod: string; serviceContractName: string }>;
        }>(`/v1/institution`);
    }
}
