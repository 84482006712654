
<div class="page">

    <main class="content">

        <div class="title">
            <div class="title-container">
                <span class="material-icons" mat-dialog-close>
                    close
                </span>
                <h1 class="title-channel">{{title | translate}}</h1>
                <p>{{subTitle | translate}}</p>
            </div>
        </div>

        <div class="form-container">
        <form [formGroup]="propertyForm">
                        
                        <div class="key-value-container">
                            <mat-form-field class="key-form-field" appearance="fill">
                                <mat-label>{{'KEY' | translate}}</mat-label>
                              <input maxlength="255" [formControl]="keyControl" type="text" matInput [matAutocomplete]="auto"> 
                               <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let key of keyList | async" [value]="key">
                                  {{key}}
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
    
                            <mat-form-field class="value-form-field" appearance="fill">
                            <mat-label>{{'VALUE' | translate}}</mat-label>
                            <input maxlength="255" [formControl]="valueControl" matInput>
                            </mat-form-field>

                        </div>
                        
                        <div class="channel-description-container">

                            <mat-form-field class="channel-form-field" appearance="fill">
                                <mat-label>{{'CHANNEL' | translate}}</mat-label>
                           
                                <mat-select [formControl]="channelsControl" multiple>
                                    <mat-option *ngFor="let channel of channelsList" [value]="channel.id">{{channel.description}}</mat-option>
                                  </mat-select>
                            </mat-form-field>
                               
                            
                            <mat-form-field class="description-form-field" appearance="fill">
                            <mat-label>{{'DESCRIPTION' | translate}}</mat-label>
                              <input maxlength="255" [formControl]="descriptionControl" matInput>
                            </mat-form-field>
    
                        </div>
                     
                        <div class="checkbox-container">
                            <mat-checkbox [formControl]="encryptControl">{{'ENCRYPT_VALUE' | translate}}</mat-checkbox>
                        </div>
    
                      </form>


        </div>

        <div class="footer-container">

            <div class="footer-button">

                <button class="conclude-button" mat-stroked-button
                    mat-dialog-close>
                    {{'BACK' | translate}}
                </button>
                <button class="conclude-button"
                    mat-stroked-button
                    type="submit"
                    mat-flat-button color="primary"
                    [disabled]="propertyForm.invalid"
                    (click)="confirmProperty()">
                    {{buttonConfirmText | translate}}
                </button>
            </div>
        </div>
    </main>

</div>