import { InstitutionCod, PathFromLogo, Partner, ServiceContract, Theme } from '../models';
import { PartnerEnum } from '../partner.enum';

export class Butterfield implements Partner {
  readonly theme: Theme = PartnerEnum.Butterfield;
  readonly logo: PathFromLogo = PartnerEnum.ButterfieldLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceButterfield;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionButterfield;
  readonly primaryColor: string = '#21324D';
}
