import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InternalRedirectGuardService {
  constructor(public router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const { queryParams } = route;

    const params = new URLSearchParams(queryParams).toString();

    const ref = params ? 'fiserv/login?' + params : 'fiserv/login';

    window.location.href = ref;

    this.router.navigate(['fiserv/login'], { queryParams });

    return false;
  }
}
