import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

// Interface for input events containing data
interface InputEvent extends Event {
  data?: string;
}

// Types for form controls
export declare type FormControlType = 'text';


@Component({
  selector: 'app-form-token',
  templateUrl: './form-token.component.html',
  styleUrls: ['./form-token.component.scss']
})
export class FormTokenComponent implements OnInit, AfterViewInit {

  @Input() label = '';
  @Input() name = '';
  @Input() type: FormControlType = 'text';
  @Input() id = Math.random().toString(); // Generate random id
  @Input() next_id = '';

  @Input() amount!: number;
  @Output() childSubmit = new EventEmitter<any>();

  form: UntypedFormGroup = new UntypedFormGroup({});

  placeholders!: number[];

  constructor(private _controlContainer: ControlContainer) {}

  // Retrieves the form control based on the control name
  get control(): UntypedFormControl { 
    return this.form.get(this.name) as UntypedFormControl; 
  }

  ngOnInit(): void {
    // Initialize placeholders and form
    this.placeholders = Array.from({length: this.amount}, (_, i) => i);
    this.form = this._controlContainer.control as UntypedFormGroup;
    // Ensure each control is part of the form group
    this.placeholders.forEach((key) => this.form.addControl(`${this.name}${key}`, new UntypedFormControl('')));
  }

  ngAfterViewInit(): void {
    // Set focus on the first input after the view is initialized
    setTimeout(() => document.getElementById(`inputtoken-0`)?.focus(), 0);
  }

  // Get the error message
  getErrorMessage(): string | undefined {
    const firstControl = this.form.get(`${this.name}0`);
    if (!firstControl?.errors) return;

    if (firstControl.errors?.attemptsToken) {
      return firstControl.errors.attemptsToken;
    }

    return Object.getOwnPropertyNames(firstControl.errors)[0].toUpperCase();
  }

  // Set the form value based on the input event
  setFormValue({ data }: InputEvent | { data: string }, key: number): void {
    if (key >= this.placeholders.length || !this.canSetValue(data)) {
      return;
    }

    this.form.patchValue({ [`${this.name}${key}`]: data?.replace(/\D/g, '') || this.form.value[`${this.name}0`]!.replace(/\D/g, '') });
    const nextControl = this.form.get(`${this.name}${key + 1}`);
    if (nextControl) {
      nextControl.enable();
    }
    document.getElementById(`inputtoken-${key + 1}`)?.focus();

    if (this.form.valid) {
      this.childSubmit.emit();
    }
  }

  // Handle paste event in the input
  onPaste(event: ClipboardEvent, key: number): void {
    const eventWithOnlyNumbers = event.clipboardData?.getData('text')?.replace(/\D/g, '');

    if (!eventWithOnlyNumbers) {
      event.preventDefault();
    }

    eventWithOnlyNumbers?.split('').forEach((value, index) => {
      if (this.hasNumber(value)) {
        if (!this.form.valid) this.setFormValue({ data: value?.replace(/\D/g, '') }, key + index);
      } else {
        event.preventDefault();
      }
    });
  }

  // Handle keydown event
  onKeydown($event: KeyboardEvent, key: number): void {
    const currentControl = this.form.get(`${this.name}${key}`);
    if ($event.key === 'Backspace' && currentControl?.value) {
      $event.preventDefault();
      this.form.patchValue({ [`${this.name}${key}`]: '' });
      return;
    }

    if ($event.key === 'Backspace' && !currentControl?.value) {
      $event.preventDefault();
      const newFocus = key - 1;
      this.form.patchValue({ [`${this.name}${newFocus}`]: '' });
      document.getElementById(`inputtoken-${newFocus}`)?.focus();
      if (key !== 0) {
        const previousControl = this.form.get(`${this.name}${key}`);
        previousControl?.disable();
      }
    } else if (!this.canSetValue($event.key) && $event.code !== 'KeyV') {
      $event.preventDefault();
    } else if (this.canSetValue($event.key)) {
      $event.preventDefault();
      this.setFormValue({ data: $event.key }, key);
    }
  }

  // Check if a value can be set
  private canSetValue(value: any): boolean {
    return !!value && /^[0-9]+$/.test(value.toString());
  }

  // Check if the value contains a number
  private hasNumber(value: any): boolean {
    return !!value?.replace(/\D/g, '');
  }
}
