export enum PartnerEnum {
    Mainbody = 'main',
    ServiceBin = '101',
    ServiceButterfield = '497',
    ServiceCaixa = '149',
    ServiceCitibank = '101',
    ServiceCredicard = '101',
    ServiceCrefisa = '113',
    ServiceScotia = '497',
    ServiceScotiaChile = '497',
    ServiceFiserv = '0',
    ServiceHipercard = '116',
    ServiceInter = '101',
    ServiceItau = '101',
    ServiceRedepop = '113',
    ServiceSicredi = '110',
    ServiceSipag = '104',
    ServiceMexico = '497',
    ServiceAfinz = '143',
    InstitutionBin = '00000003',
    InstitutionButterfield = '00000087',
    InstitutionCaixa = '00000007',
    InstitutionCitibank = '00000003',
    InstitutionCredicard = '00000003',
    InstitutionCrefisa = '00000005',
    InstitutionScotia = '00000079',
    InstitutionScotiaChile = '00000089',
    InstitutionFiserv = '00000000',
    InstitutionHipercard = '00000005',
    InstitutionInter = '00000003',
    InstitutionItau = '00000003',
    InstitutionRedepop = '00000005',
    InstitutionSicredi = '00000004',
    InstitutionSipag = '00000003',
    InstitutionAfinz = '00000010',
    InstitutionMexico = '00000074',
    Bin = 'bin',
    Butterfield = 'butterfield',
    Citibank = 'citibank',
    Credicard = 'credicard',
    Crefisa = 'crefisa',
    Fiserv = 'fiserv',
    Hipercard = 'hipercard',
    Inter = 'inter',
    Mexico = 'mexico',
    Itau = 'itau',
    Redepop = 'redepop',
    Caixa = 'caixa',
    Sicredi = 'sicredi',
    Sipag = 'sipag',
    Afinz = 'afinz',
    Scotia = 'scotia',
    ScotiaChile = 'scotia-chile',
    BinLogo = './partner/bin/assets/logo.svg',
    ButterfieldLogo = './partner/butterfield/assets/logo.svg',
    CitibankLogo = './partner/citibank/assets/logo.svg',
    CredicardLogo = './partner/credicard/assets/logo.svg',
    CrefisaLogo = './partner/crefisa/assets/logo.svg',
    FiservLogo = './partner/fiserv/assets/logo.svg',
    HipercardLogo = './partner/hipercard/assets/logo.svg',
    InterLogo = './partner/inter/assets/logo.svg',
    ItauLogo = './partner/itau/assets/logo.svg',
    RedepopLogo = './partner/redepop/assets/logo.svg',
    CaixaLogo = './partner/caixa/assets/logo.svg',
    SicrediLogo = './partner/sicredi/assets/logo.svg',
    SipagLogo = './partner/sipag/assets/logo.svg',
    AfinzLogo = './partner/afinz/assets/logo.svg',
    ScotiaLogo = './partner/scotia/assets/logo.svg',
    MexicoLogo = './partner/mexico/assets/logo.svg',
    ScotiaChileLogo = './partner/scotia-chile/assets/logo.svg',
};

