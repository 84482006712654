
<div class="page">

    <main class="content">

        <div class="title">
            <div class="title-container">
                <span class="material-icons" mat-dialog-close>
                    close
                </span>
                <h1 class="title-channel">Services Contracts</h1>
                <p>{{'FILL_IN_THE_NECESSARY_DATA_TO_CREATE_EDIT_A_SERVICE_CONTRACT_AND_CLICK_FINISH' | translate}}</p>
            </div>
        </div>

        <div class="data-service-contract-edition-open">
            <div class="details-service-contract-container-open">

                <div class="container-information">

   
                    <app-form [formGroup]="serviceContractForm">

                        <app-form-control label="{{'NAME_SERVICE_CONTRACT' | translate}}"
                            id="name" name="name"
                            type="text">
                        </app-form-control>
                        <app-form-control label="{{'CODE' | translate}}"
                            id="serviceContractCode"
                            name="serviceContractCode"
                            type="institutionCod"
                            [maxLength]="maxLengthInstitutionCode">
                        </app-form-control>
                        <div class="url-container">
                            <app-form-control
                                [objStyle]="{'margin-left.px': 209, 'max-width': 'fit-content'}"
                                [prefixWithUrl]="prefix"
                                [showError]="false"
                                label="URL"
                                id="url" name="url"
                                type="url">
                            </app-form-control>
                            <span class="description">{{'COMPLETE_THE_ADDRESS_WITH_THE_NAME_OF_THE_INSTITUTION' | translate}}</span>
                        </div>
                        <div class="institution-status">
                            <mat-slide-toggle formControlName="status">{{(serviceContractForm.value.status ? 'ACTIVE' : 'INACTIVE') | translate}}</mat-slide-toggle>
                        </div>

                        <div class="brand-name-container">
                               <mat-form-field 
                                appearance="fill">
                                <mat-label>Nome da marca</mat-label>
                                
                                <input  matInput
                                    name="brandName"
                                    type="text" formControlName="brandName" />
                              

                            </mat-form-field>
                        </div>
                        <div class="url-email-container">
                               <mat-form-field 
                                appearance="fill">
                                <mat-label>Url para e-mail</mat-label>
                                
                                <input  matInput
                                    name="urlEmail"
                                    type="text" formControlName="urlEmail" />
                              

                            </mat-form-field>
                        </div>

                        <div class="interface-section">
                            <mat-checkbox 
                                class="example-margin"
                                formControlName="graphicalInterface"
                                (change)="handleCheckBox($event)"
                                >{{'GRAPHIC_INTERFACE' | translate}}</mat-checkbox>
                            <div class="warning-box-information">
                                <div class="icon-warning-container">
                                    <span class="material-icons">
                                        error_outline
                                    </span>
                                </div>

                                <span>{{'CHECK_THIS_OPTION_IF_THIS_SERVICE_CONTRACT_WILL_USE_A_GRAPHICAL_INTERFACE' | translate}} </span>
                            </div>
                        </div>
                    </app-form>

                </div>

            </div>

        </div>

        <div class="footer-container">

            <div class="footer-button">

                <button class="conclude-button" mat-stroked-button
                    mat-dialog-close>
                    {{'CANCEL' | translate}}
                </button>
                <button class="conclude-button"
                    mat-stroked-button
                    type="submit"
                    mat-flat-button color="primary"
                    [disabled]="serviceContractForm.invalid"
                    (click)="send()">
                    {{'CLOSE' | translate}}
                </button>
            </div>
        </div>
    </main>

</div>