import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserTypeEnum } from 'src/app/api/enums/user-type.enum';
import { AuthApiService } from 'src/app/api/services/auth-api.service';
import { PartnerEnum } from 'src/partner/partner.enum';


import { SelectItem } from '../models/select-item';
import { AppPartner } from 'src/partner/partner.service';

export declare type FormControlType = 'text' | 'password' | 'email' | 'inputMid' | 'cpf' | 'select'
  | 'multi-select' | 'cpf_cnpj' | 'document' | 'phone' | 'internalFunctional' | 'url' | 'number' | 'institutionCod' | 'phone-alliance';


interface Mid {
  mid: string;
  name: string;
}
@Component({
  selector: 'app-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss']
})
export class FormControlComponent implements OnInit {

  @Input() label = '';
  @Input() name = '';
  @Input() type: FormControlType = 'text';
  @Input() items: SelectItem[] = [];
  @Input() multiErrors = false;
  @Input() showError = true;
  @Input() maxLength: number | string | null = 999;
  @Input() emailEndsWidth = '';
  @Input() disabled = false;
  @Input() showDocumentMask = this.isAlliance();
  @Input() suffix!: string;
  @Input() prefix?: string;
  @Input() prefixWithUrl?: string;
  @Input() objStyle?: { [klass: string]: any };

  @Input() midList?: Mid[];

  @Output() changeButton: EventEmitter<void> = new EventEmitter();

  form: UntypedFormGroup = new UntypedFormGroup({});

  toggleEyesOpen = false;

  constructor(
    private _controlContainer: ControlContainer,
    private _appPartner: AppPartner,
  ) { }

  get control(): UntypedFormControl { return this.form.get(this.name) as UntypedFormControl; }

  ngOnInit(): void {

    this.form = this._controlContainer.control as UntypedFormGroup;
  }


  compareObjects(o1: any, o2: any): boolean {
    return o1?.name === o2?.name && o1?.id === o2?.id;
  }

  switchEyes(): void {
    this.toggleEyesOpen = !this.toggleEyesOpen;
  }

  getErrorMessage(): string | undefined {
    if (!this.control?.errors)
      return;

    return Object.getOwnPropertyNames(this.control?.errors)[0].toUpperCase();
  }

  isAlliance(): boolean {
   return this._appPartner.isAlliance();
  }

  startWithF(): boolean {
    return new RegExp(/^F/).test(this.control.value) || this.control.value.length < 1;
  }


}
