<div class="panel " id="user-forgot-password">
    <mat-stepper
        class="default-model"
        ariaLabel="default-model-index-{{stepper.selectedIndex}}"
        [linear]="isLinear"
        #stepper>
        <mat-step [editable]="isEditable">
            <div *ngIf="!showInputPasswordForm" id="welcome-container">
                <h2>{{"DEFINE_NEW_PASSWORD" | translate}}</h2>
                <p>{{"CONFIRM_YOUR_EMAIL" | translate}}</p>
            </div>
            <div *ngIf="!showInputPasswordForm" id="form-container">
                <app-form [EnablePopState]="EnablePopState" [formGroup]="form"
                    (ngSubmit)="submit(stepper)">
                    <app-form-control label="{{'EMAIL' | translate}}" id="email"
                        name="email" type="email">
                    </app-form-control>
                    <div id="box-button-actions">
                        <button 
                            color="primary" 
                            class="enter-login-external"
                            id="continue-button"
                            data-testid="button-reset-password-step-one"
                            type="submit"
                            mat-raised-button [disabled]="!form.valid">{{"CONTINUE"
                            | translate}}
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </div>
                </app-form>
            </div>
        </mat-step>
        <mat-step [linear]="isLinear" [editable]="isEditable">
            <div *ngIf="!showInputPasswordForm" id="form-token-container">
                <app-form-token-group *ngIf="auth_data" [recovery]="2"
                    title="{{'LETS_CHECK_YOUR_CODE'
                    | translate}}?"
                    [auth_data]="auth_data"
                    (tokenSuccess)="authCompleted($event, stepper)">
                </app-form-token-group>
            </div>


        </mat-step>
        <mat-step [editable]="isEditable">
            <div class="panel" *ngIf="auth_data?.email && showInputPasswordForm"
                id="user-reset-password-forgot">
                <form [formGroup]="formPassword">
                    <app-form-change-password id="password"
                        [first_name]="first_name"
                        [last_name]="last_name" [email]="email"
                        title="{{'WE_RECOGNIZE_YOUR_CODE' | translate}}"
                        subtitle="{{'NOW_DEFINE_YOUR_PASSWORD' | translate}}"
                        buttonText="{{'CONFIRM_YOUR_PASSWORD' | translate}}"
                        (sendPassword)="receivePassword($event, stepper)">
                    </app-form-change-password>
                </form>
            </div>
        </mat-step>
        <mat-step [editable]="isEditable">
            <div class="panel final-panel">
                <div id="welcome-container">
                    <div>
                        <mat-icon svgIcon="checksquare"></mat-icon>
                    </div>
                    <h2>{{"PASSWORD_DEFINE_WITH_SUCCESS" | translate}}!</h2>
                    <p>{{"REMEMBERING_YOU_CAN_USE_THE_SAME_PASSWORD_ACCESS_OTHER_CHANNEL"
                        | translate}}</p>
                </div>
                <div id="form-container">
                    <div id="box-button-actions">
                        <button type="submit" class="enter-login-external"
                            color="primary"
                            mat-raised-button (click)="goToLogin()">{{"LOG_IN" |
                            translate}}
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </mat-step>
    </mat-stepper>
</div>
