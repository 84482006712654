import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ValidatorsUtils {

    static forbiddenEmail = (): ValidatorFn =>

        (control: AbstractControl): ValidationErrors | null => {
            const forbidden = /.*\.com/i.test(control.value);
            return !forbidden ? { forbiddenEmail: { value: control.value } } : null;
        };
    static customDomainValidator = (): ValidatorFn =>

        (control: AbstractControl): ValidationErrors | null => {
            const inputValue:string | undefined = control.value;
       
             const domainRegex = /^(@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(;(@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))*$/;

            if(inputValue && !domainRegex.test(inputValue)) {
                return {invalidEmail: true}
            }

             return null;
        };

    static allianceEmailEndsWithPartnerName = (email: string): ValidatorFn =>

        (control: AbstractControl): ValidationErrors | null => {
            const text: string = control.value;
            const hasError = !text?.toLocaleLowerCase().endsWith(email);
            if(hasError) {

                return { emailShouldHave : true};
            }
            return null ;
        };

    static noWhitespaceValidator = (): ValidatorFn =>
        (control: AbstractControl) => {
            const isWhitespace = ValidatorsUtils.isWhitespace((control && control.value && control.value.toString() || ''));
            const isValid = !isWhitespace;
            return isValid ? null : { whitespace: true };
        };

    static compositeNameValidator = (): ValidatorFn =>
        (control: AbstractControl) => {
            const value = (control?.value?.toString() || '').trim().replace(/\s{2,}/g, ' ').split(' ');
            let isValid = value.length >= 2;
            if (isValid) {
                isValid = !ValidatorsUtils.isWhitespace(value[0])
                    && !ValidatorsUtils.isWhitespace(value[1]);
            }
            return isValid ? null : { compositeName: true };
        };


    static internalFunctional = (): ValidatorFn =>
        (control: AbstractControl) => {
            const a: boolean = control.value.match(/[A-Za-z0-9]{5}$/i) ?? /.*\.com/i.test(control.value);
            return a ? null : { internalFunctionalBadlyFormatted: true };
        };

    static emailSuffixValidator = (allEmailSuffix?: string): ValidatorFn =>
        (control: AbstractControl) => {
            if(!allEmailSuffix) {
                return null;
            }
            const value: string = control.value.slice(control.value.indexOf('@'));
            const suffix = allEmailSuffix.split(';');
            let isValid = false;
            suffix.forEach(element => {
                if(new RegExp(element, "i").test(value)) {
                    isValid = true;
                }
            });
            return isValid ? null : { emailSuffix: true };
        };

    private static isWhitespace(value: string) {
        return value.trim().length === 0;
    }


}
