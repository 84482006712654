import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-optin',
  templateUrl: './optin.component.html',
  styleUrls: ['./optin.component.scss']
})
export class OptinComponent implements OnInit {

  @Input() optinTerms?: SafeHtml;

  @Output() clickedReadAndAccepted = new EventEmitter<void>();
  @Output() clickedCancelButton = new EventEmitter<void>();
  @Output() readAllTerms = new EventEmitter<void>();
  readedAllOptin = false;

  constructor() { }

  ngOnInit(): void {
    document.getElementById('option-body-container')?.addEventListener('scroll', (e: any) => {
      const tracker = e.target;
      if (tracker.offsetHeight + tracker.scrollTop + 50 >= tracker.scrollHeight) {
        this.readedAllOptin = true;
        this.readAllTerms.emit();
        return;
      }
    });
  }


}
