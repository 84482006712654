<div class="panel">
  <button (click)="clickedCancelButton.emit()" class="close-optin-button"
    mat-icon-button>
    <mat-icon>close</mat-icon>

  </button>
  <!-- <h2 class="optin-title">{{"TERMS_AND_CONDITIONS" | translate}}</h2> -->
  <div class="optin-body"
    id="option-body-container">
    <div id="text-optin" [innerHTML]="optinTerms">
    </div>

  </div>
  <button class="optin-confirm-button" (click)="clickedReadAndAccepted.emit()"
    [disabled]="!readedAllOptin"
    mat-flat-button color="primary">{{"READ_AND_ACCEPT" | translate}}
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div>