<mat-card
  (click)="selectable && selectableAction.emit(this.channel)"
  matTooltip="{{channel.name}}"
  [ngClass]="{'mat-card-connected': connectedChannel && withBorder, 'selectable': selectable,'channel-card': true, 'with-box-shadow': withBoxShadow}"
  *ngIf="channel">
  <div class="image-container">
    <ng-template #image>
      <img *ngIf="!!channel.logo" class="image" [src]="channel.logo" alt=""/>
    </ng-template>

    <mat-icon *ngIf="!channel.logo; else image" class="image" svgIcon="channel"></mat-icon>
  </div>
  <mat-card-content>
    <div class="channel-name">


        {{channel.name}}
     
    </div>
    <div class="cell">
      <p *ngIf="!channel.hasOwnProperty('status'); else elseBlock">
        {{getBodyText()}}
      </p>
      <ng-template #elseBlock>
        <div [ngClass]="channel.status?
            'active':
            'inactive'"
          class="status-container">
          <mat-icon>circle</mat-icon>

          <p style="white-space: nowrap;">{{getBodyText()}}</p>

        </div>
      </ng-template>

    </div>
  </mat-card-content>
  <button mat-button
    [ngClass]="{'connected': connectedChannel, 'action-button': true}"
    [disabled]="disabled" *ngIf="showButton" (click)="sendEvent()">
    {{getActionText()}} </button>
</mat-card>
