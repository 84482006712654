import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  private overlayRef: OverlayRef;

  constructor(
    private _overlay: Overlay) {
    this.overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy: this._overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    });
  }

  show<T>(component: ComponentType<T>) {
    this.overlayRef.attach(new ComponentPortal(component));
  }

  hide() {
    this.overlayRef.detach();
  }
}
