import { SelectionModel } from "@angular/cdk/collections";
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, 
    Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl, FormGroup, UntypedFormBuilder } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { ProfilesInfo } from "src/app/api/models/profiles-resp";
import { AuthApiService } from "src/app/api/services/auth-api.service";
import { ContentDetailLogs } from "src/app/api/services/logs.service";
import { GlobalizationService } from "src/app/globalization/services/globalization.service";

export interface FiltersLogs {
    name: string;
    email: string;
    creatorName: string;
    dhInit: string;
    dhEnd: string;
}

interface filterForm {
    name: FormControl<string>;
    email: FormControl<string>;
    dhInit: FormControl<string>;
    dhEnd: FormControl<Date>;
    creatorName: FormControl<Date>;
}

@Component({
    selector: 'app-table-user-logs',
    templateUrl: './table-user-logs.component.html',
    styleUrls: ['./table-user-logs.component.scss']
})
export class TableUserLogsComponent implements OnChanges {
    @Input() displayedColumns: string[] = ['name', 'email', 'creatorName'];
    @Input() displayedFilters: Array<string> = ['name', 'email', 'creatorName', 'datePeriod'];
    @Input() list?: ContentDetailLogs[];
    @Input() page?: number;
    @Input() profileList?: ProfilesInfo[];
    @Input() total_pages?: number;
    @Input() serviceContracts?: Array<{ serviceContractCod: string; serviceContractName: string }>;

    @Output() selected = new EventEmitter<ContentDetailLogs>();
    @Output() selectedPage = new EventEmitter<{ page: number; filters: Partial<FiltersLogs> | undefined }>();

    public maxDate: Object = new Date();

    // table data
    dataSource?: MatTableDataSource<ContentDetailLogs>;

    // Selected item (checkbox)
    selection = new SelectionModel<ContentDetailLogs>(true, []);

    // Mouse event (drag and drop with scroll)
    mouseDown = false;
    startX: any;
    scrollLeft: any;
    activeCursorGrab = false;
    drag = false;

    filtersLoaded: Partial<FiltersLogs> | undefined;

    formFilter: FormGroup<filterForm> = this._fb.group({
        name: ['', []],
        email: ['', []],
        creatorName: ['', []],
        dhInit: ['', []],
        dhEnd: ['', []]
    });

    @ViewChild('parent', { static: true }) slider!: ElementRef;

    @HostListener('window:resize', ['$event'])
    onResize(event: Event): void {
        const element = document.querySelector('.parent') as HTMLElement;
        if (element?.scrollWidth > element?.clientWidth) {
            this.activeCursorGrab = true;
        } else {
            this.activeCursorGrab = false;
        }
    }

    @HostListener('mousemove', ['$event'])
    mouseup(event: Event): void {
        this.drag = true;
    }
    @HostListener('mousedown', ['$event'])
    mousedown(event: Event): void {
        this.drag = false;
    }

    constructor(
        private _fb: UntypedFormBuilder,
        private _authApi: AuthApiService,
        private _globalizationService: GlobalizationService,
        private _changeDetectorRef: ChangeDetectorRef
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.list?.currentValue) {
            this.dataSource = new MatTableDataSource(this.list);
            this.selection.clear();
        }
        // if (changes.total_pages?.currentValue) {
        //     this.total_pages = changes.total_pages?.currentValue
        // }
        // if (changes.page?.currentValue) {
        //     this.page = changes.page?.currentValue
        // }
     
    }



    ngAfterViewInit(): void {
        //Ativa o mouse, para arrastar a tabela, caso o scroll do conteiner esteja ativo.
        const element = document.querySelector('.parent') as HTMLElement;
        if (element?.scrollWidth > element?.clientWidth) {
            this.activeCursorGrab = true;
        }

        // detecta a mudança
        this._changeDetectorRef.detectChanges();
    }

    startDragging(e: MouseEvent) {
        this.mouseDown = true;
        this.startX = e.pageX - this.slider.nativeElement.offsetLeft;
        this.scrollLeft = this.slider.nativeElement.scrollLeft;
    }

    stopDragging(e: MouseEvent) {
        this.mouseDown = false;
    }

    moveEvent(e: MouseEvent) {
        e.preventDefault();


        if (!this.mouseDown) {
            return;
        }
        const x = e.pageX - this.slider.nativeElement.offsetLeft;
        const scroll = x - this.startX;
        this.slider.nativeElement.scrollLeft = this.scrollLeft - scroll;
    }

    isItMe(code: string): string {
        return this._authApi.userDetails.userId === code ? `(${this._globalizationService.translate('I')})` : '';
    }

    insertActiveStatusClass(item: any): string {
        if (item?.locked) {
            return 'blocked';
        }
        return item?.active ? 'active' : 'inactive';
    }

    handleClickRow(item: ContentDetailLogs) {
        if (!this.drag) {
            this.selected.emit(item);
        }
    }

    navigateToDetails(item: any) {
        this.selected.emit(item);
    }



    changePage(page: number) {
        this.selectedPage.emit({ page, filters: this.filtersLoaded });
    }

    counter() {
        if (!this.page) return;
        if (this.page < 7) return new Array(10);
        return new Array(this.page + 4);
    }

    showLinkNumber(index: number) {
        const pagesAfterAndBefore = window.screen.width > 700 ? 3 : 2;

        if (this.page) {
            if (this.page + pagesAfterAndBefore < index) {
                return false;
            }
            if (this.page - pagesAfterAndBefore > index) {
                return false;
            }
        }

        if (this.total_pages) {
            if (index > this.total_pages) {
                return false;
            }
        }

        return true;
    }

    shouldShowFilters(): boolean {
        return this.displayedFilters.length > 0;
    }

    onSubmit() {
        let query:any = {
            name: this.formFilter.value.name,
            email: this.formFilter.value.email,
            creatorName: this.formFilter.value.creatorName,
            dhInit: this.formFilter.value.dhInit,
            dhEnd: this.formFilter.value.dhEnd
        }

        this.filtersLoaded = Object.fromEntries(Object.entries({ ...query }).filter(([_, v]) => v != '' && v != undefined));

        if(this.filtersLoaded.dhInit) {
            let initialStartDate =  new Date(query.dhInit);
            this.filtersLoaded
                .dhInit = encodeURIComponent(`${this.addZero(initialStartDate.getDate())}-${this.
                    addZero(initialStartDate.getMonth() + 1)}-${initialStartDate.getFullYear()}`)
            
        }
        if(this.filtersLoaded.dhEnd) {
            let initialEndDate =  new Date(query.dhEnd);
            this.filtersLoaded.dhEnd = encodeURIComponent(`${this.addZero(initialEndDate.getDate())}-${this.
                addZero(initialEndDate.getMonth() + 1)}-${initialEndDate.getFullYear()}`)
        }


        this.changePage(1);
    }

    addZero(monthOrDay: number): string | number {
        return monthOrDay < 10 ? `0${monthOrDay}` : monthOrDay;
    }

    shouldActiveFilter(name: string): boolean {
        return this.displayedFilters.some(e => e === name);
    }

    clearFilter() {
        this.filtersLoaded = undefined;
        this.selection.clear();
        this.formFilter.reset();
        this.changePage(1);
    }

    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource?.data.length;
        return numSelected === numRows;
    }

    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }
        if (this.dataSource) {
            this.selection.select(...this.dataSource.data);
        }
    }
}