import { ServiceContract, Partner, Theme, InstitutionCod, PathFromLogo } from '../models';
import { PartnerEnum } from '../partner.enum';

export class Afinz implements Partner {
  readonly theme: Theme = PartnerEnum.Afinz;
  readonly logo: PathFromLogo = PartnerEnum.AfinzLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceAfinz;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionAfinz;
  readonly primaryColor: string = '#000000';
}
