import { InstitutionCod, PathFromLogo, Partner, ServiceContract, Theme } from '../models';
import { PartnerEnum } from '../partner.enum';

export class Inter implements Partner {
  readonly theme: Theme = PartnerEnum.Inter;
  readonly logo: PathFromLogo = PartnerEnum.InterLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceInter;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionInter;
  readonly primaryColor: string = '#FF7A00';
}
