import { InstitutionCod, PathFromLogo, Partner, ServiceContract, Theme } from '../models';
import { PartnerEnum } from '../partner.enum';

export class Crefisa implements Partner {
  readonly theme: Theme = PartnerEnum.Crefisa;
  readonly logo: PathFromLogo = PartnerEnum.CrefisaLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceCrefisa;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionCrefisa;
  readonly primaryColor: string = '#FDB913';
}
