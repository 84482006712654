import { InstitutionCod, PathFromLogo, Partner, ServiceContract, Theme } from '../models';
import { PartnerEnum } from '../partner.enum';

export class Fiserv implements Partner {
  readonly theme: Theme = PartnerEnum.Fiserv;
  readonly logo: PathFromLogo = PartnerEnum.FiservLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceFiserv;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionFiserv;
  readonly partnerName: string = PartnerEnum.Fiserv;
  readonly routerPartnerName: string = PartnerEnum.Fiserv;
}
