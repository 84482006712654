import { ApiModule } from '../api.module';
import { ApiService } from 'src/app/api/services/common/api.service';
import { Injectable } from '@angular/core';
import { UserType } from '../models/user-type';
import { Observable } from 'rxjs';

@Injectable({ providedIn: ApiModule })
export class UserTypeApiService {

    constructor(
        private api: ApiService
    ) { }

    getUserTypes(isLatam: boolean, userTypesSelect: string[]): Observable<UserType[]> {
        return this.api.get<UserType[]>(
          `/v1/admin/userTypes?latam=${isLatam}${(userTypesSelect.length > 0) ? '&types=' + userTypesSelect : ''}`);
    }
}
