import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';

import { CookieService } from '../api/services/cookie.service';


import { HelpersRegistry } from './helpers.registry';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BaseUrlInterceptor } from '../api/interceptors/base-url.interceptor';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
  ],
  providers: [
    CookieService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [MatIconRegistry, DomSanitizer],
      useFactory: HelpersRegistry.init,
    }
  ]
})
export class HelpersModule { }
