import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';

@Component({
  selector: 'app-page-error-not-authorized',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './page-error-not-authorized.component.html',
  styleUrls: ['./page-error-not-authorized.component.scss'],
})
export class PageErrorNotAuthorizedComponent implements OnInit {
  title: string;

  constructor(
    private globalizationService: GlobalizationService,
    public route: ActivatedRoute
  ) {
    this.title = '401 - Não Autorizado';
  }

  ngOnInit(): void {}
}
