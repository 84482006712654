<ng-template cdkConnectedOverlay [cdkConnectedOverlayDisableClose]="disableClose" [cdkConnectedOverlayOrigin]="trigger"
  cdkConnectedOverlayPanelClass="float-menu-component" [cdkConnectedOverlayOpen]="isOpen">

  <div class="menu-box-fiserv-adm">
    <!-- Home Link -->
    <a [routerLink]="homeLink()" [ngClass]="{'active': activeHomeMenu, 'menu-line': true}">
      <mat-icon svgIcon="home"></mat-icon>
      <span class="text-information">{{"HOME" | translate}}</span>
    </a>

    <!-- Internal Users Link (FD only) -->
    <ng-template [ngIf]="isFD">
      <a [routerLink]="openInternalUsersList()" [ngClass]="{'active': activeUsersInternalMenu, 'menu-line': true}">
        <mat-icon svgIcon="users"></mat-icon>
        <span class="text-information">{{"USER_INTERNAL" | translate}}</span>
      </a>
    </ng-template>

    <!-- Reports Link (FD only) -->
    <ng-template [ngIf]="isFD">
      <a [routerLink]="openReport()" [ngClass]="{'active': activeReportMenu, 'menu-line': true}">
        <mat-icon svgIcon="report"></mat-icon>
        <span class="text-information">{{"REPORTS" | translate}}</span>
      </a>
    </ng-template>

    <!-- Settings Link (SuperAdmin only) -->
    <ng-template [ngIf]="isSuperAdmin">
      <a [routerLink]="openInstitutionChannelList()" [ngClass]="{'active': activeSettings, 'menu-line': true}">
        <mat-icon svgIcon="settings"></mat-icon>
        <span class="text-information">{{"SETTINGS" | translate}}</span>
      </a>
    </ng-template>
  </div>

</ng-template>

<div cdkOverlayOrigin #trigger="cdkOverlayOrigin" class="menu" style="position: fixed; left: 0px;"></div>
<div class="main-toolbar-m" [ngClass]="{'active':
  mobileMenuActive}">

  <mat-icon *ngIf="!showArrowBackButton; else ArrowBackButtonContainer" (click)="showMobileMenu()" class="menu-hamb"
    svgIcon="menu-hamb"></mat-icon>

  <ng-template #ArrowBackButtonContainer>
    <span (click)="backToList()" class="material-icons">
      arrow_back
    </span>
  </ng-template>
  <div class="img-partner">
    <img mat-card-image [src]="partner?.logo" alt="logo">
    <mat-icon (click)="mobileMenuActive= false" svgIcon="close"></mat-icon>
  </div>

  <div class="options">
    <mat-icon [ngClass]="{'active': activeHomeMenu}" svgIcon="home">
    </mat-icon>
    <span [ngClass]="{'active': activeHomeMenu}">{{"HOME" | translate}}</span>

    <ng-template [ngIf]="isFD">

      <mat-icon [ngClass]="{'active': activeReportMenu}" svgIcon="report">
      </mat-icon>
      <span [ngClass]="{'active': activeReportMenu}" (click)="openReport()">{{"REPORTS" | translate}}</span>
      <mat-icon [ngClass]="{'active': activeUsersInternalMenu}" svgIcon="users">
      </mat-icon>
      <span [ngClass]="{'active': activeUsersInternalMenu}" (click)="openInternalUsersList()">{{"USER_INTERNAL"
        | translate}}</span>
    </ng-template>

    <ng-template [ngIf]="isSuperAdmin">
      <mat-icon [ngClass]="{'active': activeSettings}" svgIcon="settings">
      </mat-icon>
      <span [ngClass]="{'active': activeSettings}" (click)="openInstitutionChannelList()">{{"SETTINGS" |
        translate}}</span>
    </ng-template>

    <mat-icon svgIcon="logout">
    </mat-icon>
    <span (click)="logout()">{{'SIGN_OUT' | translate}}</span>
  </div>

</div>
