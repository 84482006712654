<div class="main-toolbar-m">

    <mat-icon style="width: 63px; height: 32px" class="svgIcon"
        svgIcon="logo-fiserv"></mat-icon>
</div>

<div class="page">
    <main class="content">
        <div class="wrap">
            <mat-icon class="svgIcon" svgIcon="shape"
                style="width: 120px; height: 120px"></mat-icon>
            <div class="title-container">
                <h1>{{title}}</h1>
            </div>

            <div class="message">
                <p>Sua solicitação não foi autorizada porque seu endereço de
                    redirecionamento
                    não é válido para acessar o recurso de destino. </p>
            </div>
        </div>
    </main>
</div>