
<app-float-menu></app-float-menu>

<div *ngIf="!showMobileMenu; else menuMobileBlock" class="page">

  
    <app-user-name-authenticate></app-user-name-authenticate>

   

    <main class="content">

          <div [class.remove]="!showToastConfirmation" [class.warning]="showToastConfirmationWarning" class="toast-importation">
            <div class="information">
              <mat-icon svgIcon="checkbox-circle"></mat-icon>
              <span class="text">{{toastText}}</span>
            </div>
            <div class="close-box">
              <button (click)="showToastConfirmation = false" mat-icon-button>
    
                <mat-icon svgIcon="close"></mat-icon>
              </button>
            </div>
    
          </div>


        <div class="title">

            <a
                class="add-user-mob"
                *ngIf="userCanInvite"
                mat-raised-button
                routerLink="{{goToInviteUser()}}"
                color="primary">{{"NEW_USER" | translate}}
                <mat-icon>add</mat-icon>
            </a>

            <button class="mob-importation-button" *ngIf="isAlliance" (click)="showImportationModal(loadFileBlock)" mat-stroked-button>
              {{'NEW_IMPORTATION' | translate}}
                <mat-icon svgIcon="file-text"></mat-icon>
            </button>
           
        </div>

        <mat-tab-group 
          [preserveContent]="true" 
          #matTabsContainer
          (selectedTabChange)="onTableChange($event)" 
          [selectedIndex]="selectedIndexTab" 
          dynamicHeight>


			<mat-tab [id]="tabId.Users" label="{{'USERS' | translate}}">
       
          <app-table-users
              [list]="list" 
              [page]="pageUsersList"
              [displayedFilters]="displayedFilters"
              [profileList]="profileList"
              [total_pages]="totalPagesUsersList"
              [displayedColumns]="displayedColumns"
              (selectedPage)="selectedPage($event)"
              >
          </app-table-users>

      </mat-tab>
			<mat-tab [id]="tabId.Pending"  *ngIf="userHasPermissionToSeePendingList" label="{{'INVITES_PENDING' | translate}}">

        <app-table
          *ngIf="!isAlliance && !isGrafana"
          (selected)="selectedPendingUser($event)" 
          (selectedPage)="searchUsersPendingInvite($event)"
          [list]="pendingInvitesUsers" 
          [profileList]="profileList"
          [filtersActive]="allianceActiveFilters"  
          [totalElements]="totalElementsPendingList" 
          [total_pages]="totalPagesPendingList"   
          [page]="pagePendingList" 
        ></app-table>

      <app-table-list-invited-grafana
      *ngIf="!isAlliance && isGrafana && pendingInvitesUsersGrafana"
      (selected)="selectedPendingUser($event)" 
      (selectedPage)="searchUsersPendingInvite($event)"
      [list]="pendingInvitesUsersGrafana" 
      [profileList]="profileList"
      [filtersActive]="allianceActiveFilters"  
      [totalElements]="totalElementsPendingList" 
      [total_pages]="totalPagesPendingList"   
      [page]="pagePendingList" 
      >
      </app-table-list-invited-grafana>
      <app-table-list-invited-alliance 
          *ngIf="isAlliance && !isGrafana && pendingInvitesUsersAlliance"
          (selected)="selectedPendingUser($event)" 
          [list]="pendingInvitesUsersAlliance" 
          [profileList]="profileList" 
          [filtersActive]="allianceActiveFilters" 
          [totalElements]="totalElementsPendingList" 
          [total_pages]="totalPagesPendingList"   
          [page]="pagePendingList" 
          (selectedPage)="searchUsersPendingInvite($event)"
          (deleteBatch)="deleteInvitesBatch($event)" 
          (resendBatch)="resendInvitesBatch($event)" 
        ></app-table-list-invited-alliance>

      </mat-tab>
			<mat-tab [id]="tabId.Logs"  *ngIf="partnerCanViewLogsTabs" label="{{'USER_LOGS' | translate}}">

        <app-table-user-logs 
        [list]="listUsersLog" 
        [page]="pageUserLogs"
        [profileList]="profileList"
        [total_pages]="totalPagesUserLogs"
        [serviceContracts]="institutions"
        (selected)="detailsUserLogsDescription($event)"
        (selectedPage)="searchUserLogs($event)"
      ></app-table-user-logs>

      </mat-tab>

      <mat-tab [id]="tabId.Groups" *ngIf="isGrafana" label="{{'GROUPS' | translate}}">

      
        <div class="table-groups">

          <div class="filter-container">
            <mat-form-field appearance="fill">
              <mat-label>{{'NAME' | translate}}</mat-label>
              <input matInput  [formControl]="filterGroupControl"  #inputGroupName>
            </mat-form-field>
            </div>
         
          <div>        
      
      
          <!-- Users pending table -->
          <table id="22" aria-label="table" mat-table [dataSource]="displayedGroupData"
            class="mat-elevation-z8">
      
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th class="column-title" mat-header-cell *matHeaderCellDef> {{'NAME' | translate}}  </th>
              <td  mat-cell *matCellDef="let element">
            
                <div class="line-container">


                  <span class="cell-information">{{element.name}}</span>
                </div>
               
              </td>
            </ng-container>
              
           
            <tr  mat-header-row *matHeaderRowDef="displayedColumnsGroups"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsGroups;"></tr>
          
            
      
            <!-- Row shown when there is no matching data. -->
            <tr style="text-align: center;" class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="8">{{(inputGroupName.value ? 'NO_GROUPS_REGISTERED_WITH_THE_NAME' : 'NO_GROUPS_REGISTERED') | translate: {name: inputGroupName.value} }}</td>
            </tr>
          </table>
      
        </div>

        </div> 

        <div class="pagination-container">

          <app-pagination 
          [totalPages]="totalPagesGroups"
          [page]="pageGroups"
          [totalElements]="totalElementsGroups"
          (changeToPage)="changePageGroup($event)"
        ></app-pagination>

        </div>
      </mat-tab>
  


      <mat-tab *ngIf="userCanInvite" labelClass="tab-button-invite" disabled>
        <ng-template mat-tab-label>
        
          <a
        
          class="add-user"
          routerLink="{{goToInviteUser()}}"
          mat-flat-button
          color="primary">{{"NEW_USER" | translate}}
              <mat-icon>add</mat-icon>
            
          </a>

        </ng-template>
     
    </mat-tab>
    <mat-tab *ngIf="canUploadUsers" labelClass="tab-button-new-importation" disabled>

      <ng-template mat-tab-label>
        <button  (click)="showImportationModal(loadFileBlock, $event)" mat-stroked-button>
          {{'NEW_IMPORTATION' | translate}}
          <mat-icon svgIcon="file-text"></mat-icon>
      </button>
    </ng-template>

    </mat-tab>
    
      </mat-tab-group>
        


    </main>
</div>

<ng-template #menuMobileBlock>
    <div class="page">

        <div class="close-box">
            <span>{{'FILTER' | translate}}</span>
            <mat-icon (click)="showMobileMenu= false">close</mat-icon>
        </div>

        <div class="mobile-filtre-options">
            <form [formGroup]="form" 
                class="example-form">
                <mat-form-field *ngIf="!isAlliance" class="example-full-width"
                    appearance="fill">
                    <mat-label>{{'NAME' | translate}}</mat-label>
                    <input
                        matInput
                        id="name"
                        type="text"
                        formControlName="name"
                        />

                </mat-form-field>
                <mat-form-field  class="example-full-width"
                    appearance="fill">
                    <mat-label>{{'EMAIL' | translate}}</mat-label>
                    <input
                        matInput
                        id="email"
                        type="text"
                        formControlName="email"
                        />

                </mat-form-field>

                <mat-form-field id="userTypeSelect" appearance="fill">
                    <mat-label>{{'TYPE_USER' | translate}}</mat-label>
                    <mat-select id="userRole"
                        formControlName="userRole"
                        multiple>
                        <mat-select-trigger>
                            {{form.value['userRole'] && profileList ?
                            showProfileName(form.value['userRole'][0])
                            : ''}}
                            <span *ngIf="form.value['userRole']?.length> 1"
                                class="example-additional-selection">
                                (+{{form.value['userRole'].length - 1}}
                                {{form.value['userRole']?.length === 2 ?
                                ('OTHER' | translate)
                                :  ('OTHERS' | translate)}})
                            </span>
                        </mat-select-trigger>

                        <mat-option *ngFor="let profile of profileList"
                            [value]="profile.id">{{showProfileName(profile.id)}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="!isAlliance" appearance="fill">
                    <mat-label>{{'STATUS' | translate}}</mat-label>
                    <mat-select id="status"
                        formControlName="status"
                        matNativeControl>
                        <mat-option value="true">{{'ACTIVE' | translate}}</mat-option>
                        <mat-option value="false">{{'INACTIVE' | translate}}</mat-option>
                        <mat-option value="">{{'ALL' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>




                <div class="search-button-container">
                    <button
                        type="submit"
                        [disabled]="!form.valid"
                        class="search-button"
                        color="primary"
                        mat-raised-button>
                        {{'CONFIRM' | translate}}
                        <mat-icon>check</mat-icon>
                    </button>
                </div>


            </form>
        </div>

    </div>
</ng-template>


<ng-template #loadFileBlock [ngIf]="showUploadComponent">
    <div class="panel-upload">
        <ng-template #needUploadFile>
            <div class="alert-a">
                <div class="alert-ab">
                    <mat-icon
                        svgIcon="alert-circle"></mat-icon>
                    <span>{{'THERE_IS_NO_PRE_REGISTRATION' | translate}}</span>

                </div>

            </div>
            <div class="new-importation-text">
              <div class="close-box">
                <button (click)="newImportationDialog?.close()" mat-icon-button>

                  <mat-icon svgIcon="close"></mat-icon>
                </button>
              </div>
              <h1>{{'NEW_IMPORTATION' | translate}}</h1>
            </div>
       
            <app-file-upload
            [optionalUpload]="false"
            [ShouldHaveImagePreview]="false"
            typeRequired="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            acceptType=".xlsx"
            (fileUploaded)="sendDataToExtract($event)"
            fileTextUsedFor="FILE"
            fileTextType="Excel com relação dos usuários">
        </app-file-upload>
        </ng-template>
      
      
        <div *ngIf="obs$?.isLoading$ | async; else needUploadFile" class="animation-container">
            <div class="animation">
              <lottie-player autoplay="true"
                [src]="loadingPathJson"
                loop=""
                speed="1" background="transparent" style="height: 100%;
                background: transparent;">
      
                <div>
                  <div>
      
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0
                      45 20" width="45" height="20"
                      preserveAspectRatio="xMidYMid meet"
                      style="width: 100%; height: 100%; transform:
                      translate3d(0px, 0px, 0px);">
                      <defs>
                        <clipPath id="__lottie_element_2">
                          <rect width="45" height="20" x="0"
                            y="0"></rect>
                        </clipPath>
                      </defs>
                      <g clip-path="url(#__lottie_element_2)">
                        <g
                          transform="matrix(0.6000000238418579,0,0,0.6000000238418579,29.287200927734375,7.269000053405762)"
                          opacity="0.5" style="display: block;">
                          <image width="10px" height="10px"
                            preserveAspectRatio="xMidYMid slice"
                            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAOUlEQVQoU2P8n84wgeE/gwEDPvCfoYDxfxrDAQYGBnu8CpkYHEcV4g4hcPCkMhgwMDMI4A3HHwwXAIl/HUbY+z9pAAAAAElFTkSuQmCC">
                          </image>
                        </g>
                        <g
                          transform="matrix(0.6000000238418579,0,0,0.6000000238418579,19.5,7)"
                          opacity="0.5" style="display: block;">
                          <image width="10px" height="10px"
                            preserveAspectRatio="xMidYMid slice"
                            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAOUlEQVQoU2P8n84wgeE/gwEDPvCfoYDxfxrDAQYGBnu8CpkYHEcV4g4hcPCkMhgwMDMI4A3HHwwXAIl/HUbY+z9pAAAAAElFTkSuQmCC">
                          </image>
                        </g>
                        <g
                          transform="matrix(0.9327830672264099,0,0,0.9327830672264099,6.230002403259277,5.305084705352783)"
                          opacity="0.9159788461538623"
                          style="display: block;">
                          <image width="10px" height="10px"
                            preserveAspectRatio="xMidYMid slice"
                            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAOUlEQVQoU2P8n84wgeE/gwEDPvCfoYDxfxrDAQYGBnu8CpkYHEcV4g4hcPCkMhgwMDMI4A3HHwwXAIl/HUbY+z9pAAAAAElFTkSuQmCC">
                          </image>
                        </g>
                      </g>
                    </svg>
                  </div>
      
                </div>
                <div [appIsLoading]="false"></div>
              </lottie-player>
      
            </div>
            <p class="saving">{{'UPLOADING_FILE' | translate}}</p>
          </div>

          <div *ngIf="obs$?.isLoading$ | async;"  class="upload-cancel">
            <button (click)="cancelRequest()" mat-stroked-button>{{'CANCEL' | translate}}</button>
          </div>
      
        
    </div>

</ng-template>