import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-form-change-confirm-email',
  templateUrl: './form-change-confirm-email.component.html',
  styleUrls: ['./form-change-confirm-email.component.scss']
})
export class FormChangeConfirmEmailComponent implements OnInit {

  @Input() title?: string;
  @Input() subtitle?: string;
  @Output() sendEmail: EventEmitter<{ email: string }> = new EventEmitter();

  form = this._fb.group({
    email: ['', [Validators.required, Validators.email, forbiddenEmail(/.*\.com/i)]]
  });

  constructor(
    private _fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {

      if (params?.email)
        this.form.get('email')?.patchValue(params.email);

    });
  }


}

export const forbiddenEmail = (exp: RegExp): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const forbidden = exp.test(control.value);
    return !forbidden ? { forbiddenEmail: { value: control.value } } : null;
  };
