import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiModule } from '../api.module';
import { ApiService } from './common/api.service';

@Injectable({
  providedIn: ApiModule
})
export class LocationService {

  constructor(private _apiService: ApiService) { }

  validateClientIdAnRedirectURI(channelClientId: string, redirectUri: string):
    Observable<{ channelClientId: string; redirectUri: string }> {
    return this._apiService.get<{ channelClientId: string; redirectUri: string }>
      (`/v1/public/authenticate/validate/validate-uri?client_id=${channelClientId}&redirect_uri=${redirectUri}`);
  }
}
