import { Component, OnInit } from '@angular/core';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UserNameAuthenticateComponent } from '../user-name-authenticate/user-name-authenticate.component';
import { PageErrorComponent } from '../page-error/page-error.component';
import { FloatMenuComponent } from '../float-menu/float-menu.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'page-error-unavailable',
  standalone: true,
  imports: [
    TranslateModule,
    UserNameAuthenticateComponent,
    PageErrorComponent,
    FloatMenuComponent,
    MatButtonModule,
  ],
  templateUrl: './page-error-unavailable.component.html',
  styleUrls: ['./page-error-unavailable.component.scss'],
})
export class PageErrorUnavailableComponent implements OnInit {
  title: string;
  partner: Partner | undefined;

  constructor(
    private globalizationService: GlobalizationService,
    private _location: Location,
    private _router: Router,
    private _partner: AppPartner
  ) {
    this.title = this.globalizationService.translate('PAGE_UNAVAILABLE');
  }
  backClicked() {
    const previousUrl = document.referrer;
    const partnerName = this._partner
      .getPartnerValue()
      ?.routerPartnerName?.toLocaleLowerCase();
    if (partnerName && previousUrl.includes(partnerName)) {
      this._location.back();
    } else {
      this._router.navigate(['/' + (partnerName || 'fiserv')]);
    }
  }

  ngOnInit(): void {
    this._partner.getPartner().subscribe((el) => (this.partner = el));
  }
}
