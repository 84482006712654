import { Component, OnInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-external-user-done',
  templateUrl: './external-user-done.component.html',
  styleUrls: ['./external-user-done.component.scss']
})
export class ExternalUserDoneComponent implements OnInit, AfterContentChecked {

  currentStep = 3;

  isLinear = true;
  isEditable = false;

  state: {
    redirect_uri?: string;
    client_id?: string;
    user_name?: string;
  } = {};

  name = '';
  to_login = true;

  partner: Partner | undefined;

  constructor(
    private _appPartner: AppPartner,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _ref: ChangeDetectorRef
  ) { }

  ngAfterContentChecked() {
    this._ref.detectChanges();
  }

  ngOnInit(): void {
    this._appPartner.getPartner().subscribe(el => this.partner = el);
    this._activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.name = params.socialName;

      if((typeof params['toLogin'] == "string") && (typeof JSON.parse(params['toLogin']) == "boolean")) {
        this.to_login = JSON.parse(params['toLogin']);
      }

      this.state.redirect_uri = params.redirect_uri;
      this.state.client_id = params.client_id;
      this.state.user_name = params.user_name;
    });
  }

  goToLogin() {
    if (this.state.redirect_uri && !this.to_login) {
      window.open(this.state.redirect_uri, '_self');
    } else {
      this._router.navigate(['../../login'], { relativeTo: this._activatedRoute, queryParams: this.state });
    }
  }

}
