import { Afinz } from '../afinz/afinz';
import { Bin } from '../bin/bin';
import { Butterfield } from '../butterfield/butterfield';
import { Caixa } from '../caixa/caixa';
import { Citibank } from '../citibank/citibank';
import { Credicard } from '../credicard/credicard';
import { Crefisa } from '../crefisa/crefisa';
import { Fiserv } from '../fiserv/fiserv';
import { Hipercard } from '../hipercard/hipercard';
import { Inter } from '../inter/inter';
import { Mexico } from '../mexico/mexico';
import { PartnerEnum } from '../partner.enum';
import { Redepop } from '../redepop/redepop';
import { ScotiaChile } from '../scotia-chile/scotia-chile';
import { Scotia } from '../scotia/scotia';
import { Sicredi } from '../sicredi/sicredi';
import { Sipag } from '../sipag/sipag';
import { PathFromLogo } from './logo.model';
import { Theme } from './theme.model';

export type Partner = {
    theme: any;
    logo: any;
    serviceContract: any;
    channelType?: string;
    language?: string;
    institutionCod: any;
    primaryColor?: string;
    secondaryColor?: string;
    backgroundColor?: string;
    routerPartnerName?: string;
    channelClientId?: string;
    partnerName?: string;
};
// export type Partner = {
//     theme: Theme | string;
//     logo: PathFromLogo | string;
//     serviceContract: ServiceContract | number;
//     institutionCod: InstitutionCod | string;
// };

export const AllPartnerNameItems = [
    PartnerEnum.Scotia,
    PartnerEnum.Bin,
    PartnerEnum.Butterfield,
    PartnerEnum.Caixa,
    PartnerEnum.Citibank,
    PartnerEnum.Credicard,
    PartnerEnum.Crefisa,
    PartnerEnum.Fiserv,
    PartnerEnum.Hipercard,
    PartnerEnum.Itau,
    PartnerEnum.Inter,
    PartnerEnum.Redepop,
    PartnerEnum.Sicredi,
    PartnerEnum.Sipag,
    PartnerEnum.Afinz,
    PartnerEnum.ScotiaChile,
    PartnerEnum.Mexico] as const;


export const AllInstitutionCod = [
    PartnerEnum.InstitutionBin,
    PartnerEnum.InstitutionButterfield,
    PartnerEnum.InstitutionCaixa,
    PartnerEnum.InstitutionCitibank,
    PartnerEnum.InstitutionCredicard,
    PartnerEnum.InstitutionCrefisa,
    PartnerEnum.InstitutionScotia,
    PartnerEnum.InstitutionScotiaChile,
    PartnerEnum.InstitutionFiserv,
    PartnerEnum.InstitutionHipercard,
    PartnerEnum.InstitutionInter,
    PartnerEnum.InstitutionItau,
    PartnerEnum.InstitutionRedepop,
    PartnerEnum.InstitutionSicredi,
    PartnerEnum.InstitutionSipag,
    PartnerEnum.InstitutionAfinz,
    PartnerEnum.InstitutionMexico
] as const;

export const AllServiceContract = [
    PartnerEnum.ServiceBin,
    PartnerEnum.ServiceButterfield,
    PartnerEnum.ServiceCaixa,
    PartnerEnum.ServiceCitibank,
    PartnerEnum.ServiceCredicard,
    PartnerEnum.ServiceCrefisa,
    PartnerEnum.ServiceScotia,
    PartnerEnum.ServiceFiserv,
    PartnerEnum.ServiceHipercard,
    PartnerEnum.ServiceInter,
    PartnerEnum.ServiceItau,
    PartnerEnum.ServiceRedepop,
    PartnerEnum.ServiceSicredi,
    PartnerEnum.ServiceSipag,
    PartnerEnum.ServiceAfinz,
    PartnerEnum.ServiceMexico
] as const;

export const AllPartnerItems: Array<Partner> = [
    new Bin(),
    new Butterfield(),
    new Caixa(),
    new Citibank(),
    new Credicard(),
    new Crefisa(),
    new Fiserv(),
    new Hipercard(),
    new Inter(),
    new Redepop(),
    new Scotia(),
    new ScotiaChile(),
    new Sicredi(),
    new Sipag(),
    new Afinz(),
    new Mexico()
];


export type AllActivePartner = Required<typeof AllPartnerItems>;

export type AllPartnerName = Required<typeof AllPartnerNameItems>;

export type ServiceContract = typeof AllServiceContract[number];

export type PartnerName = typeof AllPartnerNameItems[number];

export type InstitutionCod = typeof AllInstitutionCod[number];

export interface PartnerUrlParams {
    client_id: string; redirect_uri: string; user_name: string; institutionCode: string;
}
