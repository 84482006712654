import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../modal-confirmation/modal-confirmation.component';

@Component({
  selector: 'app-modal-confirmation-report',
  templateUrl: './modal-confirmation-report.component.html',
  styleUrls: ['./modal-confirmation-report.component.scss']
})
export class ModalConfirmationReportComponent {

  constructor(public dialogRef: MatDialogRef<ModalConfirmationReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
      this.dialogRef.close(true);
  }

}
