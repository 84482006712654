import { InstitutionCod, PathFromLogo, Partner, ServiceContract, Theme } from '../models';
import { PartnerEnum } from '../partner.enum';

export class Credicard implements Partner {
  readonly theme: Theme = PartnerEnum.Credicard;
  readonly logo: PathFromLogo = PartnerEnum.CredicardLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceCredicard;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionCredicard;
  readonly primaryColor: string = '#D01481';
}
