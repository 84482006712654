<div class="information-container">
    <div class="button-container">
        <button class="close-button" mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
          </button>
    </div>
    
    <div>
        <h1 class="user-name">Usuário: {{user?.name}}</h1>
        <p>Criado por: {{user?.creatorName}}</p>
        <p>Email: {{user?.email}}</p>
        <p>Data de criação: {{user?.dhCreated}}</p>
        <p>Data do último acesso: {{user?.dhLastLogin}} </p>
    </div>
    
  
</div>

<div>

    <mat-tab-group #tabGroup [preserveContent]="true" (selectedIndexChange)="changeTab(tabGroup, $event)" [dynamicHeight]="true">

        <ng-template ngFor let-tab [ngForOf]="tabs" let-i="index" [ngForTrackBy]="trackByTab">
            <mat-tab  [label]="tab.title">

                
            <div style="max-height: 300px; overflow-y: auto;" #scrollContainer class="parent">
    
            <!-- Users pending table -->
            <table #table id="22" aria-label="table" mat-table [dataSource]="dataSource[i]" class="mat-elevation-z8">
    
                <!-- Activity Column -->
                <ng-container matColumnDef="activity">
                    <th class="column-title" mat-header-cell *matHeaderCellDef> {{"ACTIVITY" | translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="cell-information"> {{"LOGS_PAGE."+element.typeLog | translate}}</span>
                    </td>
                </ng-container>
    
                <!-- Responsible Column -->
                <ng-container matColumnDef="responsible">
                    <th class="column-title" mat-header-cell *matHeaderCellDef> {{"RESPONSIBLE" | translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="cell-information">{{element.userName}}</span>
                    </td>
                </ng-container>
    
                <!-- Location Column -->
                <ng-container matColumnDef="location">
                    <th class="column-title" mat-header-cell *matHeaderCellDef> {{"LOCATION" | translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="cell-information">{{element.geolocation}}</span>
                    </td>
                </ng-container>
    
                <!-- Date Column -->
                <ng-container matColumnDef="date">
                    <th class="column-title" mat-header-cell *matHeaderCellDef> {{"DATE" | translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <span class="cell-information">{{element.dh}}</span>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
                <!-- Row shown when there is no matching data. -->
                <tr style="text-align: center;" class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="8">{{'LOGS_PAGE.NO_RECORDS' | translate}}</td>
                </tr>
            </table>
        </div>
        <mat-progress-bar *ngIf="loading[i]" mode="query"></mat-progress-bar>
    

            </mat-tab>
          </ng-template>
      </mat-tab-group>
      


</div>