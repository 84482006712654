import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer, catchError, mergeMap } from 'rxjs';
import { PartnerEnum } from 'src/partner/partner.enum';
import { AppPartner } from 'src/partner/partner.service';

interface GeolocationPositionError {
  readonly code: number;
  readonly message: string;
}

interface GeolocationPositionError {
  PERMISSION_DENIED: 1;
}

@Injectable()
export class GeolocationInterceptor implements HttpInterceptor {
  
  // user lat. and long.
  private latitude:string = '0';
  private longitude:string = '0';
  
  // geolocation error
  readonly PERMISSION_DENIED: number = 1;
  readonly POSITION_UNAVAILABLE: number = 2;
  readonly TIMEOUT: number = 3;
  

  
  constructor(private partner: AppPartner) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
     return this.requestLocationPermission().pipe(
        mergeMap(() => {
          request = request.clone({
            setHeaders: {latitude: this.latitude, longitude: this.longitude},
          });
          return next.handle(request);
        }),
        catchError( () => {
          return next.handle(request);
        })
        
      )

  }

  requestLocationPermission(): Observable<GeolocationPosition> {
    return new Observable((observer: Observer<GeolocationPosition>) => {
      if (this.shouldRequestLocalization() && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
               
            this.latitude = position.coords.latitude.toString();
            this.longitude = position.coords.longitude.toString();

            observer.next(position);
            observer.complete();
          },
          (erro) => {
            observer.error(erro);
          }
        );
      } else {
        observer.error('Geolocation not supported by browser');
      }
    });
  }
  
  // TODO
  isPermissionDenied(erro: GeolocationPositionError): boolean {
    return erro.code === this.PERMISSION_DENIED;
  }

  private shouldRequestLocalization(): boolean {

    const partnerNameLowerCase = this.partner.getPartnerValue()?.partnerName?.toLowerCase();

    return [PartnerEnum.Afinz, PartnerEnum.Sicredi].some(_ => partnerNameLowerCase == _.toLocaleLowerCase())
  }

}
