import { ApiService } from './common/api.service';
import { Injectable } from '@angular/core';
import { ApiModule } from '../api.module';
import { ApiObservable } from 'src/app/shared/models/api-observable';


@Injectable({
  providedIn: ApiModule
})
export class RenewalApiService {

  constructor(
    private api: ApiService
  ) { }

  checkAccessRenewalPeriod(): ApiObservable<{
    renewalId?: number;
    putOff?: boolean;
    renewalPeriodEnd?: string;
  }> {
    return this.api.get<{
      renewalId?: number;
      putOff?: boolean;
      renewalPeriodEnd?: string;
    }>('/v1/renewal');
  }
  renewalUsersList(data: {
    renewalId: number;
    users: Array<{
      username: string;
      status: boolean;
    }>;
  }): ApiObservable<Record<string, unknown>> {
    return this.api.post<{
      renewalId: number;
      users: Array<{
        username: string;
        status: boolean;
      }>;
    }, Record<string, unknown>>('/v1/users/renewal', data);
  }

  generateMfaToRenewPassword(data: {
    email: string; channelClientId?: string; redirectUri?: string;
  }): ApiObservable<{
    email: string;
    expiresMilliseconds: number;
    mfaId: number;
    remainingAttempts: number;
    tokenId: number;
  }> {
    return this.api.post<{
      email: string; channelClientId?: string; redirectUri?: string;
    }, {
      email: string;
      expiresMilliseconds: number;
      mfaId: number;
      remainingAttempts: number;
      tokenId: number;
    }>('/v1/public/users/renew-password', data);
  }
  setNewPassword(data: {
    mfaId: number;
    password: string;
  }): ApiObservable<Record<string, unknown>> {
    return this.api.post<{
      mfaId: number;
      password: string;
    }, Record<string, unknown>>('/v1/public/users/renew-password-finish', data);
  }

  renewalValidTokenPassword({ mfaId, tokenValue }: { mfaId: number; tokenValue: string }):
    ApiObservable<{ firstName: string; lastName: string }> {
    return this.api.post<{ mfaId: number; tokenValue: string }, { firstName: string; lastName: string }>
      ('/v1/public/users/renew-password-token', { mfaId, tokenValue });
  }
}
