<mat-card
    class="expired-password-card">
    <mat-divider [vertical]="true"></mat-divider>
    <div class="container">
        <mat-card-subtitle>
            <mat-icon style="width: 17px; height: 17px;" svgIcon="alert-circle">
            </mat-icon><span>{{"ALERT" | translate}}</span></mat-card-subtitle>
        <mat-card-content> <p>{{"YOUR_PASSWORD_EXPIRED" |
                translate}}</p>
        </mat-card-content>
        <mat-card-footer data-testid="card-footer-new-password-process"
            (click)="startNewPasswordProcess.emit()"> <p>{{"DEFINE_YOUR_NEW_PASSWORD"
                | translate}}</p> <mat-icon
                svgIcon="arrow-right"></mat-icon></mat-card-footer>
    </div>

</mat-card>