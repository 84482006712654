import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Authenticate } from 'src/app/api/models/authentication-resp';
import { InviteApiService } from 'src/app/api/services/invite.service';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';

@Component({
  selector: 'app-user-confirmation-mfa',
  templateUrl: './user-confirmation-mfa.component.html',
  styleUrls: ['./user-confirmation-mfa.component.scss']
})
export class UserConfirmationMfaComponent implements OnInit {

  auth_data!: Authenticate;
  partner: Partner | undefined;

  mfaId?: number;
  remainingAttempts?: number;
  expiresMilliseconds?: number;
  tokenId?: number;
  email?: string;

  form = this._fb.group({
    0: [undefined, [Validators.required, Validators.minLength(1)]],
    1: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    2: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    3: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    4: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    5: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],

  });

  constructor(private _appPartner: AppPartner,
    private _fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this._appPartner.getPartner().subscribe(el => this.partner = el);

    this.mfaId = history.state.mfaId;
    this.remainingAttempts = history.state.remainingAttempts;
    this.expiresMilliseconds = history.state.expiresMilliseconds;
    this.tokenId = history.state.tokenId;
    this.email = history.state.email;

    this.auth_data = new Authenticate({
      mfaId: this.mfaId,
      remainingAttempts: this.remainingAttempts,
      expiresMilliseconds: this.expiresMilliseconds,
      tokenId: this.tokenId,
      email: this.email
    });
  }

  authCompleted({ mfaId, tokenValue }: { mfaId: number; tokenValue: string }): void {
    this.router.navigate(['../set-password'], { relativeTo: this.route, state: { mfaId, tokenValue } });
  }

}
