<form [formGroup]="form" (ngSubmit)="submitToken()">
  <div *ngIf="!blockedUser">
    <ng-container *ngIf="!token_expired && !exceededAttempts; else expiredOrExceeded">
      <h2 class="title">{{ title }}</h2>
      <div class="sub-title">
        <p>{{ weSentResent + subTitle }}</p>
      </div>
      <p class="sub-title-user-email"><strong>{{ userEmail }}</strong> </p>
    </ng-container>
  </div>
  
  <!-- Template for expired tokens or exceeded attempts -->
  <ng-template #expiredOrExceeded>
    <div *ngIf="token_expired && !exceededAttempts">
      <p class="message-warn">{{ 'TOKEN_EXPIRED_AND_CLICK_HERE' | translate }}.</p>
    </div>
    <div *ngIf="exceededAttempts">
      <p class="message-warn-exceeded-attempts-re-sended">{{ 'EXCEEDED_ATTEMPTS_RE_SEND' | translate }}.</p>
    </div>
  </ng-template>

  <!-- Blocked user message -->
  <div *ngIf="blockedUser" class="message-warn-blocked-user">
    <p>{{ 'BLOCKED_BY' | translate }} <strong>{{ timeToExpires }}</strong> {{ 'MINUTES' | translate }}.</p>
    <p>{{ 'WAIT_PERIOD_AND_RESTART_REGISTRATION' | translate }}.</p>
  </div>

  <div id="box-timer-with-input" *ngIf="!blockedUser && !token_expired && !exceededAttempts">
    <p>{{ 'TOKEN_EXPIRES_IN' | translate }}: <b>{{ timeToExpires }}</b></p>
    <div id="box-timer-with-input-token" (click)="invalidToken && unblock()">
      <app-form-token 
        [ngClass]="{'invalidToken': invalidToken}" 
        [amount]="amount" 
        type="text" 
        (childSubmit)="submitToken()">
      </app-form-token>
    </div>
  </div>

  <!-- Loader animation while token is being verified -->
  <ng-container *ngIf="obs$?.isLoading$ | async; else resendButton">
    <div class="animation-container">
      <div class="animation">
        <lottie-player autoplay="true" 
                      [src]="loadingPathJson" 
                      loop 
                      speed="1" 
                      background="transparent" 
                      style="height: 100%; background: transparent;">
                    
                      <div [appIsLoading]="obs$?.isLoading$ | async"></div>
        </lottie-player>
      </div>
      <p>{{ 'VERIFYING_TOKEN' | translate }}</p>
    </div>
  </ng-container>

  <!-- Button to resend token -->
  <ng-template #resendButton>
    <button type="button" id="button-re-send-token" mat-button 
            (click)="reSendToken()" 
            [disabled]="blockedUser">
      {{ 'RESEND_TOKEN' | translate }}
    </button>
  </ng-template>
</form>
