import { Directive, HostListener } from '@angular/core';
@Directive({
    selector: '[appOnlyNumbersDirective]'
})
export class OnlyNumbersDirective {

    regexStr = `^[0-9]*$`;

    constructor() { }


    @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
        return new RegExp(this.regexStr).test(event.key);
    }

    @HostListener('paste', ['$event']) blockPaste(event: ClipboardEvent) {
        const text = event.clipboardData?.getData('text');
        if (!text || !new RegExp(this.regexStr).test(text)) {
            event.preventDefault();
        }
    }


}
