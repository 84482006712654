<div class="canvas-container" *ngIf="isRenewerExternalUser()">
    <div class="icon-container">
        <mat-icon style="width: 33px; height: 33px;"
            svgIcon="alert-information">
        </mat-icon>

    </div>
    <div class="information-container">
        <h2>{{"RENEWAL_USER" | translate}}</h2>
        <p>{{"DEAD_LINE_RENEWAL" | translate}}
            {{renewalPeriodEnd | dateFormat}}.
            <span (click)="dialogRef.close(true)"> <strong><b>{{"CLICK_HERE" | translate}}</b></strong>
            </span> {{"TO_RENEWAL_NOW" | translate}}</p>
    </div>
    <div class="close-container">
        <mat-icon (click)="dialogRef.close(false)" svgIcon="close"></mat-icon>
    </div>
</div>
