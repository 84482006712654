import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { StorageModule } from '../storage/storage.module';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [LanguageSelectorComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatSelectModule,
    TranslateModule,
    StorageModule,
  ],
  exports: [
    TranslateModule,
    LanguageSelectorComponent
  ],
  providers: []
})
export class GlobalizationModule { }
