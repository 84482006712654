import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AuthApiService } from '../services/auth-api.service';


export const statusCodesError = [500, 501, 502, 503, 0];

@Injectable()
export class HttpErrorInterceptorComponent implements HttpInterceptor {

  constructor(private router: Router, private auth: AuthApiService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(0),
        catchError((returnedError) => {
          this.handleServerSideError(returnedError);
          return throwError(() => returnedError);
        })
      );
  }

  private handleServerSideError(error: HttpErrorResponse): void {
    if (statusCodesError.includes(error.status) && this.auth.isAuthenticated) {
      this.router.navigateByUrl('error-page-unavailable', { skipLocationChange: true });
    }

  }
}
