

    <mat-card class="container-mat">

        <mat-icon matSuffix svgIcon="FormValidation2F"></mat-icon>
        <mat-card-title *ngIf="title">

            <h2>{{title}}</h2>
        </mat-card-title>
        <mat-card-subtitle *ngIf="subtitle">

            <p>{{subtitle}}</p>
        </mat-card-subtitle>

        <app-form-password 
            [passwordForm]="mainForm"
            [first_access]="true">
        </app-form-password>

        <mat-card-actions>

            <button 
                id="buttonChangePassword" 
                class="continue-button"
                mat-raised-button 
                (click)="handleFormSubmit(mainForm.get('password')?.value)"
                color="primary"
                [disabled]="!mainForm.valid">{{buttonText}}
                <mat-icon>arrow_forward</mat-icon>

            </button>

        </mat-card-actions>
    </mat-card>

