import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable, map, startWith } from 'rxjs';
import { Channel } from 'src/app/api/models/channel';
import { Property } from 'src/app/api/services/config.service';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';



export interface ConfirmationProperty {
  id?: number | null | undefined;
  key: string | null | undefined;
  value: string | null | undefined;
  description: string | null | undefined;
  channels: number[] | null | undefined;
  encrypt: boolean | null | undefined;
}

@Component({
  selector: 'app-modal-handle-property',
  templateUrl: './modal-handle-property.component.html',
  styleUrls: ['./modal-handle-property.component.scss']
})
export class ModalHandlePropertyComponent implements OnInit {

  title: string = 'PROPERTY_CREATION';
  subTitle: string = 'PROPERTY_CREATION_SCREEN';
  buttonConfirmText: string = 'CREATE_PROPERTY';
  channelsList?: Channel[];
  keyList?: Observable<string[]>;
  isEdit = false;

 
  idControl:FormControl<number | null> = new FormControl({value: null, disabled: true});
  keyControl:FormControl<string | null> = new FormControl('', [Validators.required]);
  valueControl:FormControl<string | null> = new FormControl('', [Validators.required]);
  descriptionControl:FormControl<string | null> = new FormControl('');
  channelsControl:FormControl<number[] | null> = new FormControl([]);
  encryptControl:FormControl<boolean | null> = new FormControl(false);


  propertyForm:FormGroup<{
    id: FormControl<number | null>;
    key: FormControl<string | null>;
    value: FormControl<string | null>;
    description: FormControl<string | null>;
    channels: FormControl<number[] | null>;
    encrypt: FormControl<boolean | null>;
  }> = new FormGroup({
    id: this.idControl,
    key: this.keyControl,
    value: this.valueControl,
    description: this.descriptionControl,
    channels: this.channelsControl,
    encrypt: this.encryptControl,
  });

  constructor(
    public dialogRef: MatDialogRef<ModalHandlePropertyComponent, Partial<ConfirmationProperty>>,
    private _globalizationService: GlobalizationService,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {property?: Property, channelsList: Channel[], keyList: string[]},
  ) { }

  ngOnInit(): void {
    if (this.data?.property) {
      const {description, encrypt, id, key, value} = this.data.property;
      this.propertyForm.controls.id.enable();
      const channels = this.data.property.channels.map( e => e.id);
      this.propertyForm.patchValue({description, encrypt, id, key, value, channels})
      this.title = 'EDITING_PROPERTIES';
      this.subTitle = 'PROPERTY_EDIT_SCREEN';
      this.buttonConfirmText = 'UPDATE_PROPERTY';
      this.isEdit = true;
    }

    this.channelsList = this.data?.channelsList;

    this.keyList = this.keyControl.valueChanges.pipe(
      startWith(this.data.property?.key),
      map(value => this._filter(value || '')),
    );

  }


  confirmProperty(): void {
    const noTextEdit = this._globalizationService.translate('CANCEL');
    const yesTextEdit = this._globalizationService.translate('EDIT');
    const titleEdit = this._globalizationService.translate('ARE_YOU_SURE_YOU_WANT_TO_EDIT_THIS_PROPERTY');
    const descriptionEdit = this._globalizationService.translate('ALL_INFORMATION_EDITED_ON_THIS_PROPERTY_WILL_BE_SAVED');

    const noTextCreate = this._globalizationService.translate('CANCEL');
    const yesTextCreate = this._globalizationService.translate('CREATE');
    const titleCreate = this._globalizationService.translate('ARE_YOU_SURE_YOU_WANT_TO_CREATE_THIS_PROPERTY');
    const descriptionCreate = this._globalizationService.translate('THIS_PROPERTY_WILL_BE_CREATED');

    if (this.isEdit) {
      const _dialogRef = this._dialog.open(ModalConfirmationComponent, {
        width: '392px',
        height: '269px',
        autoFocus: false,
        data: {
          title: titleEdit,
          description: descriptionEdit,
          noText: noTextEdit,
          yesText: yesTextEdit,
          reverseOrderOfActionButtons: true
        }
      });
      _dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close(this.propertyForm.value);
        }
      });

    } else {
      const _dialogRef = this._dialog.open(ModalConfirmationComponent, {
        width: '392px',
        height: '269px',
        autoFocus: false,
        data: {
          title: titleCreate,
          description: descriptionCreate,
          reverseOrderOfActionButtons: true,
          noText: noTextCreate,
          yesText: yesTextCreate
        }
      });

      _dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close(this.propertyForm.value);
        }
      });
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.data.keyList.filter(key => key.toLowerCase().includes(filterValue));
  }

}
