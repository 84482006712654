import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, InjectionToken } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';


import { ApiModule } from '../../api/api.module';
import { SharedModule } from '../../shared/shared.module';
import { PartnerRoutingModule } from './partner-routing.module';

import { NgxMaskModule } from 'ngx-mask';

import { GlobalizationModule } from 'src/app/globalization/globalization.module';
import { ExternalLoginComponent } from './external-login/external-login.component';

import { ExternalChannelListComponent } from './channel-list/external-channel-list.component';
import { ExternalUserListComponent } from './user-list/external-user-list.component';
import { UserInviteComponent } from './user-invite/user-invite.component';
import { ExternalUserDocumentComponent } from './first-access/user-document.component';
import { ExternalUserConfirmInvitationComponent } from './first-access/user-confirm-invitation/user-confirm-invitation.component';
import { MatStepperModule } from '@angular/material/stepper';
import { UserOptInComponent } from './first-access/user-optin/user-optin.component';
import { ExternalUserEditComponent } from './user-edit/user-edit.component';
import { ExternalUserNameComponent } from './first-access/user-name/external-user-name.component';
import { UserForgotPasswordComponent } from './user-forgot-password/user-forgot-password.component';
import { UserPasswordComponent } from './first-access/user-password/user-password.component';
import { ExternalUserDoneComponent } from './first-access/user-done/external-user-done.component';
import { UserConfirmationMfaComponent } from './user-confirmation-mfa/user-confirmation-mfa.component';
import { SafeHtmlPipe } from 'src/app/shared/pipes/safe-html.pipe';
import { ImportTableAllianceExternalComponent } from './import-table-alliance/import-table-alliance-external.component';
import { MatTableModule } from '@angular/material/table';

export const WINDOW = new InjectionToken('WINDOW');
@NgModule({
  declarations: [
    ExternalLoginComponent,
    ExternalChannelListComponent,
    ExternalUserNameComponent,
    ExternalUserDoneComponent,
    ExternalUserListComponent,
    UserInviteComponent,
    ExternalUserDocumentComponent,
    ExternalUserConfirmInvitationComponent,
    UserOptInComponent, ExternalUserEditComponent,
    UserForgotPasswordComponent,
    UserPasswordComponent,
    UserConfirmationMfaComponent,
    ImportTableAllianceExternalComponent,
    SafeHtmlPipe],
  imports: [
    CommonModule,
    MatTableModule,
    PartnerRoutingModule,
    ReactiveFormsModule,
    OverlayModule,
    MatSelectModule,
    MatMenuModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatDividerModule,
    MatToolbarModule,
    ApiModule,
    MatSlideToggleModule,
    MatDialogModule,
    GlobalizationModule,
    SharedModule,
    MatTabsModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    {
      provide: WINDOW,
      useValue: window,
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PartnerModule { }

