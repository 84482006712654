export class Authenticate {
    readonly mfaId: number = 0;
    readonly expiresMilliseconds: number = 0;
    readonly remainingAttempts: number = 0;
    readonly tokenId: number = 0;
    readonly email: string = '';

    constructor(init: Partial<Authenticate>) {
        Object.assign(this, init);
    }
}