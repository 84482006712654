import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Channel } from 'src/app/api/models/channel';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';

@Component({
  selector: 'app-card-channel',
  templateUrl: './card-channel.component.html',
  styleUrls: ['./card-channel.component.scss']
})
export class CardChannelComponent {

  @Input() channel?: Channel;
  @Input() bodyText?: string;
  @Input() showButton = false;
  @Input() buttonText?: string;
  @Input() connectedChannel = false;
  @Input() withBorder = true;
  @Input() withBoxShadow = true;
  @Input() disabled = false;
  @Input() selectable = false;

  @Output() buttonAction: EventEmitter<Channel> = new EventEmitter();
  @Output() selectableAction: EventEmitter<Channel> = new EventEmitter();

  constructor(
    private _globalizationService: GlobalizationService
  ) { }

  getBodyText(): string {
    const text = this.channel?.status ?
      this._globalizationService.translate('ACTIVE') :
      this._globalizationService.translate('INACTIVE');

    return this.bodyText ?? text;
  }
  getActionText(): string {
    return this.buttonText ?? this._globalizationService.translate('MANAGE');
  }

  sendEvent() {
    this.buttonAction.emit(this.channel);
  }

}
