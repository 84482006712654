import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatListOption, MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { UserTypeEnum } from 'src/app/api/enums/user-type.enum';
import { AuthApiService } from 'src/app/api/services/auth-api.service';
import { RenewalApiService } from 'src/app/api/services/renewal.service';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';

export interface DialogData {
  renewalId: number;
  putOff: boolean;
  renewalPeriodEnd: string;
  list: Array<UserATT>;
}

interface UserATT { username: string; name: string; document?: string; status: boolean };
@Component({
  selector: 'app-modal-renewal',
  templateUrl: './modal-renewal.component.html',
  styleUrls: ['./modal-renewal.component.scss']
})
export class ModalRenewalComponent implements OnInit {

  usersToRenewal: Array<UserATT> = [];
  filteredUsers?: Array<UserATT>;
  title?: string;
  subtitle?: string;
  search?: string;
  confirmRenew = false;
  postponeAction = false;
  renewalPeriodEnd = '';
  expandedPanelSearch = false;
  selectedOptions: any = [];
  checkedValues: Array<UserATT> = [];

  constructor(
    public dialogRef: MatDialogRef<ModalRenewalComponent>,
    private _renewalService: RenewalApiService,
    private _authApi: AuthApiService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _globalizationService: GlobalizationService) { }

  ngOnInit() {
    this.usersToRenewal = this.data.list;
    this.renewalPeriodEnd = this.data.renewalPeriodEnd;
    this.title = this._globalizationService.translate('RENEWAL_USER');
    this.subtitle = this._globalizationService.translate('USERS_CONTINUE_ACCESS');
    this.search = this.userLatam()
      ? this._globalizationService.translate('SEARCH_BY_NAME_OR_EMAIL')
      : this._globalizationService.translate('SEARCH_BY_NAME_OR_CPF');
    this.confirmRenew = false;
    this.postponeAction = false;
  }



  applyFilter(event: Event) {
    const target = (event.target as HTMLInputElement).value;

    this.filteredUsers = this.usersToRenewal.filter(el =>
      el.name.toLowerCase().includes(target.toLowerCase())
      || el.document?.toLowerCase().startsWith(target.toLowerCase().replace(/\.|-/g, ''))
      || el.username.toLowerCase().includes(target.toLowerCase()));
  }

  selectedUser(user: UserATT): boolean {
    return !!this.checkedValues.find(el => el.username === user.username);
  }

  onSelection(event: MatSelectionListChange, value: Array<MatListOption>) {
    if (!event.options[0].selected) {
      this.checkedValues = this.checkedValues.filter(user => user.username !== event.options[0].value.username);
    } else {
      this.selectedOptions = value;

      for (const options of this.selectedOptions) {
        const index = this.checkedValues.indexOf(options.value);
        if (index === -1) {
          this.checkedValues.push(options.value);
        }
      }
    }
  }

  ShowExpandedPanelSearch(): void {
    this.expandedPanelSearch = true;
  }
  closeExpandMenu(): void {
    this.expandedPanelSearch = false;
  }

  onRenewClick(): void {
    this.title = this._globalizationService.translate('CONFIRM_RENEWAL');
    this.subtitle = this._globalizationService.translate('REMEMBER_USERS_INACTIVE');
    this.confirmRenew = true;
  }

  userLatam(): boolean {
    return (this._authApi.userDetails.userType === UserTypeEnum.LATAM_ALLIANCE);
  }

  onConfirmClick(checkedList: Array<UserATT>): void {
    this.usersToRenewal.forEach(user => {
      if (!checkedList.includes(user)) {
        user.status = false;
      }
    });

    const usersToRenewalDestructuring: {
      renewalId: number;
      users: Array<{
        status: boolean;
        username: string;
      }>;
    } = {
      renewalId: this.data.renewalId, users: this.usersToRenewal.map(({ username, status }) => ({
        username, status
      }))
    };

    this._renewalService.renewalUsersList(usersToRenewalDestructuring).subscribe(() => {
      this.dialogRef.close(true);
    });
  }

  onBackClick(): void {
    this.confirmRenew = false;
    this.ngOnInit();
  }
  onCloseClick(): void {

    this.confirmRenew = false;
    this.postponeAction = true;
    this.title = this._globalizationService.translate('POSTPONE_RENEWAL');
    this.subtitle = this._globalizationService.translate('POSTPONE_MONTH', { date: this.renewalPeriodEnd });
  }

  onPostponeClick(): void {
    this.dialogRef.close(false);
  }
  onSelectAllUsers(users: MatSelectionList): void {
    users.selectAll().map(e => {
      this.checkedValues.push(e.value);
    });
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1?.name === o2?.name && o1?.id === o2?.id;
  }

}
