<div class="content-modal">
    <div class="header-modal">
        <h2 mat-dialog-title>{{data.title}}</h2>
    </div>
    <div mat-dialog-content>
        <p>{{data.description}}</p>
    </div>
    <div class="controls-modal">
        <button mat-button (click)="onNoClick()">{{data.noText ? data.yesText
            :
            'NO_CANCELED' | translate}}</button>
        <button mat-button (click)="onYesClick()" cdkFocusInitial>{{data.noText ?
            data.yesText : 'YES_EMIT' | translate}}</button>
    </div>
</div>