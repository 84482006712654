<div id="modal-information-container">
    <h2 mat-dialog-title>Acesso de usuário Fiserv pendente de autorização. </h2>
    <mat-dialog-content class="mat-typography">



        <p>Para acessar esta aplicação e todos os seus recursos você, como
            usuário
            Fiserv,
            precisará solicitar ao seu gestor a devida autorização no Service
            Point.
        </p>
        <p>Entre em contato com seu gestor e após finalizar a configuração,
            volte e
            tente novamente. </p>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok, entendi</button>
    </mat-dialog-actions>
</div>