
import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';
import { HelpersModule } from './helpers.module';

@Injectable({providedIn: HelpersModule})
export class InternalRedirectGuardService implements CanActivate {

      constructor( 
        public router: Router
    ) {}
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const {queryParams} = route;

        const params = new URLSearchParams(queryParams).toString();
     
        const ref = params ? 'fiserv/login?' + params : 'fiserv/login'

        window.location.href = ref;
        
        this.router.navigate(['fiserv/login'], {queryParams});
        
        return false;
    }

    
}
