import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  Output,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-form-password',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    ReactiveFormsModule,
  ],
  templateUrl: './form-password.component.html',
  styleUrls: ['./form-password.component.scss'],
})
@Injectable()
export class FormPasswordComponent {
  @Input() first_access = false;
  @Input() passwordForm!: FormGroup;
  @Output() formSubmit = new EventEmitter<FormGroup>();

  hidePassword = true;
  hideConfirmPassword = true;

  constructor() {}

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }

  toggleConfirmPasswordVisibility() {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }
}
