import { Injectable } from '@angular/core';

import { ApiService } from './common/api.service';
import { ApiModule } from '../api.module';
import { ApiObservable } from 'src/app/shared/models/api-observable';
import { HttpParams } from '@angular/common/http';

export type typeLog = 'ERROR_ACCESS'| 'ACCESS_SUCCESS'| 'RESET_PASSWORD'| 'RENEW_PASSWORD' | 'CHANGE_LOG' | 'DELETE';

export interface QueryDetailsLogs {
    userName?: string;
    creator?: string; 
    dhInit?: string;
    dhEnd?: string;
    name?: string;
    typeLog?: typeLog | typeLog[];
    page: number;
    size: number;
    isListUser: boolean
}

export interface RetrieveDetailLogs {
    content: ContentDetailLogs[];
    pageable: PageAble;
    totalElements: number;
    last: boolean;
    totalPages: number;
    size: number;
    number: number;
    sort: Sort;
    numberOfElements: number;
    first: boolean;
    empty: boolean;
  }
  export interface PageAble {
    sort: Sort;
    offset: number;
    pageSize: number;
    pageNumber: number;
    unpaged: boolean;
    paged: boolean;
  }
  export interface Sort {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  }
  export interface ContentDetailLogs {
    userName: string;
    name: string;
    email?: string;
    dhLastLogin?: string;
    dh: string;
    qtAttempts?: number;
    dhCreated?: string;
    creatorName?: string;
    ip?: string;
    geolocation?: string;
    typeLog?: string;
    whoCreatedUpdated?: string;
  }

@Injectable({
  providedIn: ApiModule
})
export class LogsApiService {

  constructor(
    private api: ApiService
  ) { }

  retrieveDetailLogs(query: QueryDetailsLogs): ApiObservable<RetrieveDetailLogs> {
    return this.api.get<RetrieveDetailLogs>(`/v1/logs-user`, new HttpParams({fromObject: {...query}}));
  }
  retrieveDetailLogsBuffs(query: QueryDetailsLogs): ApiObservable<any> {
    return this.api.getWithProgress<RetrieveDetailLogs>(`/v1/logs-user`, new HttpParams({fromObject: {...query}}));
  }

}
