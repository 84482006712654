import { Component } from '@angular/core';
import { GlobalizationService } from '../../services/globalization.service';
import { Language } from '../../models/language';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {

  readonly languages: Language[];
  currentLanguage: Language;

  constructor(private globalizationService: GlobalizationService) {
    this.languages = globalizationService.availableLanguages;
    this.currentLanguage = globalizationService.currentLanguage;
  }

  onLanguageChange(value: string): void {

    this.globalizationService.setCurrentLanguage(value);
    this.currentLanguage = this.globalizationService.currentLanguage;
  }
}
