import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-change-done',
  templateUrl: './form-change-done.component.html',
  styleUrls: ['./form-change-done.component.scss']
})
export class FormChangeDoneComponent implements OnInit {

  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() name?: string;

  constructor() { }

  ngOnInit(): void {
  }

}
