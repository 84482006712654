<div class="panel" *ngIf="!userHasToken" id="insert-login">

    <div id=logo-container>
        <img [src]="partner?.logo" id="institutionalLogo" alt="logo
            institutional">
    </div>
    <div class="primary" id="welcome-container">
        <h2>{{'WELCOME'! | translate}}</h2>
    </div>

    <div id="form-container">
        <form [formGroup]="form" (keydown.enter)="handleEnter($event)" (ngSubmit)="userValid  ? submit() :
            validEmail()">

            <app-form-control label="{{ (userCanLogWithoutEmail ? 'USER' : 'EMAIL') | translate}}" id="email"
                name="email" type="email"
                [disabled]="userValid" suffix="{{userValid ? ('CHANGE' |
                translate) : ''}}"
                (changeButton)="changeUser()">

            </app-form-control>

            <ng-template [ngIf]="!expiredPassword && !inactiveRegistration">
                <app-form-control [@downToTop] *ngIf="userValid"
                    label="{{'PASSWORD'
                    | translate}}" name="password"
                    type="password" [minLength]="minLength"> </app-form-control>
                <div [@downToTop] id="forget-password" *ngIf="userValid">
                    <a id="reset-password" (click)="navigationForget()">{{'FORGET_MY_PASSWORD'
                        |
                        translate}}</a>
                </div>

                <div id="box-button-actions">
                    <button [@downToTop] *ngIf="userValid"
                        class="enter-login-external" id="enter-button"
                        data-testid="enter-button"
                        type="submit"
                        color="primary"
                        mat-raised-button [disabled]="!form.valid" translate>{{'ENTER'|
                        translate}}
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                    <button 
                        *ngIf="!userValid" 
                        type="submit"
                        id="continue-button"
                        data-testid="continue-button"
                        color="primary"
                        mat-raised-button
                        [disabled]="!form.get('email')?.valid" translate>{{'CONTINUE'
                        |
                        translate}}
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </div>
            </ng-template>
            <ng-template [ngIf]="expiredPassword && !userHasToken">
                <app-card-expired-password
                    (startNewPasswordProcess)="navigationForget()"></app-card-expired-password>
            </ng-template>
            <ng-template [ngIf]="inactiveRegistration && !userHasToken">
                <app-card-inactive-registration
                    content="{{'YOUR_REGISTRATION_INACTIVE'
                    | translate}}" footer="{{'CONTACT_PERSON_RESPONSIBLE' |
                    translate}}"></app-card-inactive-registration>
            </ng-template>

        </form>
    </div>
    <div id="language-container">
        <app-language-selector></app-language-selector>

    </div>
    <div id="first-access-button-box">
        <a mat-button routerLink="../first-access" id="first-access-button">{{'FIRST_ACCESS'
            |
            translate}} </a>
    </div>

</div>

<div class="panel" id="insert-code" *ngIf="userHasToken && !expiredPassword">
    <app-form-token-group
        title="{{'LETS_CHECK_YOUR_CODE' | translate}}?"
        [auth_data]="auth_data"
        (tokenSuccess)="authCompleted($event)"
        [client_id]="client_id">
    </app-form-token-group>

</div>

<div class="panel" id="insert-code" *ngIf="userHasToken &&
    expiredPassword && !userMFAforChangePasswordCompleted">
    <app-form-token-group-expired-password title="{{'LETS_CHECK_YOUR_CODE' |
        translate}}?"
        [auth_data]="auth_data"
        (tokenSuccess)="authResetPassword($event)"
        [userRealEmail]="form2.get('email')?.value">
    </app-form-token-group-expired-password>

</div>
<div class="panel" *ngIf="userHasToken &&
    userMFAforChangePasswordCompleted" id="insert-login">
    <div class="set-new-password" id="form-container">
        <form [formGroup]="form2"
            (ngSubmit)="receivePassword()">
            <h2>{{"DEFINE_YOUR_NEW_PASSWORD" | translate}}</h2>
            <p>{{"NEW_RULES_RESET_PASSWORD" | translate}}</p>
            <app-form-control label="{{'EMAIL' | translate}}" id="email"
                name="email" type="email"
                [disabled]="userValid" suffix="{{userValid ? ('CHANGE' |
                translate) : ''}}"
                (changeButton)="userValid= false">
            </app-form-control>
            <app-form-password [first_name]="first_name" [last_name]="last_name"
                [userEmail]="email" formControlName="newPassword"
                [minLength]="minLength">
            </app-form-password>
            <button data-testid="submit-button-form-set-password"
                [disabled]="!form2.valid" type="submit" class="button"
                color="primary" mat-raised-button>{{"CONFIRM_YOUR_PASSWORD" |
                translate}}<mat-icon>arrow_forward</mat-icon></button>
        </form>
    </div>
</div>
