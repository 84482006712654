<mat-card>


    <mat-icon matSuffix svgIcon="FormValidation2F"></mat-icon>
    <mat-card-title>

        <h2>{{title}} </h2>
    </mat-card-title>
    <mat-card-subtitle>

        <p>{{subtitle}}</p>
    </mat-card-subtitle>

    <mat-card-content style="margin-top: 32px;">

    </mat-card-content>

    <mat-card-actions>

        <button style="width: 100%; height: 45px;" class="continue-button" mat-raised-button color="primary">{{'ENTER' |
            translate}}

            <mat-icon>arrow_forward</mat-icon>

        </button>



    </mat-card-actions>

    <mat-card-footer style="margin: unset">
        <div class="card-footer">
            <div class="card-container">
                <div class="icons">
                    <span class="icon-Employees"></span>
                    <span class="go-to-assistant-management">


                        <mat-icon color="primary">arrow_forward_ios</mat-icon>

                    </span>

                </div>
                <strong>{{'ASSISTANT_MANAGEMENT' | translate}}</strong>
                <p>{{'ADM_WILL_BE_ABLE_TO_INVITE_PEOPLE' | translate}}.</p>
            </div>

        </div>

    </mat-card-footer>
</mat-card>