import { v4 as uuidv4 } from 'uuid';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { finalize, share, switchMap, tap, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { doOnSubscribe } from './operators';

export class ApiObservable<T> extends Observable<T> {
  private _id: string;
  private _isLoading$: BehaviorSubject<boolean>;
  private _unsubscribeIsLoading$ = new Subject<void>();
  private _internalLoading$: BehaviorSubject<boolean>;
  private _onSubscribe: (i: string, isLoading: BehaviorSubject<boolean>, obs: ApiObservable<T>) => void;
  private _onLoadingSubscribe: (i: string) => void;
  private _onFinalize: (i: string, obs: ApiObservable<T>) => void;

  constructor(
    obs$: Observable<T>,
    id: string,
    onSubscribe: (i: string, isLoading: BehaviorSubject<boolean>, obs: ApiObservable<T>) => void,
    onLoadingSubscribe: (i: string) => void,
    onFinalize: (i: string, obs: ApiObservable<T>) => void) {
    super();
    Object.assign(this, this._updateObservable(obs$));

    this._id = id;
    this._onSubscribe = onSubscribe;
    this._onLoadingSubscribe = onLoadingSubscribe;
    this._onFinalize = onFinalize;
    this._internalLoading$ = new BehaviorSubject<boolean>(false);
    this._isLoading$ = new BehaviorSubject<boolean>(false);
  }

  static fromObservable<O>(
    observable$: Observable<O>,
    id: string = uuidv4(),
    onSubscribe: (i: string, isLoading: BehaviorSubject<boolean>, obs: ApiObservable<O>) => void = () => { },
    onLoadingSubscribe: (i: string) => void = () => { },
    onFinalize: (i: string, obs: ApiObservable<O>) => void = () => { }
  ): ApiObservable<O> {
    return new ApiObservable(observable$, id, onSubscribe, onLoadingSubscribe, onFinalize);
  }

  get id(): string {
    return this._id;
  }

  get isLoading$(): Observable<boolean> {
    return this._isLoading$.pipe(
      doOnSubscribe(() => this._onLoadingSubscribe(this._id)),
      distinctUntilChanged(),
      share(),
      takeUntil(this._unsubscribeIsLoading$)
    );
  }

  private _updateObservable(obs$: Observable<T>): Observable<T> {
    return of(null).pipe(
      tap(_ => {
        this._internalLoading$.next(true);
        this._isLoading$.next(true);
      }),
      switchMap(_ => obs$),
      doOnSubscribe(() => this._onSubscribe(this._id, this._internalLoading$, this)),
      finalize(() => {
        this._internalLoading$.next(false);
        this._isLoading$.next(false);

        this._unsubscribeIsLoading$.next();
        this._unsubscribeIsLoading$.complete();
        this._unsubscribeIsLoading$ = new Subject<void>();

        this._onFinalize(this._id, this);
      }),
      share()
    );
  }
}
