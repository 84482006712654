import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  // BehaviorSubject to track the loading state
  private loadingSubject = new BehaviorSubject<boolean>(false);

  // Observable to expose the loading state
  isLoading$ = this.loadingSubject.asObservable();

  // Array of RegEx patterns for URLs that should display loading
  private loadingUrlPatterns: RegExp[] = [
    /\/v1\/authenticate$/,
    /\/v1\/authenticate\/logout$/,
    /\/v1\/users\/invite/,
    /\/v1\/users\/delete\/.*/,
    /\/v1\/invites\/delete\/.*/,
    /\/v1\/invites\/resend\/batch/,
    /\/v1\/institution\b/,
    /\/v1\/profile\b/,
    /\/v1\/users\/update\/\d+/,
    /\/v1\/users\/details/,
    /\/v1\/public.*/,
    /\/v1\/profiles\?userType=.*/,
    /\/v1\/users\?userType=.*/,
    /\/v1\/users\?key=.*/,
    /\/v1\/logs-user\?userName=.*/,
  ];

  // Loading For every url
  private shouldACtiveLoadingEveryUrl: boolean = false;

  // Set of URLs that are currently being loaded
  private activeLoadingUrls = new Set<string>();

  constructor() {}

  // Show the loading indicator
  show(url: string) {
    // Add the URL to the set of active URLs
    this.activeLoadingUrls.add(url);
    // Update the loading state
    this.updateLoadingState();
  }

  // Hide the loading indicator
  hide(url: string) {
    // Remove the URL from the set of active URLs
    this.activeLoadingUrls.delete(url);
    // Update the loading state
    this.updateLoadingState();
  }

  // Private method to update the loading state
  private updateLoadingState() {
    // Set the loading state to true if there are any active URLs
    this.loadingSubject.next(this.activeLoadingUrls.size > 0);
  }

  // Get the loading state as an Observable
  isLoading(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  // Add a URL pattern to the set of URLs that should display loading
  addLoadingUrlPattern(pattern: RegExp) {
    this.loadingUrlPatterns.push(pattern);
  }

  // Remove a URL pattern from the set of URLs that should display loading
  removeLoadingUrlPattern(pattern: RegExp) {
    this.loadingUrlPatterns = this.loadingUrlPatterns.filter(
      (p) => p.toString() !== pattern.toString()
    );
  }

  // Add loading for every url
  activeLoadingForEveryUrl(active: boolean = true): void {
    this.shouldACtiveLoadingEveryUrl = active;
  }

  shouldActiveInEveryUrl(): boolean {
    return this.shouldACtiveLoadingEveryUrl;
  }

  // Check if the URL should display loading
  shouldLoading(url: string): boolean {
    // Check if the URL matches any of the patterns

    // only when valid token on login because there an own animation
    if (url === '/v1/public/mfa/validate') {
      return false;
    }
    return this.loadingUrlPatterns.some((pattern) => pattern.test(url));
  }

  // Get the URLs that are currently being loaded
  getActiveLoadingUrls(): string[] {
    return Array.from(this.activeLoadingUrls);
  }

  // Remove all active URLs and reset the loading state
  clearAllLoading() {
    this.activeLoadingUrls.clear();
    this.updateLoadingState();
  }
}
