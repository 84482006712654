import { Component, Input, OnInit } from '@angular/core';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';

@Component({
  selector: 'app-card-inactive-registration',
  templateUrl: './card-inactive-registration.component.html',
  styleUrls: ['./card-inactive-registration.component.scss']
})
export class CardInactiveRegistrationComponent implements OnInit {

  @Input() content?: string;
  @Input() footer?: string;


  constructor(private _globalizationService: GlobalizationService) { }

  ngOnInit(): void {
    this.content = this._globalizationService.translate('YOUR_REGISTRATION_INACTIVE');
    this.footer = this._globalizationService.translate('CONTACT_PERSON_RESPONSIBLE');
  }

}
