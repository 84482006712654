import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { GlobalizationService } from '../../globalization/services/globalization.service';
import { ToastrService } from 'ngx-toastr';
import { ApiObservable } from '../models/api-observable';
import { AuthApiService } from 'src/app/api/services/auth-api.service';
import { Authenticate } from 'src/app/api/models/authentication-resp';
import { InviteApiService } from 'src/app/api/services/invite.service';
import loadingJson from '@assets/lottie/dotsloading.json';
import { RenewalApiService } from 'src/app/api/services/renewal.service';
import { HttpResponse } from '@angular/common/http';

export declare type FormControlType = 'text';

@Component({
  selector: 'app-form-token-group',
  templateUrl: './form-token-group.component.html',
  styleUrls: ['./form-token-group.component.scss']
})
export class FormTokenGroupComponent implements OnInit, OnDestroy {

  @Input() auth_data!: Authenticate;
  @Input() title: string = this.globalizationService.translate('FIRST_ACCESS_TOKEN_TITTLE');
  @Input() weSentResent: string = this.globalizationService.translate('WE_SENT');
  @Input() subTitle: string = this.globalizationService.translate('FIRST_ACCESS_TOKEN_SUB_TITTLE');
  @Output() tokenSuccess: EventEmitter<any> = new EventEmitter();
  @Input() recovery?: number;
  @Input() client_id?: string;

  // Define variables required
  amount = 6;
  loadingPathJson = loadingJson;
  isTemporaryBlock = false;
  invalidToken = false;
  exceededAttempts = false;
  blockedUser = false;
  token_expired = false;
  attempts = true;
  userEmail?: string | null;
  blockedUserTime = '';
  timeToExpires?: string;
  private subscription?: Subscription;

  // Form Controls Initialization
  form = this._fb.group({
    0: [undefined, [Validators.required, Validators.minLength(1)]],
    1: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    2: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    3: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    4: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]],
    5: [{ value: undefined, disabled: true }, [Validators.required, Validators.maxLength(1)]]
  });

  obs$: ApiObservable<HttpResponse<{
    accessToken: string; refreshToken: string; scope: string;
    idToken: string; tokenType: string; expiresIn: string;
    redirectUri?: string; authorizationCode?: string;
  }>> | null = null;

  constructor(
    private _fb: UntypedFormBuilder,
    private authService: AuthApiService,
    private toastr: ToastrService,
    private renewalApiService: RenewalApiService,
    private globalizationService: GlobalizationService,
    private _inviteService: InviteApiService
  ) { }

  ngOnInit(): void {
    this.userEmail = this.auth_data.email;
    const { expiresMilliseconds } = this.auth_data;
    this.startTimeCountToEndUsingMilliseconds(expiresMilliseconds);
  }

  // Function to start countdown for token expiration using milliseconds
  startTimeCountToEndUsingMilliseconds(totalTimeInMilliseconds: number) {
    const initialDate = new Date();
    const initialCount = totalTimeInMilliseconds;

    this.subscription = interval(1000).subscribe(milliseconds => {
      let timePassed;
      const currentDate = new Date();

      if ((currentDate.getTime() > initialDate.getTime()) && (initialDate.getDate() === currentDate.getDate())) {
        timePassed = Date.now() - initialDate.getTime();
      } else {
        timePassed = milliseconds * 1000;
      }

      const timeToEnd = initialCount - timePassed;
      const minutes = Math.floor(timeToEnd / 60000);
      const seconds = Math.round((timeToEnd % 60000) / 1000);

      if (timeToEnd <= 0) {
        if(this.isTemporaryBlock) {
          this.blockedUser = false;
        } else {
          this.tokenExpired();
        }
      }
      this.timeToExpires = seconds === 60
        ? `${minutes + 1}:00`
        : `${minutes}:${this.padTo2Digits(seconds)}`;
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  // Function to pad number to 2 digits
  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  // Function to handle token expiration
  tokenExpired() {
    this.toastr.clear();
    this.token_expired = true;
    this.form.disable();
    this.subscription?.unsubscribe();
    this.toastr.error(this.globalizationService.translate('CODE_EXPIRED'), '',
      { positionClass: 'toast-top-center', disableTimeOut: true, toastClass: 'ngx-toastr form-token-group-toast-error' });
  }

  // Function to submit token
  submitToken(): void {
    this.disableDefaultForm();

    let token = Object.values(this.form.value).toString().replace(/\s|,/g, '');

    if (this.recovery === 1) {
      this._inviteService.validationMfaUser({ mfaId: this.auth_data.mfaId, tokenValue: token }).pipe(take(1)).subscribe({
        next: data => {
          this.subscription?.unsubscribe();
          this.tokenSuccess.emit(data);
        },
        error: err => this.handleTokenError(err)
      });
    } else if (this.recovery === 2) {
      this.renewalApiService.renewalValidTokenPassword({ mfaId: this.auth_data.mfaId, tokenValue: token })
        .pipe(take(1)).subscribe({
          next: data => {
            this.subscription?.unsubscribe();
            this.tokenSuccess.emit(data);
          },
          error: err => this.handleTokenError(err)
        });
    } else {
      this.obs$ = this.authService.validToken(`${this.auth_data.mfaId}`, token, this.client_id);
      this.obs$.pipe(take(1)).subscribe({
        next: data => {
          this.subscription?.unsubscribe();
          this.tokenSuccess.emit(data.body);
        },
        error: err => this.handleTokenError(err)
      });
    }
  }

  // Function to handle token error
  private handleTokenError(err: any) {
    let msg = '';
    let code = 0;
    if (err.error?.errors) {
      msg = err.error?.errors[0]?.description;
      code = err.error?.errors[0]?.code;
    }
    this.tokenInvalid({ msg, code });
    if ([603, 604, 605].includes(code)) {
      this.toastr.error(msg, '', { positionClass: 'toast-top-center', disableTimeOut: true, toastClass: 'ngx-toastr form-token-group-toast-error' });
    } else {
      throw err;
    }
  }

  // Function to handle form unblock
  unblock() {
    if (this.exceededAttempts) {
      return;
    }

    for (let i = this.amount - 1; i >= 0; i--) {
      const formControlName = this.form.controls[i];
      formControlName.disable();
      document.getElementById(`inputtoken-${i}`)?.setAttribute('disabled', '');
    }
    
    this.form.controls[0].enable();
    document.getElementById('inputtoken-0')?.removeAttribute('disabled');
    document.getElementById('inputtoken-0')?.focus();
    this.form.reset();
    this.invalidToken = false;
  }

  // Function to resend token
  reSendToken(): void {
    this.authService.resendToken(this.auth_data.mfaId).pipe(take(1)).subscribe({
      next: data => {
        this.weSentResent = this.globalizationService.translate('WE_RESENT');
        this.subscription?.unsubscribe();

        if (data?.blockedTimeMilliseconds > 1000) {
          this.handleTemporaryBlocked(data.blockedTimeMilliseconds)
          return;
        }

        this.blockedUser = false;
        this.exceededAttempts = false;
        this.invalidToken = false;
        this.token_expired = false;
        this.isTemporaryBlock = false;
        this.toastr.clear();
        this.toastr.success(this.globalizationService.translate('CODE_SENT'), '',
          { positionClass: 'toast-top-center', toastClass: 'ngx-toastr form-token-group-toast resend' });

        this.form.reset();
        this.unblock();
        this.startTimeCountToEndUsingMilliseconds(data.expiresMilliseconds);
      },
      error: err => this.handleTokenError(err)
    });
  }

  // Function to block temporary
  private handleTemporaryBlocked(blockedTimeMilliseconds: number): void {
    this.isTemporaryBlock = true;
    this.blockedUser = true;
    this.startTimeCountToEndUsingMilliseconds(blockedTimeMilliseconds);

    this.toastr.clear();
    this.disableDefaultForm();

    this.toastr.error(this.globalizationService.translate('REGISTRATION_BLOCKED'), '',
      { positionClass: 'toast-top-center', toastClass: 'ngx-toastr form-token-group-toast-error-user-blocked', disableTimeOut: true });
  }

  // Function to disable default form
  private disableDefaultForm(withReset?: boolean) {
    for (let i = 0; i < this.amount; i++) {
      if (withReset) {
        this.form.controls[i].reset();
      }
      this.form.controls[i].disable();
      document.getElementById(`inputtoken-${i}`)?.setAttribute('disabled', '');
    }
  }

  // Function to handle invalid token error
  private tokenInvalid({ msg, code }: { msg: string; code: number }): void {
    switch(code) {
      case 602:
        this.invalidToken = true;
        this.form.controls[0].setErrors({ attemptsToken: msg });
        break;
      case 603:
        this.token_expired = true;
        this.disableDefaultForm();
        break;
      case 604:
      case 605:
        this.disableDefaultForm();
        this.exceededAttempts = true;
        break;
      case 500:
        this.disableDefaultForm();
        break;
      default:
        this.invalidToken = true;
        this.disableDefaultForm(true);
    }
  }
}
