<div class="content-modal-warning">
    <div class="icon-container">
        <mat-icon style="width: 64px; height: 64px;"
            svgIcon="warning2">
        </mat-icon>

    </div>
    <div class="header-modal">
        <h2 mat-dialog-title>{{data.title}}</h2>
    </div>
    <div mat-dialog-content>
        <p *ngIf="!data.showErrorFromImportation else elseBlock">{{data.description}}</p>
        <ng-template #elseBlock>
            <p style="max-width: 333px;">Foram encontrados um ou mais erros no
                arquivo. Acesse o
                <span (click)="downloadFileText()"
                    style="font-weight: bold;text-decoration: underline; color: #FF6600;cursor: pointer;">
                    relatório de erros</span>,
                revise as informações e tente novamente. </p>
        </ng-template>
    </div>
    <div class="controls-modal">
        <button color="primary" mat-raised-button (click)="dialogRef.close()">Entendi</button>

    </div>
</div>