import { Component, OnDestroy, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FinishProcessWithRedirectResp } from 'src/app/api/models/finish-process-with-redirect-resp';
import { UserApiService } from 'src/app/api/services/user-api.service';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';


@Component({
  selector: 'app-user-password',
  templateUrl: './user-password.component.html',
  styleUrls: ['./user-password.component.scss']
})
export class UserPasswordComponent implements OnInit, OnDestroy, AfterContentChecked {

  currentStep = 2;

  isLinear = true;
  isEditable = false;

  partner: Partner | undefined;

   first_name = '';
  last_name = '';
  name = '';
  email = '';

  state: {
    document?: string;
    mfaId?: number;
    password?: string;
    name?: string;
    optinId?: number;
    optinText?: string;
    redirect_uri?: string;
    client_id?: string;
    toLogin?: boolean;
    user_name?: string;
    socialName?: string;
  } = {};

  private subscription: Subscription = new Subscription();

  constructor(
    private _appPartner: AppPartner,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _userApi: UserApiService,
    private _ref: ChangeDetectorRef
  ) { }

  ngAfterContentChecked() {
    this._ref.detectChanges();
  }

  ngOnInit(): void {

    this._appPartner.getPartner().subscribe(el => this.partner = el,);
    this._activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.state = { ...params };
      if (this.state.document) {
        this.subscription.add(this._userApi.getUserByDocument(this.state.document).subscribe(data => {
          const userName = data.name.trimEnd();
          this.first_name = userName.split(' ')[0];
          this.last_name = userName.split(' ').reverse()[0];
          this.email = data.email;
          this.name = userName;
        }));
      }
    });


  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  submit({ password }: { password: string }): void {

    if (!this.state) {
      return;
    }
    this.state.password = password;
    this.state.name = this.name;

    if (this.state.mfaId && this.state.password && this.state.socialName) {
      this.subscription.add(this._userApi.createUser({
        mfaId: this.state.mfaId,
        name: this.state.socialName,
        password: this.state.password
      }).subscribe((value: FinishProcessWithRedirectResp) => {
        this.state.redirect_uri = value.url;
        this.state.client_id = value.clientId;
        this.state.toLogin = value.toLogin;
        this.state.user_name = value.userName;
        this._router.navigate(['../user-done'], { relativeTo: this._activatedRoute, queryParams: this.state, skipLocationChange: true });
      }));
    }

  }

}
