import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AllActivePartner, AllPartnerItems, AllTheme, AllThemeItems, Partner, PartnerName } from './models';
import { StorageService } from 'src/app/storage/services/storage.service';
import { Channel } from 'src/app/api/models/channel';



type BehaviorPartner = Partner | undefined;

@Injectable()
export class AppPartner {

  private _appPartner = new BehaviorSubject<BehaviorPartner>(undefined);

  constructor(private _storage: StorageService) { }

  get allThemeName(): AllTheme {
    return AllThemeItems;
  }

  get allPartnerTheme(): AllActivePartner {
    return AllPartnerItems;
  }

  setPartner(selectedPartner: Partner): void {

    this._bodyRef()?.setAttribute('Theme', selectedPartner.theme);

    this._appPartner.next(selectedPartner);
  }

  setPartnerByName(name?: PartnerName | null): Promise<boolean> {

    const partner = AllPartnerItems.filter(el => name && el.theme.includes(name))[0];

    if (partner) {
      this.setPartner(partner);
    }
    return Promise.resolve(!!partner);
  }



  getPartner(): Observable<BehaviorPartner> {
    return this._appPartner.asObservable();
  }

  getPartnerValue(): BehaviorPartner {
    return this._appPartner.value;
  }


  private _bodyRef(): HTMLElement | null {
    return document.getElementById('main');
  }

  isAlliance(): boolean {
    let isAlliance = false;
    const key = this._storage.get('userChannel');
    if(key) {
      const channel: Channel = JSON.parse(key);
      isAlliance = channel?.type === 'ALLIANCE'
    }
    return isAlliance;
  }



}
