import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  description: string;
  yesText?: string;
  noText?: string;
}

@Component({
  selector: 'app-modal-confirmation-invitation',
  templateUrl: './modal-confirmation-invitation.component.html',
  styleUrls: ['./modal-confirmation-invitation.component.scss']
})
export class ModalConfirmationInvitationComponent {

  constructor(public dialogRef: MatDialogRef<ModalConfirmationInvitationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close(false);
}

onYesClick(): void {
    this.dialogRef.close(true);
}

}
