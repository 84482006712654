import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BaseUrlPartnerGuardInterceptor } from './partner-guard.interceptor';

@NgModule({
  declarations: [
    
  ],
  imports: [
    CommonModule,
    HttpClientModule,
     ],
  providers: [
     { provide: HTTP_INTERCEPTORS, useClass: BaseUrlPartnerGuardInterceptor , multi: true },
  ],
  exports: []
})
export class PartnerGuardModule { }
