export enum RoleUserEnum{
    SGDA2_INVITE_EXTERNAL_USER = 'SGDA2_INVITE_EXTERNAL_USER',
    SGDA2_CREATE_INTERNAL_USER = 'SGDA2_CREATE_INTERNAL_USER',
    SGDA2_EDIT_INTERNAL_USER ='SGDA2_EDIT_INTERNAL_USER',
    SGDA2_EDIT_EXTERNAL_USER ='SGDA2_EDIT_EXTERNAL_USER',
    SGDA2_SELF_EDIT_INTERNAL_USER = 'SGDA2_SELF_EDIT_INTERNAL_USER',
    SGDA2_SELF_EDIT_EXTERNAL_USER = 'SGDA2_SELF_EDIT_EXTERNAL_USER',
    SGDA2_SELF_EDIT_PASS_INTERNAL_USER = 'SGDA2_SELF_EDIT_PASS_INTERNAL_USER',
    SGDA2_SELF_EDIT_PASS_EXTERNAL_USER = 'SGDA2_SELF_EDIT_PASS_EXTERNAL_USER',
    SGDA2_OTHER_EDIT_PASS_EXTERNAL_USER = 'SGDA2_OTHER_EDIT_PASS_EXTERNAL_USER',
    SGDA2_FIRST_ACCESS = 'SGDA2_FIRST_ACCESS',
    SGDA2_MFA_AUTHENTICATE = 'SGDA2_MFA_AUTHENTICATE',
    SGDA2_ACCEPT_OPTIN = 'SGDA2_ACCEPT_OPTIN',
    SGDA2_VIEW_INSTITUTION_LIST = 'SGDA2_VIEW_INSTITUTION_LIST',
    SGDA2_VIEW_INSTITUTION_LATAM_LIST = 'SGDA2_VIEW_INSTITUTION_LATAM_LIST',
    SGDA2_VIEW_SERVICECONTRAC_LIST = 'SGDA2_VIEW_SERVICECONTRAC_LIST',
    SGDA2_VIEW_EXTERNAL_USER_LIST = 'SGDA2_VIEW_EXTERNAL_USER_LIST',
    SGDA2_VIEW_EXTERNAL_USER_LATAM_LIST = 'SGDA2_VIEW_EXTERNAL_USER_LATAM_LIST',
    SGDA2_VIEW_INVITE_PENDING_LIST = 'SGDA2_VIEW_INVITE_PENDING_LIST',
    SGDA2_RENEWAL_EXTERNAL_USERS = 'SGDA2_RENEWAL_EXTERNAL_USERS',
    SGDA2_EDIT_INVITE_PENDING = 'SGDA2_EDIT_INVITE_PENDING',
    SGDA2_NO = 'SGDA2_NO'    
}