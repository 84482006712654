import { Pipe, PipeTransform } from '@angular/core';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { ptBR, enUS, es } from 'date-fns/locale';
import { format  } from 'date-fns';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {

  constructor(private translateService: GlobalizationService) {}

  transform(value: string | undefined) {
    
    if(!value) {
      return '';
    }
    
    return format(new Date(value),'P',{locale: this.returnLocale()} )
  }

  returnLocale(): Locale {
    if(this.translateService.currentLanguage.value === 'pt-BR') {
      return ptBR
    } else if (this.translateService.currentLanguage.value === 'en-US') {
      return enUS
    } else {
      return es
    }
  }

}
