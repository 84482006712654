import { Routes } from '@angular/router';
import { InternalUserResolver } from './helpers/internal-user.resolver';
import { PageErrorNotFoundComponent } from 'src/app/shared/page-error-not-found/page-error-not-found.component';
import { PageErrorUnavailableComponent } from './shared/page-error-unavailable/page-error-unavailable.component';
import { PageErrorNotAuthorizedComponent } from './shared/page-error-not-authorized/page-error-not-authorized.component';
import { InternalRedirectGuardService } from './helpers/internal-redirect.guard';
import { FallBackLanguageResolver } from './helpers/fall-back-language.resolver';
import { PartnerGuardService } from './partner-guard/partner.guard';

export const routes: Routes = [
  {
    path: 'error-page-unavailable',
    component: PageErrorUnavailableComponent,
    resolve: {
      temporaryLanguage: FallBackLanguageResolver,
    },
    data: {
      disableChannelClick: true,
    },
  },
  {
    path: 'error-not-authorized',
    component: PageErrorNotAuthorizedComponent,
    resolve: {
      temporaryLanguage: FallBackLanguageResolver,
    },
    data: {
      disableChannelClick: true,
    },
  },
  {
    path: 'actuator',
    loadChildren: () =>
      import('./site/actuator/actuator.routes').then((r) => r.ACTUATOR_ROUTES),
  },
  {
    path: 'login',
    canActivate: [InternalRedirectGuardService],
    component: PageErrorNotFoundComponent,
  },
  {
    path: '',
    canActivate: [InternalRedirectGuardService],
    component: PageErrorNotFoundComponent,
  },
  {
    path: 'channels',
    canActivate: [InternalRedirectGuardService],
    component: PageErrorNotFoundComponent,
  },
  {
    path: 'fiserv',
    loadChildren: () =>
      import('./site/fiserv/fiserv.routes').then((r) => r.FISERV_ROUTES),
    resolve: {
      InternalUserResolver,
    },
    data: {
      partner: 'fiserv',
      onlyPtBr: true,
    },
  },
  {
    path: ':partner',
    loadChildren: () =>
      import('./site/partner/partner.routes').then((r) => r.PARTNER_ROUTES),
    canActivate: [PartnerGuardService],
  },
  {
    path: '**',
    component: PageErrorNotFoundComponent,
    resolve: {
      temporaryLanguage: FallBackLanguageResolver,
    },
  },
];
