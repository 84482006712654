<div class="panel" id="user-password">
    <mat-stepper class="default-model"
        ariaLabel="default-model-index-{{stepper.selectedIndex}}"
        [linear]="isLinear"
        [selectedIndex]="currentStep"
        color="primary" #stepper>

        <mat-step [editable]="isEditable"></mat-step>
        <mat-step [editable]="isEditable"></mat-step>
        <mat-step [editable]="isEditable">
            <div>
             
                    <app-form-change-password id="password"
                        [first_name]="first_name" [last_name]="last_name"
                        [email]="email" [first_access]="true"
                        buttonText="{{'CONTINUE' | translate}}"
                        title="{{'SET_PASSWORD' | translate}}"
                        subtitle="{{'NEW_RULES_RESET_PASSWORD' | translate}}"
                        (sendPassword)="submit($event)">
                    </app-form-change-password>
                
            </div>
        </mat-step>

        <mat-step [editable]="isEditable"></mat-step>
    </mat-stepper>
</div>
