<div class="panel" id="external-user-confirm-invitation">



  <div *ngIf="hasError" [@TopToDown]
    class="alert-message">
    <mat-icon svgIcon="cancel-circle"></mat-icon>
    <p>
      {{"INVITE_SEND_SUCCESS" | translate}}
    </p>
  </div>


  <mat-stepper class="default-model"
    ariaLabel="default-model-index-{{stepper.selectedIndex}}"
    [linear]="isLinear"
    #stepper>
    <mat-step [editable]="isEditable">
      <div *ngIf="!hasError; else errorBlock" class="primary"
        id="welcome-container">
        <h2>{{"CONFIRM_YOUR_INVITE" | translate}}</h2>
        <p>
          {{"IF_YOU_RECEIVE_YOUR_CODE" | translate}}
        </p>
      </div>

      <ng-template #errorBlock>

        <div class="errorBlock">
          <p>
            {{errorMessageBody}}

          </p>
        </div>

      </ng-template>


      <div tabindex="0" (keyup.enter)="validToken &&
        unblock()" (click)="validToken &&
        unblock()" id="form-container">
        <form [formGroup]="form">
          <app-form-token [amount]="amount" type="text"
            (childSubmit)="submitToken()">
          </app-form-token>

        </form>
      </div>

      <ng-template [ngIf]="obs$?.isLoading$ | async">
        <div class="animation-conteiner">
          <div class="animation">
            <lottie-player autoplay="true"
              src="https://assets6.lottiefiles.com/packages/lf20_h0ylx3xz.json"
              loop=""
              speed="1" background="transparent" style="height:
              100%;
              background: transparent;">

              <div>
                <div>

                  <svg xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0
                    45 20" width="45" height="20"
                    preserveAspectRatio="xMidYMid meet"
                    style="width: 100%; height: 100%;
                    transform:
                    translate3d(0px, 0px, 0px);">
                    <defs>
                      <clipPath id="__lottie_element_2">
                        <rect width="45" height="20"
                          x="0"
                          y="0"></rect>
                      </clipPath>
                    </defs>
                    <g clip-path="url(#__lottie_element_2)">
                      <g
                        transform="matrix(0.6000000238418579,0,0,0.6000000238418579,29.287200927734375,7.269000053405762)"
                        opacity="0.5" style="display:
                        block;">
                        <image width="10px"
                          height="10px"
                          preserveAspectRatio="xMidYMid
                          slice"
                          xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAOUlEQVQoU2P8n84wgeE/gwEDPvCfoYDxfxrDAQYGBnu8CpkYHEcV4g4hcPCkMhgwMDMI4A3HHwwXAIl/HUbY+z9pAAAAAElFTkSuQmCC">
                        </image>
                      </g>
                      <g
                        transform="matrix(0.6000000238418579,0,0,0.6000000238418579,19.5,7)"
                        opacity="0.5" style="display:
                        block;">
                        <image width="10px"
                          height="10px"
                          preserveAspectRatio="xMidYMid
                          slice"
                          xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAOUlEQVQoU2P8n84wgeE/gwEDPvCfoYDxfxrDAQYGBnu8CpkYHEcV4g4hcPCkMhgwMDMI4A3HHwwXAIl/HUbY+z9pAAAAAElFTkSuQmCC">
                        </image>
                      </g>
                      <g
                        transform="matrix(0.9327830672264099,0,0,0.9327830672264099,6.230002403259277,5.305084705352783)"
                        opacity="0.9159788461538623"
                        style="display: block;">
                        <image width="10px"
                          height="10px"
                          preserveAspectRatio="xMidYMid
                          slice"
                          xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAOUlEQVQoU2P8n84wgeE/gwEDPvCfoYDxfxrDAQYGBnu8CpkYHEcV4g4hcPCkMhgwMDMI4A3HHwwXAIl/HUbY+z9pAAAAAElFTkSuQmCC">
                        </image>
                      </g>
                    </g>
                  </svg>
                </div>

              </div>
              <div [appIsLoading]="obs$?.isLoading$ | async"></div>
            </lottie-player>

          </div>
          <p>{{'VERIFYING_TOKEN' | translate}}</p>
        </div>



      </ng-template>

    </mat-step>

    <mat-step [editable]="isEditable">


    </mat-step>

    <mat-step [editable]="isEditable">


    </mat-step>
    <mat-step [editable]="isEditable">


    </mat-step>

  </mat-stepper>




</div>
