import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthApiService } from '../services/auth-api.service';

import { environment } from 'src/environments/environment';
import { AppPartner } from 'src/partner/partner.service';
import { PartnerEnum } from 'src/partner/partner.enum';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthApiService, private partner: AppPartner) { }


    private get serviceContract(): string | undefined {
        return this.partner.getPartnerValue()?.serviceContract;
    }

    private get institutionCod(): string | undefined {
        return this.partner.getPartnerValue()?.institutionCod;
    }
    
    private get fiservChannelClientId(): string | undefined {
        return this.partner.getPartnerValue()?.theme === PartnerEnum.Fiserv ? environment.channelClientIdInternal : undefined
    } 

    private get allianceLatamClientId(): string | undefined {
        return this.partner.getPartnerValue()?.serviceContract === '497' ? environment.channelClientIdLatamAlliance : undefined
    }

    private get externalChannelClientId(): string | undefined {
        return this.partner.getPartnerValue()?.channelClientId
    }

    private get channelClientId(): string {
         return this.fiservChannelClientId || this.allianceLatamClientId || this.externalChannelClientId || environment.channelClientIdExternal
    }

    private get apiKey(): string | undefined {
        return environment.api.apiKey;
    }

    headerGenerate(request: HttpRequest<any>): {
        Authorization?: string;
        ServiceContract?: string;
        InstitutionCod?: string;
        ChannelClientId: string;
        'api-key'?: string;
    } {
        const { accessToken } = this.authenticationService.token;


        const pathThatCantIncludeAuthorizationInHeaders = [
            '/public/',
            '/actuator/info',
            '/v1/authenticate?redirect_uri='
        ];

        const pathThatCantEndsWithAuthorizationInHeaders = [
            '/v1/authenticate'
        ];

        const headers: {
            Authorization?: string; ServiceContract?: string;
            InstitutionCod?: string; ChannelClientId: string;
            'api-key'?: string;
        } = {
            InstitutionCod: this.institutionCod,
            ServiceContract: this.serviceContract,
            ChannelClientId: this.channelClientId,
            'api-key': this.apiKey

        };

        if (accessToken !== null && accessToken !== undefined && !this.isRefreshToken(request.url) &&
            !pathThatCantEndsWithAuthorizationInHeaders.some(el => request.url.endsWith(el)) &&
            !pathThatCantIncludeAuthorizationInHeaders.some(el => request.url.includes(el))) {
            headers.Authorization = `Bearer ${accessToken}`;
        }

        return headers;

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const newHeader = this.headerGenerate(request);

        request = request.clone({ setHeaders: Object.fromEntries(Object.entries(newHeader).filter(([_, v]) => v != null)) });

        return next.handle(request);

    }

    private isRefreshToken(url: string): boolean {
        return url.includes('refresh-token');
    }


}
