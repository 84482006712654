<form [formGroup]="form" (ngSubmit)="submitToken()" >

  <div *ngIf="!blockedUser">


    <ng-template [ngIf]="!token_expired && !exceededAttempts">

      <!-- <h2 class="title">{{'FIRST_ACCESS_TOKEN_TITTLE' | translate}}. </h2> -->
      <h2 class="title">{{title}} </h2>
      <div class="sub-title">
        <p>{{weSentResent + subTitle}}</p>
      </div>
      <!-- <div class="sub-title">
                <p>Enviamos um código no E-mail abaixo.</p>
                <p> Digite-o no campo a seguir para validar seu
                    acesso.</p>
            </div> -->
      <!-- <p class="sub-title">{{'FIRST_ACCESS_TOKEN_SUB_TITTLE' | translate}}.</p> -->
      <p class="sub-title-user-email"><strong>{{userEmail}}</strong> </p>
    </ng-template>

    <ng-template [ngIf]="token_expired && !exceededAttempts">

      <!-- <div class="message-warn">
        <p>O prazo de validade de seu código expirou!</p>
        <p>Clique em reenviar novo código e tente novamente.</p>
         <div>O prazo de validade de seu código expirou!</div>
        <div>Clique em reenviar novo código e tente novamente.</div> 
      </div> -->
      <p class="message-warn">{{'TOKEN_EXPIRED_AND_CLICK_HERE' |
        translate}}.</p>
    </ng-template>


    <ng-template [ngIf]="exceededAttempts">
      <p class="message-warn-exceeded-attempts-re-sended">{{'EXCEEDED_ATTEMPTS_RE_SEND'
        | translate}}.
      </p>
    </ng-template>

  </div>
  <div *ngIf="blockedUser">
    <div class="message-warn-blocked-user">
      <p>{{'BLOCKED_BY' | translate}}
        <strong> {{blockedUserTime}} </strong> {{'MINUTES' | translate}}.
      </p>
      <p>
        {{'WAIT_PERIOD_AND_RESTART_REGISTRATION' | translate}}.
      </p>
    </div>

  </div>
  <div id="box-timer-with-input">
    <p *ngIf="!token_expired && !exceededAttempts && !blockedUser">
      {{'TOKEN_EXPIRES_IN' |
      translate}}: <span id="minutes"> <b>{{hoursTotal ? hoursTotal + ':'
          : ''}}{{minutesToDday ?
          minutesToDday <10 ? '0' +minutesToDday+':' :
            minutesToDday+':' : hoursTotal ? '00:' : ''
            }}{{secondsToDday <10 ? '0' : '' }}{{secondsToDday}}</b></span></p>
    <div id="box-timer-with-input-token" (click)="invalidToken &&
      unblock()">
      <app-form-token [amount]="amount" type="text"
        (childSubmit)="submitToken()">
      </app-form-token>
    </div>


  </div>




  <ng-template [ngIf]="obs$?.isLoading$ | async">
    <div class="animation-conteiner">
      <div class="animation">
        <lottie-player autoplay="true"
          src="https://assets6.lottiefiles.com/packages/lf20_h0ylx3xz.json"
          loop=""
          speed="1" background="transparent" style="height: 100%;
          background: transparent;">

          <div>
            <div>

              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0
                45 20" width="45" height="20"
                preserveAspectRatio="xMidYMid meet"
                style="width: 100%; height: 100%; transform:
                translate3d(0px, 0px, 0px);">
                <defs>
                  <clipPath id="__lottie_element_2">
                    <rect width="45" height="20" x="0"
                      y="0"></rect>
                  </clipPath>
                </defs>
                <g clip-path="url(#__lottie_element_2)">
                  <g
                    transform="matrix(0.6000000238418579,0,0,0.6000000238418579,29.287200927734375,7.269000053405762)"
                    opacity="0.5" style="display: block;">
                    <image width="10px" height="10px"
                      preserveAspectRatio="xMidYMid slice"
                      xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAOUlEQVQoU2P8n84wgeE/gwEDPvCfoYDxfxrDAQYGBnu8CpkYHEcV4g4hcPCkMhgwMDMI4A3HHwwXAIl/HUbY+z9pAAAAAElFTkSuQmCC">
                    </image>
                  </g>
                  <g
                    transform="matrix(0.6000000238418579,0,0,0.6000000238418579,19.5,7)"
                    opacity="0.5" style="display: block;">
                    <image width="10px" height="10px"
                      preserveAspectRatio="xMidYMid slice"
                      xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAOUlEQVQoU2P8n84wgeE/gwEDPvCfoYDxfxrDAQYGBnu8CpkYHEcV4g4hcPCkMhgwMDMI4A3HHwwXAIl/HUbY+z9pAAAAAElFTkSuQmCC">
                    </image>
                  </g>
                  <g
                    transform="matrix(0.9327830672264099,0,0,0.9327830672264099,6.230002403259277,5.305084705352783)"
                    opacity="0.9159788461538623"
                    style="display: block;">
                    <image width="10px" height="10px"
                      preserveAspectRatio="xMidYMid slice"
                      xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAOUlEQVQoU2P8n84wgeE/gwEDPvCfoYDxfxrDAQYGBnu8CpkYHEcV4g4hcPCkMhgwMDMI4A3HHwwXAIl/HUbY+z9pAAAAAElFTkSuQmCC">
                    </image>
                  </g>
                </g>
              </svg>
            </div>

          </div>
          <div [appIsLoading]="obs$?.isLoading$ | async"></div>
        </lottie-player>

      </div>
      <p>{{'VERIFYING_TOKEN' | translate}}</p>
    </div>



  </ng-template>


  <button type="button" id="button-re-send-token" mat-button
    (click)="reSendToken()"
    *ngIf="(obs$?.isLoading$ | async) !== true" [disabled]="blockedUser">{{'RESEND_TOKEN'
    |
    translate}}</button>



</form>