import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ApiModule } from '../../api.module';
import { ApiStatusService } from './api.status.service';
import { ApiObservable } from 'src/app/shared/models/api-observable';
import { ApiResp } from '../../models/api-resp';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: ApiModule
})
export class ApiService {

  constructor(
    private _http: HttpClient,
    private _apiStatusService: ApiStatusService
  ) { }

  get<P>(url: string, params?: HttpParams): ApiObservable<P> {


    const obs$ = this._http.get<P>(url, {
      headers: {
        'Content-Type': 'application/json'
      }, params
    }, );

    return this._apiStatusService.addObservable(obs$);
  }

  getWithProgress<P>(url: string, params?: HttpParams): ApiObservable<any> {


    const obs$ = this._http.get<P>(url, {
      observe: 'events', 
      reportProgress: true,
      headers: {
        'Content-Type': 'application/json'
      }, params
    }, );

    return this._apiStatusService.addObservableWithoutLoading(obs$);
  }

 
  downloadFile(url: string, filename: string = ''): void {
    this._http.get(url, { responseType: 'blob' as 'json' }).subscribe(
      (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        if (filename)
          downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    );
  }


  postFormUrlEncoded<P>(url: string, body: HttpParams): ApiObservable<P> {
    const obs$ = this._http.post<P>(url, body.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return this._apiStatusService.addObservable(obs$);
  }

  post<Q, P>(url: string, body: Q, refreshToken?: string): ApiObservable<P> {
    const obs$ = this._http.post<P>(url, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    return this._apiStatusService.addObservable(obs$);
  }

  postSendInvite<Q, P>(url: string, body: Q): ApiObservable<P> {
    const obs$ = this._http.post<P>(url, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return this._apiStatusService.addObservable(obs$);
  }

  postWithFullResponse<Q, P>(url: string, body: Q, refreshToken?: string): ApiObservable<HttpResponse<P>> {
    const obs$ = this._http.post<P>(url, body, {
      headers: {
        'Content-Type': 'application/json',
      },
      observe: 'response',
      responseType: 'json'
    });

    return this._apiStatusService.addObservable(obs$);
  }

  put<Q, P>(url: string, body: Q, refreshToken?: string): ApiObservable<P> {
    const obs$ = this._http.put<P>(url, body, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    return this._apiStatusService.addObservable(obs$);
  }

  postCustomHeader<Q, P>(url: string, body: Q, header?: HttpHeaders): ApiObservable<ApiResp<P>> {
    const obs$ = this._http.post<ApiResp<P>>(url, body, { headers: header });
    return this._apiStatusService.addObservable(obs$);
  }



}
