import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {


  private _prefix: string = '_fiserv_';

  constructor( private _activatedRoute: ActivatedRoute, private router: Router) { 
    this.router.events.
    pipe(
      filter(event => event instanceof ActivationEnd),
      take(1)
    )
    .subscribe((e: any) => {
      const partnerName = e.snapshot.params.partner 
      if(partnerName) {
        this._prefix = '_' + e.snapshot.params.partner + '_';
      }
    })
  }

  save(key: string, value: string): void {
    localStorage.setItem( this._prefix + key, value);
  }
  saveUsingPrefix(key: string, value: string, _prefix: string): void {
    localStorage.setItem( _prefix + key, value);
  }

  saveObject<T>(key: string, value: T): void {
    if (value == null)
      localStorage.setItem(this._prefix + key, '');
    else
      localStorage.setItem(this._prefix + key, JSON.stringify(value));
  }

  push(key: string, value: any): void {

    const obj: Array<string> = JSON.parse(localStorage.getItem(this._prefix + key) || '[]');

    obj.push(value);

    localStorage.setItem(this._prefix + key, JSON.stringify(obj));
  }

  get = (key: string): string | null => localStorage.getItem(this._prefix + key);

  getUsingPrefix = (key: string, _prefix: string): string | null => localStorage.getItem(_prefix + key);

  getObject<T>(key: string): T | null {
    const value = localStorage.getItem(this._prefix + key);
    if (value == null)
      return null;

    return JSON.parse(value) as T;
  }

  remove = (key: string) => localStorage.removeItem(this._prefix + key);

  clear = () => localStorage.clear();
}
