import { InstitutionCod, PathFromLogo, Partner, ServiceContract, Theme } from '../models';
import { PartnerEnum } from '../partner.enum';

export class Scotia implements Partner {
  readonly theme: Theme = PartnerEnum.Scotia;
  readonly logo: PathFromLogo = PartnerEnum.ScotiaLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceScotia;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionScotia;
  readonly primaryColor: string = '#EC111A';
}
