import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmationGroup {
  id: number | null | undefined;
  name: string | null
}

@Component({
  selector: 'app-modal-handle-group',
  templateUrl: './modal-handle-group.component.html',
  styleUrls: ['./modal-handle-group.component.scss']
})
export class ModalHandleGroupComponent implements OnInit {

  title: string = 'GROUP_CREATION';
  actionButtonText: string = 'CREATE_GROUP';

  nameFormControl = new FormControl('', [Validators.required]);
 

  constructor(
    public dialogRef: MatDialogRef<ModalHandleGroupComponent, Partial<ConfirmationGroup>>,
    @Inject(MAT_DIALOG_DATA) public data?: {name: string, id: number},
  ) { }

  ngOnInit(): void {
    if (this.data?.id) {
      this.title = 'GROUP_EDITION';
      this.actionButtonText = 'EDIT_GROUP';
      this.nameFormControl.setValue(this.data.name)
    }

   

  }


  confirmGroup(): void {
    let group:{name:string | null, id?: number} = {name: this.nameFormControl.value};
    if(this.data?.id) {
      group.id = this.data.id
    }
    this.dialogRef.close(group);
  }



}
