import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OptinApiService } from 'src/app/api/services/optin.service';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { ModalConfirmationComponent } from 'src/app/shared/modal-confirmation/modal-confirmation.component';
import { Partner } from 'src/partner/models';
import { AppPartner } from 'src/partner/partner.service';
import { take } from 'rxjs/operators';
@Component({
    selector: 'app-user-optin',
    templateUrl: './user-optin.component.html',
    styleUrls: ['./user-optin.component.scss']
})
export class UserOptInComponent implements OnInit, OnDestroy {

    partner: Partner | undefined;

    state: {
        document?: string;
        mfaId?: number;
        password?: string;
        name?: string;
        optinId?: number;
        optinText?: string;
        redirect?: string;
        socialName?: string;
    } = {};

    private subscription: Subscription = new Subscription();

    constructor(
        public _dialog: MatDialog,
        private _appPartner: AppPartner,
        private _optinService: OptinApiService,
        private _activatedRoute: ActivatedRoute,
        private _globalizationService: GlobalizationService,
        private _router: Router
    ) { }

    @HostListener('window:beforeunload')
    onReload() {
        return false;
    }

    @HostListener('window:popstate')
    onPopState() {
        this.showModalConfirmation();
    }


    ngOnInit(): void {
        this._appPartner.getPartner().subscribe(el => this.partner = el);
        this._activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
            this.state = { ...params };
            if (this.state.document) {
                this.subscription.add(this._optinService.retrieveTextOptin(this.state.document).subscribe(
                    (data) => this.state.optinText = data.optinText));
            }
        });

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    showModalConfirmation(): void {
        const dialogRef = this._dialog.open(ModalConfirmationComponent, {
            width: '320px',
            height: '300px',
            data: {
                title: this._globalizationService.translate('WANT_STOP_YOUR_FIRST_ACCESS_TITTLE'),
                description: this._globalizationService.translate('WANT_STOP_YOUR_FIRST_ACCESS_SUB_TITTLE'),
                yesText: this._globalizationService.translate('YES'),
                noText: this._globalizationService.translate('NO')
            }
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {
                this._router.navigate([`${this.partner?.partnerName}/login`]);
            }

        });
    }

    clickedReadAndAccepted(): void {

        if (this.state.optinId && this.state.document) {
            this.subscription.add(
                this._optinService.acceptOptin({ optinId: this.state.optinId, key: this.state.document }).subscribe(() => {
                    this._router.navigate([this.state.redirect],
                        { relativeTo: this._activatedRoute, queryParams: this.state, skipLocationChange: true });
                })
            );
        }

    }

}
