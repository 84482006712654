import { HttpEventType } from "@angular/common/http";
import { AfterViewInit,  Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { MatTabGroup } from "@angular/material/tabs";
import { Subscription } from "rxjs";
import { ContentDetailLogs, LogsApiService, RetrieveDetailLogs, typeLog } from "src/app/api/services/logs.service";

interface TabsInternal {
    title: string, 
    id: number,
    query?: typeLog | typeLog[]
}

interface DataInjected {
    logs: RetrieveDetailLogs,
    user: ContentDetailLogs,
    serviceContractFilter?: string,
    creatorName?: string,
}

@Component({
    selector: 'app-table-user-logs-description',
    templateUrl: './table-user-logs-description.component.html',
    styleUrls: ['./table-user-logs-description.component.scss']
})
export class TableUserLogsDescriptionComponent implements OnInit, AfterViewInit, OnDestroy  {
    
    private subscription: Subscription = new Subscription();

    displayedColumns: string[] = ['activity', 'responsible', 'location', 'date'];
    displayedFilters: Array<string> = ['name', 'email', 'creatorName', 'datePeriod'];
    page: number[] = [0];
    total_pages: number[] = [0];


    // user selected
    user?: ContentDetailLogs;

    // table data
    dataSource: MatTableDataSource<ContentDetailLogs>[] = [];
    oldTabIndex:number = 0;


    tabs:TabsInternal[] = [
        {title: 'Todas as atividades', id: 1},
        {title: 'Logins', id: 2, query: "ACCESS_SUCCESS"},
        {title: 'Edição', id: 3, query: ["DELETE", "CHANGE_LOG"]},
        {title: 'Tentativa de Login', id: 4, query: "ERROR_ACCESS"},
        {title: 'Reset de senha', id: 5, query: ["RESET_PASSWORD", "RENEW_PASSWORD"]}
    ]

    loading: boolean[] = [false];
    scrollTop: number[] = [0];


    @ViewChild('scrollContainer') scrollContainer!: ElementRef;
    @ViewChild('tabGroup') tabGroup!: MatTabGroup;


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DataInjected,
        private _logsApiService: LogsApiService,
    ) { }

    ngOnInit(): void {
        this.dataSource[0] = new MatTableDataSource(this.data.logs.content);
        this.total_pages[0] = this.data.logs.totalPages;
        this.page[0] = this.data.logs.pageable.pageNumber
        this.user = this.data.user;
    }


    ngAfterViewInit(): void {

        this.scrollContainer.nativeElement.addEventListener('scroll', () => {

                if (
                    this.scrollContainer.nativeElement.scrollTop + 
                    this.scrollContainer.nativeElement.clientHeight >= 
                    this.scrollContainer.nativeElement.scrollHeight) {

                    this.fetchData(this.tabGroup.selectedIndex || 0);
                    
                }
              });
    
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    

    fetchData(id: number): void {

      
        if(this.total_pages[id] && !this.loading[id] && this.page[id] && (this.total_pages[id] >= (this.page[id] + 1))) {
            
            let query:any = {
                userName: this.user?.userName,
                serviceContractFilter: this.data.serviceContractFilter,
                page: this.page[id] + 1,
                typeLog:this.tabs[this.tabGroup.selectedIndex || 0].query,
                isListUser: false,
                size: 10
            }

            query = Object.fromEntries(Object
                .entries(query)
                .filter(([_, v]) => v != null));
            
         
            this.loading[id] = true;
            this.subscription.add(this._logsApiService.retrieveDetailLogsBuffs(query).subscribe({
                next: (value) => {

                  if ((value.type === HttpEventType.Response) && this.dataSource[id]) {
                      
                        this.loading[id] = false;
                        this.dataSource[id].data.push(...value.body.content)
                        this.dataSource[id].data = [...this.dataSource[id].data];
                        this.page[id] = value.body.pageable.pageNumber;  
                        this.total_pages[id] = value.body.totalPages;        
                    }
         
                }, error: () => this.loading[id] = false
            }))
        }

    

    }

    

    changeTab(tab:MatTabGroup, id: number) {

        tab.selectedIndex = this.oldTabIndex;

        if((this.oldTabIndex !== id) &&  !this.dataSource[id]) {
            let query: any = {
                userName: this.user?.userName,
                serviceContractFilter: this.data.serviceContractFilter,
                page: 1, 
                isListUser: false,
                size: 10
            }
            if (this.tabs[id].query) {
                query.typeLog = this.tabs[id].query
            }

            query = Object.fromEntries(Object
                .entries(query)
                .filter(([_, v]) => v != null));

            this.subscription.add(this._logsApiService.retrieveDetailLogs(query).subscribe({
                next: (value) => {
                    this.dataSource[id] = new MatTableDataSource(value.content);
                    this.oldTabIndex = id;
                    tab.selectedIndex = id;
                    this.page[id] = value.pageable.pageNumber  
                    this.total_pages[id] = value.totalPages
                    this.loading[id] = false;
                },
                error: () => tab.selectedIndex = this.oldTabIndex
            }))
        } else {
            tab.selectedIndex = id;
        }  
    }

    trackByTab(index: number, tab: TabsInternal) {
        return tab.id;
    }


}