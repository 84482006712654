import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { RoleUserEnum } from 'src/app/api/enums/role-user.enum';
import { UserProfile } from 'src/app/api/enums/user-profile.enum';
import { GroupResp } from 'src/app/api/models/group-resp';
import { Profiles } from 'src/app/api/models/profiles-resp';
import { AuthApiService } from 'src/app/api/services/auth-api.service';
import { ChannelApiService } from 'src/app/api/services/channel-api.service';
import { GroupApiService } from 'src/app/api/services/group-api.service';
import { InviteApiService } from 'src/app/api/services/invite.service';
import { UpdateExternalUser, UserApiService } from 'src/app/api/services/user-api.service';
import { GlobalizationService } from 'src/app/globalization/services/globalization.service';
import { ModalConfirmationInvitationComponent } from 'src/app/shared/modal-confirmation-invitation/modal-confirmation-invitation.component';
import { ModalConfirmationComponent } from 'src/app/shared/modal-confirmation/modal-confirmation.component';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { ValidatorsUtils } from 'src/app/shared/validators/validators.utils';
import { Partner } from 'src/partner/models';
import { PartnerEnum } from 'src/partner/partner.enum';
import { AppPartner } from 'src/partner/partner.service';

interface profile {
  id: number;
  name: string;
  user_type_description: string;
  description: string;
  text: string;
}

@Component({
  selector: 'app-external-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class ExternalUserEditComponent implements OnInit, OnDestroy {

  channel$?: Observable<string>;
  partner: Partner | undefined;


  profileList?: profile[];

  languageList = [
    { key: 'pt-BR' },
    { key: 'en-US' },
    { key: 'es' }
  ];

  userCanEdit = false;
  filledInvitation = true;
  invitationSentSuccessfully = false;
  invitationValidity = '';
  mfaId?: number;
  maxLength = 80;

  isUserLocked = false;

  disabledField = true;

  EnablePopState = false;

  isUserActive?: boolean;


  dateExpired?: string;

  showHowWantBeTreat = false;

  formBackUpUser: any;

  form = this._formBuilder.group({
    name: ['', [Validators.required, ValidatorsUtils.noWhitespaceValidator(), ValidatorsUtils.compositeNameValidator()]],
    socialName: ['', [Validators.required, Validators.maxLength(this.maxLength), ValidatorsUtils.noWhitespaceValidator()]],
    profileId: [null, [Validators.required]],
    maskedDocument: ['', []],
    profileName: ['', []],
    document: ['', []],
    status: ['', []],
    email: ['', [Validators.required, Validators.email]],
    telephone: ['', [Validators.required]],
    expiresAt: [null],
    language: ['', [Validators.required]]
  });

  UserProfileEnum = UserProfile;

  userSelected?: UpdateExternalUser;

  groups?:{id: number, name: string}[]
  login?:string;
  creator?:string;
  invitationCreatingDate?:Date;
  createdDate?:string;

  isGrafana: boolean = false;

  allGroups?:GroupResp[];
  showUserGroups: boolean = false;


  private subscription: Subscription = new Subscription();


  constructor(
    public location: Location,
    public dialog: MatDialog,
    private _appPartner: AppPartner,
    private _snackBarService: SnackBarService,
    private _userApi: UserApiService,
    private _globalizationService: GlobalizationService,
    private _inviteService: InviteApiService,
    private _authService: AuthApiService,
    private _activatedRoute: ActivatedRoute,
    private _groupApiService: GroupApiService,
    private _formBuilder: UntypedFormBuilder,
    private _groupApi: GroupApiService,
    private _channelApiService: ChannelApiService,
    private _router: Router
  ) {

  }

  get concludeMessage() {
    return this.filledInvitation ? 'Voltar' : 'Concluir';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  ngOnInit(): void {
    this.channel$ = this._channelApiService.channel$;

    this._appPartner.getPartner().subscribe(el => this.partner = el);

    const userId = this._activatedRoute.snapshot.paramMap.get('userId');

    this.isGrafana = this._channelApiService.isGrafana();

    if(this.isGrafana) {
      this._groupApiService.getAll().subscribe(g => this.allGroups = g);
    }

    if (!this.userCanEdit) {
      this.form.disable();
    }
    if (userId && !this.invitePending()) {
      const forkObservable = forkJoin<[UpdateExternalUser, Profiles]>([
        this._userApi.getUserById(userId, 'external'),
        this._groupApi.getProfile()
      ]);

      this.subscription.add(forkObservable.subscribe({
        next: ([user, profile]) => {

          this.form.patchValue({ ...user, groups: user.groups?.map(e => e.id)});

          if (user.hasOwnProperty('status')) {
            this.isUserActive = user.status;
          } else if (user.hasOwnProperty('active')) {
            this.isUserActive = user.active;
          }
          this.userSelected = user;

          if(this.isGrafana) {
            this.groups = user.groups && (user.groups?.length > 0) ? user.groups : undefined;
            this.login = user.email;
            this.creator = user.creatorName;

            const controlName = 'groups';
            if(!this.form.get(controlName)) {
              this.form.addControl(controlName, new FormControl(user.groups?.map(e => e.id), Validators.required));
              this.form.disable();
              this.showUserGroups = true;
            }
            this.createdDate = this.formattedCreateDate(user.createdDate);

          }

          this.profileList = profile?.profiles;
          if (user.serviceContracts) {

            const profileName = this.profileList.filter(p => p.id === user.profileId)[0].name ?? '';
            this.form.patchValue({ profileName: this._globalizationService.translate(profileName) });

          }
          if(this.isAlliance()) {
            this.isUserLocked = user.locked;
          }

        }
      }
      ));


    } else if (userId && this.invitePending()) {
      const forkObservable = forkJoin([
        this._inviteService.getInvitePending(userId),
        this._groupApi.getProfile()
      ]);

      this.subscription.add(forkObservable.subscribe({
        next: ([user, profiles]) => {
          this.profileList = profiles.profiles;
          if(this.isGrafana) {
            this.groups = user.groups && (user.groups?.length > 0) ? user.groups : undefined;
            this.login = user.email;
            this.creator = user.creatorName;
            this.invitationCreatingDate = user.dateCreated;
            this.createdDate = this.formattedCreateDate(user.createdDate);
          }

          this.dateExpired = new Date(user.expiresAt).toLocaleString(this._globalizationService.currentLanguage.value);
          user.expiresAt = this.dateExpired;
          this.mfaId = user.mfaId;
          this.form.patchValue({ ...user });
          if(this.isAlliance()) {

            this.isUserLocked = user.locked;
          }
        }
      }));
    }
  }



 private formattedCreateDate(isoDate?: string):string|undefined {

  if(!isoDate) {
    return undefined
  }

  const date = new Date(isoDate);

  // Extrair o dia, mês e ano da data
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses são indexados de 0 a 11
    const year = date.getFullYear();

  // Formatar a data no formato dd/mm/yyyy
  const formattedDate = `${day}/${month}/${year}`;


  return formattedDate
 }



  isNotItMe(): boolean {
    return this._authService.userDetails.userId !== this.userSelected?.userId.toString();
  }

  isAlliance():boolean {
    return this._appPartner.isAlliance();
  }

  isUserAllowedToResendInvite() {
    return this._authService.hasRoles(RoleUserEnum.SGDA2_EDIT_INVITE_PENDING);
  }

  invitePending(): boolean {
    return this._activatedRoute.snapshot.data.invitedUser || false;
  }
  reSendInvite(): void {
    if (this.mfaId) {
      this._inviteService.resendInvite(this.mfaId).subscribe(d => {
        this._snackBarService.showSuccess(this._globalizationService.translate('INVITE_SEND_SUCCESS'));
        this.ngOnInit();
      });
    }

  }

  deleteUser(): void {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: '392px',
      height: '269px',
      data: {
        title: this.invitePending() ? this._globalizationService.translate('WANT_TO_DELETE_THE_SENT_USER_INVITE') :
          this._globalizationService.translate('WANT_TO_CANCEL_THE_SENT_USER'),
        description: this.invitePending() ? this._globalizationService.translate('WANT_TO_DELETE_THE_SENT_USER_INVITE_SUB') :
          this._globalizationService.translate('WANT_TO_CANCEL_THE_SENT_USER_SUB'),
        yesText: this._globalizationService.translate('YES_DELETE'),
        noText: this._globalizationService.translate('NO_KEEP_IT')
      },

    });

    dialogRef.afterClosed().subscribe(result => {

      if (result && this.userSelected?.userId && !this.invitePending()) {

        this.subscription.add(this._userApi.delete({ userId: this.userSelected.userId }).subscribe({
          next: () => {
            this._snackBarService.showSuccess(this._globalizationService.translate('USER_SUCCESSFULLY_DELETED'));
          },
          complete: () => this.showUsersList()
        }));
      } else if (result && this.mfaId && this.invitePending()) {
        this.subscription.add(this._inviteService.deleteInvite({ mfaId: this.mfaId }).subscribe({
          next: () => {
            this._snackBarService.showSuccess(this._globalizationService.translate('INVITE_SUCCESSFUL_DELETED'));
          },
          complete: () => this.showUsersList()
        }));
      }

    });
  }


  submitUserEdited(): void {
    if (this.isSelfEditing()) {
      const isFieldUpdated = this.hasChangedFields(['profileId', 'email']);

      if (isFieldUpdated) {
        this.openModalReport().afterClosed().subscribe(result => {
          if (result) {
            this.subscription.add(this._userApi.edit(
              { ...this.form.value, status: this.isUserActive, userId: this.userSelected?.userId })
              .subscribe(() => {
                if (this.userSelected) {
                  this.userSelected.socialName = this.form.controls.socialName.value;
                }
                this.showUsersList(true);
              }))
          }
        })
      } else {
        this.subscribeUserEdit();
      }
    } else {
      this.subscribeUserEdit();
    }
  }

  subscribeUserEdit() {
    this.subscription.add(this._userApi.edit(
      { ...this.form.value, status: this.isUserActive, userId: this.userSelected?.userId })
      .subscribe(() => {
        if (this.userSelected) {
          this.userSelected.socialName = this.form.controls.socialName.value;
        }
        this.showUsersList(true);
      }));
  }


  openModalReport(): MatDialogRef<ModalConfirmationInvitationComponent> {

    const title = this._globalizationService.translate('USER_INFO_UPDATED');
    const yesText = this._globalizationService.translate('YES_CONFIRM');
    const noText = this._globalizationService.translate('NO_CANCELED');


    const dialogRef = this.dialog.open(ModalConfirmationInvitationComponent, {
      panelClass: 'cancel-invite-batch',
      width: '450px',
      height: '250px',
      autoFocus: false,
      data: {
        title,
        yesText,
        noText
      }
    });
    return dialogRef;
  }

  hasChangedFields(fieldsNames: string[]): any {
    const originalForm = this.userSelected;
    return fieldsNames.some(fieldsName => this.form.get(fieldsName)?.value !== originalForm?.[fieldsName as keyof typeof originalForm]);

  }

  editForm(): void {
    this.filledInvitation = false;
    this.formBackUpUser = { ...this.form.value };
    this.form.enable();
    if (this.isAlliance()) {
      this.form.get('document')?.addValidators(Validators.required);
    }

    if(this.isGrafana) {
      this.form.get('telephone')?.clearValidators();
    }
    this.form.updateValueAndValidity()
  }

  toggleChange($event: Event) {
    $event.preventDefault();

    if (!this.userSelected || (this.isUserActive === undefined)) {
      return;
    }

    const { name, email, profileId, telephone, userId, socialName, language, locked } = this.userSelected;
    this._userApi.edit({
      name, email, profileId, status: !this.isUserActive,
      telephone, userId, socialName, language, locked
    }).subscribe(() => {
      this.isUserActive = !this.isUserActive;
    });
  }

  toggleLockUser($event: Event) {

    $event.preventDefault();

    if (!this.userSelected || (this.isUserActive === undefined) || !this.isUserLocked) {
      return;
    }


    const { name, email, profileId, telephone, userId, socialName, language, locked } = this.userSelected;
    this._userApi.edit({
      name, email, profileId, status: this.isUserActive,
      telephone, userId, socialName, language, locked: !locked
    }).subscribe(() => {
      this.isUserLocked = !locked;
      this._snackBarService.showSuccess(this._globalizationService.translate('USER_UNBLOCK_SUCCES'));
    });
  }



  userCanEditOwnerType(): boolean {
    return this._authService.userIsAdmin();
  }
  userCanEditEmail(): boolean {
    if (this.isAlliance()) {
      return false;
    }
    return this._authService.userIsAdmin();
  }

  userProfileName(): string {
    return this._authService.userDetails.profileName;
  }

  userCantEditOrDelete(): boolean {
    if((this.userSelected?.serviceContracts?.[0].profileName === this.UserProfileEnum.ADMINISTRADOR_ALLIANCE)
      && !this.isSelfEditing()) {
      return false;
    }
    return this.isEditorExternalUser();
  }



  isEditorExternalUser(): boolean {

    const role = this.isSelfEditing() ?
      RoleUserEnum.SGDA2_SELF_EDIT_EXTERNAL_USER : RoleUserEnum.SGDA2_EDIT_EXTERNAL_USER;

    return this._authService.hasRoles([role]);
  }


  isSelfEditing(): boolean {
    return this._activatedRoute.snapshot.params.userId === this._authService.userDetails?.userId;
  }

  mobileWidth(): boolean {
    return window.innerWidth < 557;
  }

  showUsersList(restart?: boolean): void {
    if (this.filledInvitation === false) {
      this.filledInvitation = true;
      this.form.disable();
      if (restart) {
        this.ngOnInit();
      } else {
        this.form.patchValue({ ...this.formBackUpUser });
      }
    } else if (this._activatedRoute.snapshot.data.pending) {

      // Obtém a URL anterior do histórico de navegação
      const previousUrl = this.location.path();
      const urlTree = this._router.parseUrl(previousUrl);

      // Verifica se a URL anterior é '/pending' com queryParams
      if (urlTree.root.children['primary'] && urlTree.root.children['primary'].segments.map(it => it.path).join('/') === 'pending' && urlTree.queryParams) {
        // Se for '/pending' com queryParams, volta normalmente
        this.location.back();
      } else {
        // Se não for '/pending' com queryParams, navega para '/pending' sem queryParams
        this._router.navigate(['../../pending'], { relativeTo: this._activatedRoute });
      }


    } else {

      this._router.navigate(['../../'], { relativeTo: this._activatedRoute });
    }
  }

  getName(): string {
    return this.form.get('name')?.value;
  }

  getStatus(): string {
    return this.form.get('status')?.value;
  }

  showLanguageName(name: string): string {
    if (name === 'pt-BR') {
      return this._globalizationService.translate('LANGUAGES.PORTUGUESE');
    } else if (name === 'en-US') {
      return this._globalizationService.translate('LANGUAGES.ENGLISH');
    } else {
      return this._globalizationService.translate('LANGUAGES.SPANISH');
    }
  }

  partnerUsesDocument(): boolean {
    return this.isAlliance();
  }


}
