<mat-card-content class="form-container">

    <div class="close-container">
        <button mat-icon-button class="close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="text-container">
        <h1>{{'TO_ALTER_PASSWORD' | translate}}</h1>
        <span>{{'FILL_IN_THE_FIELDS_BELOW_TO_CHANGE_YOUR_PASSWORD' | translate}}.</span>

    </div>

    <form class="form" [formGroup]="passwordForm">


        <mat-form-field class="form-field-container" appearance="fill">

            <mat-label>{{'PASSWORD' | translate}} </mat-label>

            <input tabindex="0" formControlName="oldPassword" matInput [type]="hideOldPassword ? 'password' : 'text'"
                autocomplete="off" />
            <button mat-icon-button matSuffix (click)="toggleOldPasswordVisibility()" type="button">
                <mat-icon class="toggleEyesOpenIconButton" svgIcon="{{hideOldPassword ? 'eyes-closed': 'eyes-open' }}">
                </mat-icon>
            </button>

        </mat-form-field>
        <mat-form-field class="form-field-container" appearance="fill">

            <mat-label>{{'NEW_PASSWORD' | translate}} </mat-label>
            <input data-testid="new-password-generate-input" formControlName="newPassword" matInput
                [type]="hideNewPassword ? 'password' : 'text'" autocomplete="off" />
            <button mat-icon-button matSuffix (click)="toggleNewPasswordVisibility()" type="button">
                <mat-icon class="toggleEyesOpenIconButton" svgIcon="{{hideNewPassword ? 'eyes-closed': 'eyes-open' }}">
                </mat-icon>
            </button>

        </mat-form-field>
        <mat-form-field class="form-field-container" appearance="fill">

            <mat-label>{{'CONFIRM_YOUR_PASSWORD_2' | translate}} </mat-label>
            <input data-testid="new-password-generate-input-confirm" formControlName="confirmNewPassword" matInput
                [type]="hideConfirmNewPassword ? 'password' : 'text'" autocomplete="off" />
            <button mat-icon-button matSuffix (click)="toggleConfirmNewPasswordVisibility()" type="button">
                <mat-icon class="toggleEyesOpenIconButton"
                    svgIcon="{{hideConfirmNewPassword ? 'eyes-closed': 'eyes-open' }}">
                </mat-icon>
            </button>
        </mat-form-field>
    </form>
    <div class="rules-form-warning">
        <span>{{'MINIMUM_OF_CHARACTERS_AND_MAX' | translate : { characters:
            9, maxCharacters: 64 } }}
            <mat-icon *ngIf="!passwordForm.get('newPassword')?.hasError('lengthRange')" class="checked-item">check
            </mat-icon>
        </span>
        <span>{{'CONTAIN_UPPERCASE_LOWERCASE_NUMBER' | translate}}
            <mat-icon *ngIf="!passwordForm.get('newPassword')?.hasError('containsLowercase')  &&
                !passwordForm.get('newPassword')?.hasError('containsUppercase') &&
                !passwordForm.get('newPassword')?.hasError('containsNumber')" class="checked-item"> check
            </mat-icon>
        </span>

        <span>{{'CONTAIN_SPECIAL_CHARACTER' | translate}}: ! &#64; # $ % ^ &
            <mat-icon *ngIf="!passwordForm.get('newPassword')?.hasError('containsSpecialChar')"
                class="checked-item">check</mat-icon>
        </span>
        <br>
        <span>{{'CANT_CONTAIN_EMAIL_FIRST_LAST_NAME' | translate}}
            <mat-icon *ngIf="passwordForm.get('newPassword')?.dirty && (!passwordForm.get('newPassword')?.hasError('noFirstName')  &&
            !passwordForm.get('newPassword')?.hasError('noLastName') &&
            !passwordForm.get('newPassword')?.hasError('noEmail'))" class="checked-item">check</mat-icon>
        </span>
        <span> {{'CANT_CONTAIN_WHITE_SPACES' | translate}}
            <mat-icon
                *ngIf="passwordForm.get('newPassword')?.dirty &&  !passwordForm.get('newPassword')?.hasError('noWhitespace')"
                class="checked-item">check</mat-icon>
        </span>
        <span> {{'CANT_CONTAIN_IDENTICAL_SEQUENCES' | translate}}
            <mat-icon
                *ngIf="passwordForm.get('newPassword')?.dirty &&  !passwordForm.get('newPassword')?.hasError('noIdenticalSequence')"
                class="checked-item">check</mat-icon>
        </span>
        <br>
        <span> {{'PASSWORDS_MUST_BE_THE_SAME' | translate}}
            <mat-icon
                *ngIf="passwordForm.get('confirmNewPassword')?.dirty && passwordForm.get('newPassword')?.dirty && !passwordForm.hasError('passwordMismatch')"
                class="checked-item">check</mat-icon>
        </span>
    </div>

    <div class="bottom-container">
        <button class="confirm-button" mat-flat-button color="primary" (click)="submitForm(passwordForm.value)"
            [disabled]="!passwordForm.valid">{{'SEND' | translate}}</button>
    </div>

</mat-card-content>
