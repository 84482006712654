import { InstitutionCod, PathFromLogo, Partner, ServiceContract, Theme } from '../models';
import { PartnerEnum } from '../partner.enum';

export class Sipag implements Partner {
  readonly theme: Theme = PartnerEnum.Sipag;
  readonly logo: PathFromLogo = PartnerEnum.SipagLogo;
  readonly serviceContract: ServiceContract = PartnerEnum.ServiceSipag;
  readonly institutionCod: InstitutionCod = PartnerEnum.InstitutionSipag;
  readonly primaryColor: string = '#76B82A';
}
