
import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot
} from '@angular/router';
import { HelpersModule } from './helpers.module';
import { StorageService } from '../storage/services/storage.service';

@Injectable({ providedIn: HelpersModule })

export class ChannelGuardService implements CanActivate {
    constructor(
        public _storageServe: StorageService, 
        private _router: Router
    ) {}
    canActivate(route: ActivatedRouteSnapshot): boolean {

        const partner = route.paramMap.get('partner') || 'fiserv';
 
        const channelJSON = this._storageServe.getUsingPrefix('userChannel', '_'+partner+'_');
     
        if(channelJSON && !!JSON.parse(channelJSON)?.clientId) {
            return true
        }

        const newRouter = [partner + '/channels'];
        

        this._router.navigate(newRouter)

        return false;
    }


}
