import { Injectable } from '@angular/core';

import { ApiService } from './common/api.service';

import { ApiModule } from '../api.module';

import { ApiResp } from '../models/api-resp';
import { GroupResp, ChannelResponse, usersResponse } from '../models/group-resp';
import { Mids } from '../models/mids';

import { ApiObservable } from 'src/app/shared/models/api-observable';
import { HttpParams } from '@angular/common/http';
import { StorageService } from 'src/app/storage/services/storage.service';
import { Observable, of } from 'rxjs';
import { Profiles } from '../models/profiles-resp';
import { UserTypeEnum } from '../enums/user-type.enum';


@Injectable({
  providedIn: ApiModule
})
export class GroupApiService {

  constructor(
    private api: ApiService,
    private storageServe: StorageService
  ) { }

  getAll(): ApiObservable<GroupResp[]> {
    return this.api.get<GroupResp[]>('/v1/groups');
  }
  createOrUpdate(group:{id: number, name: string, institutionCod: string,serviceContractCod: string}): ApiObservable<any> {
    return this.api.post<GroupResp, any>('/v1/group', group);
  }
  deleteGroup(id: number): ApiObservable<any> {
    return this.api.post<Object, any>(`/v1/group/${id}`, {});
  }

  getAssistantMids(): ApiObservable<Mids> {
    return this.api.get<Mids>('/v1/users/mids');
  }
  getChannelList(): ApiObservable<ChannelResponse> {
    return this.api.get<ChannelResponse>('/v1/channels');
  }

  getChannelName(id: string): Observable<{ name: string; description: string }> {
    // const channel: string | null = (this.storageServe.get('channel') || null);
    // console.log("TEM NA STORAGE => ", channel)
    // if (channel != null) {
    //   let channelInfo: { name: string; description: string } = JSON.parse(channel)
    //   return of(channelInfo).pipe(tap(() => this._requestChannelInfo(id).subscribe()));
    // }


    return this.api.get<{ name: string; description: string }>(`/v1/channels/${id}`);//this._requestChannelInfo(id);
  }
  getUsersByChannel(serviceContractId: number, params?: HttpParams): ApiObservable<usersResponse> {
    return this.api.get<usersResponse>(`/v1/users/channel/${serviceContractId}`, params);
  }
  getProfile(userType?: UserTypeEnum): ApiObservable<Profiles> {
    return this.api.get<Profiles>(`/v1/profiles${userType ? '?userType=' + userType : ''}`);
  }
}
