import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
    title: string;
    description: string;
    yesText?: string;
    noText?: string;
    reverseOrderOfActionButtons?: boolean;
}

@Component({
    selector: 'app-modal',
    templateUrl: './modal-confirmation.component.html',
    styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent {

    constructor(
        public dialogRef: MatDialogRef<ModalConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    onYesClick(): void {
        this.dialogRef.close(true);
    }
}
