import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { FormPasswordComponent } from '../form-password/form-password.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-form-change-password',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    FormPasswordComponent,
    MatIconModule,
  ],
  templateUrl: './form-change-password.component.html',
  styleUrls: ['./form-change-password.component.scss'],
})
export class FormChangePasswordComponent {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() buttonText?: string;

  @Output() sendPassword: EventEmitter<{ password: string }> =
    new EventEmitter();

  @Input() first_name!: string;
  @Input() last_name!: string;
  @Input() email!: string;
  @Input() first_access = false;

  mainForm: FormGroup<{
    password: FormControl<string | null>;
    confirmPassword: FormControl<string | null>;
  }>;

  constructor(private _fb: FormBuilder) {
    this.mainForm = this._fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            PasswordValidators.lengthRange,
            PasswordValidators.containsLowercase,
            PasswordValidators.containsUppercase,
            PasswordValidators.containsNumber,
            PasswordValidators.containsSpecialChar,
            PasswordValidators.noFirstName(this.first_name),
            PasswordValidators.noLastName(this.last_name),
            PasswordValidators.noEmail(this.email),
            PasswordValidators.noWhitespace,
            PasswordValidators.noIdenticalSequence,
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      { validators: this.passwordsMatch }
    );
  }

  passwordsMatch(group: AbstractControl) {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { passwordMismatch: true };
  }

  handleFormSubmit(password: string | null | undefined) {
    if (password) {
      this.sendPassword.emit({ password });
    }
  }
}

export class PasswordValidators {
  static containsLowercase(control: AbstractControl): ValidationErrors | null {
    const value = control.value || '';
    return /[a-z]/.test(value) ? null : { containsLowercase: true };
  }

  static containsUppercase(control: AbstractControl): ValidationErrors | null {
    const value = control.value || '';
    return /[A-Z]/.test(value) ? null : { containsUppercase: true };
  }

  static containsNumber(control: AbstractControl): ValidationErrors | null {
    const value = control.value || '';
    return /[0-9]/.test(value) ? null : { containsNumber: true };
  }

  static containsSpecialChar(
    control: AbstractControl
  ): ValidationErrors | null {
    const value = control.value || '';
    return /[!@#$%^&]/.test(value) ? null : { containsSpecialChar: true };
  }

  static noWhitespace(control: AbstractControl): ValidationErrors | null {
    const value = control.value || '';
    return /\s/.test(value) ? { noWhitespace: true } : null;
  }

  static noIdenticalSequence(
    control: AbstractControl
  ): ValidationErrors | null {
    const value = control.value || '';
    return /(.)\1/.test(value) ? { noIdenticalSequence: true } : null;
  }

  static lengthRange(control: AbstractControl): ValidationErrors | null {
    const value = control.value || '';
    return value.length >= 9 && value.length <= 64
      ? null
      : { lengthRange: true };
  }

  static noFirstName(firstName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || '';
      return value.includes(firstName) ? { noFirstName: true } : null;
    };
  }
  static noLastName(LastName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || '';
      return value.includes(LastName) ? { noLastName: true } : null;
    };
  }
  static noEmail(email: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || '';
      return value.includes(email) ? { noEmail: true } : null;
    };
  }
}
