import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
    title: string;
    description: string;
    showErrorFromImportation?: boolean;
    dataDownloadTextPlain: BlobPart[] | undefined;
    fileName?: string;
}

@Component({
    selector: 'app-modal-warning',
    templateUrl: './modal-warning.component.html',
    styleUrls: ['./modal-warning.component.scss']
})
export class ModalWarningComponent {

    constructor(
        public dialogRef: MatDialogRef<ModalWarningComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }


    downloadFileText() {
        Object.assign(document.createElement('a'), {
            download: this.data.fileName,
            href: URL.createObjectURL(new Blob(this.data.dataDownloadTextPlain, { type: 'text/plain' }))
        }).click();
    }

}
