import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalizationService } from 'src/app/globalization/services/globalization.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(private globalizationService: GlobalizationService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const currentLanguage = this.globalizationService.currentLanguage;

    const req = request.clone({
      headers: request.headers.set('Accept-Language', currentLanguage.value)
    });

    return next.handle(req);
  }
}
