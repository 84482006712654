<mat-card-content class="form-container">

    <form class="form" [formGroup]="passwordForm" >

   
    <mat-form-field class="form-field-container" appearance="fill">

        <mat-label *ngIf="first_access">{{'PASSWORD' | translate}} </mat-label>
        <mat-label *ngIf="!first_access">{{'NEW_PASSWORD' | translate}} </mat-label>
        <input data-testid="new-password-generate-input" formControlName="password" matInput [type]="hidePassword ? 'password' : 'text'" autocomplete="off" />
        <button mat-icon-button matSuffix (click)="togglePasswordVisibility()" type="button">
            <mat-icon 
            class="toggleEyesOpenIconButton" 
            svgIcon="{{hidePassword ? 'eyes-closed': 'eyes-open' }}">
        </mat-icon>
        </button>
     
    </mat-form-field>
    <mat-form-field class="form-field-container" appearance="fill">

        <mat-label>{{'CONFIRM_YOUR_PASSWORD_2' | translate}} </mat-label>
        <input 
            data-testid="new-password-generate-input-confirm" 
            formControlName="confirmPassword" 
            matInput 
            [type]="hideConfirmPassword ? 'password' : 'text'" autocomplete="off" />
        <button mat-icon-button matSuffix (click)="toggleConfirmPasswordVisibility()" type="button">
            <mat-icon 
            class="toggleEyesOpenIconButton" 
            svgIcon="{{hideConfirmPassword ? 'eyes-closed': 'eyes-open' }}">
        </mat-icon>
        </button>
    </mat-form-field>
    </form>
    <div class="rules-form-warning">
        <span>{{'MINIMUM_OF_CHARACTERS_AND_MAX' | translate : { characters:
            9, maxCharacters: 64 } }}
            <mat-icon *ngIf="!passwordForm.get('password')?.hasError('lengthRange')" class="checked-item">check
            </mat-icon>
        </span>
        <span>{{'CONTAIN_UPPERCASE_LOWERCASE_NUMBER' | translate}}
            <mat-icon 
                *ngIf="!passwordForm.get('password')?.hasError('containsLowercase')  && 
                !passwordForm.get('password')?.hasError('containsUppercase') &&
                !passwordForm.get('password')?.hasError('containsNumber')" 
                class="checked-item"> check
            </mat-icon>
        </span>

        <span>{{'CONTAIN_SPECIAL_CHARACTER' | translate}}: ! &#64; # $ % ^ &
            <mat-icon *ngIf="!passwordForm.get('password')?.hasError('containsSpecialChar')"
                class="checked-item">check</mat-icon>
        </span>
        <br>
        <span>{{'CANT_CONTAIN_EMAIL_FIRST_LAST_NAME' | translate}}
            <mat-icon *ngIf="passwordForm.dirty && (!passwordForm.get('password')?.hasError('noFirstName')  && 
            !passwordForm.get('password')?.hasError('noLastName') &&
            !passwordForm.get('password')?.hasError('noEmail'))"
                class="checked-item">check</mat-icon>
        </span>
        <span> {{'CANT_CONTAIN_WHITE_SPACES' | translate}}
            <mat-icon *ngIf="passwordForm.dirty &&  !passwordForm.get('password')?.hasError('noWhitespace')" class="checked-item">check</mat-icon>
        </span>
        <span> {{'CANT_CONTAIN_IDENTICAL_SEQUENCES' | translate}}
            <mat-icon *ngIf="passwordForm.dirty &&  !passwordForm.get('password')?.hasError('noIdenticalSequence')"
                class="checked-item">check</mat-icon>
        </span>
        <br>
        <span> {{'PASSWORDS_MUST_BE_THE_SAME' | translate}}
            <mat-icon *ngIf="passwordForm.dirty && !passwordForm.hasError('passwordMismatch')"
                class="checked-item">check</mat-icon>
        </span>
    </div>

</mat-card-content>