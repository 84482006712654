<app-float-menu [showArrowBackButton]="true"></app-float-menu>

<div *ngIf="invitationSentSuccessfully" [@TopToDown]
    class="messageBlockMobile">
    <mat-icon svgIcon="checkbox-circle"></mat-icon>
    <p>
        {{"INVITE_SEND_SUCCESS" | translate}}
    </p>
</div>

<div class="page">

            <app-user-name-authenticate></app-user-name-authenticate>
  
    <main class="content">

        <div class="title">

            <ng-template #showInviteText>
                <p class="subtitle">{{"INVITE_CHANNEL_USER_PENDING" | translate:
                    {channel:partner?.partnerName} }}<span style="text-transform:
                        capitalize;"></span>
                </p>
                <h4 style="margin: 0;">{{"USER_HAS_BEEN_INVITED" | translate}}</h4>
            </ng-template>

        </div>

        <div *ngIf="invitationSentSuccessfully" [@TopToDown]
            class="messageBlock">
            <mat-icon svgIcon="checkbox-circle"></mat-icon>
            <p>
                {{"INVITE_SEND_SUCCESS" | translate}}
            </p>
        </div>

        <div
            class="form-container" [ngClass]="{'form-container-filled':
            filledInvitation, 'form-container-SentSuccess':
            invitationSentSuccessfully}">

            <app-form [EnablePopState]="EnablePopState" [formGroup]="form"
                [ngClass]="{'filledInvitationForm':
                filledInvitation}">

                <div class="form-invite" [ngClass]="{'filledInvitation':
                    filledInvitation}">

                    <app-form-control *ngIf="!filledInvitation"
                        label="{{'NAME_LASTNAME' |
                        translate}}"
                        id="name" name="name" type="text">
                    </app-form-control>

                    <div *ngIf="filledInvitation" class="nameWithSocialName">
                        <div class="containerName">
                            <span>{{"NAME" | translate}}</span>
                            <p id="nameUser">{{getName()}}</p>
                            <p *ngIf="!invitePending()">{{"TREAT_LIKE" |
                                translate}}:{{userSelected?.socialName}}</p>
                        </div>
                    </div>

                    <app-form-control label="{{'HOW_WANT_BE_TREAT' |
                        translate}}"
                        *ngIf="!filledInvitation || !invitePending() &&
                        showHowWantBeTreat"
                        id="socialName" name="socialName" type="text"
                        [maxLength]="maxLength">
                    </app-form-control>

                    <mat-form-field [ngClass]="{'disabled-user-type' :
                        !userCanEditOwnerType() || isAlliance()}" class="selectedUserType"
                        appearance="fill">
                        <mat-label>{{"PROFILE" | translate}}</mat-label>
                        <mat-select *ngIf="userCanEditOwnerType()"
                            name="profileId"
                            formControlName="profileId">
                            <mat-option *ngFor="let item of profileList"
                                [value]="item.id">
                                {{item.name | translate}}
                            </mat-option>
                        </mat-select>

                        <mat-select *ngIf="!userCanEditOwnerType()"
                            name="profileId"
                            formControlName="profileId">
                            <mat-option *ngFor="let item of profileList"
                                [disabled]="disabledField"
                                [readonly]="disabledField"
                                [value]="item.id">
                                {{item.name | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="form.get('document')?.value" id="cpf"
                        appearance="fill">
                        <mat-label>{{'DOCUMENT' | translate}}

                            <mat-icon *ngIf="filledInvitation" svgIcon="lock"></mat-icon>
                        </mat-label>
                        <input *ngIf="invitePending(); else NotPendingInvite"
                            matInput name="maskedDocument"
                            formControlName="maskedDocument"
                            [readonly]="disabledField" />
                        <ng-template #NotPendingInvite>
                            <input matInput name="maskedDocument"
                                formControlName="maskedDocument"
                                [readonly]="disabledField" />
                        </ng-template>

                    </mat-form-field>
                    <mat-form-field [ngClass]="{'disabled-user-email' :
                        !userCanEditEmail()}" id="email"
                        appearance="fill">
                        <mat-label>{{"EMAIL" | translate}}

                        </mat-label>
                        <input matInput name="email"
                            formControlName="email"
                            [readonly]="!userCanEditEmail()"
                            [ngClass]="{'displayNone' : filledInvitation}" />

                        <span [ngClass]="{'displayNone' : !filledInvitation}"
                            class="input-email">
                            {{form.get('email')?.value}}</span>
                    </mat-form-field>

                    <app-form-control 
                        label="{{'TELEPHONE' | translate}}"
                        *ngIf="form.get('telephone')?.value"
                        id="telephone"
                        name="telephone"
                        type="phone">
                    </app-form-control>

                    <!-- <mat-form-field *ngIf="groups && filledInvitation"
                    appearance="fill">
                    <mat-label>{{'GROUPS' | translate}}
                    </mat-label>
                    <ng-template ngFor let-group [ngForOf]="groups">
                      <div>
        
                        <input disabled matInput
                      [value]="group.name" />
                      
                      </div>
        
                    </ng-template>
                  </mat-form-field> -->

                  
                  <ng-template [ngIf]="groups || !filledInvitation" >
                    <mat-form-field  *ngIf="showUserGroups"  appearance="fill" >
                        <mat-label>{{"GROUPS" | translate}}</mat-label>
                        <mat-select id="groups" name="groups" formControlName="groups" multiple >
                            <mat-option *ngFor="let group of allGroups" [id]="group.name"
                                [value]="group.id">
                                {{ group.name }}
                            </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-template>

                    <mat-form-field *ngIf="login && filledInvitation"
                    appearance="fill">
                    <mat-label>Login
                    </mat-label>
                      <div>
                        <input disabled matInput  [value]="login" />        
                      </div>
                  </mat-form-field>
        
                  <mat-form-field *ngIf="creator && filledInvitation"
                    appearance="fill">
                    <mat-label>{{'CREATOR' | translate}}
                    </mat-label>
                      <div>
                        <input disabled matInput  [value]="creator" />        
                      </div>
                  </mat-form-field>

                  <mat-form-field *ngIf="createdDate && filledInvitation"
                  appearance="fill">
                  <mat-label>{{'CREATION_DATE' | translate}}
                  </mat-label>
                    <div>
                      <input disabled matInput  [value]="createdDate" />        
                    </div>
                </mat-form-field>
                

                      <mat-form-field *ngIf="invitationCreatingDate"
                    appearance="fill">
                    <mat-label>{{'INVITATION_CREATION_DATE' | translate}}
                    </mat-label>
                        <div>
                        <input disabled matInput  [value]="invitationCreatingDate" />        
                        </div>
                    </mat-form-field>

                    <mat-form-field *ngIf="languageList"
                        class="selectLanguageFormField" appearance="fill">
                        <mat-label>{{(!filledInvitation? "LANGUAGE" :
                            "LANGUAGE_EMAIL_SMS") | translate}}</mat-label>
                        <mat-select name="language"
                            formControlName="language">
                            <mat-option *ngFor="let enum of languageList"
                                [value]="enum.key">
                                {{showLanguageName(enum.key)}}
                            </mat-option>
                        </mat-select>
                        <mat-hint *ngIf="!filledInvitation" align="end">
                            <mat-icon style="width: 17px; height: 17px;"
                                svgIcon="alert-circle"></mat-icon>
                            <span style="text-align: justify;">
                                {{"DEFINE_WHICH_LANGUAGE" | translate}}
                            </span>
                        </mat-hint>
                    </mat-form-field>

                    <ng-template [ngIf]="invitePending()">
                        <mat-form-field id="expiresAt"
                            appearance="fill">
                            <mat-label>{{"VALID_UNTIL" | translate}}
                            </mat-label>
                            <div class="code">
                                <!-- <span *ngIf="form.get('expiresAt')?.value">
                                    ******
                                </span> -->
                                <!-- <span>{{'VALID_UNTIL' | translate}}</span> -->
                                <input *ngIf="!mobileWidth()" matInput
                                    name="expiresAt"
                                    formControlName="expiresAt"
                                    [readonly]="disabledField" />
                                <input style="display: none;"
                                    *ngIf="mobileWidth()" matInput disabled
                                    value="*****" />
                            </div>
                        </mat-form-field>
                    </ng-template>

                    <ng-template [ngIf]="invitePending() && mobileWidth()">
                        <mat-form-field id="expiresAt"
                            appearance="fill">
                            <mat-label>{{"VALIDITY_INVITE" | translate}}
                            </mat-label>
                            <div>
                                <input matInput name="expiresAt"
                                    formControlName="expiresAt"
                                    [readonly]="disabledField" />
                            </div>
                        </mat-form-field>
                    </ng-template>

                    <mat-form-field *ngIf="invitationSentSuccessfully"
                        appearance="fill">
                        <mat-label>{{"CODE_INVITE" | translate}}

                        </mat-label>
                        <input disabled matInput
                            value="****" />
                    </mat-form-field>

                    <mat-form-field *ngIf="invitationSentSuccessfully"
                        appearance="fill">
                        <mat-label>{{"VALIDITY_INVITE" | translate}}
                        </mat-label>
                        <input disabled matInput
                            [value]="invitationValidity" />
                    </mat-form-field>

                </div>
                <div *ngIf="isEditorExternalUser()" class="actions">

                    <div>

                        <mat-divider [inset]="true"></mat-divider>
                    </div>
                    <div class="buttons">
                        <div class="slide-container" *ngIf="!invitePending(); else
                        showInvitePendingActions">
                            <mat-slide-toggle
                            *ngIf="!isSelfEditing()"
                            [style.color]="isUserActive ? 'black' : '#fe5a59'"
                            (click)="toggleChange($event)"
                            [checked]="isUserActive">
                            {{(isUserActive ? "ACTIVE" : "INACTIVE") | translate}}
                         </mat-slide-toggle>

                            <mat-slide-toggle
                            *ngIf="isAlliance() && !isSelfEditing()"
                            [style.color]="!isUserLocked ?  'black': '#F39200'"
                            [disabled]="!(isUserActive && isUserLocked)"
                            (click)="toggleLockUser($event)"
                            [checked]="!isUserLocked">
                            {{(isUserLocked ? "LOCKED" :"UNLOCKED") | translate}}
                        </mat-slide-toggle>
                        </div>
                       

                        <ng-template #showInvitePendingActions>
                            <div class="invitePendingCircleContainer"
                                style="width: 96px;">

                                <mat-icon [class]="getStatus()">circle</mat-icon>
                                <p [style.Color]="getStatus() === 'EXPIRED' ? 'red':'#DBDBDB'">{{ (getStatus() === 'EXPIRED'?
                                    "INVITE_EXPIRED" : "INVITE_PENDING") |
                                    translate }}</p>
                            </div>
                        </ng-template>

                        <button
                            *ngIf="(!invitationSentSuccessfully &&
                            !invitePending()) && userCantEditOrDelete()"
                            data-testid="open-edit-form"
                            (click)="editForm()"
                            mat-icon-button>
                            <mat-icon svgIcon="edit"></mat-icon>
                        </button>
                        <button data-testid="delete-user" mat-icon-button
                            *ngIf="isNotItMe() && userCantEditOrDelete()"
                            (click)="deleteUser()">
                            <mat-icon
                                svgIcon="trash"></mat-icon>
                        </button>
                    </div>

                </div>

            </app-form>

        </div>

        <div class="footer-container">

            <mat-divider class="hidden-mobile" [inset]="true"></mat-divider>
            <div class="footer-button">

                <div *ngIf="filledInvitation; else showContinueButton"
                    class="buttonsContainerActions">
                    <button
                        *ngIf="!invitePending(); else showReSendInviteButton"
                        class="back-button" (click)="showUsersList()"
                        mat-stroked-button>
                        {{"BACK" | translate}}
                    </button>
                    <ng-template #showReSendInviteButton>
                        <button
                            class="back-button" (click)="showUsersList()"
                            mat-stroked-button>
                            {{"BACK" | translate}}
                        </button>
                        <button 
                        *ngIf="isUserAllowedToResendInvite()"
                            data-testid="resend-invite"
                            class="resend-button" (click)="reSendInvite()"
                            mat-flat-button color="primary">
                            {{"RESEND_INVITATION" | translate}}
                        </button>
                    </ng-template>
                </div>

                <ng-template #showContinueButton>
                    <button
                        class="back-button" (click)="showUsersList()"
                        mat-stroked-button>
                        {{"BACK" | translate}}
                    </button>
                    <button [disabled]="form.invalid" color="primary"
                        (click)="submitUserEdited()"
                        data-testid="done-edit-form"
                        class="continue-button"
                        mat-raised-button>
                        {{"CLOSE" | translate}}
                    </button>
                </ng-template>
            </div>
        </div>

    </main>
</div>
