import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { GlobalizationModule } from '../globalization/globalization.module';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { BaseUrlInterceptor } from './interceptors/base-url.interceptor';
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { RefreshTokenInterceptor } from './interceptors/refresh-token.interceptor';

import { HttpErrorInterceptorComponent } from './interceptors/http-error-interceptor';
import { GeolocationInterceptor } from './interceptors/geolocation.interceptor';
import { ChannelAuthorizationInterceptor } from './interceptors/channelAuthorization.interceptor';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    GlobalizationModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GeolocationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ChannelAuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorComponent, multi: true },

  ],
  exports: []
})
export class ApiModule { }
